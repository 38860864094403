import { useNavigate } from "react-router-dom";
import { getLocalStorage, setLocalStorage } from "../localStorageUtil";
import { PDP_REDIRECT_PATH } from "../../components/organisms/login/constant";
import { ASSISTANCE_PATH, CAFETERIA_CASH_REGISTERY, MANAGE_ORDER, SCAN_ROUTE_PATH } from "../../routes/routes-constant";
import { isCafeteriaManager, isStoreManager } from "../utlis";
import { doActionPost } from "../httpRequest";

const pdp_redirect_sku = sessionStorage.getItem('pdp_redirect_sku');

navigator?.serviceWorker?.addEventListener("message", (event) => {
  if (event.data.action === "redirect" && event.data.url) {
    window.location.href = event.data.url;
  }
});
export const requestPushPermission = async (cognitoUserId,fromNotifPage=false) => {
  if ('safari' in window && 'pushNotification' in window.safari) {
    await requestSafariPush(JSON.parse(cognitoUserId.toString()));
}
else if ('Notification' in window) {
  const permission = await window?.Notification?.requestPermission();
    console.log(permission);
   if (permission === "granted") {
    console.log("Push notification permission granted.");
    await subscribeToPushNotifications(JSON.parse(cognitoUserId.toString()));
  } else {
    console.error("Push notification permission denied.");
  }
  }
else {
    console.error('Notifications API not supported in this browser.');
  }
  // if(!fromNotifPage){
  //   if (getLocalStorage('access-token')) {
  //         if (isStoreManager()) window.location.href= ASSISTANCE_PATH
  //           else if(isCafeteriaManager()) window.location.href= CAFETERIA_CASH_REGISTERY
  //           else window.location.href= ASSISTANCE_PATH
  //   }
  //   else{
  //     if (pdp_redirect_sku) {
  //       window.location.href= PDP_REDIRECT_PATH(pdp_redirect_sku);
  //       sessionStorage.removeItem('pdp_redirect_sku')
  //     }
  //     else window.location.href=SCAN_ROUTE_PATH;
  //   }
  // }

};
async function requestSafariPush(cognitoUserId) {
  // Check the current notification permission state
  var permissionData = window.safari.pushNotification.permission(process.env.REACT_APP_WEB_PUSH_ID);

  if (permissionData.permission === 'default') {
      console.log("Requesting permission...");

      // Request permission and serve the push package from your backend
      window.safari.pushNotification.requestPermission(
          process.env.REACT_APP_WEB_PUSH_PACKAGE_PATH,  // Backend endpoint serving the push package
          process.env.REACT_APP_WEB_PUSH_ID,
          {},  // Optional user data
          async function (permission) {
              if (permission.permission === 'granted') {
                  console.log("Permission granted. Device token: " + permission.deviceToken);
                  await sendSubscriptionToServer(permission.deviceToken, cognitoUserId);
              } else if (permission.permission === 'denied') {
                  console.log("Permission denied.");
              } else {
                  console.log("Permission request status: " + permission.permission);
              }
          }
      );
  } else {
      console.log("Already granted or denied.");
  }
}

export const subscribeToPushNotifications = async (cognitoUserId) => {
  const swScope = getLocalStorage("swScope");
  if (swScope) {
    try {
      const registration = await navigator.serviceWorker.getRegistration(
        swScope
      );
      if (registration) {
        const subscription = await registration.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: process.env.REACT_APP_APPLICATION_SERVER_KEY,
        });
        console.log("Push subscription:", subscription);
        setLocalStorage(
          "push-notif-subscription",
          JSON.stringify(subscription)
        );
        await sendSubscriptionToServer(subscription, cognitoUserId);
      } else {
        console.error("Service Worker registration not found.");
      }
    } catch (error) {
      console.error("Error subscribing to push notifications:", error);
    }
  } else {
    console.error("Service Worker scope not found in localStorage.");
  }
};

const sendSubscriptionToServer = async (
  subscription,
  cognitoUserId
) => {
  if(process.env.REACT_APP_PUSH_NOTIFICATION_SUBSCRIPTION_API){
    try {
      doActionPost({
        url: process.env.REACT_APP_PUSH_NOTIFICATION_SUBSCRIPTION_API,
        data:
        { user_id: cognitoUserId,
          subscription: subscription,
        }
      })?.then((response) => {
        console.log("Subcription saved response ",response);
      })
      // const response = await fetch(
      //   `${process.env.REACT_APP_PUSH_NOTIFICATION_SUBSCRIPTION_API}`,
      //   {
      //     method: "POST",
      //     headers: {
      //       "Content-Type": "application/json",
      //     },
      //     body: JSON.stringify({
      //       user_id: cognitoUserId,
      //       subscription: subscription,
      //     }),
      //   }
      // );
      // if (response.ok) {
      //   console.log("Subscription saved successfully.");
      // } else {
      //   console.error("Failed to save subscription.");
      // }
    } catch (error) {
      console.error("Error sending subscription to server:", error);
    }
  }
  else{
    console.error("No url exists");
  }
  
};

const productURL = (sku = "") =>
  `${process.env.REACT_APP_API_URL}products/${sku}/`;
const productItemWithCustomerTokenURL = (itemId = "") =>
  `${process.env.REACT_APP_API_URL}carts/mine/items/${itemId}`;
const productItemWithEmployeeTokenURL = (cartId = "", itemId = "") =>
  `${process.env.REACT_APP_API_URL}carts/${cartId}/items/${itemId}`;
const productChildrenURL = (sku = "") =>
  `${process.env.REACT_APP_API_URL}configurable-products/${sku}/children`;
const quoteIdURL = `${process.env.REACT_APP_API_URL}carts/mine`;
const addToCartURL = `${process.env.REACT_APP_API_URL}carts/mine/items`;
const specialOrderAddToCartURL = (quote_id: string) =>
  `${process.env.REACT_APP_API_URL}carts/${quote_id}/items`;

const PDP_CONSTANTS = {
  add_to_cart: "Add to Cart",
  designer_story: "Discover designer story",
  shipping: "Delivery",
  use_and_care: "Use and Care",
  special_order: "Request a Special Order",
  product_not_found: "Product Not Found",
  product_info: "Product Info",
  out_of_stock: "This item is out of stock",
};

enum BUTTON_STATE {
  ADD_TO_CART = "ADD_TO_CART",
  DISABLED = "DISABLED",
  OUT_OF_STOCK = "OUT_OF_STOCK",
}

export {
  quoteIdURL,
  addToCartURL,
  specialOrderAddToCartURL,
  productURL,
  productItemWithEmployeeTokenURL,
  productItemWithCustomerTokenURL,
  productChildrenURL,
  PDP_CONSTANTS,
  BUTTON_STATE,
};

const LABELS = {
    Orders: "My Orders",
    Wishlist: "My Wish List",
    Profile: "My Profile",
    Logout: "Logout",
    Notifications:"My Notifications",
    Addresses:"My Addresses",
    Quote:"My Quotes"
}

export {LABELS}
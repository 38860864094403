import styled from "styled-components";
import MoreVertIcon from '../../../../../../../globals/svg/more-vert.svg';

interface AddressDetailsContainerProps {
  isExpanded?: boolean;
  shouldLimitHeight: boolean;
}

export const AddressCard = styled.div`
  background: var(--white);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin: 10px; // Gap between each card
  padding: 20px;
  padding-top: 40px;  // Adjusted to move content below the separator
  position: relative;
  overflow: hidden;

  &:before {
    content: '';
    display: block;
    height: 1px; // Thickness of the separator line
    background-color: var(--light-grey); // Color of the line
    width: 100%; // Line takes the full width of the card
    position: absolute;
    left: 0px;
    top: 40px; // Position the line below the top elements like labels or icons
  }

  .card-content {
    flex: 1; /* Takes up all available space pushing the button down */
  }

.toggle-details-button {
    align-self: center; /* Center the button horizontally */
    color: var(--sand-button-color);
    font-size: 12px;
    cursor: pointer;
    margin-top: 10px; /* Space between content and button */
}

.toggle-details-button:hover {
    color: var(--sand-button-hover);
}
`;

export const AddressDetailsContainer = styled.div<AddressDetailsContainerProps>`
  max-height: ${({ isExpanded, shouldLimitHeight }) => isExpanded ? 'none' : (shouldLimitHeight ? '150px' : 'none')};
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
  margin-top: 5px; // Adds a small gap after the separator
`;

export const CardTitle = styled.span`
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: var(--text-dark);
  // margin: 0 0 10px 0;
`;

export const CardText = styled.span`
  display: block;
  font-size: 14px;
  line-height: 21px;
  color: var(--text-dark);
  white-space: normal; // Ensure text wraps
  overflow-wrap: break-word; // Break words that are too long
`;

export const OptionsIcon = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 15px;
  height: 20px;
  background: url(${MoreVertIcon}) no-repeat center center;
  background-size: contain;
  cursor: pointer;
  z-index: 2; // Ensure it's above the text
`;

// For Menu items (if we're using MUI Menu for the 3-dot icon)
export const MenuItem = styled.div`
  padding: 10px;
  cursor: pointer;
  &:hover {
    background-color: var(--light-grey);
  }
`;

  export const DefaultLabel = styled.div`
  width: 70px;
  text-align: center;
  padding: 3px 6px;
  background-color: var(--sand-button-color);
  color: var(--white);
  font-family: 'Poppins', sans-serif;
  font-size: 8px;
  font-weight: bold;
  border-radius: 5px;
  position: absolute;
  top: 10px;
  // right: 25px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); /* subtle shadow for depth */
  z-index: 1;
`;

export const ReadMoreButton = styled.button`
  background: none;
  border: none;
  color: var(--sand-button-color);
  cursor: pointer;
  padding: 10px;
  &:hover {
    text-decoration: underline;
  }
`;

import React from 'react';
import { Box, Divider, Grid } from '@mui/material';
import { getStaticConstantValue } from '../../../../../helpers/utlis';
import { PLP_CAFETERIA_MENU_PAGE_ROUTE_PATH, ASSISTANCE_PATH } from '../../../../../routes/routes-constant';
import { FullWidthButton, LogoImage, LogoLinksContainer, boxStyle, dividerStyle, gridItemStyle } from './styles/styles';
import CDPLogo from '../../../../../globals/images/employeeDashboard/svg/CDP_Logo.svg';
import ERPLogo from '../../../../../globals/images/employeeDashboard/svg/ERP_Logo.svg';
import { EMPLOYEE_CTA_CONSTANTS, EMPLOYEE_ROLE_CONSTANTS } from '../../constant';

const LogoLinks: React.FC = () => {
    const handleRedirect = (url: string) => {
        window.location.href = url;
    };

    const role = getStaticConstantValue('requested_by');
    // const showConditionalDivider = role === EMPLOYEE_ROLE_CONSTANTS.cafeteria_manager.en || role === EMPLOYEE_ROLE_CONSTANTS.store_manager.en;

    return (
        <LogoLinksContainer>
            <Box sx={boxStyle}>
                <Grid container spacing={2} justifyContent="center" alignItems="center">
                    <Grid item xs={6} sm={6} sx={gridItemStyle}>
                        <LogoImage
                            src={CDPLogo}
                            alt="CDP Logo"
                            onClick={() => handleRedirect('https://cdp-url.com')}
                        />
                    </Grid>
                    <Grid item xs={6} sm={6} sx={gridItemStyle}>
                        <LogoImage
                            src={ERPLogo}
                            alt="ERP Logo"
                            onClick={() => handleRedirect('https://erp-url.com')}
                        />
                    </Grid>
                </Grid>
            </Box>
            <Divider sx={dividerStyle} />
            <Box sx={boxStyle}>
                <Grid container spacing={2} justifyContent="center">
                    {role === EMPLOYEE_ROLE_CONSTANTS.cafeteria_manager.en && (
                        <Grid item xs={12} sm={6}>
                            <FullWidthButton
                                variant="contained"
                                onClick={() => handleRedirect(PLP_CAFETERIA_MENU_PAGE_ROUTE_PATH)}
                            >
                                {EMPLOYEE_CTA_CONSTANTS.cafeteria_flow.en}
                            </FullWidthButton>
                        </Grid>
                    )}
                    {role !== EMPLOYEE_ROLE_CONSTANTS.cafeteria_manager.en && (
                        <Grid item xs={12} sm={6}>
                            <FullWidthButton
                                variant="contained"
                                onClick={() => handleRedirect(ASSISTANCE_PATH)}
                            >
                                {EMPLOYEE_CTA_CONSTANTS.normal_flow.en}
                            </FullWidthButton>
                        </Grid>
                    )}
                </Grid>
            </Box>
        </LogoLinksContainer>
    );
};

export default LogoLinks;

import styled, { css } from "styled-components";

export const Content = styled.div<any>`
  ${(p: any) => {
    return css`
      background: #f8f7f2;
    .title {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 110%;
    color: #6f6855;
    padding: 10px 20px;
    }

    .no-item-text {
    margin: 20px;
    span {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 110%;
        color: #6f6855;
    }
    }
    .order {
        background: white;
        background: white;
        margin: 20px 0;
        display: flex;
        flex-direction: column;
        padding: 10px 20px;

        .title-line {
          display: flex;
          width: 100%;
          align-items: center;
          justify-content: space-between;
          line-height: 0px;
          .orderId{
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            color: #1c1c1c;
          }
          span {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            color: #1c1c1c;
          }
          p {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            color: #6f6855;
          }
        }

        .status-line {
          display: flex;
          width: 100%;
          -webkit-box-align: center;
          align-items: center;
          -webkit-box-pack: justify;
          justify-content: flex-start;
          line-height: 0px;
          span {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            color: #1c1c1c;
          }
          p {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            color: #b6b2a6;
            margin-left: 10px;
          }
        }

        .item-container {
          .button-container {
            display: flex;
            margin: 10px 0;
            justify-content: flex-start;
            line-height: 15px;
            .cancel-return-btn {
              box-sizing: border-box;
              // padding: 8px 16px;
              width: 124px;
              height: 31px;
              border: 1px solid #6f6855;
              background: white;
              filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.05));
              border-radius: 10px;
              font-family: "Poppins";
              font-style: normal;
              font-weight: 500;
              font-size: 10px;
              text-align: center;
              color: #6f6855;
              margin-left: 2px;
            }
            .order-detail-btn {
              border-radius: 10px;
              width: 124px;
              height: 31px;
              font-family: "Poppins";
              font-style: normal;
              font-weight: 500;
              font-size: 10px;
              text-align: center;
              background: #6f6855;
              border: none;
              color: white;
              margin-left: 2px;
            }
  }

    `;
  }}
`;

import React, { useEffect, useState } from "react";
import DeliveryAddress from "./deliveryAddress";
import BillingAddress from "./billingAddress";
import ShipmentMethod from "./shipmentMethod";
import apiList, { ALERT_STATUS, CONITNUE_BUTTON_LABEL, SELECT_BILLING_ADDRESS, SELECT_SHIPMENT_ADDRESS, SELECT_SHIPMENT_METHOD, SOMETHING_WENT_WRONG, USER_TYPE } from "../../../../lib/constant";
import { gtmDataConstructor } from "../helper";
import { doActionGet, doActionPost, doActionPut } from "../../../../helpers/httpRequest";
import { useNotification } from "../../../../hooks/useNotification";
import { Address } from "../../my-account/my-profile/types/types";
import { deliveryMethodProps, newAddressFormDataProps } from "../types/types";
import { setLocalStorage } from "../../../../helpers/localStorageUtil";
import { useNavigate } from "react-router-dom";
import { CHECKOUT_ORDER_CONFIRMATION_PAGE, MY_ACCOUNT_EDIT_PROFILE_PAGE_ROUTE_PATH } from "../../../../routes/routes-constant";
import { Loading } from "../../../atoms/loading/Loading";


const CheckoutAddressStep: React.FC = () => {
    const [isAPICallRunning,setIsAPICallRunning] =useState(false);
    const { showAlert } = useNotification();
    const [addresses,setAddresses] =useState<Address[]>([]);
    const [countryList , setCountryList] = useState([]);
    const [selectedShipId,setSelectedShipId]= useState(0);
    const [selectedBillToId,setSelectedBillToId]= useState(0);
    const [isBillingSame,setIsBillingSame] = useState<Boolean>(true);
    const [availableShipmentMethods, setAvailableShipmentMethods] =useState<deliveryMethodProps[]>([]);
    const [selectedCarrierCode , setSelectedCarrierCode] =useState<string>("");
    const [selectedMethodCode,setSelectedMethodCode] =useState<string>("");
    const [isGift,setIsGift]= useState<Boolean>(false);
    const [carrierTitle,setCarrierTitle] =useState<string>("");
    const navigate = useNavigate();
    const [isBillingExpand,setIsBillingExpand] = useState(false);
    const [isDeliveryExpand,setIsDeliveryExpand] = useState(false);
    
    useEffect(()=>{
        const fetchData = async()=> {
        await fetchCountryList();
        await fetchCartData();
        }
        fetchData();
        gtmDataConstructor("begin_checkout");
      },[])
    
    useEffect(()=>{
        if(selectedShipId!=0 && addresses?.length>0){
            let selectedShipmentAddressList =addresses?.filter((address)=>{
                return address?.id===selectedShipId;
            })
            if(selectedShipmentAddressList &&selectedShipmentAddressList?.length>0){
                const transformedObject = {
                    ...selectedShipmentAddressList[0],
                    region_id: selectedShipmentAddressList[0]?.region_id,
                    region_code: selectedShipmentAddressList[0]?.region_code,
                    region: selectedShipmentAddressList[0]?.region?.region
                  }
                delete transformedObject?.default_shipping;
                delete transformedObject?.default_billing;
                setIsAPICallRunning(true);
                doActionPost({
                  url: apiList.estimateShippingAPI,
                  data:
                  {
                    "address": transformedObject
                  },
                  userType:USER_TYPE.Customer
                })?.then((resp: any) => {
                   setAvailableShipmentMethods(arrangeShippingOptions(resp?.data));
                   if(carrierTitle){
                    let availablePaymentList = resp?.data?.filter((resp:any)=>{
                        return resp?.carrier_title===carrierTitle;
                    })
                    if(availablePaymentList && availablePaymentList?.length>0){
                        setSelectedMethodCode(availablePaymentList[0].method_code);
                        setSelectedCarrierCode(availablePaymentList[0].carrier_code);
                    }
                   }
                }
                ).catch((error: any) => {
                  console.error("error msg", error);
                  showAlert(SOMETHING_WENT_WRONG, ALERT_STATUS.error);
                }).finally(()=>{
                  setIsAPICallRunning(false);
                })
            }
           
        }
    },[selectedShipId])
    
    const fetchCartData=async()=>{
        setIsAPICallRunning(true);
        doActionGet({
          url: apiList.getcartDetails,
          userType:USER_TYPE.Customer
        })?.then((resp: any) => {
            console.log("address",resp?.data);
            const shipmentData = resp?.data?.extension_attributes?.shipping_assignments??[];
            if(shipmentData && shipmentData?.length>0 ){
                setSelectedShipId(Number(shipmentData[0]?.shipping?.address?.customer_address_id));
                if(!shipmentData[0]?.shipping?.address?.country_id){
                    setSelectedBillToId(Number(shipmentData[0]?.shipping?.address?.customer_address_id));
                }
                else{
                    setSelectedBillToId(Number(resp?.data?.billing_address?.customer_address_id));
                }
            }
            setIsGift(resp?.data?.extension_attributes?.gift?resp?.data?.extension_attributes?.gift:false);
            setAddresses(resp?.data?.customer?.addresses);
            setCarrierTitle(resp?.data?.extension_attributes?.delivery_method?.split(" - ")[0]);
        }
        ).catch((error: any) => {
          console.log("error msg", error);
          showAlert(SOMETHING_WENT_WRONG, ALERT_STATUS.error);
        }).finally(()=>{
          setIsAPICallRunning(false);
        })
    }

    const fetchCountryList=async()=>{
        setIsAPICallRunning(true);
        doActionGet({
          url: apiList.getCountries,
        })?.then((resp: any) => {
            console.log("country ",resp?.data);
            setCountryList(resp?.data);
        }
        ).catch((error: any) => {
          console.log("error msg", error);
          showAlert(SOMETHING_WENT_WRONG, ALERT_STATUS.error);
        }).finally(()=>{
          setIsAPICallRunning(false);
        })
    }

    const selectShipToHandler=(selectedId:Number)=>{
        setSelectedShipId(Number(selectedId));
    }

    const selectBillToHandler=(selectedId:Number)=>{
        setSelectedBillToId(Number(selectedId));
    }

    const onBillingSameAsShippingChangeHandler=(isSame:Boolean)=>{
        setIsBillingSame(isSame);
    }

    const arrangeShippingOptions = (options: deliveryMethodProps[]): deliveryMethodProps[] => {
        return options?.sort((a, b) => {
            if (a?.method_code === "clickandcollectshipping") return -1;
            if (b?.method_code === "clickandcollectshipping") return 1;
            return 0;
        });
    };

    const shipmentMethodChangeHandler = (item:deliveryMethodProps)=>{
        setSelectedCarrierCode(item.carrier_code);
        setSelectedMethodCode(item.method_code);
        setCarrierTitle(item?.carrier_title);
    }

    const giftCardChangeHandler = (isGift:Boolean)=>{
        setIsGift(isGift);
    }

    const setGiftCard=()=>{
        setIsAPICallRunning(true);
        doActionPut({
            url: apiList.setGiftCart+Number(isGift),
            userType:USER_TYPE.Customer
          })?.then((resp: any) => {
          }
          ).catch((error: any) => {
            console.error("error msg", error);
            showAlert(SOMETHING_WENT_WRONG, ALERT_STATUS.error);
          }).finally(()=>{
            setIsAPICallRunning(false);
          })
    }

    const checkoutHandler=async()=>{
        if(selectedShipId===0){
            showAlert(SELECT_SHIPMENT_ADDRESS,ALERT_STATUS.error);
        }
        else if(selectedBillToId===0){
            showAlert(SELECT_BILLING_ADDRESS,ALERT_STATUS.error);
        }
        else if(!(selectedCarrierCode && selectedMethodCode)){
            showAlert(SELECT_SHIPMENT_METHOD,ALERT_STATUS.error)
        }
        else{
            setGiftCard();
            let selectedShipmentAddressList =addresses?.filter((address)=>{
                return address?.id===selectedShipId;
            });
            let selectedBillingAddressList =isBillingSame ?selectedShipmentAddressList:addresses?.filter((address)=>{
                return address?.id===selectedBillToId;
            });
            setIsAPICallRunning(true);
            doActionPost({
                url: apiList.setShippingInformation,
                data:
                {
                  "addressInformation": {
                    "shipping_address":selectedShipmentAddressList?.length>0?addressFormer(selectedShipmentAddressList[0]):{},
                    "billing_address":selectedBillingAddressList?.length>0?addressFormer(selectedBillingAddressList[0]):{},
                    "shipping_carrier_code": selectedCarrierCode,
                    "shipping_method_code": selectedMethodCode
                  }
                },
                userType:USER_TYPE.Customer
              })?.then((resp: any) => {
                 setLocalStorage("grand_total",JSON.stringify(resp?.data?.totals?.grand_total));
                 gtmDataConstructor("add_shipping_info");
                 doActionGet({ url: apiList.customerDetails,userType:USER_TYPE.Customer})?.then((resp:any)=>{
                  if(resp?.data?.firstname && resp?.data?.lastname && resp?.data?.prefix){
                    navigate(CHECKOUT_ORDER_CONFIRMATION_PAGE)
                  }
                  else{
                    navigate(MY_ACCOUNT_EDIT_PROFILE_PAGE_ROUTE_PATH,{state:{fromCheckoutPage:true}})
                  }
                 }).catch((error: any) => {
                  console.log("error msg", error);
                  showAlert(SOMETHING_WENT_WRONG, ALERT_STATUS.error);
                })

                 //navigate(CHECKOUT_ORDER_CONFIRMATION_PAGE)
              }
              ).catch((error: any) => {
                console.log("error msg", error);
                showAlert(SOMETHING_WENT_WRONG, ALERT_STATUS.error);
              }).finally(()=>{
                setIsAPICallRunning(false);
              })
        }
    } 

    const addressFormer=(address:Address)=>{
        const transformedObject = {
            ...address,
            region_id: address?.region_id,
            region_code: address?.region_code,
            region: address?.region?.region
          }
          transformedObject.customer_address_id=address?.id;
        delete transformedObject?.default_shipping;
        delete transformedObject?.default_billing;
        delete transformedObject?.id;
        return transformedObject;
    }

    const newAddressSaveHandler= (formData:newAddressFormDataProps)=>{
        setIsAPICallRunning(true);
        let payload = {
            "address":{
                "street":formData?.street,
                "city":formData?.city,
                "region":{"region_id":formData?.region?.region_id,"region":formData?.region?.region,"region_code":formData?.region?.region_code},
                "postcode":formData?.postcode,
                "country_id":formData?.country_id,
                "region_id":formData?.region?.id,
                "firstname":formData?.firstname,
                "lastname":formData?.lastname,
                "default_shipping":false,
                "default_billing":false,
                "telephone":formData?.telephone
            }
        };
        doActionPost({ url: apiList.customerAddress, userType: USER_TYPE.Customer, data: payload })?.then((resp)=>{
            addresses.push(resp?.data);
            setSelectedShipId(Number(resp?.data?.id));
        })?.catch((error: any) => {
            console.log("error msg", error);
            showAlert(SOMETHING_WENT_WRONG, ALERT_STATUS.error);
          }).finally(()=>{
            setIsAPICallRunning(false);
          })
    }
    const newBillingAddressSaveHandler= (formData:newAddressFormDataProps)=>{
        setIsAPICallRunning(true);
        let payload = {
            "address":{
                "street":formData?.street,
                "city":formData?.city,
               "region":{"region_id":formData?.region?.region_id,"region":formData?.region?.region,"region_code":formData?.region?.region_code},
                "postcode":formData?.postcode,
                "country_id":formData?.country_id,
                "region_id":formData?.region?.id,
                "firstname":formData?.firstname,
                "lastname":formData?.lastname,
                "default_shipping":false,
                "default_billing":false,
                "telephone":formData?.telephone
            }
        };
        doActionPost({ url: apiList.customerAddress, userType: USER_TYPE.Customer, data: payload })?.then((resp)=>{
            
            addresses.push(resp?.data);
            setSelectedBillToId(Number(resp?.data?.id));
        })?.catch((error: any) => {
            console.log("error msg", error);
            showAlert(SOMETHING_WENT_WRONG, ALERT_STATUS.error);
          }).finally(()=>{
            setIsAPICallRunning(false);
          })
    }

    const deliveryExpandHandler =(isExpanded:boolean)=>{
      if(isExpanded){
        setIsDeliveryExpand(true);
        setIsBillingExpand(false);
      }
      else{
        setIsDeliveryExpand(false);
      }
    }
    const billingExpandHandler =(isExpanded:boolean)=>{
      if(isExpanded){
        setIsDeliveryExpand(false);
        setIsBillingExpand(true);
      }
      else setIsBillingExpand(false);
    }


 
    return (
        <div className="customer-checkout">
           {isAPICallRunning && <Loading/>}
        <DeliveryAddress addressList={addresses} selectedShipToId={selectedShipId} onChange={selectShipToHandler} countryData={countryList} newAddressSaveHandler={newAddressSaveHandler} isDeliveryExpand={isDeliveryExpand} expandChangeHandler={deliveryExpandHandler} />
        <BillingAddress addressList={addresses} selectedBillToId={selectedBillToId} onChange={selectBillToHandler} countryData={countryList} onBillingSameAsShippingChangeHandler={onBillingSameAsShippingChangeHandler} newAddressSaveHandler={newBillingAddressSaveHandler} isBillingExpand={isBillingExpand} expandChangeHandler={billingExpandHandler}/>
        <ShipmentMethod shipmentMethodList={availableShipmentMethods} carrierTitle={carrierTitle} onChange={shipmentMethodChangeHandler} changeGiftHandler={giftCardChangeHandler} isGiftOrder={isGift}/>
        <div className="button-section continue-button">
            <button className="save-button" type="button" onClick={checkoutHandler}>{CONITNUE_BUTTON_LABEL}</button>
         </div>
        </div>
  );
};

export default CheckoutAddressStep;
import styled from "styled-components";

export const Content = styled.div`

    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    word-break: break-word;
    overflow-wrap: break-word;    

    .video-list {
        text-align: left;
    }

    .file-link {
        display: block;
        margin-top: 8px;
        cursor: pointer;
    }

    .listItem {
        align-items: flex-start;
    }

    .dialog-content {
        padding: 0px;
    }

    .title {
        display: flex;
        align-items: center;
        color: var(--dark-black);
        font-size: 16px;
        font-weight: 500;
        line-height: 17.6px;
    }

    .view-cta {
        display: flex;
        justify-content: end;
        color: var(--sand);
        font-size: 14px;
        font-weight: 600;
    }

    .link {
        color: var(--sand);
        font-size: 14px;
        font-weight: 600;
    }

`
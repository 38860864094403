import { CartTotal } from "../special-cart/types/types";

export const getCostInfo = (info: CartTotal[] = []) => {
  let costs: { [key: string]: number } = {};

  info.forEach((item) => {
    if (item.code) {
      const key = String(item.code) ?? "";
      costs[key] = item.value ?? 0;
    }
  });

  return costs;
};

import styled, { css } from "styled-components";

export const Content = styled.div<any>`
  ${(p: any) => {
    return css`
      .order-detail-page {
        height: 80vh;
        overflow: scroll;
      }
        .cancel-return-btn {
              box-sizing: border-box;
              // padding: 8px 16px;
                     padding-right: 10px;
    padding-left: 10px;
              height: 31px;
              border: 1px solid #6f6855;
              background: white;
              filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.05));
              border-radius: 10px;
              font-family: "Poppins";
              font-style: normal;
              font-weight: 500;
              font-size: 10px;
              text-align: center;
              color: #6f6855;
              margin-left: 2px;
            }
      .my-orders-heading{
      font-weight:400 !important;
      font-size:14px !important;
      line-height:20.8px !important;
      color:#6F6855 !important;
      margin-top:32px;
      }
      .order-detail-btn {
              border-radius: 10px;
                  padding-right: 10px;
    padding-left: 10px;
              height: 31px;
              font-family: "Poppins";
              font-style: normal;
              font-weight: 500;
              font-size: 10px;
              text-align: center;
              background: #6f6855;
              border: none;
              color: white;
              margin-left: 2px;
            }
      .tab-container{
      margin-bottom:24px;
      }
      .order-detail-section{
      background-color:white;
      padding-top:16px;
      padding-left:30px;
      padding-right:24px;
      }
      .formated-date{
       font-weight:400;
      font-size:16px;
      line-height:20.8px ;
      margin-bottom:24px;
      }
      .formated-orderid{
      font-weight:600;
      font-size:16px;
      line-height:20.8px ;
      margin-bottom:24px;
      }
      .order-status-heading{
       font-weight:500;
      font-size:16px;
      line-height:17.6px ;
      color:#1C1C1C;
      }
      .status-value{
      font-weight:500;
      font-size:16px;
      line-height:17.6px ;
      color:#6F6855;
      margin-left:10px;
      }
       .view-pay-button {
        background-color: var(--sand-button-color);
        color: white;
        font-size: 16px;
        border: none;
        padding: 11px 52px 11px 52px;
        border-radius: 100px;
        cursor: pointer;
        margin-top:24px;
        margin-bottom:24px;
        margin-left:auto;
        margin-right:auto;
        display:flex;
      height:50px;
      font-weight:600;
      line-height:24px;

      }
       
      .total-breakup{
      display:flex;
      flex-direction:row;
      
      }
      .view-pay-button:hover {
        background-color: var(--sand-button-color-on-hover);
      }
      .heading {
        color: var(--grey-text);
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.8px;
        padding: 20px;
      }
      .info {
        color: var(--grey-text);
        font-family: Poppins;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        padding: 0 20px;
      }
      .text1 {
        color: #6F6855;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 18.2px;
        margin-bottom:5px;
      }
        .customer-detail-value{
         color: #B6B2A6;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 18.2px;
        margin-left:2px;
        }
      .variant-data {
        color: var(--sku-font-color);
      }
      .invoice-section {
        padding-left: 20px;
      }
        .invoice-div-section{
            box-shadow: 0px 6px 10px rgba(0, 0, 0, 1);
        }
      .sub-data-heading{
      font-weight:500;
      }
      .title {
        font-weight: 700;
      }
        .newTittle{
        font-size: 10px;
        font-style: normal;
        font-weight: 700;
        line-height: 15px;
        color:#6F6855;
        }
        .order-information{
           font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: 15px;
        color:#1C1C1C;
        }
      .table-info {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
        align-items: baseline;
      }
      .table-info-margin {
        margin-top: 20px;
      }
      .bottom-border {
        border-bottom: 1px solid #979797;
      }
      .text2 {
        color: var(--text-dark);
        font-family: Poppins;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: 15px;
        letter-spacing: 0.5px;
        color:#1C1C1C;
      }
        .final-section{
        margin-bottom:24px;
        padding-bottom:16px;
        }
      .value {
        color: #B6B2A6;
      }
      .product-table {
        width: 100%;
        border-collapse: collapse;
      }
      .product-table thead,
      tbody {
        width: 100%;
      }
      .product-table th,
      .product-table td {
        padding: 8px;
        text-align: center;
      }
      .product-table td {
        word-wrap: break-word;
      }
      .product-table th:nth-child(1),
      .product-table td:nth-child(1) {
        width: 30%;
        align-items: center;
      justify-content: center;
      display: flex;
          flex-direction: column;
      }
      .product-table th:nth-child(2),
      .product-table td:nth-child(2) {
        width: 20%;
      }
      .product-table th:nth-child(3),
      .product-table td:nth-child(3) {
        width: 20%;
      }
      .product-table th:nth-child(4),
      .product-table td:nth-child(4) {
        width: 10%;
      }
      .product-table th:nth-child(5),
      .product-table td:nth-child(5) {
        width: 20%;
      }
      .payment-details {
        display: flex;
        justify-content: flex-end;
        padding: 10px 20px;
      }
        .text3{
         color: #1C1C1C;
        font-family: Poppins;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: 15px;
        margin-bottom:5px;
        }
        .payment-summary-table{
           padding-right: 20px;
            padding-left: 15px;
            padding-top:10px;
            width: 100%;
        }
        .payment-summary-header{
        display:grid;
        grid-template-columns:1fr 1fr 1fr;
        }
      .final-payment {
        display: flex;
        background: #6F6855;
        justify-content: space-between;
        margin-top:20px;
        padding:2px 24px 2px 24px;
        color:white;
      }
        .payment-text{
        margin-top:auto;
        margin-bottom:auto;
        color:white;
        font-size: 10px;
        font-style: normal;
        font-weight: 700;
        line-height: 15px;
        }
        .detail-heading{
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 17.6px;
        margin-top:20px;
        margin-bottom:20px;
        }
        .payment-method-list{
            align-items: center;
            justify-content: space-between;
            display:grid;
            grid-template-columns: 2fr 1.5fr 1.5fr 1fr 1fr;
            flex-direction:row;
            margin-bottom: 20px;
        }
        .payment-title{
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: 15px;
        }
        .payment-section{
         background: #d8d8d8;
          border: 2px solid #979797;
        }
      .shipment-summary{
      display:flex;
          flex-direction: column;
      }
      .method-name-section{
         display: flex;
        align-items: center;
        column-gap: 70px;
      }
      .payment-input{
      margin-left: 3px;
    margin-bottom: 17px;
    margin-top: 18px;
    width: 100%;
    font-weight: 500;
    font-size: 10px;
    line-height: 15px;
    color: rgb(182, 178, 166);
    padding-left: 9px;
    padding-top: 8px;
    padding-bottom: 8px;
   
      }
    .update-button-section{
       height: 38px;
    border-radius: 100px;
    padding: 7px 52px;
    border: none;
    margin-bottom: 13px;
     background-color: var(--sand-button-color);
     color:white;
    }
      .customer-details {
      margin-top:16px;
      padding-bottom:24px;
      }
      .order-status {
        padding: 5px 35vw;
      }
      .amount-breakup {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-right: 40px;
      }
       .amount-value-breakup {
      display: flex;
    flex-direction: column;
    align-items: flex-end;
      }
      .shipment-table {
        width: 100%;
        border-collapse: collapse;
      }
      .shipment-table thead {
        color: var(--grey-text);
        font-family: Poppins;
        font-size: 8px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        border-bottom: 1px solid#7A7A7A;
      }
      .shipment-table thead,
      .shipment-table tbody {
        width: 100%;
      }
      .shipment-table th,
      .shipment-table td {
        text-align: left;
      }
      .shipment-table td {
        overflow-wrap: anywhere;
        color: var(--text-dark);
        font-family: Poppins;
        font-size: 8px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      .shipment-table th:nth-child(1),
      .shipment-table td:nth-child(1) {
        width: 30%;
      }
      .shipment-table th:nth-child(2),
      .shipment-table td:nth-child(2) {
        width: 30%;
      }
      .shipment-table th:nth-child(3),
      .shipment-table td:nth-child(3) {
        width: 20%;
      }
      .shipment-table th:nth-child(4),
      .shipment-table td:nth-child(4) {
        width: 20%;
      }
      .shipment-table td:nth-child(1) {
        //padding-left: 20px;
      }
      .shipment-table td:nth-child(4) {
        //padding-right: 20px;
      }
      .shipment-table th:nth-child(1) {
        //padding-left: 20px;
      }
      .shipment-table th:nth-child(4) {
        //padding-right: 20px;
      }
          .moveToCartBtn{
        background:#FFFFFF;
        position: sticky;
        bottom: 0px;
        left: 0;
        height:32px;
        width:170px;
        border:1px solid #FFFFFF;
        margin-bottom:34px;
        margin-right: auto;
        text-align: center;
        margin-left: auto;
        
    }
    .moveToCartBtn button{
     color: var(--white);
    text-align: center;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    background: rgb(111, 104, 85);
    border-radius: 100px;
    border: none;
    padding: 5px;
    width: 100%;
    height:50px;
    align-self: center;
}
    }
    .moveToCartBtn button:disabled {
        color:#B9B9B9;
        background:#F6F6F6;
    }
        .horiz-line{
            border-bottom: 1px solid rgb(151, 151, 151);
            margin-bottom:24px;
            margin-top:24px;
        }
            .tab-container {
        padding-bottom: 20px;
        & .MuiTabs-indicator {
          position: absolute;
          height: 4px;
          bottom: 4px;
          background: rgb(111, 104, 85);
          border-radius: 0px 0px 6px 6px;
          transform: rotate(180deg) translateX(-55%);
          width: 15% !important;
        }
      }
      .tab-label {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 130%;
        letter-spacing: 0.03em;
        text-transform: none;
        &.tab-label-selected {
          color: #1c1c1c;
          font-weight: 500;
        }
      }
        

              .no-item-text {
        margin: 20px;
        span {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 110%;
          color: #6f6855;
        }
      }
    `;
  }}
`;

import { getCurrentUser, signOut } from 'aws-amplify/auth';
import { clearPreviousLoginData, getLocalStorage, removeLocalStorage } from '../helpers/localStorageUtil';
import { LOGOUT_CONSTANTS } from '../components/organisms/logout/constant';
import { AlertColor } from '@mui/material';
import { ALERT_STATUS, LOGOUT_MESSAGES, LOGOUT_USER_CONSTANT_URL } from '../lib/constant';
import { AccountInfo, IPublicClientApplication } from '@azure/msal-browser';
import { logoutFromMsAD } from '../authConfig';
import { COGNITO_MSAD_LOGOUT_URL } from '../awsEmployeeConfig';
import { doActionPut } from './httpRequest';
import { manageIndexedDB } from './indexedDB';

type AlertFunction = ((message: string, severity?: AlertColor | undefined) => void);

export const performCustomerLogout = async (showAlert?: AlertFunction): Promise<void> => {

    const redirectUri = `${process.env.REACT_APP_POOL_REDIRECT_SIGN_OUT}`;

    const logoutUrl: string = `${LOGOUT_USER_CONSTANT_URL}&logout_uri=${encodeURIComponent(redirectUri)}`;

    try {
        await signOut();
        await removeNotificationSubscription();
        const token = getLocalStorage('user-token');
        if (token) {
            removeLocalStorage('user-token');
            manageIndexedDB({ key: 'userToken', toDelete: true })
        }
        clearPreviousLoginData("Customer");

        window.location.href = logoutUrl;
    } catch (error) {
        const errorObj = error instanceof Error ? error : new Error(LOGOUT_MESSAGES.error_messages.unknown_error.en);
        if (errorObj.message.includes(LOGOUT_MESSAGES.error_messages.no_user_found.en) || errorObj.message.includes(LOGOUT_MESSAGES.error_messages.user_not_authenticated.en) || errorObj.message.includes(LOGOUT_MESSAGES.error_messages.authentication_needed.en)) {
            showAlert?.(LOGOUT_CONSTANTS.already_logged_out.en, ALERT_STATUS.info);
            removeLocalStorage('user-token');
            manageIndexedDB({ key: 'userToken', toDelete: true })
            window.location.href = logoutUrl;
        } else {
            showAlert?.(LOGOUT_CONSTANTS.logout_failed.en, ALERT_STATUS.error);
        }
    }
};

export const performEmployeeLogoutFromMSAD = async (
    instance: IPublicClientApplication,
    accounts: AccountInfo[],
    showAlert?: AlertFunction
): Promise<void> => {
    try {
        // We Assumed only single sign-on is used (a single account for global login); we have to adjust the logic for multiple accounts can be signed in (In case, If required, in future)
        if (accounts.length === 0) {
            showAlert?.(LOGOUT_MESSAGES.error_messages.no_signed_in_user_found.en, ALERT_STATUS.error);
            return;
        }

        const currentAccount = instance.getAccountByHomeId(accounts[0].homeAccountId);
        console.log(currentAccount);
        if (!currentAccount) {
            showAlert?.(LOGOUT_MESSAGES.error_messages.session_not_found.en, ALERT_STATUS.error);
            console.error("No account found for given homeAccountId");
            return;
        }

        const email = currentAccount.username;
        if (!email) {
            showAlert?.(LOGOUT_MESSAGES.error_messages.email_not_found.en, ALERT_STATUS.error);
            return;
        }

        // Redirect for logout
        window.location.href = logoutFromMsAD(email);

        // Clears the employee token stored in PWA
        clearPreviousLoginData("Employee");
    } catch (error) {
        console.error("Logout failed:", error);
        showAlert?.(LOGOUT_MESSAGES.error_messages.logout_failed.en, ALERT_STATUS.error);
    }
};


export const removeNotificationSubscription = async () => {
    if (process.env.REACT_APP_PUSH_NOTIFICATION_SUBSCRIPTION_API && getLocalStorage('push-notif-subscription')) {
        doActionPut({
            url: process.env.REACT_APP_PUSH_NOTIFICATION_SUBSCRIPTION_API,
            data:
            {
                "user_id": JSON.parse(getLocalStorage('cognitoId')),
                "sub_token": extractAfterSend(JSON.parse(getLocalStorage('push-notif-subscription'))?.endpoint)
            }
        })?.then((resp: any) => {
            console.log("Push notification unsubsribed successfully");
        }
        ).catch((error: any) => {
            console.error("error msg", error);
        }).finally(() => {
            removeLocalStorage('push-notif-subscription');
        })
    }
}

function extractAfterSend(url: string) {
    const searchString = "send/";
    const index = url.indexOf(searchString);
    if (index !== -1) {
        return url.substring(index + searchString.length);
    }
    return null;
}

export const performEmployeeLogout = async (
    showAlert?: AlertFunction
): Promise<void> => {
    try {
        clearPreviousLoginData("Employee");

        await removeNotificationSubscription();
        removeLocalStorage("swScope");
        //Logout From Microsoft First and then Redirect to Cognito logout
        window.location.href = COGNITO_MSAD_LOGOUT_URL;

    } catch (error) {
        console.error("Logout failed:", error);
        showAlert?.(LOGOUT_MESSAGES.error_messages.logout_failed.en, ALERT_STATUS.error);
    }
};

export function generatePassword(length = 12) {
    const uppercase = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const lowercase = "abcdefghijklmnopqrstuvwxyz";
    const numbers = "0123456789";
    const symbols = "!@#$%^&*()_+[]{}|;:,.<>?";
    
    // Ensure at least one character of each type
    let password = "";
    password += uppercase[Math.floor(Math.random() * uppercase.length)];
    password += lowercase[Math.floor(Math.random() * lowercase.length)];
    password += numbers[Math.floor(Math.random() * numbers.length)];
    password += symbols[Math.floor(Math.random() * symbols.length)];
    
    const allCharacters = uppercase + lowercase + numbers + symbols;
    for (let i = password.length; i < length; i++) {
      password += allCharacters[Math.floor(Math.random() * allCharacters.length)];
    }
    
    return password.split('').sort(() => 0.5 - Math.random()).join('');
  }
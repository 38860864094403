import styled, { css } from "styled-components";
import PencilIconForEdit from '../../../../../../../globals/svg/pencil-icon.svg'

export const Content = styled.div<any>`
  ${(p: any) => {
        return css`

    background-color: var(--background-light);
    
    .checkout-header{
        display:flex;
        height:56px;
        display: flex;
        -webkit-box-pack: justify;
        justify-content: space-between;
    }
    .headertext{
      align-content: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 110%;
      margin-top: 32px;
      margin-left: 30px;
      margin-right: 30px;
      margin-bottom: 16px;
      color:#6F6855;
    }
      .first-address-row{
        color: var(--Sand, #6F6855);
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 110%; 
        margin-bottom:10px;
      }
      .address-row{
      color: var(--Silver-Foil, var(--Soft-Sand, #B6B2A6));
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 130%
      }
      .custom-radio:checked{
        accent-color:var(--sand-button-color);
        border:1px solid ;
        height:20px;
        width:20px;
        }
         .custom-radio{
        accent-color:var(--sand-button-color);
        border:1px solid ;
        height:20px;
        width:20px;
        }
        .address-data{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom:20px;
        }
        .card-address-data{
        flex-direction: row;
        justify-content: space-between;
        margin-bottom:20px;
        }
        .line{
        border-bottom: 1px solid var(--Soft-Sand, #B6B2A6);
        
        }
        .address-name{
        font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 17.6px; 
          color:#6F6855;
        }
          
        .select-handler{
          color: var(--Sand, #6F6855);
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 130%; /* 20.8px */
          letter-spacing: 0.48px;
          border-radius: 4px;
          border: 1px solid var(--Soft-Sand, #B6B2A6);
          background: var(--White, #FFF);
        }
          .address-list-section{
          padding:4px;
          }
          .delivery-method-item{
          margin-bottom:5px;
          color:black;
          font-weight:550;
          }
          .line-item{
            margin-bottom:5px;
          }
          .line-item-selected {
            margin-bottom:5px;
            background: var(--Chinese-White, #E3E2DE);
          }
            .line-item-content {
                padding: 16px 0px 16px 0px;
            color:black;
            font-weight:550;
            }
          .accordian-summary{
          padding:0px;
          }
          .inner-accordian{
           padding:0px;
          }
          .input-box{
              // width: 95%;
              margin-top: 13px;
              // padding: 10px;
              flex-direction: column;
              border-radius: 4px;
              border: 1px solid var(--Soft-Sand, #B6B2A6);
              background: var(--White, #FFF);
              font-size: 14px;
          font-weight: 500;
          line-height: 18.2px;
          }
               .select-input-box{
              width: 101%;
              margin-top: 13px;
              padding: 10px;
              flex-direction: column;
              border-radius: 4px;
              border: 1px solid var(--Soft-Sand, #B6B2A6);
              background: var(--White, #FFF);
              color:#6F6855;
              appearance: auto;
                font-size: 14px;
          font-weight: 500;
          line-height: 18.2px;
              display: flex; /* Ensure flex container does not restrict width */
          }
              .select-input-box:focus{
  outline:none;
}
                .drop-down{
              width: 100% !important;
              overflow:auto !important;
              margin-top: 13px;
              padding: 10px;
              border-radius: 4px;
              border: 1px solid #B6B2A6;
              background: #FFF;
              position: static !important; /* Ensure default positioning */
                display: block !important; /* Ensure normal display */
                appearance: auto !important; 
          }
                #country option{
                width:100%;
                    min-block-size:50px;
                    max-block-size:100px;   
                    font-size: 14px;
          font-weight: 500;
          line-height: 18.2px;
          color:#6F6855;
              }
              #country selected{
              background-color:#6F6855;
               accent-color:##6F6855;
               font-weight: 500;
          line-height: 18.2px;
          color:#6F6855;
              }
          .save-button{
          display: flex;
          height: 50px;
          padding: 7px 52px;
          justify-content: center;
          align-items: center;
          border-radius: 100px;
          background: var(--Sand, #6F6855);
          box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.05);
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: 0.48px;
          color: var(--White, #FFF);
          text-align: center;
          border: 1px solid var(--Soft-Sand, #B6B2A6);

          &:disabled {
            background-color: var(--disabled-button-color); // Different color when the button is disabled
            color: var(--disabled-text-color); // Optional: change text color if needed
            cursor: not-allowed; // Change cursor to indicate the button is disabled
            box-shadow: none; // Optional: remove shadow if it suits the design better
        }
          }
          .button-section{
          display:flex;
          justify-content:center;
          margin-top:10px;
          margin-bottom: 10px;
          }
           .shipment-method-sub-title{
            font-weight:500;
            font-size:14px;
            line-height:21px;
            color:var(--sku-font-color);
          }
   
            .code-title{
            text-align: center;
            /* Links */
            font-family: Poppins;
            font-size: 9px;
            font-style: normal;
            font-weight: 500;
            line-height: 15px;
            }
            .address-list-data{
            padding-top:0;
            }
            .total-line{
            margin-top:6px;
            margin-bottom:24px;
            }
            .grand-total-line{
            margin-top:20px;
            margin-bottom:24px;
  }
          .first-row{
          margin-bottom:16px;
          }
            .checkout-card-section{
              display: grid;
              grid-template-columns: 1fr 1fr;
              margin-top:16px;
            }
              .card-element{
                height:41px;
              }
              .card-error{
                color:red;
                font-size:12px;
                margin-bottom:10px;
              }
              .card-heading{
                margin-bottom:10px;
                font-size:14px;
                font-weight:500;
                line-height:18.2px;
                color:#6F6855;
              }
                 .new-address-section{
                      display:flex;
                    justify-content:end;
              }
              
                 .first-address-card-row{
      display:flex;
        column-gap:4px;
        font-weight:500;
        font-size:16px;
        line-height:24px;
        justify-content:space-between;
    }
    .address-row{
        font-weight:500;
        font-size:14px;
        line-height:21px;
        color:var(--sku-font-color);
    }
    .item-line{
        border-bottom-color: var(--line-color); 
        margin-bottom:14px;
    }
      .add-new-address{
        font-Weight:500;
        font-size:10px;
        line-height:15px;
        color:#B6B2A6;
        width:96px;
        height:16px;
        border-radius:23px;
        border: 1px solid var(--sku-font-color);
        display:flex;
        justify-content:center;
        padding:4px, 8px, 4px, 8px;
    }
    .add-new-address-list{
      font-Weight:400;
      font-size:10px;
      line-height:15px;
      color:var(--sku-font-color);
      width:68px;
      height:16px;
      border-radius:12px;
      border: 1px solid var(--sku-font-color);
      display:flex;
      justify-content:center;
  }
      .card-holder-heading{
  font-size: 14px;
  margin-bottom: 3px;
}

.input-box:focus{
  outline:none;
}
  .second-content-row{
    font-weight:500;
    font-size:14px;
    line-height:21px;
    color:var(--sku-font-color);
    margin-bottom:10px;
   }
    .name-of-delivery-address {
        display: flex;
        justify-content: space-between;
        align-items: center;
        vertical: center;
    }
         .dropdown-container {
            display: flex;
            flex-direction: column;
            margin-top: 15px;
        }
        
 }
    `
    }}
`

export const cardStyle = {
    style: {
        base: {
            color: "#32325d",
            fontFamily: 'Arial, sans-serif',
            fontSmoothing: "antialiased",
            fontSize: "28px",
            "::placeholder": {
                color: "#32325d"
            }
        },
        invalid: {
            fontFamily: 'Arial, sans-serif',
            color: "#fa755a",
            iconColor: "#fa755a"
        }
    }
};

export const LoaderContainer = styled.div`
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 16px;
`;

export const SectionContent = styled.div`
      padding: 16px 0px 0px 0px;
      flex-direction: column;
      align-items: flex-start;
    //   gap: 16px;
    //   margin-bottom:16px;
`

export const Section = styled.div`
    background: #ffffff;
    padding: 16px 30px 16px 30px;
`;

export const AddressHeading = styled.div`
      color: var(--Sand, #6F6855);
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 17.6px;
      margin-bottom: 10px;
      padding: 0px 30px;
`

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    // margin-bottom: 16px;
    line-height: 20px;
`;

export const Title = styled.h4`
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 19.8px;
    color: #1c1c1c;
`;

export const EditButton = styled.button`
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 10px;
    line-height: 15px;
    color: #b6b2a6;
    padding: 0px;
    background: none;
    border: none;
    cursor: pointer;
    border-bottom: 0.8px solid var(--silver-foil);
`;

export const PencilIcon = styled.div`
  width: 16px;
  height: 16px;
  color: var(--silver-foil);
  background: url(${PencilIconForEdit}) no-repeat center center;
  background-size: contain;
  cursor: pointer;
  margin-left: 4px;
`;

export const AddressDetails = styled.div`
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: #1c1c1c;
    line-height: 21px;
    margin-bottom: 16px;
`;

export const CardText = styled.span`
  display: block;
  font-weight:400;
  font-size: 16px;
  line-height: 20.8px;
  color: var(--text-dark);
  white-space: normal; // Ensure text wraps
  overflow-wrap: break-word; // Break words that are too long
`;


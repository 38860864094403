import React from 'react';
import * as S from './styles/styles';
import { RESTRICTED_PATHS } from '../../../lib/constant';
import { useAuth } from '../../../Context/AuthContext';

interface MainContainerProps {
    children: React.ReactNode;
}

const MainContainer: React.FC<MainContainerProps> = ({ children }) => {
    
    const { isUserLoggedIn, isEmployeeLoggedIn } = useAuth();

    const shouldShowContentInContainer = () => {
        return (isUserLoggedIn || isEmployeeLoggedIn) && !RESTRICTED_PATHS.includes(window.location.pathname);
    };

    return (
        <>
            {shouldShowContentInContainer() ? (
                <S.MainContent isUser={isUserLoggedIn} isEmployee={isEmployeeLoggedIn}>
                    {children}
                </S.MainContent>
            ) : (
                <>{children}</>
            )}
        </>
    );
};

export default MainContainer;

import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ReactComponent as Assistance } from '../../../globals/svg/footerIcons/assistance.svg';
import { ReactComponent as InAssistance } from '../../../globals/svg/footerIcons/assistance-selected.svg';
import { ReactComponent as Cart } from '../../../globals/svg/footerIcons/cart.svg';
import { ReactComponent as InCart } from '../../../globals/svg/footerIcons/cart-selected.svg';
import { ReactComponent as Scanner } from '../../../globals/svg/footerIcons/scanner.svg';
import { ReactComponent as InScanner } from '../../../globals/svg/footerIcons/scanner-selected.svg';
import { ReactComponent as Profile } from '../../../globals/svg/footerIcons/profile.svg';
import { ReactComponent as InProfile } from '../../../globals/svg/footerIcons/profile-selected.svg';
import { ReactComponent as Wishlist } from "../../../globals/svg/wishlist_pdp.svg";
import { ReactComponent as InWishlist } from '../../../globals/svg/footerIcons/wishlist-selected.svg';
import * as S from "./styles/styles";
import { MOLECULES_CONSTANT } from "../Constant";
import apiList, { MyAccount, RESTRICTED_PATHS, USER_TYPE } from "../../../lib/constant";
import { CART_PAGE_PATH, SCAN_ROUTE_PATH, REQUEST_ASSISTANCE_PATH, WISHLIST_ROUTE_PATH } from "../../../routes/routes-constant";
import { CART_API_URL } from "../../organisms/cart/constants";
import { DataContext } from "../../../Context/AppContext";
import { ACTION_TYPE } from "../../../Context/Constant";
import { useAuth } from "../../../Context/AuthContext";
import IconWithCount from "../icon-with-count";
import useFetchData from "../../../hooks/useFetchData";
import { CartDetails } from "../../organisms/cart/types";
import { getLocalStorage, removeLocalStorage } from "../../../helpers/localStorageUtil";
import { useGlobalState } from "../../../Context/GlobalStateContext";
import { login_gtm } from "../../../helpers/GTM";
import { getStaticConstantValue } from "../../../helpers/utlis";

interface FooterProps {
  activeIndex?: number;
}
interface WishListItems {
  items_count: number;
  items?: {
    id?: string;
    product?: {
      sku: string;
    };
  }[];
}

const Footer: React.FC<FooterProps> = () => {
  const { isUserLoggedIn } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { state, dispatch } = useContext(DataContext);
  const { cart_item_count, wishlist_data } = state;
  const [showFooter, setShowFooter] = useState(false);
  const { customerData, setCustomerData } = useGlobalState();

  const footerData = [
    { icon: <Assistance />, name: MOLECULES_CONSTANT.assistance.en, selectedIcon: <InAssistance />, path: REQUEST_ASSISTANCE_PATH, hasCount: false },
    {
      icon: <IconWithCount icon={<Wishlist />} count={wishlist_data?.length ?? 0} />, name: MOLECULES_CONSTANT.wish_list.en,
      selectedIcon: <IconWithCount icon={<InWishlist />} count={wishlist_data?.length ?? 0} />, path: WISHLIST_ROUTE_PATH, hasCount: true
    },
    { icon: <Scanner />, name: MOLECULES_CONSTANT.scan.en, selectedIcon: <InScanner />, path: SCAN_ROUTE_PATH, hasCount: false },
    { icon: <IconWithCount icon={<Cart />} count={cart_item_count} />, name: MOLECULES_CONSTANT?.cart?.en, selectedIcon: <IconWithCount icon={<InCart />} count={cart_item_count} />, path: CART_PAGE_PATH, hasCount: true },
    { icon: <Profile />, name: MOLECULES_CONSTANT.profile.en, selectedIcon: <InProfile />, path: `/${MyAccount}`, hasCount: false }
  ];

  useEffect(() => {
    const shouldShowFooter = isUserLoggedIn && !RESTRICTED_PATHS.includes(location.pathname);
    setShowFooter(shouldShowFooter);
  }, [isUserLoggedIn, location.pathname]);

  const { data: cartItems } = useFetchData(CART_API_URL, USER_TYPE.Customer, false);
  const items = ((cartItems as unknown as CartDetails)?.items ?? []).reduce((a, item) => a + item.qty, 0) ?? 0;

  const { data: wishListItems } = useFetchData(apiList.wishlist, USER_TYPE.Customer, false);
  const wishlist_items = (wishListItems as unknown as WishListItems)?.items

  useEffect(() => {
    if (isUserLoggedIn && customerData) {
      if (cartItems) {
        const email = (cartItems as unknown as CartDetails)?.customer?.email;
        const communicationPreferenceData = (cartItems as unknown as CartDetails)?.customer?.custom_attributes?.filter((customerCommData: any) =>
          (customerCommData?.attribute_code === "communication_preference")
        );
        const communicationPreferenceValue = communicationPreferenceData && communicationPreferenceData.length > 0 ? communicationPreferenceData[0]?.value : "";
        const constCommunicationPreference = getStaticConstantValue("communication_preference");
        const keys = communicationPreferenceValue?.split(",");
        const values = keys.map((key: number) => constCommunicationPreference[key]);

        login_gtm(
          getLocalStorage("loginMethod"),
          customerData,
          email,
          values
        );

        //clear customer data after triggering the events
        clearCustomerData();
      }
    }

  }, [isUserLoggedIn, cartItems]);

  const clearCustomerData = () => {
    removeLocalStorage("loginMethod");
    setCustomerData({});
  }
  useEffect(() => {
    dispatch({ type: ACTION_TYPE.set_item_count, payload: { count: items } });
  }, [dispatch, items]);

  useEffect(() => {
    if (wishlist_items) {
      dispatch({ type: ACTION_TYPE.set_wishlist_data, payload: wishlist_items.map((item) => ({ id: item?.id, sku: item?.product?.sku })) });
    }
  }, [dispatch, wishlist_items]);

  return (
    <S.Content>
      {showFooter && (
        <div className="footer">
          {footerData.map(({ icon, name, selectedIcon, path, hasCount }) => {
            const isSelected = (location.pathname === path);
            return (
              < div key={name} className={`icon ${!hasCount ? 'no-count' : ''}`} onClick={() => { navigate(path) }}>
                <div className={`${isSelected ? 'top' : 'top-inactive'}`}></div>
                {isSelected ? selectedIcon : icon}
                <span className={`name ${isSelected ? 'active' : ""}`}>{name}</span>
              </div>)
          })}
        </div>
      )
      }
    </S.Content >
  );
};

export default Footer;

export const LOGIN_CONSTANTS = {
    "privacy_policy" : {
        en: "Privacy Policy"
    },
    "terms_conditions" : {
        en: "Terms and Conditions"
    },
    "employee_login": {
        en: "Employee Login"
    }
}
export const PDP_REDIRECT_PATH = (sku:string) => `/product/${sku}`
import styled from "styled-components";

export const Content = styled.div`
    padding: 16px;

    .project-btn {
        width: 100%;
        border-radius: 40px;
        display: flex;
        align-items: center;
    }

    .project-icon {
        width: 24px;
        height: 24px;
        color: #FFFFFF;
        fill: currentColor; /* Ensure the SVG uses currentColor */
        stroke: currentColor; /* For SVGs using stroke */
    }
`
export const DisconnectedMessage = styled.div`
        text-align: center;
        font-size: 12px;
        padding: 20px;
        color: #ff4444; /* Red for a warning */
        font-weight: 500;
`

export const LoadingIndicator = styled.div`
        border: 2px solid #f3f3f3; /* Light gray border */
        border-top: 2px solid #3498db; /* Blue top border */
        border-radius: 50%;
        width: 12px;
        height: 12px;
        animation: spin 1s linear infinite;
        }

        @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);   

        }
`
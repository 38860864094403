import styled, { css } from "styled-components";

export const Content = styled.div<any>`
  ${(p: any) => {
    return css`
      .assistance-container {
        padding: 20px;
        display: flex;
        flex-direction: column;
        height: 75vh;
        justify-content: space-between;
      }
      .assist-button {
        height: 50px;
        padding: 7px 52px;
        border-radius: 100px;
        background: var(--sand-button-color);
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.05);
        color: white;
        border: none;
        font-size: 16px;
        font-weight: 700;
        line-height: normal;
        width: 80vw;
        align-self: center;
      }
      .title {
        font-family: Poppins;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
      .message {
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      .info {
        color: black;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      .enquiries {
        height: 40vh;
        overflow: scroll;
      }
    `;
  }}
`;

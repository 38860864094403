import React from "react";

import * as S from "./styles/styles";
import Scanner from "../../../helpers/Scanner";
import { SCANNER_CONSTANTS } from "./constant";


const ScannerPage: React.FC = () => {

  return (
    <S.Content>
      <div className="scan-product-title">{SCANNER_CONSTANTS.Scanner_title.en}</div>
      <div className="scanner-container">
        <Scanner />
      </div>
    </S.Content>
  );
};

export default ScannerPage;

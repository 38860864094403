import React, { useEffect, useState } from "react";
import * as S from "./styles/styles";
import OrderSuccessLogo from '../../../globals/svg/order-success-icon.svg';
import { BACK_TO_CART_LABEL, BACK_TO_MENU_LABEL, CONTINUE_SHOPPING_LABEL, FINAL_ORDER_MESSAGE_INITIAL_TEXT, FINAL_ORDER_MESSAGE_LAST_TEXT, FINAL_PAYMENT_MESSAGE_INITIAL_TEXT, ORDER_CONFIRMATION_INITIALS, ORDER_CONFIRMATION_TEXT, ORDER_DELIVERY_TRACKING_INITIAL_TEXT, ORDER_DELIVERY_TRACKING_LAST_TEXT, ORDER_DETAILS_PAGE, PAYMENT_APPROVAL_PENDING, THANK_YOU_TEXT } from "../../../lib/constant";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { MANAGE_ORDER, PLP_CAFETERIA_MENU_PAGE_ROUTE_PATH, VIEW_EMPLOYEE_ORDER_DETAILS_ROUTE_PATH } from "../../../routes/routes-constant";

const EmployeeOrderConfirmation: React.FC = () => {
    const location = useLocation();
    const [orderId, setOrderId] = useState('');
    const [incrementId, setIncrementId] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        console.log(location?.state?.data);
        if (location?.state?.data?.order_id) {
            setOrderId(location?.state?.data?.order_id);
        }
        if (location?.state?.data?.increment_id) {
            setIncrementId(location?.state?.data?.increment_id);
        }
    }, [location?.state]);

    return (
        <S.Content>
            <div className="payment-method-section">
                <div className="order-success-section">
                    <div className="order-success-icon">
                        <img src={OrderSuccessLogo} />
                    </div>
                    <div className="details-section">
                        <h3 className="success-main-heading">{THANK_YOU_TEXT}</h3>
                        <div className="sub-heading">
                            <div className="success-sub-heading">
                                {location?.state?.isSecondPayment ? FINAL_PAYMENT_MESSAGE_INITIAL_TEXT : FINAL_ORDER_MESSAGE_INITIAL_TEXT}
                                <span className="order-id" onClick={() => { navigate(`${VIEW_EMPLOYEE_ORDER_DETAILS_ROUTE_PATH}/${orderId}`, {state: { orderId: orderId }}); }}>
                                    {"#" + incrementId}
                                </span>
                                {FINAL_ORDER_MESSAGE_LAST_TEXT}
                            </div>
                            <div className="success-sub-heading">
                                {ORDER_DELIVERY_TRACKING_INITIAL_TEXT}
                                <span className="order-id" onClick={() => { navigate(`${VIEW_EMPLOYEE_ORDER_DETAILS_ROUTE_PATH}/${orderId}`, {state: { orderId: orderId }}); }}>
                                    {ORDER_DELIVERY_TRACKING_LAST_TEXT}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="checkout-footer">
                    <div className="back-to-menu">
                        <button disabled={false} onClick={() => { navigate(PLP_CAFETERIA_MENU_PAGE_ROUTE_PATH) }} >{BACK_TO_MENU_LABEL}</button>
                    </div>
                </div>
            </div>
        </S.Content>
    );
};

export default EmployeeOrderConfirmation;

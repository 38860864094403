import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as S from './styles/styles';
import { useNotification } from '../../../../../hooks/useNotification';
import { Address, CustomerDetails } from '../types/types';
import { BILLING_ADDRESS_MESSAGES, BILLING_ADDRESS_UI } from './constants';
import AddressCardWithMenuItems from '../shared-components/address-card/AddressCardWithMenuItems';
import { fetchAddresses, fetchCustomerDetails } from '../helpers/dataProvider';
import { ALERT_STATUS } from '../../../../../lib/constant';
import { getCountries } from '../helpers/api-calls/apiCalls';

const BillingAddressPage = () => {
    const navigate = useNavigate();
    const { showAlert } = useNotification();

    const [addresses, setAddresses] = useState<Address[]>([]);
    const [customerDetails, setCustomerDetails] = useState<CustomerDetails>();
    const [currentAddress, setCurrentAddress] = useState<Address | null>(null);
    const [countryList, setCountryList] = useState([]);
    const [billingAddress, setBillingAddress] = useState<Address[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const location = useLocation();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    useEffect(() => {
        getAddressList();
        getCustomerDetails();
    }, []);

    useEffect(() => {
        if (location.state?.customerDetails) {
            const customerData = location.state?.customerDetails;
            setCustomerDetails(customerData);
        }
    }, [location.state]);

    const getAddressList = async () => {
        setIsLoading(true);
        const addressData = await fetchAddresses(showAlert, navigate);
        if (addressData) {
            const defaultBillingAddressDetails = addressData?.find(addr => addr.default_billing === true);
    
            setAddresses(addressData);
    
            if (defaultBillingAddressDetails) {
                setBillingAddress([defaultBillingAddressDetails]);
            } else {
                setBillingAddress([]);
                showAlert(BILLING_ADDRESS_MESSAGES.no_default_billing_address.en, ALERT_STATUS.info);
            }
        } else {
            showAlert(BILLING_ADDRESS_MESSAGES.fetch_address_error.en, ALERT_STATUS.error);
        }
        setIsLoading(false);
    };

    const getCustomerDetails = async () => {
        setIsLoading(true);
        const customerData = await fetchCustomerDetails(showAlert, navigate);
        if (customerData) {
            setCustomerDetails(customerData);
        } else {
            showAlert(BILLING_ADDRESS_MESSAGES.fetch_customer_info_error.en, ALERT_STATUS.error);
        }
        setIsLoading(false);
    };
    
    const getCountryList = async () => {
        try {
            setIsLoading(false);
            const countryList = await getCountries(showAlert);
            setCountryList(countryList);
        } catch (err) {
            console.log(err);
        } finally {
            setIsLoading(false);
        }

    }
    
    const handleEditAddress = () => {
        if (currentAddress) {
            navigate(`/edit-address/${currentAddress?.id}`, {
                state: { currentAddressDetails: currentAddress, allAddresses: addresses, customerDetails: customerDetails, addressType: 'Billing', isEditing: true }
            });
        } else {
            console.error(BILLING_ADDRESS_MESSAGES.no_address_selected.en);
        }
    }

    const handleMenuClick = (event: React.MouseEvent<HTMLElement>, address: Address) => {
        setAnchorEl(event.currentTarget);
        setCurrentAddress(address);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <S.Content>
            <div className="billing-address-title">{BILLING_ADDRESS_UI.billing_address_title.en}</div>
            {isLoading ? <S.Loader size={25} /> : billingAddress.map((address, index) => (
                <AddressCardWithMenuItems
                    key={index}
                    address={address}
                    handleMenuClick={handleMenuClick}
                    anchorEl={anchorEl}
                    open={open}
                    handleClose={handleClose}
                    currentAddress={currentAddress}
                    handleEditAddress={handleEditAddress}
                    addressType='billing'
                    countryList={countryList}
                />
            ))}
        </S.Content>
    );
};

export default BillingAddressPage;
import React, { useEffect, useState } from "react";
import { ADD_NEW_SHIPPING_ADDRESS, BILLING_ADDRESS_HEADING, DELIVERY_OPTION_HEADING, GIFT_CART_LABEL, IS_BILLING_ADDRESS_SAME_AS_SHIPPING, IS_THAT_A_GIFT, SAVE_BUTTON_LABEL, SELECT_ANOTHER_ADDRESS, SELECT_ANOTHER_BILLING_ADDRESS, SHIPPING_ADDRESS_HEADING } from "../../../../../lib/constant";
import { Accordion, AccordionDetails, AccordionSummary, PlusAccordionSummary } from "../../../../molecules/accordion/accordionHelpers";
import { useNotification } from "../../../../../hooks/useNotification";
import { deliveryMethodPageProps, deliveryMethodProps } from "../../types/types";
import { useNavigate } from "react-router-dom";
import { formatPriceAndCurrency } from "../../../../../helpers/utlis";
import { setLocalStorage } from "../../../../../helpers/localStorageUtil";



const ShipmentMethod: React.FC <deliveryMethodPageProps>= ({carrierTitle,shipmentMethodList,onChange,changeGiftHandler,isGiftOrder}) => {
    const [isAPICallRunning,setIsAPICallRunning] =useState(false);
    const [isGiftCart,setIsGiftCart] =useState<Boolean>();
    const { showAlert } = useNotification();
    const [availableShipmentMethods, setAvailableShipmentMethods] =useState<deliveryMethodProps[]>([]);
    const [selectedCarrierCode , setSelectedCarrierCode] =useState<string>("");
    const [selectedMethodCode,setSelectedMethodCode] =useState<string>("");
    const navigate = useNavigate();
    const expressDeliverySlots = [{"id":"1","label":"Saturday 7th October","slots":[{"label":"9AM to 2PM","id":"11"},{"label":"2PM to 9PM","id":"12"}]},{"id":"2","label":"Sunday 8th October","slots":[{"label":"9AM to 2PM","id":"11"},{"label":"2PM to 9PM","id":"12"}]}];
    const [selectedSlot,setSelectedSlot] = useState("");
    const [selectedMainDate,setSelectedMainDate] = useState("");

    const  radioButtonChangeHandler=(item:deliveryMethodProps)=>{
        setSelectedCarrierCode(item.carrier_code);
        setLocalStorage("deliveryCarrierCode",item.carrier_code);
        setSelectedMethodCode(item.method_code);
        onChange(item);

    }

    useEffect(()=>{
        setIsGiftCart(isGiftOrder);
    },[isGiftOrder])
    useEffect(()=>{
        setAvailableShipmentMethods(shipmentMethodList);
    },[shipmentMethodList])

    const giftCartChangeHandler = () =>{
        changeGiftHandler(!isGiftCart);
        setIsGiftCart(isGiftCart => !isGiftCart);
    }
  
    return (
        <div className="customer-delivery">
            <div className="address-heading">{DELIVERY_OPTION_HEADING}</div>
        <div className="page-delivery-sub-heading"></div>
        {availableShipmentMethods?.length>0 && 
            availableShipmentMethods.map((item:deliveryMethodProps, index: number)=>{
                return (
                  <React.Fragment key={item?.carrier_code}>
                <div className={item.carrier_code===selectedCarrierCode ?"shipment-section":""}>
                <div className="shipment-method-section">
                      <div className="shipment-method-title-section">
                        <div className="shipment-content">
                            <div className="delivery-method-item"> {item?.method_title}</div>
                            <div className="shipment-method-sub-title">{item?.extension_attributes?.delivery_message}</div>
                        </div>
                        <div className="shipment-method-amount">{item?.amount===0?"Free":formatPriceAndCurrency(item?.amount)}</div>
                        <div className="address-radio">
                        <input className="custom-radio" type="radio" id="address-radio-button" value={item?.carrier_code} onChange={(e:React.ChangeEvent<HTMLInputElement>)=>radioButtonChangeHandler(item)} checked={item?.carrier_code===selectedCarrierCode||item?.carrier_title===carrierTitle}></input>
                      </div>
                      </div>
                </div>
                {selectedCarrierCode ==="none" && item?.carrier_code ===selectedCarrierCode &&
                      expressDeliverySlots.map((slotData) => {
                        return (
                          <div className="split-section-box" key={slotData.id}>
                            <div className="address-check-section delivery-slot-section">
                              <input 
                                className="custom-checkbox" 
                                type="checkbox" 
                                checked={slotData?.id === selectedMainDate}
                                onChange={() => setSelectedMainDate(slotData?.id)} 
                              />
                              <label className="split-text">{slotData?.label}</label>
                            </div>
                            <div className="split-sub-text slot-sub-label">
                              {slotData.slots.map((subSlot) => (
                                <div className="slot-div" key={subSlot.id}>{subSlot.label}</div>
                              ))}
                            </div>
                          </div>
                        );
                      })
                    }
                 <hr className="item-line"/>
                 </div>
                 </React.Fragment>
                )
            })    
        }
         <div className="line-item gift-line-section"> {IS_THAT_A_GIFT}</div>
                         <div className="gift-section">
          <input className="gift-checkbox" type="checkbox" checked={Boolean(isGiftCart)}
          onChange={giftCartChangeHandler}/>
          <label className="consent-text">{GIFT_CART_LABEL}</label>
          </div>
    </div>
  );
};

export default ShipmentMethod;
export function getTimeAgoString(timestamp: string): string {
    const currentTime = new Date();
  
    const currentTimeGST = new Date(currentTime.getTime() + (currentTime.getTimezoneOffset() * 60000) + (4 * 3600000));
  
    const [day, month, year, hours, minutes, seconds] = timestamp.split(/[\s/:]+/).map(Number);
    const createdAt = new Date(Date.UTC(year, month - 1, day, hours - 4, minutes, seconds)); // Convert input time to UTC first
    const createdAtGST = new Date(createdAt.getTime() + (4 * 3600000)); // Convert to GST
  
    const timeDifference = currentTimeGST.getTime() - createdAtGST.getTime();
    const secondsDiff = Math.floor(timeDifference / 1000);
  
    const intervals = {
      year: 31536000,
      month: 2592000,
      week: 604800,
      day: 86400,
      hour: 3600,
      minute: 60,
    };
  
    let timeAgo: string | undefined;
  
    for (const interval in intervals) {
      if (Object.prototype.hasOwnProperty.call(intervals, interval)) {
        const secondsInterval = intervals[interval as keyof typeof intervals];
        const count = Math.floor(secondsDiff / secondsInterval);
  
        if (count > 0) {
          switch (interval) {
            case "minute":
              timeAgo = `${count}m ago`;
              break;
            case "hour":
              timeAgo = `${count}h ago`;
              break;
            case "day":
              timeAgo = `${count}d ago`;
              break;
            default:
              timeAgo = `${count} ${interval}${count === 1 ? "" : "s"} ago`;
              break;
          }
          break;
        }
      }
    }
  
    if (!timeAgo) {
      timeAgo = "Just now";
    }
  
    return timeAgo;
  }
  
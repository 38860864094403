import { useContext } from "react";
import { useAuth } from "../../../Context/AuthContext";
import { performEmployeeLogout } from "../../../helpers/authUtils";
import { useNotification } from "../../../hooks/useNotification";
import { DataContext } from "../../../Context/AppContext";
import { ACTION_TYPE } from "../../../Context/Constant";
import * as S from "./styles/styles";

export const EmployeeLogout: React.FC = () => {
    
    const { showAlert } = useNotification();
    const { logoutEmployee } = useAuth();
    const { dispatch } = useContext(DataContext);
    
    const handleEmployeeLogout = () => {
        logoutEmployee();
        dispatch({ type: ACTION_TYPE.set_item_count, payload: { count: 0 } });
        performEmployeeLogout(showAlert);
    }
  
    return (
        <S.Content>
            <div onClick={() => handleEmployeeLogout() } className="menu-item">
                Logout
            </div>
        </S.Content>
    );
}
export const API_CALL_MESSAGES = {
    fetch_user_data_fail: {
        en: "Failed to fetch user data",
        ar: "فشل في جلب بيانات المستخدم"
    },
    login_required: {
        en: "Login required",
        ar: "تسجيل الدخول مطلوب"
    },
    fetch_fail: {
        en: "Failed to fetch data",
        ar: "فشل في جلب البيانات"
    },
    internal_server_error: {
        en: "Internal server error. Unable to fetch countries.",
        ar: "خطأ في الخادم الداخلي. تعذر جلب البلدان."
    },
    fetch_countries_fail: {
        en: "Failed to load country data. Please try again.",
        ar: "فشل في تحميل بيانات البلد. حاول مرة أخرى."
    }
};

export const DATA_PROVIDER_MESSAGES = {
    fetch_user_data_fail: {
        en: "Failed to fetch user data",
        ar: "فشل في جلب بيانات المستخدم"
    },
    fetch_countries_fail: {
        en: "Failed to load country data. Please try again.",
        ar: "فشل في تحميل بيانات البلد. حاول مرة أخرى."
    },
    fetch_country_name_fail: {
        en: "Failed to fetch country name. Please try again.",
    },
    no_countries_fetched : {
        en: "No countries available to search"
    },
    invalid_or_missing_country_id : {
        en: "Invalid or missing country ID"
    },
    fetch_addresses_fail: {
        en: "Failed to fetch address data.",
        ar: "فشل جلب بيانات العنوان."
    }
}

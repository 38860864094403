import styled from "styled-components";

export const Content = styled.div`
  background: var(--background-light);
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;

  /* Shipping Address Title CSS */
  .shipping-address-title {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.05em;
    color: var(--text-dark);
    padding: 15px 30px;
  }

  .address-count-container {
    display: flex;
    justify-content: flex-end; /* Aligns the count to the right */
    margin-right: 20px;
    height: 40px;
    align-items: center;
    position: relative;
  }

  .address-count {
      color: var(--grey-medium); /* Medium grey color for the "disabled" look */
      font-size: 10px;
      font-weight: normal;
      opacity: 0.75;
      // position: absolute;
      // right: 10px;
      margin-left: auto;  // Pushes the count to the right edge, within the container context
    padding-right: 10px;  // Use padding instead of position for spacing from the edge

  }
`;

export const AddNewButton = styled.button`
  background: var(--white);
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  color: var(--grey-text);
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  margin: 10px; 
  width: auto;  // Allows the button to grow with the text content
  min-width: 100px;  // Ensures the button does not become too small

`;

// src/components/Modal.tsx
import React from 'react';

interface ModalProps {
  isOpen: boolean;
  toggleModal: () => void;
  children:any
}

const Modal: React.FC<ModalProps> = ({ isOpen, toggleModal, children }) => {
  if (!isOpen) return null;

  return (
    <div className="modal">
      <div className="modal-content">
      <div className="close" onClick={toggleModal}>&times;</div>
        {children}
      </div>
    </div>
  );
};

export default Modal;

import React, { useEffect, useState } from "react";
import * as S from "../../styles/styles";
import { Loading } from "../../../../atoms/loading/Loading";
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import PaymentForm from "./PaymentForm";
import { doActionGet } from "../../../../../helpers/httpRequest";
import apiList, { ALERT_STATUS, SOMETHING_WENT_WRONG, USER_TYPE } from "../../../../../lib/constant";
import { useNotification } from "../../../../../hooks/useNotification";
import { paymentMethodProps } from "../../types/types";


const CustomerPaymentForm:React.FC<paymentMethodProps> = ({isSecondPayment,methodTitle,paid_percent}) =>{
    const [isAPICallRunning,setIsAPICallRunning] =useState(false);
    const { showAlert } = useNotification();
    const [publishableKey,setPublishableKey] = useState<string>("");
    const [stripeOption,setStripeOption] =useState<any>();
    
    useEffect(()=>{
        setIsAPICallRunning(true);
        doActionGet({
          url: apiList.getPublishableKey,
          userType:USER_TYPE.Customer
        })?.then((resp: any) => {
            setPublishableKey(resp?.data);
        }
        ).catch((error: any) => {
          console.log("error msg", error);
          showAlert(SOMETHING_WENT_WRONG, ALERT_STATUS.error);
        }).finally(()=>{
          setIsAPICallRunning(false);
        })
    },[])

    // useEffect(()=>{
    //   let options = {
    //     // passing the client secret obtained from the server
    //     clientSecret:'pi_3PEAPMFQe6gdPJc91lz9Qpjq_secret_wRCoYjB1dyUakrG5MYfkptXCu',
    //   };
    //   setStripeOption(options);
    // },[])

    
    return (
    <S.Content>
         {isAPICallRunning && <Loading />}
         {publishableKey &&
        <Elements stripe={loadStripe(publishableKey)}>
          <PaymentForm isSecondPayment={isSecondPayment} methodTitle={methodTitle} paid_percent={paid_percent}/>
        </Elements>}
    
    </S.Content>
  );
};

export default CustomerPaymentForm;

import React, { useState, useEffect } from 'react';
import { doActionGet, doActionPut } from '../../../helpers/httpRequest';
import * as S from './styles/styles';
import apiList, { ALERT_STATUS, AMOUNT_LABEL, APPROVED_BUTTON_LABEL, CUSTOMERS_PENDING_ORDER_LIST, NO_PENDING_ORDER_FOUND, ORDER_AMOUNT, PAYMENT_METHOD, PAYMENT_STATUS_UPDATED_SUCCESSFULLY, REJECTED_BUTTON_LABEL, SEARCH_BY_CUSTOMER_NAME_OR_TRANSACTION_ID, SOMETHING_WENT_WRONG, TRANSACTION_REFERENCE_NUMBER, USER_TYPE } from '../../../lib/constant';
import { useNotification } from '../../../hooks/useNotification';
import { Loading } from '../../atoms/loading/Loading';
import { getLocalStorage } from '../../../helpers/localStorageUtil';
import { CustomerOrderData } from './types/types';
import { getStaticConstantValue } from '../../../helpers/utlis';
import { add_payment_approval_info } from '../../../helpers/GTM';

const EmployeeOrderApprovalRequests: React.FC = () => {
    const [customerOrders, setCustomerOrders] = useState<CustomerOrderData[]>([]);
    const [searchTerm, setSearchTerm] = useState('');
    const { showAlert } = useNotification();
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        setIsLoading(true)
        doActionGet({
            url: apiList.getPendingOrderRequest,
            userType: USER_TYPE.Employee
        })?.then((resp: any) => {
            setCustomerOrders(resp?.data?.items);
        }
        ).catch((error: any) => {
            console.log("error msg", error);
            showAlert(SOMETHING_WENT_WRONG, ALERT_STATUS.error);
        }).finally(() => { setIsLoading(false) })
    }, [])

    const filteredOrders = searchTerm
        ? customerOrders?.filter(customer =>
            customer?.parent_id?.toString()?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            customer?.po_number?.toLowerCase().includes(searchTerm.toLowerCase())
        )
        : customerOrders;

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setSearchTerm(value);
    };

    const handleApproveButton = (entityId: number, status: number,orderData:CustomerOrderData) => {
        if (entityId) {
            doActionPut({
                url: apiList.approvePendingPayment(entityId, status),
                userType: USER_TYPE.Employee
            })?.then((resp: any) => {
                if (resp) {
                    deleteItemById(entityId);
                    showAlert(PAYMENT_STATUS_UPDATED_SUCCESSFULLY, ALERT_STATUS.success);
                }
                add_payment_approval_info(status===1?"approve":"decline",getLocalStorage("employee_email"),orderData?.parent_id,orderData?.method,orderData?.amount_ordered,orderData?.amount_authorized,getStaticConstantValue('requested_by'));
            }
            ).catch((error: any) => {
                console.error("error msg", error);
                showAlert(SOMETHING_WENT_WRONG, ALERT_STATUS.error);
            })
        }
    }

    const deleteItemById = (idToDelete: number) => {
        const updatedItems = customerOrders?.filter((item: any) => item.entity_id !== idToDelete);
        setCustomerOrders(updatedItems);
    };

    return (
        <S.Content>
            <div className="container">
                {/* Todo : will coment this code if confirmation pop up required */}
                {/* <button onClick={handleOpenModal}>Open Confirmation Modal</button>
      <ConfirmationModal
        isOpen={isModalOpen}
        message="Are you sure you want to proceed?"
        onConfirm={handleConfirm}
        onCancel={handleCancel}
      /> */}
                <h1>{CUSTOMERS_PENDING_ORDER_LIST}</h1>
                <input
                    type="text"
                    placeholder={SEARCH_BY_CUSTOMER_NAME_OR_TRANSACTION_ID}
                    value={searchTerm}
                    onChange={handleSearch}
                />
                <div className="card-container">
                    {filteredOrders?.length === 0 ? (
                        <div className="no-customer-found">
                            {isLoading ? <Loading /> : NO_PENDING_ORDER_FOUND}
                        </div>
                    ) : (
                        <div className="alphabet-section">
                            {filteredOrders && filteredOrders?.length > 0 && filteredOrders?.map((customer) => (
                                <div key={customer?.entity_id} className="customer-card">
                                    <div className="customer-info">
                                        <h3>{customer?.parent_id}</h3>
                                        <div className='button-section'>
                                            <button
                                                className="view-cart-button"
                                                onClick={() => { handleApproveButton(customer?.entity_id, 1,customer) }}
                                            >
                                                {APPROVED_BUTTON_LABEL}
                                            </button>
                                            <button
                                                className="view-cart-button"
                                                onClick={() => { handleApproveButton(customer?.entity_id, 0,customer) }}
                                            >
                                                {REJECTED_BUTTON_LABEL}
                                            </button>
                                        </div>
                                    </div>
                                    <p><span>{PAYMENT_METHOD + " : "}{(customer?.additional_information && customer?.additional_information.length > 0) ? customer?.additional_information[0] : ""}</span></p>
                                    {customer?.po_number && <p><span>{TRANSACTION_REFERENCE_NUMBER + " : "}{customer?.po_number}</span></p>}
                                    <p><span>{ORDER_AMOUNT + " : "}{customer?.amount_ordered}</span></p>
                                    <p><span>{AMOUNT_LABEL + " : "}{customer?.amount_authorized}</span></p>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </S.Content>
    );
};

export default EmployeeOrderApprovalRequests;
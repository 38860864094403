import styled, { css } from "styled-components";
import { media } from "../../../../helpers/breakpoints";

export const Content = styled.div<any>`
  ${(p: any) => {
    return css`
      .container {
        display: flex;
        flex-direction: column;
        overflow: scroll;
        margin-bottom: calc(var(--footer-height));
      }
      .container::-webkit-scrollbar {
        display: none;
      }

      .image-carousel {
        flex: 1;
        background-color: #000;
        @media screen and ${media.largeMobileAndUpMax2} {
          margin-bottom: -10%;
        }
      }

      .product-data {
        flex: 0 0 60%;
        background-color: #fff;
        border-radius: 28px 28px 0 0;
        position: relative;
        z-index: 1;
      }
      .no-item-text {
        margin: 20px;
        span {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 110%;
          color: #6f6855;
        }
      }
    `;
  }}
`;

import React, { useContext, useEffect, useState } from "react";
import * as S from "./styles/styles";
import { doActionGet, doActionPost, doActionPut } from "../../../helpers/httpRequest";
import { getLocalStorage, removeLocalStorage, setLocalStorage } from "../../../helpers/localStorageUtil";
import apiList, { ALERT_STATUS, BANKTRANSFER_CODE, COMPLETE_ORDER_BUTTON_LABEL, GET_PAYMENT_METHODS, OFFER_LABEL, PAYMENT_REFERENCE_CODE_PLACEHOLDER, PLACE_ORDER, PLEASE_ENTER_CUSTOMER_EMAIL, PLEASE_ENTER_PAYMENT_REF_NUMBER_ERROR_MSG, PLEASE_ENTER_VALID_EMAIL, SOMETHING_WENT_WRONG, SUBTOTAL_LABEL, TAX_AND_FEE_LABEL, TOTAL_LABEL, USER_TYPE } from "../../../lib/constant";
import { useNotification } from "../../../hooks/useNotification";
import PaymentMethods from "./payment-methods";
import { PaymentMethodsType } from "./types/types";
import OrderReceipt from "./order-receipt";
import EmployeeFooter from "../../molecules/employee-footer";
import { useAsyncValue, useLocation, useNavigate } from "react-router-dom";
import { Loading } from "../../atoms/loading/Loading";
import { ORDER_CONFIRMATION_PAGE_ROUTE_PATH } from "../../../routes/routes-constant";
import { formatPriceAndCurrency, isEmptyString } from "../../../helpers/utlis";
import { ACTION_TYPE } from "../../../Context/Constant";
import { DataContext } from "../../../Context/AppContext";
import CheckoutInput from "./checkout-input";

const EmployeeCheckout: React.FC = () => {
  const [isAPICallRunning, setIsAPICallRunning] = useState(false);
  const { showAlert } = useNotification();
  const [selectedValue, setSelectedValue] = useState<string>("");
  const [availablePaymentMethods, setAvailablePaymentMethods] = useState<PaymentMethodsType[]>([]);
  const [paymentMethodCode, setPaymentMethodCode] = useState<string>("");
  const [paymentReferenceNo, setPaymentReferenceNo] = useState<string>("");
  const [isOrderReciptRequired, setIsOrderReciptRequired] = useState<boolean>(false);
  const [customerEmail, setCustomerEmail] = useState<string>("");
  const navigate = useNavigate();
  const location = useLocation();
  const cartTotals = location.state?.cartTotal;
  const { dispatch } = useContext(DataContext);
  const [isPayLaterOptionAvailable,setIsPayLaterOptionAvailable] =useState(true);

  useEffect(() => {
    setIsAPICallRunning(true);
    if(location?.state?.isSecondPayment){
      doActionGet({
        url: apiList.getPendingPaymentMethodList,
        userType:USER_TYPE.Employee
      })?.then((resp: any) => {
        setAvailablePaymentMethods(resp?.data);
      }
      ).catch((error: any) => {
        console.log("error msg", error);
        showAlert(SOMETHING_WENT_WRONG, ALERT_STATUS.error);
      }).finally(()=>{
        setIsAPICallRunning(false);
      })
    }
    else{
      const quoteId = getLocalStorage('quoteId');
      doActionGet({
        url: apiList.getCartDetails + quoteId + GET_PAYMENT_METHODS,
        userType: USER_TYPE.Employee
      })?.then((resp: any) => {
        if(getLocalStorage('EatIn')==="true"  || getLocalStorage('EatIn')===""){
          setAvailablePaymentMethods(resp?.data);
        }
        else{
          let availablePayMethods = resp?.data?.filter((payMethod:any)=>{
            return payMethod?.code !=="paylater";
          })??[];
          setAvailablePaymentMethods(availablePayMethods);
          setIsPayLaterOptionAvailable(false);
        }
        
      }
      ).catch((error: any) => {
        console.log("error msg", error);
        showAlert(SOMETHING_WENT_WRONG, ALERT_STATUS.error);
      }).finally(() => {
        setIsAPICallRunning(false);
      })
    }
  }, [])

  const paymentReferenceNumberHandler = (paymentRefNo: string) => {

    setPaymentReferenceNo(paymentRefNo);

  }

  const placeOrderButtonHandler = () => {
    if (paymentMethodCode === BANKTRANSFER_CODE && isEmptyString(paymentReferenceNo)) {
      showAlert(PLEASE_ENTER_PAYMENT_REF_NUMBER_ERROR_MSG, ALERT_STATUS.error);
    }
    else if (isOrderReciptRequired && isEmptyString(customerEmail)) {
      showAlert(PLEASE_ENTER_CUSTOMER_EMAIL, ALERT_STATUS.error);
    }
    else if (isOrderReciptRequired && !isEmptyString(customerEmail) && !validateEmail(customerEmail)) {
      showAlert(PLEASE_ENTER_VALID_EMAIL, ALERT_STATUS.error);
    }
    else {
      const quoteId = getLocalStorage('quoteId');
      setIsAPICallRunning(true);
      if(location?.state?.isSecondPayment && location?.state?.subTotal && location?.state?.orderId){
        doActionPut({
          url: apiList.setPendingOrder(location?.state?.orderId),
          data:
          {
              "paymentMethod": {
                  "method": paymentMethodCode,
                  "po_number": paymentReferenceNo,
                  "extension_attributes": {
                      "paid_amount":location?.state?.subTotal+location?.state?.tax
                  }
              }
          },
          userType: USER_TYPE.Employee
      })?.then((resp: any) => { navigate(ORDER_CONFIRMATION_PAGE_ROUTE_PATH, { state: { data: resp?.data } });
    }
    ).catch((error: any) => {
      console.log("error msg", error);
      showAlert(SOMETHING_WENT_WRONG, ALERT_STATUS.error);
    }).finally(() => {
      setIsAPICallRunning(false);
    })
      }else{
      doActionPut({
        url: apiList.getCartDetails + quoteId + "/" + PLACE_ORDER,
        data:
        {
          "paymentMethod": {
            "method": paymentMethodCode,
            "po_number": paymentReferenceNo,
            "extension_attributes": {
              "order_customer_email": customerEmail
            }
          }
        },
        userType: USER_TYPE.Employee
      })?.then((resp: any) => {
        setLocalStorage('cartItems', JSON.stringify([]));
        removeLocalStorage('quoteId');
        removeLocalStorage('Table Number');
        dispatch({ type: ACTION_TYPE.set_item_count, payload: { count: 0 } });
        navigate(ORDER_CONFIRMATION_PAGE_ROUTE_PATH, { state: { data: resp?.data } });

      }
      ).catch((error: any) => {
        console.log("error msg", error);
        showAlert(SOMETHING_WENT_WRONG, ALERT_STATUS.error);
      }).finally(() => {
        setIsAPICallRunning(false);
      })
    }
    }
  }

  const validateEmail = (email: string) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  return (
    <S.Content>
      {isAPICallRunning && <Loading />}
      <div className="payment-method-section">
        <PaymentMethods paymentMethods={availablePaymentMethods} selectedValue={selectedValue} setSelectedValue={setSelectedValue} codeChangeHandler={(selectedCode: string) => {
          setPaymentMethodCode(selectedCode);
          if(selectedCode==="paylater"){
            setIsPayLaterOptionAvailable(false);
          }
          else{
            setIsPayLaterOptionAvailable(true);
          }
        }
        } paymentRefNumberChangeHandler={(paymentRefNo: string) => {
          setPaymentReferenceNo(paymentRefNo);
        }} />
        { }
       {availablePaymentMethods && availablePaymentMethods?.length>0 && <div className="subtotal-reference-and-order-section">
          <div className="subtotal-reference-container">
           <div className="subtotal-container">
              <div className="subtotal-section">
                <div className="subtotal">
                  <div className="subtotal-label">{SUBTOTAL_LABEL}</div>
                  <span>:</span>
                  <div className="subtotal-value">{location?.state?.isSecondPayment ?formatPriceAndCurrency(location?.state?.subTotal) :formatPriceAndCurrency(cartTotals?.subtotal)}</div>
                </div>
                <div className="tax-and-fee">
                  <div className="tax-and-fee-label">{TAX_AND_FEE_LABEL}</div>
                  <span>:</span>
                  <div className="tax-and-fee-value">{location?.state?.isSecondPayment ?formatPriceAndCurrency(location?.state?.tax) :formatPriceAndCurrency(cartTotals?.tax)}</div>
                </div>
                {cartTotals?.couponName  &&cartTotals?.couponAmount!==0 &&  <div className="tax-and-fee">
                  <div className="tax-and-fee-label">{cartTotals?.couponName}</div>
                  <span>:</span>
                  <div className="tax-and-fee-value">{location?.state?.isSecondPayment ?formatPriceAndCurrency(location?.state?.tax) :cartTotals?.couponAmount? "-"+(formatPriceAndCurrency(cartTotals?.couponAmount * (-1))):0}</div>
                </div>}
              </div>
              
              <div className="total-section">
                <div className="total">
                  <div className="total-label">{TOTAL_LABEL}</div>
                  <span>:</span>
                  <div className="total-value">{location?.state?.isSecondPayment ?formatPriceAndCurrency(location?.state?.subTotal+location?.state?.tax) :formatPriceAndCurrency(cartTotals?.grandTotal)}</div>
                </div>
              </div>
            </div>
            <div className="reference-container">
              {/* Show the payment reference number input field when BANKTRANSFER_CODE is selected */}
              {selectedValue === BANKTRANSFER_CODE && (
                <CheckoutInput
                  placeHolder={PAYMENT_REFERENCE_CODE_PLACEHOLDER}
                  onchangeHandler={paymentReferenceNumberHandler}
                />
              )}
            </div>
          </div>
          {!location?.state?.isSecondPayment && isPayLaterOptionAvailable && <OrderReceipt emailChangeHandler={(email: string) => {
            setCustomerEmail(email);
          }} orderReceiptToggleHandler={(isOrderReciptRequired: boolean) => {
            setIsOrderReciptRequired(isOrderReciptRequired);
          }} />}
        </div>}
      </div>
      <div className="checkout-footer">
        <EmployeeFooter isDisabled={false} label={COMPLETE_ORDER_BUTTON_LABEL} checkoutHandler={placeOrderButtonHandler} />
      </div>
    </S.Content>
  );
};

export default EmployeeCheckout;

export const MOLECULES_CONSTANT={
  assistance: {
      "en": "Assistance",
      "ar": "أضف إلى السلة"
    },
    notification: {
      "en": "Notifications",
      "ar": "أضف إلى السلة"
    },
    scan: {
      "en": "Scan",
      "ar": "أضف إلى السلة"
    },
    wish_list: {
      "en": "Wish list",
      "ar": "أضف إلى السلة"
    },
    cart: {
      "en": "Cart",
      "ar": "أضف إلى السلة"
    },
    view_order: {
      "en": "View Order",
      "ar": "أضف إلى السلة"
    },
    profile: {
      "en": "Profile",
      "ar": "أضف إلى السلة"
    },
    out_of_stock:{
      "en": "Out of stock",
      "ar": "أضف إلى السلة"
    },
    home: {
      "en": "Home",
      "ar": "أضف إلى السلة"
    },
    orders: {
      "en": "Orders",
      "ar": "أضف إلى السلة"
    },
    requests: {
      "en": "Requests",
      "ar": "أضف إلى السلة"
    },
    training: {
      "en": "Training",
      "ar": "أضف إلى السلة"
    },
    menu: {
      "en": "Menu",
      "ar": "أضف إلى السلة"
    },
    registry: {
      "en": "Registry",
      "ar": "أضف إلى السلة"
    },
    inventory: {
      "en": "Inventory",
      "ar": "أضف إلى السلة"
    },

  }
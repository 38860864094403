import styled from "styled-components";
import CircularProgress from "@mui/material/CircularProgress";

export const Content = styled.div`
  background: var(--background-light);
  width: 100%;
  // min-height: 100%;
  display: flex;
  flex-direction: column;

  /* Address Title CSS */
  .address-title {
    // position: absolute;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-start;  // Center content horizontally
    text-align: center;
    letter-spacing: 0.05em;
    color: var(--text-dark);
    padding: 15px 30px;
    margin-top: 15px; 
  }

  .address-form-container {
    // margin-top: 5rem;
    padding: 0 30px;
    margin-top: 1rem;
  }

  .save-button-container {
    // position: absolute;
    // left: 50%;
    // transform: translateX(-50%);
    // width: 9rem;
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 15px 0;
  }

  .dropdown-container {
    display: flex;
    flex-direction: column;
    margin-top: 13px;
    background: var(--white);
  }

  .input-box{
    margin-top: 13px;
    // padding: 10px;
    flex-direction: column;
    border-radius: 4px;
    border: 1px solid var(--Soft-Sand, #B6B2A6);
    background: var(--White, #FFF);
    font-size: 14px;
    font-weight: 500;
    line-height: 18.2px;
  }

  .default-address-checkbox {
    margin-top: 13px;
    padding: 10px 0px;
    flex-direction: column;
    font-size: 14px;
    font-weight: 500;
  }
  
  .input-checkbox{
    font-size: 14px;
    font-weight: 500;
    line-height: 18.2px;
  }

  .street-action-button {
    cursor: pointer;
    color: var(--blue-link);
    font-size: 12px;
    // margin-bottom: 15px;
  }

  .save-button {
    // width: 9rem;
    width: auto;  // Allow the button to size based on its content
    min-width: 9rem;  // Ensure it does not become too small
    height: 50px;
    background-color: var(--sand-button-color);
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.05);
    border-radius: 100px;
    border: none;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.5rem;
    color: var(--white);
    text-transform: none;
    padding: 7px 52px;
    cursor: pointer;
    &:hover {
      background-color: var(--sand-button-hover);
    }

    &:disabled {
      background-color: var(--disabled-button-color); // Different color when the button is disabled
      color: var(--disabled-text-color); // Optional: change text color if needed
      cursor: not-allowed; // Change cursor to indicate the button is disabled
      box-shadow: none; // Optional: remove shadow if it suits the design better
  }
  }
`;

export const Label = styled.label<{inputType?: string}>`
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  line-height: 21px;
  color: var(--grey-text);
  margin-bottom: 5px;
  `;

export const Loader = styled(CircularProgress)`
  align-self: center;
`;

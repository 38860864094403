import styled, { css } from "styled-components";

export const Content = styled.div<any>`
  ${(p:any) => {
    return css`
      .footer-content{
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 1rem;
        display:grid;
        grid-template-columns:1fr 1fr 1fr;
        font-size:10px;
        font-weight:500;
        line-height:15px;
        color:#6F6855;
        //border-top:1px solid #b6b2a6;
        padding-top:22px;
      } 
     .footer-mid-section {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 0.5rem;
        paading-left:35px;
        padding-right:35px;
  }
    
    `
  }}
`

import React, { useEffect, useMemo, useState } from "react";
import * as S from "./styles/styles";
import { useLocation, useNavigate } from "react-router-dom";
import { Tabs, Tab } from "@mui/material";
import { Order } from "@stripe/stripe-js";
import { doActionGet, doActionPut } from "../../../helpers/httpRequest";
import { formatPriceAndCurrency } from "../../../helpers/utlis";
import { useNotification } from "../../../hooks/useNotification";
import apiList, { USER_TYPE, ALERT_STATUS } from "../../../lib/constant";
import ItemList from "../employee-cart/item-list";
import { capitalizeFirstLetter } from "../employee-manage-order/helpers";
import { tabMap, LIST_PAGE_LABELS, statusMap, NO_ORDERS_FOUND, NO_DETAILS_FOUND } from "../my-account/my-orders/constants";
import useFetchOrders from "../my-account/my-orders/hooks/useFetchOrders";
import { mergeProducts, formatDate } from "../my-account/my-orders/orderHelpers";
import { customerQuote } from "./types/types";

const  sampleQuoteJson= [
    {
        quote_id:"12345",
        quote_name:"Test Quote",
        quote_created_at:"22-oct-24",
        quote_expired_at:"22-oct-26",
        quote_pdf_url:"https://s28.q4cdn.com/392171258/files/doc_downloads/test.pdf",
        quote_status:"In pending"
    },
    {
        quote_id:"6789",
        quote_name:"Test Quote",
        quote_created_at:"20-oct-24",
        quote_expired_at:"22-oct-26",
        quote_pdf_url:"https://s28.q4cdn.com/392171258/files/doc_downloads/test.pdf",
        quote_status:"In Awaited"
    }
]

const CustomerQuotes = () => {
    const [quoteData,setQuoteData] = useState<customerQuote[]>([]);

    const getCustomerQuote = async()=>{
        try {
            const response = await doActionGet({ url: apiList.getCustomerQuote, userType: USER_TYPE.Customer });
            setQuoteData(response?.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    useEffect(()=>{
       getCustomerQuote();
    },[])

    const quoteList = quoteData?.map((quote: customerQuote) => {

        return <React.Fragment key={quote?.quote_id}>
            <div className='order'>
                <div className='title-line'>
                    <div className="orderId">{`Quote ${quote?.quote_name}`}</div>
                    <p>{formatDate(quote?.quote_created_at)}</p>
                </div>
                <div className='status-line'>
                    <span>{LIST_PAGE_LABELS.orderStatus.en}</span>
                    <p>{capitalizeFirstLetter(quote?.quote_status)}</p>
                </div>
                <div className='status-line'>
                    <span>{LIST_PAGE_LABELS.quote_expiry_date.en}</span>
                    <p>{formatDate(quote?.quote_expired_at)}</p>
                </div>
                <div className='item-container'>
                    <div className="button-container">
                        <button className="order-detail-btn" onClick={() => {window.open(quote?.quote_pdf_url,"_blank") }}>View Quote Details</button>
                    </div>
                </div>
            </div>
        </React.Fragment >

    })
    return <S.Content >
        <div className="title">My Quotes</div>
        {(quoteData?.length ) ? quoteList : (
            <div className='no-item-text'><span>{NO_DETAILS_FOUND}</span></div>)}</S.Content >


}
export default CustomerQuotes; 
export const setPageToFullHeight = (isLogin?: boolean) => {
    const page_container = document.getElementById("page_container");
    if (page_container) {
        if (isLogin) {
            page_container.style.height = '100vh';
        } else {
            const mediaQuery = window.matchMedia('(max-height: 500px) and (min-height: 200px)');
            if (mediaQuery.matches) {
                page_container.style.height = '75vh';
            } else {
                page_container.style.height = '80vh';
            }
        }
    }
}

import styled, { css } from "styled-components";

export const Content = styled.div<any>`
  ${(p:any) => {
    return css`
    .moveToCartBtn{
        position: sticky;
        bottom: 0px;
        left: 0;
        height:32px;
        width:170px;
        // border:1px solid #FFFFFF;
        margin-bottom:34px;
        margin-right: auto;
        text-align: center;
        margin-left: auto;
        
    }
    .moveToCartBtn button{
     color: var(--white);
    text-align: center;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    background: rgb(111, 104, 85);
    border-radius: 100px;
    border: none;
    padding: 5px;
    width: 100%;
    height:50px;
    align-self: center;
}
    }
    .moveToCartBtn button:disabled {
        color:#B9B9B9;
        opacity:50%;
      //background:#F6F6F6;
    }
        .continue-shopping{
        width:200px !important;
        }
    `
}}
`

import React from "react";
import * as S from "./styles";
import logo from './../../../globals/svg/tresori-golden-logo.svg';
import { FOOTER_ADDR_FIFTH_ROW, FOOTER_ADDR_FIRST_ROW, FOOTER_ADDR_FOURTH_ROW, FOOTER_ADDR_SECOND_ROW, FOOTER_ADDR_SIXTH_ROW, FOOTER_ADDR_THIRD_ROW } from "../../../lib/constant";

const PrintFooter: React.FC = () => {
  
  return (
    <S.Content>
          <div className="footer-content" >
            <img className="header-app-logo" src={logo} alt="logo" />
            <div className="footer-mid-section">
              <div>{FOOTER_ADDR_FIRST_ROW}</div>
              <div>{FOOTER_ADDR_SECOND_ROW}</div>
              <div>{FOOTER_ADDR_THIRD_ROW}</div>
            </div>
            <div className="footer-mid-section">
              <div>{FOOTER_ADDR_FOURTH_ROW}</div>
              <div> {FOOTER_ADDR_FIFTH_ROW}</div>
              <div>{FOOTER_ADDR_SIXTH_ROW}</div>
            </div>
          </div>
    </S.Content>
  );
};

export default PrintFooter;

import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  html {
    --light-grey:#F6F6F6;
    --dark-black:#1C1C1C;
    --sand-button-color:#6F6855;
    --sand-button-color-on-hover:#5a5647;
    --white:#FFFFFF;
    --red:#FF0000;
    --input-field-color:#F4F4F4;
    --input-field-text-color:#6F6855;
    --toggle-button-enable-color:#ABD87E;
    --toggle-button-disable-color:#C7C9D9;
    --sku-font-color:#B9B9B9;
    --apply-button-color:#979797;
    --apply-button-disabled-color:#DADEDF;
    --input-button-border-color:#DBDBDB;
    --line-color:#C6C5C5;
    --light-grey:#F0F0F0;
    --icon-count-color:#6E6754;
    --soft-grey:#B9B9B9;
    --silver-foil:#B6B2A6;
    --sand:#6F6855;
    --cultured: #F8F7F2;

    //Profile Page Colors
    --background-light: #FAFAFD;
    --text-dark: #1C1C1C;
    --grey-text: #4E4E4E;
    --sand-button-hover: #5a5647;
    --grey-medium: #808080;
    --blue-link: #007BFF;
    --light-grey: #f4f4f4;
    --disabled-button-color: #CCCCCC;
    --disabled-text-color: #666666;
  }
`;
import { doActionGet } from "../../../../helpers/httpRequest";
import apiList, { USER_TYPE } from "../../../../lib/constant";
import { ADDRESS_LABELS } from "./constants";
import { Address, Country } from "./types";

function formatDate(inputDate: string): string {
    const dateObject = new Date(inputDate);
    const formattedDate = new Intl.DateTimeFormat('en-AE', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
    }).format(dateObject);
    return formattedDate;
}

const getAddress = (address: Address, countries: Country[]) => {
    const { firstname = '', lastname = '' } = address ?? {}
    const customerName = firstname + ' ' + lastname
    return (
        <>{(firstname || lastname) && <div key='customer-name'>{customerName}</div>}
            {Object.entries(ADDRESS_LABELS).map(([key, label]) => {
                if (key !== 'country_id') return <div key={key}>{`${label}: ${address[key as keyof Address]}`}</div>
                const countryName = countries.filter(c => c.id === address[key as keyof Address])?.[0]?.full_name_locale ?? address[key as keyof Address]
                return <div key={key}>{`${label}: ${countryName}`}</div>
            }
            )}
        </>
    );
};


const fetchCustomerEmail = async () => {
    const config = {
        url: apiList.customerDetails,
        userType: USER_TYPE.Customer
    };

    try {
        const response = await doActionGet(config);
        if (response?.data) return response.data?.email;
    } catch {
        return null
    }
};

const mergeProducts = (products: any[]): any[] => {
    const modifiedItems = products?.map((item:any) => {
        // If the item has a parent_item_id, find the parent
        if (item?.parent_item_id) {
          const parent = products.find(p => p?.item_id === item?.parent_item_id);
          if (parent) {
            // Initialize child_item_array if not already set
            parent.child_item_array = parent?.child_item_array || [];
            
            // Check for uniqueness based on item_id
            const isUnique = !parent.child_item_array.some((child:any) => child.item_id === item.item_id);
            if (isUnique) {
                parent?.child_item_array.push(item);
            }
        }
        }}
    )
    const productMap: { [sku: string]: any } = {};
    products?.forEach((product) => {
        if (!productMap[product.sku] && !product?.parent_item_id) {
            productMap[product.sku] = { ...product };
        }
    });

    return Object.values(productMap);
};

function capitalizeFirstLetter(str:string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

export { formatDate, getAddress, fetchCustomerEmail, mergeProducts,capitalizeFirstLetter }
import styled, { css } from "styled-components";

export const Content = styled.div<any>`
  ${(p:any) => {
    return css`
    .accordian-section{
     box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
     background-color: rgb(255, 255, 255);
    border: 1px solid rgb(221, 221, 221);
    margin-bottom:10px;
    }
    .accordion-text{
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 110%;
    color: rgb(28, 28, 28);

    }
      `
}}
`
import styled, { css } from "styled-components";

export const Content = styled.div<any>`
  ${(p: any) => {
    return css`
      .video-player {
        width: 100%;
        height: 100vh;
        object-fit: contain;
      }
      .skip-button {
        position: fixed;
        bottom: 100px;
        right: 20px;
        width: 100px;
        padding: 5px;
        margin: 5px;
        background: rgba(0, 0, 0, 0.5);
        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        border: 1px solid white;
      }
      .vid-container {
        display: flex;
        align-items: center;
        justify-content: center;

        video {
          max-height: 400px;
          margin-bottom: 25px;
          padding-bottom: 10px;
          width: 100vw;
        }
      }
    `;
  }}
`;

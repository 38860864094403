import { doActionDelete, doActionGet, doActionPost } from "../../../helpers/httpRequest";
import apiList, {
  ADDTOWISHLIST_ERROR_MSG,
  ADDTOWISHLIST_SUCCESS_MSG,
  ALERT_STATUS,
  USER_TYPE,
} from "../../../lib/constant";
import { ReactComponent as Wishlist } from "../../../globals/svg/wishlist_pdp.svg";
import { useContext, useState } from "react";
import { Loading } from "../../atoms/loading/Loading";
import { useNotification } from "../../../hooks/useNotification";
import { DataContext, useAppContext } from "../../../Context/AppContext";
import { addToWishList_gtm } from "../../../helpers/GTM";
import { ACTION_TYPE } from "../../../Context/Constant";
import * as S from "./styles"
import { WISHLIST_CONSTANT } from "../../organisms/wishlist/Constant";

const AddToWishList = () => {
  const { state } = useAppContext();
  const [isAPICallRunning, setIsAPICallRunning] = useState<boolean>(false);
  const { showAlert } = useNotification();
  const { dispatch } = useContext(DataContext);
  let { sku, variantSku } = state?.current_pdp ?? {}
  variantSku = state?.current_pdp?.type_id === "simple" ? sku : state?.current_pdp?.variantSku

  const selectedItem = state?.wishlist_data?.filter((item: { sku: string; }) => item?.sku === variantSku)?.[0]

  const refreshWishListData = () => {
    setIsAPICallRunning(true);
    doActionGet({ url: apiList.wishlist, userType: USER_TYPE.Customer })
      ?.then((response: any) => {
        const itemList = response?.data?.items
        if (itemList) {
          dispatch({ type: ACTION_TYPE.set_wishlist_data, payload: itemList.map((item: { id: any; product: { sku: any; }; }) => ({ id: item?.id, sku: item?.product?.sku })) });
        }

      })
      ?.catch((err) => {
        console.error("API Error", err);
      })
      ?.finally(() => {
        setIsAPICallRunning(false);
      });
  };

  const handleAddToWishListAction = async (sku: string | undefined) => {
    let varaintOptions= state?.productVariant?.variantID;
    setIsAPICallRunning(true);
    if (!selectedItem?.id) {
      try {
        Object.keys(varaintOptions)?.forEach(key => {
          if (key === "") {
            delete varaintOptions[key];
          }
        });
        const payload = { "options": varaintOptions };
        const response = await doActionPost({
          url: apiList.wishlist + `/${sku}`,
          data: payload,
          userType: USER_TYPE.Customer,
        });
        if (response?.data) {
          if (!state?.current_pdp) {
            console.warn("Current PDP details missing in context")
          }
          addToWishList_gtm(state?.current_pdp || {}, state?.productVariant?.variantName)
          refreshWishListData()
          showAlert(ADDTOWISHLIST_SUCCESS_MSG, ALERT_STATUS.success);
        } else {
          console.error("Error while adding item to wish list", response?.data);
          showAlert(ADDTOWISHLIST_ERROR_MSG, ALERT_STATUS.error);
        }
      } catch (error) {
        console.error("Error while adding item to wish list", error);
        showAlert(ADDTOWISHLIST_ERROR_MSG, ALERT_STATUS.error);
      } finally {
        setIsAPICallRunning(false);
      }
    } else {
      const url = apiList.wishlist + `/${selectedItem?.id}`;
      doActionDelete({ url, userType: USER_TYPE.Customer })
        ?.then((response: any) => {
          if (response) {
            refreshWishListData()
            showAlert(WISHLIST_CONSTANT.del_item_msg.en, ALERT_STATUS.success);
          } else {
            throw new Error("Unable to remove the item");
          }
        })
        ?.catch((err) => {
          console.error("API Error", err);
          showAlert(WISHLIST_CONSTANT.del_item_err_msg.en, ALERT_STATUS.error);
        })
        ?.finally(() => {
          setIsAPICallRunning(false);
        });
    }
  };

  return (
    <S.Content>
      {isAPICallRunning && (
        <div className="loading_container">
          <Loading />
        </div>
      )}
      <div className={`wishlist-icon ${selectedItem?.id ? 'active' : 'in-active'}`} onClick={() => handleAddToWishListAction(sku)}>
        <Wishlist />
      </div>
    </S.Content>
  );
};

export default AddToWishList;

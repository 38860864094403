import { Box, Typography, Button } from "@mui/material";
import { styled } from '@mui/material/styles';


export const PrintButton = styled(Button)({
    marginLeft: 'auto',
    paddingRight:'30px',
    '@media print': {
        display: 'none',
    },
});

export const PrintWrapper = styled('div')({
    '@media print': {
        margin: '0',
        padding: '0',
    }
    
});
export const PrintHeader = styled('div')({
    display:"none",
    '@media print': {
       display:"block"
    }
    
});


export const globalPrintStyles = `
    @media print {
      body * {
        visibility: hidden;
      }
      .print-content, .print-content * {
        visibility: visible;
      }
      .print-content {
        padding-left:20px;
        padding-right:20px;
        padding-top:50px;
        width: 95%;
      }
      .no-print {
        display: none !important;
      }
    .print-header{
        display:block;
    }
    }
  `;


export const OrderDetailsContainer = styled(Box)(({ theme }) => ({
    position: 'relative',
    overflowY: 'scroll',
    background: '#FFFFFF',
    height: '80vh'
}));

export const LoadingContainer = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
});

export const OrderHeader = styled(Typography)(({ theme }) => ({
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '110%',
    display: 'flex',
    alignItems: 'center',
    color: '#6F6855',
    marginTop: '16px',
    marginBottom: '16px',
    padding: '0 30px',
}));

export const OrderInfo = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
}));

export const OrderInfoRow = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '15px 30px',
}));



export const OrderInfoLabel = styled(Typography)(({ theme }) => ({
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '110%',
    color: '#1C1C1C',
}));

export const OrderInfoValue = styled(Typography)(({ theme }) => ({
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '110%',
    color: '#6F6855',
}));

export const Divider = styled(Box)(({ theme }) => ({
    width: '100%',
    height: '1px',
    background: '#E3E2DE',
    margin: '8px 0',
}));

export const ProductTable = styled(Box)(({ theme }) => ({
    marginTop: '16px',
    padding: '0 0px',
}));

export const ProductTableHeader = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 30px',
    marginBottom: '8px',
}));

export const ProductTableHeaderCell = styled(Typography)(({ theme }) => ({
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '10px',
    lineHeight: '15px',
    color: '#6F6855',
}));

export const ProductRow = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    padding: '4px 30px',
}));

export const ProductCell = styled(Typography)(({ theme }) => ({
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '10px',
    lineHeight: '15px',
    color: '#6F6855',
}));

export const TotalSection = styled(Box)(({ theme }) => ({
    background: '#F8F7F2',
    padding: '8px 30px',
    marginTop: '16px',
}));

export const TotalRow = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '2px 0',
}));

export const TotalLabel = styled(Typography)(({ theme }) => ({
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '10px',
    lineHeight: '15px',
    color: '#1C1C1C',
}));

export const TotalValue = styled(Typography)(({ theme }) => ({
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '10px',
    lineHeight: '15px',
    color: '#1C1C1C',
}));

export const TotalDueRow = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '2px 24px',
    background: '#6F6855',
    marginTop: '4px',
}));

export const TotalDueLabel = styled(Typography)(({ theme }) => ({
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '10px',
    lineHeight: '15px',
    color: '#FFFFFF',
    marginRight: '28px',
}));

export const TotalDueValue = styled(Typography)(({ theme }) => ({
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '10px',
    lineHeight: '15px',
    color: '#FFFFFF',
}));

export const PaymentMethodSection = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: '16px 30px',
}));

export const PaymentMethodLabel = styled(Typography)(({ theme }) => ({
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '10px',
    lineHeight: '15px',
    color: '#6F6855',
}));

export const PaymentMethodInfo = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
}));

export const PaymentMethodValue = styled(Typography)(({ theme }) => ({
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '10px',
    lineHeight: '15px',
    color: '#6F6855',
}));

export const PaymentButton = styled(Button)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '7px 48px',
    position: 'absolute',
    width: '370px',
    height: '38px',
    left: 'calc(50% - 370px/2)',
    margin: '30px 0',
    background: '#6F6855',
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.05)',
    borderRadius: '100px',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',
    textAlign: 'center',
    letterSpacing: '0.03em',
    color: '#FFFFFF',
    textTransform: 'none',
}));


export const CancelButton = styled(Button)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '200px',
    height: '38px',
    background: '#6F6855',
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.05)',
    borderRadius: '100px',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',
    textAlign: 'center',
    letterSpacing: '0.03em',
    color: '#FFFFFF',
    textTransform: 'none',
}));


import React from "react";
import * as S from "./styles";
import { Variant, VariantAccordionProps } from "./types";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "../../../molecules/accordion/accordionHelpers";
import DOMPurify from "dompurify";
import { getAllowedVariantList } from "../common/helpers";
import { PDP_CONSTANTS } from "../common/constants";
import { getStaticConstantValue } from "../../../../helpers/utlis";

const VariantAccordion: React.FC<VariantAccordionProps> = ({
  variants,
  attributes,
  accordionAttributes,
  filters,
  setFilters,
  children
}) => {
  const defaultImage = getStaticConstantValue("material_placeholder") || ''
  const variantCodes = variants?.map((variant) => variant.code);
  const { shipping_notes, use_and_care } = accordionAttributes ?? {};
  const [expanded, setExpanded] = React.useState<string>(
    variantCodes?.[0] ?? ""
  );
  const [infoExpanded, setInfoExpanded] = React.useState<string>("");
  const allowedVariantList = getAllowedVariantList(
    attributes,
    filters, variantCodes);
  const handleChange = (panel: string) => {
    setExpanded((expanded) => (expanded === panel ? "" : panel));
  };
  const handleInfoChange = (panel: string) => {
    setInfoExpanded((expanded) => (expanded === panel ? "" : panel));
  };

  const handleButtonClick = (label: string, index: string) => {
    let updatedFilters = { ...filters };
    let keys = Object.keys(allowedVariantList);
    let index2 = keys.indexOf(label);
    if (index2 !== -1) keys = keys.slice(index2);

    for (let k of keys) {
      if (k in updatedFilters) delete updatedFilters[k];
    }
    updatedFilters = { ...updatedFilters, [label]: index };
    setFilters(updatedFilters);
  };

  const isSelected = (key: string, value: string) => (Object.entries(filters) ?? []).some(([k, v]) => k === key && String(v) === value);

  const isDisabled = (code: string) => {
    const index = variantCodes.indexOf(code);
    return (Object.keys(filters ?? {}).length) < index
  };

  const variantInfo = (variant: Variant, index: number) => {
    return (variant?.options?.some(option => Boolean(option.image))) ?
      <div className="image-container">
        {variant?.options?.map((option, count) => {
          const selected = isSelected(variant?.code, String(option.value_index))
          return (
            !index ||
              allowedVariantList[variant?.code]?.includes(String(option.value_index))
              ? (
                <div key={option.value_index} className={`variant-image ${selected
                  ? "selected"
                  : ""
                  }`}
                  onClick={() => handleButtonClick(variant?.code, option.value_index)}
                >
                  {<img className="swatch-image" src={option.image || defaultImage} alt={`sw-${index}-${count}`}></img>}
                  <p className="image-name" >{option.label}</p>
                </div>)
              : null)
        }
        )}
      </div> :
      <div className="button-container">
        {variant?.options?.map((option) =>
          !index ||
            allowedVariantList[variant?.code]?.includes(
              String(option.value_index)
            ) ? (
            <React.Fragment key={option.value_index} >
              <button
                className={`button ${isSelected(variant?.code, String(option.value_index))
                  ? "selected"
                  : ""
                  }`}
                onClick={() =>
                  handleButtonClick(variant?.code, option.value_index)
                }
              >
                <div className="buttonText">{option.label}</div>
              </button>
            </React.Fragment>
          ) : null
        )}
      </div>
  }
  return (
    <S.Content>
      {variants?.map((variant, index) => (
        <Accordion
          expanded={expanded === variant?.code}
          onChange={() => handleChange(variant?.code)}
          disabled={isDisabled(variant?.code)}
          key={variant?.code}
        >
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <div className="accordionSummaryData">
              <div className="variant-title-text ">{variant?.label}</div>
              <div className='selectedVariantOption'>{(variant?.options ?? []).find(value => String(value?.value_index) === String(filters?.[variant?.code]))?.label}</div>
            </div>
          </AccordionSummary>
          <AccordionDetails> {variantInfo(variant, index)}
          </AccordionDetails>
        </Accordion>
      ))}
      {children}
      {shipping_notes ? (
        <Accordion
          expanded={infoExpanded === "shipping_notes"}
          onChange={() => handleInfoChange("shipping_notes")}
        >
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" >
            <div className="accordion-title-text">{PDP_CONSTANTS.shipping}</div>
          </AccordionSummary>
          <AccordionDetails>
            <div className="accordion-text"
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(shipping_notes),
              }}
            ></div>
          </AccordionDetails>
        </Accordion>
      ) : null}
      {use_and_care ? (
        <Accordion
          expanded={infoExpanded === "use_and_care"}
          onChange={() => handleInfoChange("use_and_care")}
        >
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" >
            <div className="accordion-title-text">{PDP_CONSTANTS.use_and_care}</div>
          </AccordionSummary>
          <AccordionDetails>
            <div className="accordion-text"
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(use_and_care),
              }}
            ></div>
          </AccordionDetails>
        </Accordion>
      ) : null}
    </S.Content>
  );
};

export default VariantAccordion;

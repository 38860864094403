import React, { useEffect, useState } from "react";
import { ADD_NEW_BILLING_ADDRESS, BILLING_ADDRESS_HEADING, SELECT_ANOTHER_BILLING_ADDRESS } from "../../../../../../../lib/constant";
import { Accordion, AccordionDetails, AccordionSummary, PlusAccordionSummary } from "../../../../../../molecules/accordion/accordionHelpers";
import { Address, billToAddressProps, CountryRegion, Region } from "../../../types/types";
import { getCountryName } from "../../../helpers/utils";
import * as S from '../styles/styles';
import { ADDRESS_CARD_UI_STRING } from "../../../constants";
import { ReactComponent as Delete } from '../../../../../../../globals/svg/delete.svg';
import MyAddressForm from "../common/MyAddressForm";

const BillingAddress: React.FC<billToAddressProps> = ({ addressList, selectedBillToId, onChange, countryData, onBillingSameAsShippingChangeHandler, newAddressSaveHandler, handleDefaultBilling, handleEditAddress, handleRemoveAddress }) => {

    const [isExpanded, setIsExpanded] = useState(false);
    const [isnewAddressExpand, setIsNewAddressExpand] = useState(false);
    const [isSameAsShipping, setIsSameAsShipping] = useState(true);
    const [defaultBillingAddressDetails, setDefaultBillingAddressDetails] = useState<Address | undefined>();
    const [addresses, setAddresses] = useState<Address[]>([]);

    useEffect(() => {
        const selectedAddress = addressList?.filter((address) => {
            return address?.id === selectedBillToId;
        })
        if (selectedAddress && selectedAddress?.length > 0) {
            setDefaultBillingAddressDetails(selectedAddress[0]);
            handleDefaultBilling(selectedAddress[0]);
            setIsExpanded(false);
        }
    }, [addressList, selectedBillToId]);

    useEffect(() => {
        setDefaultBillingAddressDetails(addresses?.find(addr => addr?.default_billing === true));
        setAddresses(addressList);
    }, [addressList, addresses]);

    const onBillingChangeHandler = () => {
        onBillingSameAsShippingChangeHandler(!isSameAsShipping);
        setIsSameAsShipping(prev => !prev);
    }

    const addressSelectHandler = (id: Number) => {
        onChange(id);
        onBillingChangeHandler();
    }

    const handleDeleteAddress = (addressId: number | undefined) => {
        handleRemoveAddress(addressId);
        const updatedAddresses = addresses.filter(addr => addr?.id !== addressId);
        setAddresses(updatedAddresses);
    }

    return (
        <S.SectionContent>
            <S.AddressHeading>{BILLING_ADDRESS_HEADING}</S.AddressHeading>
            <S.Section>
                {defaultBillingAddressDetails && (
                    <>
                        <S.Header>
                            <S.Title>Default Billing Address</S.Title>
                            <S.EditButton onClick={() => handleEditAddress(defaultBillingAddressDetails)}><span>Edit</span><S.PencilIcon /></S.EditButton>
                        </S.Header>
                        <S.AddressDetails>
                            <S.CardText>{ADDRESS_CARD_UI_STRING.street.en} {defaultBillingAddressDetails?.street?.join(', ')}</S.CardText>
                            <S.CardText>{ADDRESS_CARD_UI_STRING.city.en} {defaultBillingAddressDetails?.city}</S.CardText>
                            <S.CardText>{ADDRESS_CARD_UI_STRING.state_province_area.en} {defaultBillingAddressDetails?.region?.region}</S.CardText>
                            <S.CardText>{ADDRESS_CARD_UI_STRING.zip_code.en} {defaultBillingAddressDetails?.postcode}</S.CardText>
                            <S.CardText>{ADDRESS_CARD_UI_STRING.country.en} {getCountryName(countryData, defaultBillingAddressDetails?.country_id)[0]?.full_name_locale}</S.CardText>
                            {/* <S.CardText>{ADDRESS_CARD_UI_STRING.telephone.en} {defaultBillingAddressDetails?.telephone}</S.CardText> */}
                        </S.AddressDetails>
                    </>
                )}

                <Accordion className="select-handler" expanded={isExpanded}>
                    <AccordionSummary onClick={() => { setIsExpanded(pre => !pre) }}>
                        <div className="accordionText">{SELECT_ANOTHER_BILLING_ADDRESS}</div>
                    </AccordionSummary >
                    <AccordionDetails className="address-list-data">
                        {isExpanded && <div className="address-list-section">
                            {addresses && addresses?.length > 0 && addresses?.map((address, index) => {
                                return (
                                    <div key={index}>
                                        <div className={Number(address?.id) === Number(selectedBillToId) ? "line-item-selected name-of-delivery-address" : "line-item name-of-delivery-address"} onClick={() => addressSelectHandler(Number(address.id))}>
                                            <div className="line-item-content">{address?.firstname}&nbsp;{address?.lastname}</div>
                                            <Delete onClick={(event) => { event.stopPropagation(); handleDeleteAddress(address?.id) }} />
                                        </div>
                                        <div className="line"></div>
                                    </div>
                                )
                            })}

                            <Accordion expanded={isnewAddressExpand}>
                                <PlusAccordionSummary className="accordian-summary" onClick={() => { setIsNewAddressExpand(pre => !pre) }}>
                                    <div className="line-item">{ADD_NEW_BILLING_ADDRESS}</div>
                                </PlusAccordionSummary >
                                <AccordionDetails className="inner-accordian">
                                    <MyAddressForm
                                        addressType="Bill"
                                        countryData={countryData}
                                        isNewAddressExpand={isnewAddressExpand}
                                        setIsNewAddressExpand={setIsNewAddressExpand}
                                        setDefaultBillingAddressDetails={setDefaultBillingAddressDetails}
                                        setIsExpanded={setIsExpanded}
                                        newAddressSaveHandler={newAddressSaveHandler}
                                    />
                                </AccordionDetails>
                            </Accordion>
                        </div>}
                    </AccordionDetails>
                </Accordion>
            </S.Section>
        </S.SectionContent>
    );
};

export default BillingAddress;
import React, { useState, useEffect, useCallback } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import * as S from './styles/styles';
import { CancelModalProps } from './types/types';
import { ReactComponent as Cross } from "../../../globals/svg/remove_wishlist_item.svg";
import crossIcon from '../../../globals/svg/cross-icon.svg';
import { REASON_FOR_CANCELLATION, REASON_FOR_CANCELLATION_PLACEHOLDER, REF_NO_CANCELLATION_PLACEHOLDER, REF_NO_FOR_CANCELLATION } from '../../../lib/constant';

const CancelModalPopUp: React.FC<CancelModalProps> = ({ isOpen,
  onClose,
  onCancel,
  isCardPayment}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const [transctionRefNo, setTransactionRefNo] = useState<string>("");
  const [reason ,setReason] =useState<string>("");
  
  return (
    <S.Content>
      <Dialog open={isOpen} onClose={onClose}>
      <S.Content><div className='title-section'>
        <DialogTitle>Please provide details</DialogTitle>
        <img className="login-logo"  onClick={onClose} src={crossIcon}></img>
        </div>
        <DialogContent className='dialog-content'>
        <div className="checkout-card-data ">
                {isCardPayment && <><div className='card-holder-heading transaction-box'>{REF_NO_FOR_CANCELLATION}</div>
                <div className="second-content-row">
                    <input className="input-box transaction-input-box" type="text" placeholder={REF_NO_CANCELLATION_PLACEHOLDER} value={transctionRefNo} onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setTransactionRefNo(e.target.value) }}></input>
                </div></>}
                <div className='card-holder-heading transaction-box bank-input'>{REASON_FOR_CANCELLATION}</div>
                <div className="second-content-row">
                    <input className="input-box transaction-input-box" type="text" placeholder={REASON_FOR_CANCELLATION_PLACEHOLDER} value={reason} onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setReason(e.target.value) }}></input>
                </div>
            </div>
        </DialogContent>
        
        <button className="cancel-return-btn" onClick={()=>{onCancel(reason,transctionRefNo,isCardPayment)}}>Cancel Order</button>
        
        </S.Content>
      </Dialog>
    </S.Content>
  );
};

export default CancelModalPopUp;
import styled, { css } from "styled-components";
import BG from '../../../../globals/images/Background/BG.svg';

export const Content = styled.div`
  height: 100vh;
  width: 100%;
  background-image: url(${BG});
  background-size: cover; /* Ensures image covers the entire container */
  background-position: center; /* Centers the image horizontally and vertically */
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #FFFFFF;
  overflow: hidden;

  .top-logo {
    position: absolute;
    top: 10rem;
    left: 50%;
    transform: translateX(-50%);
    width: calc(8.125rem * 2);
  }

  .welcome-message-button-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 25rem;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 22rem;
  }

  .welcome-message-button {
    height: 40px;
    background: none;
    color: var(--sand);
    border: none;
    padding: 8px 52px;
    font-size: 36px;
    font-weight: 500;
    line-height: 39.6px;
    margin-bottom: 20px;
    text-transform: none;
    cursor: pointer;
        &:hover {
            background-color: #5a5647;
        }
  }

  .bottom-logo {
    position: absolute;
    width: 2rem;
    height: 2rem;
    bottom: 2.5rem;
    left: 2.5rem;
  }
`;
import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { getLocalStorage, removeLocalStorage } from './../helpers/localStorageUtil';
import { AuthContextType } from './types/types';
import { clearCartLocalStorage } from '../components/organisms/customer-checkout/helper';
import { manageIndexedDB } from '../helpers/indexedDB';

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [isEmployeeLoggedIn, setIsEmployeeLoggedIn] = useState(false);
  useEffect(() => {
    if (getLocalStorage('user-token')) {
      setIsUserLoggedIn(true);
    }
    if (getLocalStorage('access-token')) {
      setIsEmployeeLoggedIn(true);
    }
  }, []);

  const loginUser = () => {
    setIsUserLoggedIn(true);
  };

  const loginEmployee = () => {
    setIsEmployeeLoggedIn(true);
  };

  const logoutUser = () => {
    setIsUserLoggedIn(false);
    removeLocalStorage('user-token');
    manageIndexedDB({ key: 'userToken', toDelete: true })
    removeLocalStorage('notificationList');
    clearCartLocalStorage();
    removeLocalStorage('static-constants');
  };

  const logoutEmployee = () => {
    setIsEmployeeLoggedIn(false);
    removeLocalStorage('access-token');
    removeLocalStorage('EatIn');
    removeLocalStorage('Table Number');
    manageIndexedDB({ key: 'accessToken', toDelete: true })
    removeLocalStorage("notification-section");
    removeLocalStorage('static-constants');
  };

  return (
    <AuthContext.Provider value={{ isUserLoggedIn, isEmployeeLoggedIn, loginUser, loginEmployee, logoutUser, logoutEmployee }}>
      {children}
    </AuthContext.Provider>
  );
};

import { useState, useCallback, useEffect } from "react";
import { doActionGet } from "../../../../../helpers/httpRequest";
import apiList, { ALERT_STATUS, USER_TYPE } from "../../../../../lib/constant";
import { ENQUIRY_STATUSES } from "../../../assistance/constants";
import { getLocalStorage, setLocalStorage } from "../../../../../helpers/localStorageUtil";
import { Request, ErrorResponse } from "../types/types";
import { useNotification } from "../../../../../hooks/useNotification";
import { CUSTOMER_LISTING_MESSAGES } from "../constant";
import { isManager } from "../../../../../helpers/utlis";
import { useGlobalState } from "../../../../../Context/GlobalStateContext";


function useEmployeeEnquiries(currentEmployee: { role: string; email: string; }) {
    const [customersRequests, setCustomersRequests] = useState<Request[]>([]);
    const [requestsLoaded, setRequestsLoaded] = useState(false);
    const { showAlert } = useNotification();
    const {setHasActiveRequests} = useGlobalState();

    const fetchCustomersRequests = useCallback(async () => {
        if (currentEmployee) {
            const config = {
                url: apiList.getActiveRequests,
                userType: USER_TYPE.Employee,
                ...(!isManager(currentEmployee.role) && {
                    params: {
                        searchCriteria: {
                            filterGroups: [
                                {
                                    filters: [
                                        {
                                            field: "current_handler_email",
                                            value: getLocalStorage('employee_email'),
                                            conditionType: "eq",
                                        },
                                    ],
                                },
                            ],
                        },
                    }
                })
            };
            try {
                const response = await doActionGet(config);
                if(response?.data?.items?.length>0){
                    const isActiveRequest= response?.data?.items?.some((item:Request)=>{
                      return item.status==="new";
                    })
                    setHasActiveRequests(isActiveRequest);
                }
                if (response?.data?.items) {
                    const sortedCustomersRequests = (response.data.items as Request[]).sort((a, b) => {
                        const nameA = a.customer_name.toLowerCase();
                        const nameB = b.customer_name.toLowerCase();
                        return nameA.localeCompare(nameB);
                    });
                    if (isManager(currentEmployee.role)) setCustomersRequests(sortedCustomersRequests)
                    else setCustomersRequests(sortedCustomersRequests.filter(request => (request.status === ENQUIRY_STATUSES.WORKING)));
                }
            } catch (error) {
                const typedError = error as ErrorResponse;
                if (typedError.response && typedError.response.status === 400) {
                    const errorMessage = typedError.response.data.message;
                    console.error(errorMessage);
                    showAlert(errorMessage, ALERT_STATUS.error);
                } else {
                    console.error("An unexpected error occurred:", error);
                    showAlert(CUSTOMER_LISTING_MESSAGES.unexpected_error.en, ALERT_STATUS.error);
                }
            } finally {
                setRequestsLoaded(true);
            }
        }
    }, [currentEmployee?.email, currentEmployee?.role]);

    useEffect(() => {
        fetchCustomersRequests();
    }, [fetchCustomersRequests]);

    const refetchCustomersRequests = () => {
        setRequestsLoaded(false);
        fetchCustomersRequests();
    };

    return { customersRequests, requestsLoaded, refetchCustomersRequests };
}

export default useEmployeeEnquiries;
import { Amplify } from "aws-amplify";

Amplify.configure({
    Auth: {
      Cognito: {
        userPoolClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID as string,
        userPoolId: process.env.REACT_APP_USER_POOL_ID as string,
        loginWith: {
          // Optional
          oauth: {
            domain: process.env.REACT_APP_POOL_DOMAIN as string,
            scopes: ["openid email phone openid email aws.cognito.signin.user.admin"],
            redirectSignIn: [process.env.REACT_APP_POOL_REDIRECT_SIGN_IN ?? ""],
            redirectSignOut: [process.env.REACT_APP_POOL_REDIRECT_SIGN_OUT ?? ""],
            responseType: "token",
          },
          username: true,
          email: false, // Optional
          phone: false
        },
      },
    }
  });

const CART_API_URL = `${process.env.REACT_APP_API_URL}carts/mine`;
const CART_UPDATE_API_URL = (item_id: number) =>
  `${process.env.REACT_APP_API_URL}carts/mine/items/${item_id}`;
const CART_CONSTANTS = {
  cart: {
    en: "CART",
  },
  shipping_fee: {
    en: "Delivery Fee",
  },
  sub_total: {
    en: "Sub Total",
  },
  estimating_tax: {
    en: "Tax",
  },
  total: {
    en: "Total",
  },
  advance_pay: {
    en: "Advance Payment"
  },
  balance_due: {
    en: "Balance Due"
  },
  checkout: {
    en: "Proceed to checkout",
  },
  clear_cart:{
    en: "Remove All items"
  },
  empty_cart: {
    en: "No items in the cart!!",
  },
  OFFER_LABEL: {
    en: "Add Coupon",
  },
  REMOVE_LABEL: {
    en: "Remove",
  },
  APPLY_LABEL: {
    en: "Apply",
  },
  DISCOUNT: {
    en: "Offer",
  },
  COUPON_TITLE: {
    en: "Coupon"
  }
};
const CART_API_ERRORS = {
  item_removal_error: "Item removal Failed!!",
  cart_update_error: "Cart Update Failed!!",
};
const PRODUCT_URL = (sku: string) => `/product/${sku}`;
export {
  CART_API_URL,
  CART_CONSTANTS,
  CART_UPDATE_API_URL,
  CART_API_ERRORS,
  PRODUCT_URL,
};

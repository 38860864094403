import { Address, AlertFunction } from "../types/types";
import { VALIDATION_CONSTANTS } from "../constants";

export interface ValidationErrors {
    firstname?: string;
    lastname?: string;
    street?: { [index: number]: string };
    city?: string;
    region?: string;
    postcode?: string;
    telephone?: string;
    general?: string; // Optional general error message
    [key: string]: string | { [index: number]: string } | undefined;
}

export const isValidForm = (formData: Address) => {
    // Check that at least one street field is non-empty
    const atLeastOneStreetFilled = formData?.street?.some(street => street.trim() !== '');

    return (
        atLeastOneStreetFilled &&
        formData?.city?.trim() !== '' &&
        formData?.region?.region?.trim() !== '' &&
        formData?.postcode?.trim() !== '' &&
        formData?.country_id?.trim() !== '' &&
        formData?.telephone?.trim() !== '' &&
        formData?.firstname?.trim() !== '' &&
        formData?.lastname?.trim() !== ''
    );
};

export const validateForm = (formData: Address) => {
    let isValid = true;
    const newErrors: Partial<ValidationErrors> = {};

    // Add a general error message if the form is not valid
    if (!isValid) {
        newErrors.general = "Please provide necessary details.";
    }

    return { isValid, newErrors };
};

export const handleValidation = (
    name: string, 
    value: string | any,
    setFormData: React.Dispatch<React.SetStateAction<Address>>,
    setErrors: React.Dispatch<React.SetStateAction<Partial<ValidationErrors>>>,
    onBlur = false
) => {
    let errorsUpdate: Partial<ValidationErrors> = {};

    // Helper functions for validation
    const isValidName = (name: string) => /^[A-Za-z\s]+$/.test(name);
    const isValidStreetOrCity = (input: string) => /^[A-Za-z0-9\s]+$/.test(input) && input.trim() !== '';
    const isValidPostcode = (postcode: string) => /^\d*$/.test(postcode);
    const isValidTelephone = (phone: string) => /^\d*$/.test(phone);

    if (value.trim() === '') {
        errorsUpdate[name] = '';
        setFormData(prev => ({ ...prev, [name]: value }));
        if (onBlur) {
            errorsUpdate[name] = "Please fill this field"; // Set error if field is empty on blur
        }
    } else {
        if (name === 'firstname' || name === 'lastname') {
            if (isValidName(value)) {
                if (value.length > VALIDATION_CONSTANTS.name.maxLength) {
                    // Name is valid but too long
                    errorsUpdate[name] = VALIDATION_CONSTANTS.name.tooLong;
                } else {
                    // Name is valid and within length constraints
                    setFormData(prev => ({ ...prev, [name]: value }));
                    errorsUpdate[name] = '';
                }
            } else {
                // Name is invalid
                errorsUpdate[name] = VALIDATION_CONSTANTS.name.invalidCharacters;
            }
        }

        if (name === 'city') {
            if (isValidStreetOrCity(value)) {
                if (value.length > VALIDATION_CONSTANTS.city.maxLength) {
                    errorsUpdate[name] = VALIDATION_CONSTANTS.city.tooLong;
                } else {
                    setFormData(prev => ({ ...prev, [name]: value }));
                    errorsUpdate[name] = '';

                }
            } else {
                errorsUpdate[name] = VALIDATION_CONSTANTS.city.invalidCharacters;
            }
        }

        if (name === 'region') {
            if (isValidStreetOrCity(value)) {
                if (value.length > VALIDATION_CONSTANTS.state.maxLength) {
                    errorsUpdate[name] = VALIDATION_CONSTANTS.state.tooLong;
                } else {
                    setFormData(prev => ({
                        ...prev,
                        region: {
                            ...prev.region,
                            region: value,
                            region_code: '',
                            region_id: undefined
                        },
                        region_id: undefined
                    }));
                    errorsUpdate[name] = '';

                }
            } else {
                errorsUpdate[name] = VALIDATION_CONSTANTS.state.invalidCharacters;
            }
        }

        if (name === 'postcode') {
            if (isValidPostcode(value)) {
                if (value.length > VALIDATION_CONSTANTS.zip_code.maxLength) {
                    errorsUpdate[name] = VALIDATION_CONSTANTS.zip_code.tooLong;
                } else {
                    setFormData(prev => ({ ...prev, [name]: value }));
                    errorsUpdate[name] = '';

                }
            } else {
                errorsUpdate[name] = VALIDATION_CONSTANTS.zip_code.postcode_numeric_error.en;
            }
        }

        if (name === 'telephone') {
            if (isValidTelephone(value)) {
                if (value.length > VALIDATION_CONSTANTS.telephone.maxLength) {
                    errorsUpdate[name] = VALIDATION_CONSTANTS.telephone.tooLong;
                } else {
                    setFormData(prev => ({ ...prev, [name]: value }));
                    errorsUpdate[name] = '';

                }
            } else {
                errorsUpdate[name] = VALIDATION_CONSTANTS.telephone.number_must_be_integer.en;
            }
        }
    }

    // Batch update to avoid overwrites due to async state update
    setErrors(prev => ({
        ...prev,
        ...errorsUpdate
    }));
};


export const validateStreet = (value: string, onBlur = false) => {
    const isValidStreetOrCity = (input: string) => /^[A-Za-z0-9'\.\-\s]+$/.test(input) && input.trim() !== '';
        
    if (value.trim() === '') {
        if (onBlur) {
            return "Please fill this field"; // Set error if field is empty on blur
        }
        return '';
    } else {
    if (isValidStreetOrCity(value)) {
            if (value.length > VALIDATION_CONSTANTS.street.maxLength) {
                return VALIDATION_CONSTANTS.street.tooLong;
            } else {
                return '';
            }
        } else {
            return VALIDATION_CONSTANTS.street.invalidCharacters;
        }
    }
};
import React, { useState, useEffect, useCallback } from 'react';
import { CART_API_ERRORS, EMPLOYEE_CART_API_URL, CART_CONSTANTS, CART_UPDATE_API_URL, CART_DELETE_ITEMS_API_URL, TOGGLE_BUTTON_LABELS, CART_ADVANCE_PAYMENT_SELECTION_API_URL, CART_LOCK_API_URL, PRODUCT_URL, SEARCH_API_URL, ADD_TO_CART_API_URL, GET_REALTED_PRODUCT_API_URL } from "./constant"
import { ALERT_STATUS, OUT_OF_STOCK, USER_TYPE, YOUR_CART_IS_LOCKED } from "../../../../lib/constant"
import useFetchData from "../../../../hooks/useFetchData"
import { Loading } from "../../../atoms/loading/Loading"
import * as S from "./styles/styles";
import { CartDetails, CartItem, GTMProductDetail, OrganizedCartItems, QuantityHandlerParams } from "./types/types";
import { formatPriceAndCurrency } from "../../../../helpers/utlis";
import { ReactComponent as DeleteIcon } from '../../../../globals/svg/delete.svg'
import { ReactComponent as SubtractIcon } from "../../../../globals/svg/subtract.svg"
import { ReactComponent as SearchIcon } from "../../../../globals/images/icons/search-icon.svg";
import { ReactComponent as AddIcon } from "../../../../globals/svg/add.svg"
import { ReactComponent as ScannerIcon } from "../../../../globals/svg/scanner-icon.svg"
import { doActionDelete, doActionPost, doActionPut, doActionGet } from "../../../../helpers/httpRequest";
import { useNotification } from "../../../../hooks/useNotification";
import { getCostInfo } from "../helpers/helpers";
import { useLocation, useNavigate } from "react-router-dom";
import { FormControl, Select, MenuItem } from "@mui/material";
import { cart_gtm, productDetails_gtm, removeAllItemFromCart_gtm, updateCart_gtm } from "../../../../helpers/GTM";
import { ProductItem, QuantityUpdate } from './types/specialComponentProductType';
import { PRODUCT_PAGE_ROUTE_PATH, SCAN_ROUTE_PATH } from '../../../../routes/routes-constant';
import Modal from './Modal';

import { ENQUIRY_STATUSES } from '../../assistance/constants';
import { getLocalStorage } from '../../../../helpers/localStorageUtil';

const DEBOUNCE_DELAY = 1000; // Delay in milliseconds

const SpecialOrderCartScreen = () => {
    const location = useLocation();
    const { quoteId, role, customerEmail } = location?.state ?? {};
   
    const [localQuantity, setLocalQuantity] = useState<{ [key: string]: number }>({})
    const [isAPICallInProgress, setIsAPICallInProgress] = useState(false)
    const [costInfo, setCostInfo] = useState<{ [key: string]: number; }>()
    const { showAlert } = useNotification();
    const { isLoading, data: cartItems } = useFetchData(EMPLOYEE_CART_API_URL(quoteId), USER_TYPE.Employee)
    const initialSelectedAdvancePaymentOption = (cartItems as unknown as CartDetails)?.extension_attributes?.selected_advance_payment_option ?? '';
    const items = (cartItems as unknown as CartDetails)?.items ?? [];
    const [specialCartItems, setSpecialCartItems] = useState<CartItem[]>([]);
    const cartResp = (cartItems as unknown as CartDetails);
    const availableAdvancePaymentOptions = (cartItems as unknown as CartDetails)?.extension_attributes?.available_advance_payment_options ?? [];
    const [isCartLocked, setIsCartLocked] = useState(1);
    const [formattedItemsForGTM, setFormattedItemsForGTM] = useState<GTMProductDetail[]>([]);
    const [advancePayAmount, setAdvancePayAmount] = useState(0);
    const [balanceDueAmount, setBalanceDueAmount] = useState(0);
    const [couponValue, setCouponValue] = useState<string>("");
    const [searchTerm, setSearchTerm] = useState("");
    const [searchResults, setSearchResults] = useState<ProductItem[]>([]);
    const [searchQuantityUpdate, setSearchQuantityUpdate] = useState<{ [key: string]: number }>({});
    const [isSearchDropdownVisible, setIsSearchDropdownVisible] = useState(false);
    const [refreshKey, setRefreshKey] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [relatedProducts, setRelatedProducts] = useState<ProductItem[]>([]);
    const [parentItemId, setParentItemId] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [searchKeyword,setSearchKeyword]= useState("");
    const paymentSlabPercentage = availableAdvancePaymentOptions.map(value => ({
        value: value.toString(),
        label: `${value} %`
    }));

    const [selectedAdvancePaymentOption, setSelectedAdvancePaymentOption] = useState('');
    const hasAdvancePaymentOption = Boolean(selectedAdvancePaymentOption) || Boolean(initialSelectedAdvancePaymentOption);

    const navigate = useNavigate();
    useEffect(() => {
        window?.dataLayer?.push({
            event: "show_customer_cart",
            customer_data: {
                customer: {
                    email: customerEmail,
                },
            },
            ecommerce: {
                cartItems: formattedItemsForGTM,
            },
            assign_data: {
                employee: {
                    email: "", firstName: "", lastName: ""
                },
                user_id: "",
                request_id: "",
                customer_id: customerEmail,
            },
            status: ENQUIRY_STATUSES.WORKING,
            cognito_id: (getLocalStorage("cognitoId") ?? "").replaceAll('\"', ''),
            emp_type: role,
        });

    }, [])
    //Maintain state of the Product Items
    useEffect(() => {
        if (items?.length) {
            setSpecialCartItems(items);
        }
    }, [items?.length]);

    useEffect(() => {
        if (searchResults?.length > 0) {
            const initialQuantities = searchResults.reduce<QuantityUpdate>((acc, item) => {
                acc[item.id] = 1;
                return acc;
            }, {});
            setSearchQuantityUpdate(initialQuantities);
        }
    }, [searchResults]);

    useEffect(() => {
        if (initialSelectedAdvancePaymentOption) {
            setSelectedAdvancePaymentOption(initialSelectedAdvancePaymentOption.toString());
        }
    }, [initialSelectedAdvancePaymentOption]);

    // useEffect(() => {
    //     let paymentPercentage: number = 0;
    //     if (selectedAdvancePaymentOption || initialSelectedAdvancePaymentOption) {
    //         paymentPercentage = Number(selectedAdvancePaymentOption) || Number(initialSelectedAdvancePaymentOption);
    //     }

    //     if (paymentPercentage && costInfo) {
    //         const advancePay = ((costInfo?.['grand_total'] * paymentPercentage) / 100);
    //         const balanceDue = (costInfo?.['grand_total'] - (costInfo?.['grand_total'] * paymentPercentage) / 100);
    //         setAdvancePayAmount(Number(advancePay.toFixed(2)));
    //         setBalanceDueAmount(Number(balanceDue.toFixed(2)));
    //     }

    // }, [initialSelectedAdvancePaymentOption, costInfo, selectedAdvancePaymentOption]);

    useEffect(() => {
        if (items?.length) {
            const formattedItems = items.map(item => {
                const attributes = item?.extension_attributes?.attribute_info?.reduce((acc: Record<string, string>, attr) => {
                    const label = attr.label ?? '';
                    const key = label.replace(/\s/g, '_');
                    if (key) {
                        acc[key] = attr.value ? attr.value : '';
                    }
                    return acc;
                }, {});

                return {
                    'id': `${item.item_id}`,
                    'name': `${item.name}`,
                    'price': `${item.price}`,
                    'quantity': `${item.qty}`,
                    ...attributes
                }
            });

            setFormattedItemsForGTM(formattedItems);
            let specialTotal=0;
            if((selectedAdvancePaymentOption || initialSelectedAdvancePaymentOption) && costInfo)
                items?.map((item:any)=>{
                  if(item?.extension_attributes?.is_available_item===0){
                    specialTotal += item?.price;
                  }
                  let paymentPercentage: number = 0;
                  paymentPercentage = Number(selectedAdvancePaymentOption) || Number(initialSelectedAdvancePaymentOption);
                const advancePay =(costInfo?.['grand_total']-specialTotal) + ((specialTotal * paymentPercentage) / 100);
                const balanceDue = costInfo?.['grand_total'] - advancePay;
                setAdvancePayAmount(Number(advancePay.toFixed(2))); //round off to 2 digits and then convert it to a number
                setBalanceDueAmount(Number(balanceDue.toFixed(2)));
          
              })
        }

    }, [items?.length,initialSelectedAdvancePaymentOption,costInfo, selectedAdvancePaymentOption]);

    useEffect(() => {
        if (formattedItemsForGTM && formattedItemsForGTM?.length) {
            cart_gtm(formattedItemsForGTM);
        }
    }, [formattedItemsForGTM]);

    useEffect(() => {
        if (items?.length) {
            let quantity: { [key: string]: number } = {};
            items.forEach(item => { quantity[String(item.item_id)] = item.qty })
            setLocalQuantity(quantity);
            setSpecialCartItems(items); //Maintain state of the Product Items
        }
        if (cartItems) {
            setCostInfo(getCostInfo((cartItems as unknown as CartDetails)?.extension_attributes?.cart_total))
            const lockedStatus = (cartItems as unknown as CartDetails)?.extension_attributes?.locked ?? 0;
            setIsCartLocked(lockedStatus);
        }
        if (cartResp?.extension_attributes?.coupon_string) {
            setCouponValue(cartResp?.extension_attributes?.coupon_string);
        }
    }, [cartItems, items?.length])

    const handleNavigateToPDP = (sku: string, quote_id: string, quote_item_id: number) => {
        navigate(PRODUCT_URL(sku), { state: { sku: sku, quoteId: quote_id, quoteItemId: quote_item_id ,specialOrderQuoteId:quoteId,user: "Employee"} })
        const itemData = formattedItemsForGTM.find(item => item.id === quote_item_id.toString());
        if (itemData) {
            productDetails_gtm(itemData);
        }
    };

    const quantityComponent = ({ qty, item_id, sku, quote_id }: QuantityHandlerParams) => {
        const handleUpdate = async (valueChange: number) => {
            const payload = {
                "cartItem": {
                    sku,
                    qty: (qty + valueChange),
                    quote_id,
                    item_id
                }
            }
            try {
                setIsAPICallInProgress(true)
                const response = await doActionPost({
                    url: CART_UPDATE_API_URL(quote_id),
                    userType: USER_TYPE.Employee,
                    data: payload
                })
                if (response?.data) {
                    setLocalQuantity(pre => ({ ...pre, [item_id]: (response?.data?.qty) }))
                    const cartTotal = response?.data?.extension_attributes?.cart_total
                    if (cartTotal) setCostInfo(getCostInfo(cartTotal))

                    const itemData = formattedItemsForGTM.find(item => item.id === item_id.toString());
                    const updatedItemData = {
                        quantity: qty + valueChange,
                        ...itemData
                    }
                    if (itemData) {
                        updateCart_gtm(updatedItemData);
                    }
                }
            }
            catch (err) { showAlert(CART_API_ERRORS.cart_update_error.en, ALERT_STATUS.error) }
            finally { setIsAPICallInProgress(false) }
        }

        return <div className="quantity-changer">
            <div onClick={() => handleUpdate(-1)} className={`quantity-button ${qty < 2 ? 'disabled' : ''}`}><SubtractIcon /></div>
            {qty}
            <div onClick={() => handleUpdate(1)} className={`quantity-button`}><AddIcon /></div>
        </div>
    }

    const handleDeleteItem = async (quote_id: string, item_id: number) => {
        try {
            setIsAPICallInProgress(true)
            const response = await doActionDelete({
                url: CART_DELETE_ITEMS_API_URL(quote_id, item_id),
                userType: USER_TYPE.Employee,
            })
            if (response?.data) {
                showAlert(CART_CONSTANTS.item_deleted.en, ALERT_STATUS.success);
                const itemData = formattedItemsForGTM.find(item => item.id === item_id.toString());
                if (itemData) {
                    removeAllItemFromCart_gtm([{ itemData }]);
                }
                // setLocalQuantity(pre => ({ ...pre, [item_id]: (response?.data?.qty) }))

                // Remove the deleted item from specialCartItems
                setSpecialCartItems(prevItems => prevItems.filter(item => item.item_id !== item_id));
                setCostInfo(getCostInfo(response?.data))
            }
        }
        catch (err) {
            console.log(err);
            showAlert(CART_API_ERRORS.item_removal_error.en, ALERT_STATUS.error)
        }
        finally { setIsAPICallInProgress(false) }
    }

    const handleAdvancePaymentOption = async (paymentOption: string) => {
        try {
            setIsAPICallInProgress(true)
            const response = await doActionPut({
                url: CART_ADVANCE_PAYMENT_SELECTION_API_URL(quoteId, paymentOption),
                userType: USER_TYPE.Employee,
            })
            if (response?.data) {
                if (response?.data === true) {
                    showAlert(CART_CONSTANTS.payment_slab_selection_success.en, ALERT_STATUS.success)
                }
            }
        }
        catch (err) { showAlert(CART_CONSTANTS.payment_slab_selection_failed.en, ALERT_STATUS.error) }
        finally { setIsAPICallInProgress(false) }
    }

    const handleApproveAndSubmit = async () => {
        try {
            setIsAPICallInProgress(true)
            const response = await doActionPut({
                url: CART_LOCK_API_URL(quoteId, 1),
                userType: USER_TYPE.Employee,
            })
            if (response?.data) {
                if (response?.data === true) {
                    setIsCartLocked(1);
                    // setShowModal(true);
                    showAlert(YOUR_CART_IS_LOCKED, ALERT_STATUS.success)
                }
            }
        }
        catch (err) { showAlert(CART_CONSTANTS.order_submission_failed.en, ALERT_STATUS.error) }
        finally { setIsAPICallInProgress(false) }
    }

    const organizeCartItems = (items: CartItem[]): OrganizedCartItems[] => {
        const parentItems: { [key: number]: OrganizedCartItems } = {};
        items?.forEach(item => {
            if (item?.extension_attributes?.parent_id) {
                const parentId = item?.extension_attributes?.parent_id;
                if (parentItems[parentId]) {
                    parentItems[parentId].children = parentItems[parentId].children || [];
                    parentItems[parentId].children?.push(item);
                } else {
                    parentItems[parentId] = { ...item, children: [item] };
                }
            } else {
                parentItems[item.item_id] = { ...item, children: [] };
            }
        });
        return Object.values(parentItems);
    };

    const handleSearch = async () => {
        try {
            setIsAPICallInProgress(true);
            const response = await doActionGet({
                url: SEARCH_API_URL(searchTerm),
                userType: USER_TYPE.Employee,
            });
            if (response?.data) {
                setSearchResults(response.data.items);
                setIsSearchDropdownVisible(true);
            }
        } catch (err) {
            console.log(err);
            showAlert(CART_API_ERRORS.search_error.en, ALERT_STATUS.error);
        } finally {
            setIsAPICallInProgress(false);
        }
    };

    const debouncedSearch = useCallback(() => {
        const handler = setTimeout(() => {
            handleSearch();
        }, DEBOUNCE_DELAY);
        return () => {
            clearTimeout(handler);
        };
    }, [searchTerm]);

    useEffect(() => {
        if (searchTerm) {
            const cleanUp = debouncedSearch();
            return cleanUp;
        } else {
            setIsSearchDropdownVisible(false);
        }
    }, [searchTerm, debouncedSearch]);

    const updateCartItems = (newCartItem: CartItem, quantity: number, imageUrl: string = "") => {

        if (imageUrl) {
            newCartItem.extension_attributes.image = imageUrl;
        }
        newCartItem.extension_attributes.parent_id = parentItemId;
        setSpecialCartItems(prevItems => {
            const updatedItems = [...prevItems];
            const existingItemIndex = updatedItems.findIndex(item => item.item_id === newCartItem.item_id);

            if (existingItemIndex >= 0) {
                updatedItems[existingItemIndex] = newCartItem;
            } else {
                updatedItems.push(newCartItem);
            }

            return updatedItems;
        });

        const existingItemIndex = specialCartItems.findIndex((item) => item.item_id === newCartItem.item_id);

        if (existingItemIndex >= 0) {
            // If the item already exists in the cart, update the quantity in the localQuantity state
            setLocalQuantity((prevQuantity) => ({
                ...prevQuantity,
                [newCartItem.item_id]: (prevQuantity[newCartItem.item_id] || 0) + quantity,
            }));
        } else {
            // If the item is new, add it to the specialCartItems state and set the initial quantity in the localQuantity state
            // setSpecialCartItems((prevItems) => [...prevItems, newCartItem]);
            setLocalQuantity((prevQuantity) => ({
                ...prevQuantity,
                [newCartItem.item_id]: quantity,
            }));
        }
    };

    const handleAddToCart = async (item: ProductItem, quantity: number) => {
        let sku = (item?.extension_attributes?.special_configurable_attributes && item?.extension_attributes?.special_configurable_attributes?.length > 0) ? item?.extension_attributes?.special_configurable_attributes[0]?.parent_sku : "";
        let imageUrl = (item?.media_gallery_entries && item?.media_gallery_entries?.length > 0) ? item?.media_gallery_entries[0]?.file : "";
        const filteredAttributes = (item?.extension_attributes?.special_configurable_attributes && item?.extension_attributes?.special_configurable_attributes?.length > 0) ? item?.extension_attributes?.special_configurable_attributes?.flatMap((item: any) => {
            return Object.values(item).filter(value => typeof value === 'object');
        }) : [];
        let payload: any = {
            "cartItem": {
                "sku": item?.sku,
                "qty": quantity,
                "quote_id": quoteId,
                "product_option": {
                    "extension_attributes": {
                        "configurable_item_options": filteredAttributes
                    }
                }
            }
        };
        
        // Conditionally add parent_quote_item_id if parentItemId exists
        if (parentItemId) {
            payload.cartItem.extension_attributes = {
                "parent_quote_item_id": parentItemId
            };
        }
      

        try {
            setIsAPICallInProgress(true);
            const response = await doActionPost({
                url: ADD_TO_CART_API_URL(quoteId),
                userType: USER_TYPE.Employee,
                data: payload
            });

            if (response?.data) {
                let newCartItem = response?.data as CartItem;
                // Update the cart items state with the new item
                updateCartItems(newCartItem, quantity, imageUrl);
                setIsModalOpen(false);
                setParentItemId(0);
                showAlert(CART_CONSTANTS.item_added_to_cart.en, ALERT_STATUS.success);
                setCostInfo(getCostInfo(response.data?.extension_attributes?.cart_total));
                handleClearSearch(); //clear the search
            }
        } catch (err) {
            console.log(err);
            showAlert(CART_API_ERRORS.add_to_cart_error.en, ALERT_STATUS.error);
        } finally {
            setIsAPICallInProgress(false);
        }
    };

    if (!quoteId) {
        return <div>{CART_CONSTANTS.please_select_valid_cart.en}</div>;
    }

    const getImageUrl = (item: ProductItem): string => {
        const imageEntry = item.media_gallery_entries.find(entry => entry.media_type === 'image');
        return imageEntry ? `${process.env.REACT_APP_PDP_IMAGES_BASE_URL}${imageEntry.file}` : '';
    }

    const handleClearSearch = () => {
        setSearchTerm("");
        setIsSearchDropdownVisible(false);
    };

    const handleSearchItemsQuantityDecrement = (itemId: number) => {
        setSearchQuantityUpdate((prevQuantities) => ({
            ...prevQuantities,
            [itemId]: (prevQuantities[itemId] || 1) > 1 ? (prevQuantities[itemId] || 1) - 1 : 1,
        }));
    };

    const handleSearchItemsQuantityIncrement = (itemId: number) => {
        setSearchQuantityUpdate((prevQuantities) => ({
            ...prevQuantities,
            [itemId]: (prevQuantities[itemId] || 1) + 1,
        }));
    };

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
        setParentItemId(0);
    };

    const getRelatedProductListData = async (realtedProductArray: any) => {
        try {
            setIsAPICallInProgress(true);
            const response = await doActionGet({
                url: GET_REALTED_PRODUCT_API_URL(realtedProductArray.toString()),
                userType: USER_TYPE.Employee,
            });
            if (response?.data) {
                setRelatedProducts(response.data.items);
                setIsModalOpen(true);
            }
        } catch (err) {
            console.log(err);
            showAlert(CART_API_ERRORS.search_error.en, ALERT_STATUS.error);
        } finally {
            setIsAPICallInProgress(false);
        }

    }
    const addComponentHandler = async (item: any) => {
        setParentItemId(item?.item_id);
        await getRelatedProductListData(item?.extension_attributes?.related_products);
    }

    const filteredRelatedItems = searchKeyword
    ? relatedProducts?.filter(item =>
        item?.sku?.toString()?.toLowerCase().includes(searchKeyword.toLowerCase()) ||
        item?.name?.toLowerCase().includes(searchKeyword.toLowerCase())
    )
    : relatedProducts;

    const handleRelatedProductSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setSearchKeyword(value);
    };

    return <S.Content>
        {/* <ConfirmationModal isOpen={showModal} message={YOUR_CART_IS_LOCKED} onConfirm={() => setShowModal(false)} onCancel={() => setShowModal(false)} /> */}
        <Modal isOpen={isModalOpen} toggleModal={toggleModal}>
            <div className='related-product-heading'>{CART_CONSTANTS.add_component_label.en}</div>
            <input
                    type="text"
                    className='input-search'
                    placeholder={"Search component"}
                    value={searchKeyword}
                    onChange={handleRelatedProductSearch}
                />
            {filteredRelatedItems?.length > 0 ? (
                filteredRelatedItems?.map((item) => (
                    <div key={item?.id} className="item">
                        <div className="item-flex-container">
                            <img className="item-image" src={getImageUrl(item)} alt={item?.name} />
                            <div className="item-details">
                                <div className="name">{item?.name}</div>
                                <div className="price">{item?.sku}</div>
                                <div className="price">{formatPriceAndCurrency(item?.price)}</div>
                            </div>
                            <div className="search-item-quantity-changer">
                                <div className="quantity-changer">
                                    <div
                                        onClick={() => handleSearchItemsQuantityDecrement(item?.id)}
                                        className={`quantity-button ${searchQuantityUpdate[item?.id] === 1 ? 'disabled' : ''}`}
                                    >
                                        <SubtractIcon />
                                    </div>
                                    {searchQuantityUpdate[item?.id] || 1}
                                    <div onClick={() => handleSearchItemsQuantityIncrement(item?.id)} className="quantity-button">
                                        <AddIcon />
                                    </div>
                                </div>
                                <button className="select-button" onClick={() => handleAddToCart(item, searchQuantityUpdate[item?.id] || 1)}>
                                    {CART_CONSTANTS.select.en}
                                </button>
                            </div>

                        </div>
                    </div>
                ))
            ) : (
                <div>No results found</div>
            )}
        </Modal>
        <div className='label-container'>
            <div className="title-text"> {CART_CONSTANTS.cart.en} </div>
            <div className='scanner' onClick={() => navigate(SCAN_ROUTE_PATH, { state: { user: "Employee", specialOrderQuoteId: quoteId } })}><ScannerIcon /></div>
        </div>
        <div className="search-bar">
            <div className="search-input-container">
                <SearchIcon className='search-icon' />
                <input
                    type="text"
                    className="search-input"
                    placeholder="Search for products"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
                {/* <button className="search-button" onClick={searchTerm ? handleClearSearch : handleSearch}>
                    {searchTerm ? "x" : <SearchIcon className='search-icon' />}
                </button>  */}
            </div>

            {searchTerm && isSearchDropdownVisible && (
                <div className="search-dropdown">
                    {searchResults?.length > 0 ? (
                        searchResults?.map((item) => {
                            // const inStock = item?.extension_attributes?.saleable_quantity>0?true:false;
                            const inStock =true;
                            return(
                            <div key={item.id} className="item">
                                <div className="item-flex-container">
                                    <img className="item-image" src={getImageUrl(item)} alt={item?.name} />
                                    <div className="item-details">
                                    {inStock ? (<div className="name">{item?.name}</div>) : (<div className="out-of-stock">{OUT_OF_STOCK}</div>)}
                                    {!inStock && <div className="name">{item?.name}</div>}
                                        <div className="price">{item?.sku}</div>
                                        <div className="price">{formatPriceAndCurrency(item?.price)}</div>
                                    </div>
                                    <div className="search-item-quantity-changer">
                                        {item?.type_id != "configurable" && item?.type_id != "bundle" && <div className="quantity-changer">
                                            <div
                                                onClick={() => handleSearchItemsQuantityDecrement(item?.id)}
                                                className={`quantity-button ${searchQuantityUpdate[item?.id] === 1 ? 'disabled' : ''}`}
                                            >
                                                <SubtractIcon />
                                            </div>
                                            {searchQuantityUpdate[item?.id] || 1}
                                            <div onClick={() => handleSearchItemsQuantityIncrement(item?.id)} className="quantity-button">
                                                <AddIcon />
                                            </div>
                                        </div>}
                                        <button onClick={() => { item?.type_id === "simple" ? handleAddToCart(item, searchQuantityUpdate[item?.id] || 1) : navigate(PRODUCT_PAGE_ROUTE_PATH.replace(':sku', item?.sku), { state: { user: "Employee", specialOrderQuoteId: quoteId } }) }}>
                                            Add to cart
                                        </button>
                                    </div>

                                    </div>
                                </div>
                            );
                        })
                    ) : (
                        <div>No results found</div>
                    )}
                </div>
            )}
        </div>

        {(costInfo?.['grand_total'] ?? 0) > 0 ? (
            <div className="cart">
                <div className="cart-container">
                    <div className="item-container">
                        {organizeCartItems(specialCartItems)?.map((item, index) => {
                            const { name, price, quote_id, sku, item_id, extension_attributes } = item ?? {};
                            if (!localQuantity[item_id]) return null;

                            const productSku = extension_attributes?.configure_parent_sku ?? sku;
                            let variantData: { [key: string]: string | undefined } = {}
                            extension_attributes?.attribute_info?.forEach(variant => {
                                if (variant.label) {
                                    const modifiedLabel = variant.label
                                        .toLowerCase()
                                        .replace(/ /g, '_');
                                    variantData[modifiedLabel as string] = variant.value;
                                }
                            });

                            const hasChildren = item?.children && item?.children?.length > 0;
                            const hasRelatedProducts = item?.extension_attributes?.related_products && item?.extension_attributes?.related_products?.length > 0
                            const inStock = item?.extension_attributes?.in_stock;
                            return (
                                <div key={`${item_id}-${index}`}>
                                    <div className={inStock ? "item" : "item item-out-of-stock"} onClick={() => handleNavigateToPDP(productSku, quote_id, item_id)}>
                                        <div className="item-flex-container">
                                            <img className="item-image"
                                                src={`${process.env.REACT_APP_PDP_IMAGES_BASE_URL}${extension_attributes?.image}`}
                                                alt={`item${index}`} />
                                            <div className="item-details">
                                                {inStock ? (<div className="name">{name}</div>) : (<div className="out-of-stock">{OUT_OF_STOCK}</div>)}
                                                {!inStock && <div className="name">{name}</div>}
                                                {variantData && Object.keys(variantData)?.length > 0 && <div className="price">
                                                    {"(" + Object.values(variantData)?.join('  ,  ') + ")"}
                                                </div>}
                                                <div className="price">{formatPriceAndCurrency(price)}</div>
                                            </div>
                                            <div className="item-quantity" onClick={(e) => e.stopPropagation()}>
                                                {quantityComponent({ qty: localQuantity?.[item_id], item_id, sku, quote_id })}
                                                <DeleteIcon className="delete-icon" onClick={(event) => { event.stopPropagation(); handleDeleteItem(quote_id, item_id); }} />
                                                {hasRelatedProducts && <div className='add-products-title' onClick={() => addComponentHandler(item)}>{CART_CONSTANTS.add_component_label.en}</div>}
                                            </div>
                                        </div>
                                    </div>

                                    {hasChildren && item.children && (
                                        <div className="item-child-container">
                                            {item.children.map(child => {
                                                if (!localQuantity[child.item_id]) return null;
                                                return (<div className="item-flex-container" key={child.item_id} onClick={(e) => e.stopPropagation()}>
                                                    <img className="item-image" src={`${process.env.REACT_APP_PDP_IMAGES_BASE_URL}${child.extension_attributes.image}`} alt={`child${child.item_id}`} />
                                                    <div className="item-details">
                                                        <div className="name">{child.name}</div>
                                                        <div className="price">{child?.sku}</div>
                                                        <div className="price">{formatPriceAndCurrency(child.price)}</div>
                                                    </div>
                                                    <div className="item-quantity">
                                                        <div className="item-quantity-changer">
                                                            {quantityComponent({ qty: localQuantity[child.item_id], item_id: child.item_id, sku: child.sku, quote_id: child.quote_id })}
                                                        </div>
                                                        <DeleteIcon className="delete-icon" onClick={() => handleDeleteItem(child.quote_id, child.item_id)} />

                                                    </div>
                                                </div>)
                                            })}
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                    </div>

                    <div className="payment-slab">
                        <S.Label>{CART_CONSTANTS.advance_payment_slab.en} </S.Label>
                        <FormControl fullWidth className="payment-slab-dropdown" size='small'>
                            <Select
                                displayEmpty
                                labelId="title-label"
                                id="advance-payment-slab"
                                name="advance-payment-slab"
                                value={selectedAdvancePaymentOption || ''}
                                onChange={(e) => { setSelectedAdvancePaymentOption(e.target.value); handleAdvancePaymentOption(e.target.value); }}
                                variant="outlined"
                                inputProps={{ 'aria-label': 'Without label' }}
                                renderValue={(selected) => {
                                    if (!selected) {
                                        return <span>{CART_CONSTANTS.select_payment_percentage.en}</span>;
                                    }
                                    return paymentSlabPercentage?.find(r => r.value === selected)?.label || CART_CONSTANTS.select_payment_percentage.en;
                                }}
                            >
                                <MenuItem disabled value="">
                                    <span>{CART_CONSTANTS.select_payment_percentage.en}</span>
                                </MenuItem>
                                {paymentSlabPercentage?.map((option) => (
                                    <MenuItem key={option?.value} value={option?.value}>
                                        {option?.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>

                    <div className="cart-total">
                        <div className="cost-info-container">
                            <div className="cost-info">
                                <div className='label'>{CART_CONSTANTS.sub_total.en}</div><span>:</span><p className='value'>{formatPriceAndCurrency(costInfo?.['subtotal'])}</p>
                            </div>
                            <div className="cost-info">

                            </div>
                            <div className="cost-info">
                                <div className='label'>{CART_CONSTANTS.estimating_tax.en}</div><span>:</span><p className='value'>{formatPriceAndCurrency(costInfo?.['tax'])}</p>
                            </div>
                            {costInfo?.["discount"] && <div className="cost-info">
                                <div>{`${CART_CONSTANTS.discount.en}(${couponValue})`}</div>
                                <span>:</span>
                                <p>
                                    -{formatPriceAndCurrency((costInfo?.["discount"] ? costInfo?.["discount"] * (-1) : 0))}
                                </p>
                            </div>}
                        </div>

                        <div className="total-amount">
                            <div className="cost-info">
                                <p className="cost-total">{CART_CONSTANTS.total.en}</p><span>:</span><p>{formatPriceAndCurrency(costInfo?.['grand_total'], 2)}</p>
                            </div>
                            {hasAdvancePaymentOption && advancePayAmount!==costInfo?.['grand_total'] && (
                                <>
                                    <div className="cost-info">
                                        <p className="cost-total">{CART_CONSTANTS.advance_pay.en}</p><span>:</span><p>{formatPriceAndCurrency(advancePayAmount, 2)}</p>
                                    </div>
                                    <div className="cost-info">
                                        <p className="cost-total">{CART_CONSTANTS.balance_due.en}</p><span>:</span><p>{formatPriceAndCurrency(balanceDueAmount, 2)}</p>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>

                <button onClick={handleApproveAndSubmit}>{CART_CONSTANTS.proceed.en}</button>
            </div>
        ) : !(isLoading || isAPICallInProgress) ? (
            <div className="empty-cart">{CART_CONSTANTS.empty_cart.en}</div>
        ) : null}
        {(isLoading || isAPICallInProgress) && <Loading />}
    </S.Content>
}

export default SpecialOrderCartScreen;

import { useEffect, useRef, useState } from "react";
import YoutubeVideoPlayer from "./YoutubeVideoPlayer";
import * as S from "./styles";
import { VIDEO_PLAYER_CONSTANTS } from "./constants";

type VideoPlayerProps = {
    videoUrl: string;
    onSkipOrFinish: () => void;
    allowSkip?: boolean;
    fullScreen?: boolean;
};

const CustomVideoPlayer: React.FC<VideoPlayerProps> = ({ videoUrl, onSkipOrFinish, allowSkip = true, fullScreen = true }) => {
    const [isVideoLoaded, setIsVideoLoaded] = useState(false);

    let videoComponent = null
    const videoRef = useRef<HTMLVideoElement>(null);

    useEffect(() => {
        const footer = document.getElementsByClassName("footer")[0] as HTMLElement;
        if (footer && fullScreen) {
            footer.style.display = "none";
        }
        if (!videoUrl?.includes('youtube')) setIsVideoLoaded(true)
    }, [fullScreen, videoUrl])

    if (!videoUrl?.includes('youtube')) {
        videoComponent = <div className="vid-container"><video
            ref={videoRef}
            src={videoUrl}
            controls
            autoPlay={fullScreen}
            className={fullScreen ? "video-player" : ''}
            muted
            playsInline
            onEnded={onSkipOrFinish}
        />
        </div>

    } else {
        const urlParams = new URLSearchParams(new URL(videoUrl).search);
        const videoId = urlParams.get('v') ?? '';
        videoComponent = <YoutubeVideoPlayer videoId={videoId} setIsVideoLoaded={setIsVideoLoaded} fullScreen={fullScreen} />

    }
    return <S.Content>{videoComponent}
        {allowSkip && isVideoLoaded && fullScreen && <button onClick={onSkipOrFinish} className="skip-button">
            {VIDEO_PLAYER_CONSTANTS.skip}
        </button>}</S.Content>
}
export default CustomVideoPlayer;
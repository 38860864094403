// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family: 'VideoJS';
    src: url('https://vjs.zencdn.net/7.15.4/font/video-js.eot');
    src: url('https://vjs.zencdn.net/7.15.4/font/video-js.eot?#iefix') format('embedded-opentype'),
         url('https://vjs.zencdn.net/7.15.4/font/video-js.woff2') format('woff2'),
         url('https://vjs.zencdn.net/7.15.4/font/video-js.woff') format('woff'),
         url('https://vjs.zencdn.net/7.15.4/font/video-js.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  
  .vjs-icon-placeholder:before,
  .vjs-icon-play:before,
  .vjs-icon-pause:before,
  .vjs-icon-volume-mute:before,
  .vjs-icon-volume-max:before,
  .vjs-icon-fullscreen-enter:before,
  .vjs-icon-fullscreen-exit:before,
  .vjs-icon-spinner:before,
  .vjs-icon-cog:before,
  .vjs-icon-subtitles:before {
    font-family: 'VideoJS';
  }
  `, "",{"version":3,"sources":["webpack://./src/helpers/VideoPlayer/videojs-icons.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,2DAA2D;IAC3D;;;kFAG8E;IAC9E,mBAAmB;IACnB,kBAAkB;EACpB;;EAEA;;;;;;;;;;IAUE,sBAAsB;EACxB","sourcesContent":["@font-face {\n    font-family: 'VideoJS';\n    src: url('https://vjs.zencdn.net/7.15.4/font/video-js.eot');\n    src: url('https://vjs.zencdn.net/7.15.4/font/video-js.eot?#iefix') format('embedded-opentype'),\n         url('https://vjs.zencdn.net/7.15.4/font/video-js.woff2') format('woff2'),\n         url('https://vjs.zencdn.net/7.15.4/font/video-js.woff') format('woff'),\n         url('https://vjs.zencdn.net/7.15.4/font/video-js.ttf') format('truetype');\n    font-weight: normal;\n    font-style: normal;\n  }\n  \n  .vjs-icon-placeholder:before,\n  .vjs-icon-play:before,\n  .vjs-icon-pause:before,\n  .vjs-icon-volume-mute:before,\n  .vjs-icon-volume-max:before,\n  .vjs-icon-fullscreen-enter:before,\n  .vjs-icon-fullscreen-exit:before,\n  .vjs-icon-spinner:before,\n  .vjs-icon-cog:before,\n  .vjs-icon-subtitles:before {\n    font-family: 'VideoJS';\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

export const PROJECT_SCREEN_CONSTANTS = {
    in_presenting: {
        en: "This screen is currently displaying a product. Please choose another screen or wait for it to become available."
    },
    disconnected: {
        en: "This screen is currently disconnected. Please choose another screen."
    },
    reconnect: {
        en: "Disconnected from server. Attempting to reconnect..."
    }
}

export const PROJECT_SCREEN_STATUS = {
    AVAILABLE: 'available',
    PRESENTING: 'presenting',
    OFFLINE: 'offline',
}
import React, { useEffect, useState } from "react";
import * as S from "../styles/styles";


const CheckoutInput: React.FC<{placeHolder:string,onchangeHandler:(value:string)=>void}> = ({placeHolder,onchangeHandler}) => {
    const[inputValue,setInputValue] =useState<string>("");

    const inputValueChangeHandler= (value:string)=>{
        setInputValue(value);
        onchangeHandler(value);
    }
    return (
    <S.Content className="input-box-content">
       <input className="input-box" type="text" placeholder={placeHolder} value={inputValue} onChange={(e:React.ChangeEvent<HTMLInputElement>)=>{inputValueChangeHandler(e.target.value)}}></input>
    </S.Content>
  );
};

export default CheckoutInput;

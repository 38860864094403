import { useEffect, useRef } from "react";
import { useGlobalState } from "../Context/GlobalStateContext";
import { useContext } from "react";
import { Notifications } from "../components/organisms/notification-listing/types/types";
import { DataContext } from "../Context/AppContext";
import { ACTION_TYPE } from "../Context/Constant";

const encodeAppSyncCredentials = () => {
  const creds = {
    host: process.env.REACT_APP_APPSYNC_HOST,
    "x-api-key": process.env.REACT_APP_APPSYNC_API_KEY,
  };
  const b64Creds = window.btoa(JSON.stringify(creds));
  return b64Creds;
};

const getWebsocketUrl = () => {
  const header = encodeAppSyncCredentials();
  const payload = window.btoa(JSON.stringify({}));
  const url = `wss://${process.env.REACT_APP_APPSYNC_REALTIME_HOST}/graphql?header=${header}&payload=${payload}`;
  return url;
};

const startSubscription = (websocket: WebSocket, cognitoUserId: string) => {
  const subscribeMessage = {
    id: window.crypto.randomUUID(),
    type: "start",
    payload: {
      data: JSON.stringify({
        query: `subscription OnMessageAdded($user_id: String!) {
                    messageAdded(user_id: $user_id) {
                      message_id
                      content
                      user_id
                      is_read
                      is_new_notification
                      created_at
                    }
                  }`,
        variables: { user_id: cognitoUserId }
      }),
      extensions: {
        authorization: {
          "x-api-key": process.env.REACT_APP_APPSYNC_API_KEY,
          host: process.env.REACT_APP_APPSYNC_HOST,
        },
      },
    },
  };
  websocket.send(JSON.stringify(subscribeMessage));
};

const startSubscriptionCount = (websocket: WebSocket, cognitoUserId: string) => {
  const subscribeMessage = {
    id: window.crypto.randomUUID(),
    type: "start",
    payload: {
      data: JSON.stringify({
        query: `subscription OnMessageCount($user_id: String!) {
                    messageCount(user_id: $user_id) {
                      count
                      read_count
                      user_id
                    }
                  }`,
        variables: { user_id: cognitoUserId }
      }),
      extensions: {
        authorization: {
          "x-api-key": process.env.REACT_APP_APPSYNC_API_KEY,
          host: process.env.REACT_APP_APPSYNC_HOST,
        },
      },
    },
  };
  websocket.send(JSON.stringify(subscribeMessage));
};

const useWebSocket = (cognitoUserId: string | null) => {
  const websocketRef = useRef<WebSocket | null>(null);
  const { notificationListData, setNotificationListData } = useGlobalState();
  const { state, dispatch } = useContext(DataContext);
  const { notification_data } = state

  useEffect(() => {
    if (!cognitoUserId) return;

    const url = getWebsocketUrl();
    const websocket = new WebSocket(url, ["graphql-ws"]);
    websocketRef.current = websocket;

    websocket.addEventListener("open", () => {
      websocket.send(
        JSON.stringify({
          type: "connection_init",
        })
      );
    });

    websocket.addEventListener("message", (event) => {
      let message = JSON.parse(event.data);
      console.log(message);
      switch (message.type) {
        case "connection_ack":
          console.log("connection established");
          startSubscription(websocket, cognitoUserId);
          startSubscriptionCount(websocket, cognitoUserId);
          break;
        case "start_ack":
          console.log("start_ack");
          break;
        case "error":
          console.error(message);
          break;
        case "data":
          console.log("data", message.payload.data);
          if (message?.payload?.data?.messageAdded) {
            const messageData = message?.payload?.data?.messageAdded
            const payload = { ...notification_data, [messageData.message_id]: (messageData.is_read === '0') }
            dispatch({ type: ACTION_TYPE.set_notification_data, payload });
            handleMessage(messageData);
          } else if (message?.payload?.data?.messageCount) handleMessageCount(message?.payload?.data?.messageCount);
          break;
      }
    });

    // return () => {
    //   if (websocketRef.current) {
    //     websocketRef.current.close();
    //   }
    // };
  }, [cognitoUserId, notificationListData]);

  const handleMessage = (message: any) => {
    let notificationList = Array.isArray(notificationListData?.notifications) ? notificationListData.notifications : [];

    let updatedNotificationList: Notifications[] = [];

    if (!(message?.is_new_notification && message?.is_new_notification === 1)) {
      updatedNotificationList = notificationList?.map((notification: Notifications) => {
        if (notification?.message_id === message?.message_id) {
          notification.seen = Number(message?.is_read);
        }
        return notification;
      });
    } else {
      let messageContent = JSON.parse(message?.content);
      const newNotification: Notifications = {
        message_id: message?.message_id,
        content: messageContent?.body,
        seen: Number(message?.is_read),
        created_at: message?.created_at,
        title: messageContent?.title,
        actions: messageContent?.actions,
        type: messageContent?.type
      };
      updatedNotificationList = [...notificationList, newNotification];
    }
    setNotificationListData({ ...notificationListData, notifications: updatedNotificationList });
  };

  const handleMessageCount = (newCountData: any) => {
    setNotificationListData({ ...notificationListData, count: newCountData?.count, seen_count: newCountData?.read_count });
  };
};

export default useWebSocket;

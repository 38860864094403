import styled, { css } from "styled-components";

export const Content = styled.div<any>`
  ${(p: any) => {
    return css`
      .image-viewer {
        background: white;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 400px;
        position: relative;

        img {
          object-fit: contain;
          max-width: 100%;
          height: 400px;
        }
      }
    `;
  }}
`;

import styled, { css } from "styled-components";

export const Content = styled.div<any>`
  ${(p: any) => {
    return css`
    @media print {
    .header,
    #non-printable {
        display: none !important;
    }
    #printable {
        display: block;
    }
    }

      .header {
        height: var(--header-height);
        box-shadow: rgba(0, 0, 0, 0.04) 0px 5px 5px;
        grid-template-columns: 1fr 6fr 1fr;
        display: grid;
        align-content: center;
      }
      .header-back-arrow {
        width: 33.51px;
        height: 35.29px;
      }
      .header-app-logo {
        width: 130px;
        height: 32px;
      }
      .header-scanner-section {
        width: 33.51px;
        height: 35.29px;
      }
      .header-content {
        display: flex;
        justify-content: center;
      }
      .disable-btn {
        pointer-events: none;
        opacity: 0.5;
      }
      .loading_container{
        position:absolute;
       }
    `;
  }}
`;

import styled from 'styled-components';

export const ModalOverlay = styled.div<{ isOpen: boolean }>`
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

export const ModalContent = styled.div`
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 87%;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  @media (min-width: 600px) {
    width: 80%;
  }
`;

 export const ModalMessage = styled.p`
  margin-bottom: 20px !important;
`;

export const ModalButton = styled.button`
  padding: 10px 20px;
  margin: 0 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: var(--sku-font-color);
  color: white;
  font-size: 16px;
  transition: background-color 0.3s ease;
  border-radius:100px;
  &:hover {
    background-color: #0056b3;
  }

  &:first-child {
    background-color: var(--sand-button-color);

    &:hover {
      background-color: #5a6268;
    }
  }
`;

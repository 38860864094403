import React, { ChangeEvent, useEffect, useState } from "react";
import { ADD_NEW_BILLING_ADDRESS, ADD_NEW_SHIPPING_ADDRESS, BILLING_ADDRESS_HEADING, GIFT_CART_LABEL, IS_BILLING_ADDRESS_SAME_AS_SHIPPING, SAVE_BUTTON_LABEL, SELECT_ANOTHER_ADDRESS, SELECT_ANOTHER_BILLING_ADDRESS, SHIPPING_ADDRESS_HEADING } from "../../../../../lib/constant";
import { Accordion, AccordionDetails, AccordionSummary, PlusAccordionSummary } from "../../../../molecules/accordion/accordionHelpers";
import { billToAddressProps, Region } from "../../types/types";
import { Address } from "../../../my-account/my-profile/types/types";
import { getCountryName } from "../../helper";
import { FormControl, MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material";
import { ADDRESS_FORM_UI_STRINGS } from "../../../my-account/my-profile/shared-components/constants";
import InputField from "../../../../molecules/input/InputField";
import { INPUT_FIELDS_PLACEHOLDERS, VALIDATION_CONSTANTS } from "../../../my-account/my-profile/constants";
import { handleValidation, isValidForm, validateStreet, ValidationErrors } from "../../../my-account/my-profile/shared-components/AddressFormUtility";
import { initialFormData } from "../../../my-account/my-profile/my-addresses/types/types";
import { CountryCode, getCountries, getCountryCallingCode } from "libphonenumber-js";
import * as S from "../../styles/styles";


const BillingAddress:React.FC<billToAddressProps> = ({addressList,selectedBillToId,onChange,countryData,onBillingSameAsShippingChangeHandler,newAddressSaveHandler,isBillingExpand,expandChangeHandler})=>{
    const [isGiftCart,setIsGiftCart] =useState<boolean>(false);
    const [isExpanded,setIsExpanded] = useState(false);
    const [isnewAddressExpand,setIsNewAddressExpand] = useState(false);
    const [isSameAsShipping,setIsSameAsShipping] = useState(true);
    const [telephoneNo,setTelephoneNo] =useState("");
    const [selectedAddressData,setSelectedAddressData]= useState<Address>();
    const [selectedCountry, setSelectedCountry] = useState<string>('');
    const [selectedRegion,setSelectedRegion]= useState<Region>();
    const [regions, setRegions] = useState<Region[]>([]);
    const [errors, setErrors] = useState<ValidationErrors>({});
    const [hasRegions, setHasRegions] = useState(false);
    const [formData, setFormData] = useState<Address>(initialFormData);
    const [phoneCode,setPhoneCode] = useState("+971");
    const [countryCodes,setCountryCodes] = useState<string[]>();
    useEffect(()=>{
        const countries: CountryCode[] = getCountries() as CountryCode[];
        
        let  countryCodesData: string[] = [];
    
        countries.forEach((country: CountryCode) => {
          if(!countryCodesData.includes(`+${getCountryCallingCode(country)}`)){
            countryCodesData.push(`+${getCountryCallingCode(country)}`);
          }
        });
        setCountryCodes(countryCodesData);
      },[])
    useEffect(()=>{
        setIsExpanded(isBillingExpand);
    },[isBillingExpand])

    useEffect(()=>{
        const selectedAddress= addressList.filter((address)=>{
            return address?.id === selectedBillToId;
        })
        if(selectedAddress && selectedAddress.length>0){
            setSelectedAddressData(selectedAddress[0]);
        }
    },[addressList,selectedBillToId])

    const onBillingChangeHandler=()=>{
        onBillingSameAsShippingChangeHandler(!isSameAsShipping);
        setIsSameAsShipping(prev=>!prev);
    }

    const addressSelectHandler = (id:Number) =>{
        onChange(id);
        onBillingChangeHandler();
    }

    
    const saveButtonHandler = ()=>{
        let payload ={
            "street":formData?.street,
            "city":formData?.city,
            region: {
                region_id: formData?.region?.region_id || 0,
                region: formData?.region?.region || '',
                region_code: formData?.region?.region_code || ''
              },
            "postcode":formData?.postcode,
            "country_id":formData?.country_id,
            "region_id": formData?.region?.region_id || 0,
            "firstname":formData?.firstname,
            "lastname":formData?.lastname,
            "default_shipping":false,
            "default_billing":false,
            "telephone":phoneCode+formData?.telephone
        }
        newAddressSaveHandler(payload);
        resetForm();
        setIsNewAddressExpand(false);
        setIsExpanded(false);

    }

    const handleCountryChange = (event: SelectChangeEvent) => {
        const countryId = event.target.value;
        setFormData(prev => ({
            ...prev,
            country_id: countryId,
        }));
        setupRegionsForCountry(countryId);
    };

    const setupRegionsForCountry = (countryId: string) => {
        const selectedCountry = countryData.find(country => country.id === countryId);
        const regionsAvailable = selectedCountry?.available_regions || [];
        setRegions(regionsAvailable);
        setHasRegions(!!regionsAvailable.length);
    };

    const resetForm = () => {
        setFormData(initialFormData);
        setHasRegions(false);
        setRegions([]);
    };


    const handleBlur = (event: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent) => {
        const { name, value } = event.target;
        handleValidation(name, value, setFormData, setErrors, true);
    };
    
    const handleStreetBlur = (index: number, value: string) => {
        const streetError = validateStreet(value, true);

        setErrors(prev => ({
            ...prev,
            street: {
                ...prev.street,
                [index]: streetError  // Store the error for the specific index
            }
        }));

    };

    const handleStreetChange = (index: number, value: string) => {

        const streetError = validateStreet(value);

        if (value.length <= VALIDATION_CONSTANTS.street.maxLength) {
            setFormData((prevFormData) => ({
                ...prevFormData,
                street: prevFormData.street?.map((item, i) => i === index ? value : item),
            }));
        }

        setErrors(prev => ({
            ...prev,
            street: {
                ...prev.street,
                [index]: streetError  // Store the error for the specific index
            }
        }));
    };

   

    const handleStateChangeSelectedFromDropdown = (event: SelectChangeEvent) => {
        const selectedRegion = regions.find(r => r.id === event.target.value);
        setFormData(prev => ({
            ...prev,
            region: {
                region_code: selectedRegion?.code || '',
                region: selectedRegion?.name || '',
                region_id: selectedRegion ? parseInt(selectedRegion.id, 10) : undefined
            },
            region_id: selectedRegion ? parseInt(selectedRegion.id, 10) : undefined
        }));
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent) => {
        const target = event.target as HTMLInputElement;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        handleValidation(name, value, setFormData, setErrors);
    };

    const onExpandChangeHandler = ()=>{
        expandChangeHandler(!isExpanded);
        setIsExpanded(pre =>!pre)
    } 
    return (
        <div className="customer-delivery">
            <div className="address-heading">{BILLING_ADDRESS_HEADING}</div>
            <div className="address-data">
            <div className="address-section">
            <div className="first-address-row">
                <div className="address-name">{IS_BILLING_ADDRESS_SAME_AS_SHIPPING}</div>
            </div>
            </div>
            <div className="address-radio">
            <input type="radio"  className="custom-radio" id="option3" checked={isSameAsShipping} onClick={onBillingChangeHandler} />
            </div>
            </div>
            {!isSameAsShipping &&  selectedAddressData &&  <div className="address-data"> <div className="address-section">
            <div className="first-address-row">
                <div className="address-name">{selectedAddressData?.firstname}&nbsp;{selectedAddressData?.lastname}</div>
            </div>
            <div className="address-row">
                    <div className="content-data">{selectedAddressData?.street}, {selectedAddressData?.city}</div>
                </div>
            <div className="address-row">
                <div className="content-data"> {selectedAddressData?.region?.region},{selectedAddressData?.postcode}</div>
            </div>
            <div className="address-row">
                <div className="content-data">{getCountryName(countryData,selectedAddressData?.country_id)[0]?.full_name_locale}</div>
            </div>
            </div>
            <div className="address-radio">
            <input type="radio"  className="custom-radio" id="option3" checked={true} />
            </div></div>}
            <div className="line"></div>

            <Accordion className="select-handler" expanded={isExpanded}>
            <AccordionSummary onClick={onExpandChangeHandler}>
                <div className="accordionText">{SELECT_ANOTHER_BILLING_ADDRESS}</div>
            </AccordionSummary >
            <AccordionDetails className="address-list-data">
                {isExpanded && <div className="address-list-section">
                {addressList && addressList.length>0 && addressList.map((address,index)=>{
                    return(
                        <>
                        <div onClick={()=>addressSelectHandler(Number(address.id))} className={Number(address.id)===Number(selectedBillToId) ?"line-item-selected":"line-item"}>{address?.firstname}&nbsp;{address?.lastname}</div>
                        <div className="line"></div>
                        </>
                    )
                })}
               
                    <Accordion expanded={isnewAddressExpand}>
                        <PlusAccordionSummary className="accordian-summary" onClick={() => { setIsNewAddressExpand(pre =>!pre) }}>
                            <div className="line-item">{ADD_NEW_BILLING_ADDRESS}</div>
                        </PlusAccordionSummary >
                        <AccordionDetails className="inner-accordian">
                            {isnewAddressExpand && 
                            <>
                            <div className="input-form">
                        <InputField type="text" className="input-box" name="firstname" value={formData.firstname} placeholder={INPUT_FIELDS_PLACEHOLDERS.firstname} onBlur={handleBlur} onChange={handleChange} error={errors.firstname} />
                        <InputField type="text" className="input-box" name="lastname" value={formData.lastname} placeholder={INPUT_FIELDS_PLACEHOLDERS.lastname} onBlur={handleBlur} onChange={handleChange} error={errors.lastname} />
                        {formData.street?.map((street, index) => (
                            <div key={index}>
                                <InputField
                                    type="text"
                                    className="input-box"
                                    name={`street${index}`}
                                    value={street}
                                    placeholder={INPUT_FIELDS_PLACEHOLDERS.street}
                                    onBlur={(e) => handleStreetBlur(index, e.target.value)}
                                    onChange={(e) => handleStreetChange(index, e.target.value)}
                                    error={errors.street && errors.street[index]}
                                />
                                {index > 0 && (
                                    <></>
                                )}
                            </div>
                        ))}
                        <InputField type="text" className="input-box" name="city" value={formData.city} placeholder={INPUT_FIELDS_PLACEHOLDERS.city} onBlur={handleBlur} onChange={handleChange} error={errors.city} />
                        <InputField type="text" className="input-box" name="postcode" value={formData.postcode} placeholder={INPUT_FIELDS_PLACEHOLDERS.zip_code} onBlur={handleBlur} onChange={handleChange} error={errors.postcode} />
                        <div className="phone-input-container">
                        <FormControl fullWidth className="phone-code-dropdown" size="small">
                            <S.StyledAutocomplete
                            options={countryCodes ?? []} 
                            value={phoneCode} 
                            onChange={(
                                event: React.SyntheticEvent<Element, Event>,
                                value: unknown
                            ) => {
                                setPhoneCode(value as string ?? "");
                            }}
                            renderInput={(params: any) => (
                                <TextField
                                {...params}
                                variant="outlined"
                                size="small"
                                
                                />
                            )}
                            />
                        </FormControl>

                        <InputField type="tel" noLabel={true} className={errors.telephone?"input-box tel-error-box":"input-box tel-input-box"} name="telephone" value={formData.telephone} placeholder={INPUT_FIELDS_PLACEHOLDERS.telephone} onBlur={handleBlur} onChange={handleChange} error={errors.telephone} />
                    </div>
                        <div className="dropdown-container">
                            <FormControl fullWidth size='small'>
                                <Select className="select-input-field" displayEmpty labelId="country-select-label" id="country" name="country_id" value={formData.country_id} onChange={handleCountryChange}
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    renderValue={(selected) => {
                                        if (!selected) {
                                            return <span>{ADDRESS_FORM_UI_STRINGS.actions.selectCountry.en}</span>;
                                        }
                                        return countryData?.find(c => c.id === selected)?.full_name_english || 'Select Country';
                                    }}
                                >
                                    <MenuItem disabled value="">
                                        <span>{ADDRESS_FORM_UI_STRINGS.actions.selectCountry.en}</span>
                                    </MenuItem>
                                    {countryData?.length > 0 ? (
                                        countryData?.map(country => (
                                            <MenuItem key={country?.id} value={country?.id}>{country?.full_name_english}</MenuItem>
                                        ))
                                    ) : (
                                        <MenuItem value="" disabled>
                                            Loading...
                                        </MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </div>
                        {/* Conditional State Selection */}
                        {hasRegions && (
                            <div className="dropdown-container">
                                <FormControl fullWidth size='small'>
                                    <Select className="select-input-field" displayEmpty labelId="state-select-label" id="state" name="region" value={formData.region?.region_id?.toString() || ''} onChange={handleStateChangeSelectedFromDropdown}
                                        inputProps={{ 'aria-label': 'Without label' }}
                                        renderValue={(selected) => {
                                            if (!selected) {
                                                return <span>{ADDRESS_FORM_UI_STRINGS.actions.selectState.en}</span>;
                                            }
                                            return regions?.find(r => r.id === selected)?.name || 'Select Emirates';
                                        }}
                                    >
                                        <MenuItem disabled value="">
                                            <span>{ADDRESS_FORM_UI_STRINGS.actions.selectState.en}</span>
                                        </MenuItem>
                                        {regions?.map(region => (
                                            <MenuItem key={region?.id} value={region?.id.toString()}>{region?.name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                        )}
                    </div>
                    <div className="button-section">
                        <button className="save-button" onClick={saveButtonHandler} type="button" disabled={!isValidForm(formData)}>{SAVE_BUTTON_LABEL}</button>
                    </div>
                             </>
                            }
                        </AccordionDetails>
                 </Accordion>
              
                </div>}
            </AccordionDetails>
        </Accordion>
        </div>
  );
};

export default BillingAddress;
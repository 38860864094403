import { ALERT_STATUS } from "../../../../../lib/constant";
import { Address, CustomerDetails, NavigateFunction, ShowAlert } from "../types/types";
import { getCountries, getCustomerData } from "./api-calls/apiCalls";
import { DATA_PROVIDER_MESSAGES } from "./constants";
import { getAttribute } from "./utils";

export const fetchAddresses = async (showAlert: ShowAlert, navigate: NavigateFunction) => {

    const customerData = await getCustomerData(showAlert, navigate);

    if (customerData) {
        return customerData.addresses as Address[];
    } else {
        showAlert(DATA_PROVIDER_MESSAGES.fetch_addresses_fail.en, ALERT_STATUS.error);
    }
};

export const fetchCustomerDetails = async (showAlert: ShowAlert, navigate: NavigateFunction) => {

    const customerData = await getCustomerData(showAlert, navigate);

    if (customerData) {
        const encryptionData = customerData.extension_attributes?.customer_encryption_attribute_data;

        const customerDetails = {
            firstname: getAttribute(encryptionData, 'firstname'),
            lastname: getAttribute(encryptionData, 'lastname'),
            email: getAttribute(encryptionData, 'email'),
            encrypted_email: customerData.email,
        };

        return customerDetails as CustomerDetails;
    } else {
        showAlert(DATA_PROVIDER_MESSAGES.fetch_user_data_fail.en, ALERT_STATUS.error);
    }
};

export const fetchCountries = async (showAlert?: ShowAlert) => {

    const countryList = await getCountries(showAlert);
    if (countryList) {
        return countryList;
    } else {
        showAlert?.(DATA_PROVIDER_MESSAGES.fetch_countries_fail.en, ALERT_STATUS.error);
    }
};

export const getCountryNameById = async (countryId: string | undefined, showAlert?: ShowAlert): Promise<string | undefined> => {
    
    if (!countryId) {
        showAlert?.(DATA_PROVIDER_MESSAGES.invalid_or_missing_country_id.en, ALERT_STATUS.error);
        return undefined;
    }

    try {
        const countryList = await fetchCountries(showAlert);
        if (countryList) {
            const country = countryList.find((c: { id: string; }) => c.id === countryId);
            return country ? country.full_name_english : undefined;
        } else {
            // Case where no countries could be fetched
            showAlert?.(DATA_PROVIDER_MESSAGES.no_countries_fetched.en, ALERT_STATUS.error);
        }
    } catch (error) {
        showAlert?.(DATA_PROVIDER_MESSAGES.fetch_country_name_fail.en, ALERT_STATUS.error);
    }
};
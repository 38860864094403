import React from 'react';
import { Box, Typography, Divider, Grid, useTheme, useMediaQuery } from '@mui/material';
import TrainingMaterial from './section-2/training-resources/TrainingMaterial';
import LogoLinks from './section-1/tresori-apps';
import { Root, LogoLinksContainer, StyledDivider, TrainingMaterialContainer, TresoriAppsContainer, HeadingContainer, getDividerStyles } from './styles/styles';
import { Apps, AttachFile } from '@mui/icons-material';
import { EMPLOYEE_DASHBOARD_CONSTANTS } from './constant';

const Dashboard: React.FC = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Root>
            <TresoriAppsContainer>
                <HeadingContainer>
                    <Typography variant="h6">{EMPLOYEE_DASHBOARD_CONSTANTS.section_1_heading.en} </Typography>
                </HeadingContainer>
                <LogoLinksContainer>
                    <LogoLinks />
                </LogoLinksContainer>
            </TresoriAppsContainer>
            <StyledDivider />
            <TrainingMaterialContainer>
                <HeadingContainer>
                    <Typography variant="h6">{EMPLOYEE_DASHBOARD_CONSTANTS.section_2_heading.en}</Typography>
                    <AttachFile />
                </HeadingContainer>
                <TrainingMaterial />
            </TrainingMaterialContainer>
        </Root>
    );
};

export default Dashboard;


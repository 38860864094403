export const PROFILE_MESSAGES = {
    update_success: {
        en: "Profile updated successfully",
        ar: "تم تحديث الملف الشخصي بنجاح"
    },
    update_fail_no_data: {
        en: "Failed to update profile data - No data received",
        ar: "فشل في تحديث بيانات الملف الشخصي - لم يتم استلام بيانات"
    },
    login_required: {
        en: "Please log in to continue.",
        ar: "الرجاء تسجيل الدخول للمتابعة."
    },
    update_fail_generic: {
        en: "Failed to update profile data. Please try again later.",
        ar: "فشل في تحديث بيانات الملف الشخصي. الرجاء المحاولة لاحقاً."
    },
    fetch_fail: {
        en: "Failed to fetch profile data. Please try again later.",
        ar: "فشل في استرجاع بيانات الملف الشخصي. الرجاء المحاولة لاحقاً."
    },
    fetch_preferences_fail: {
        en: "Failed to process preferences data. Please check the format of stored data.",
        ar: "فشل في معالجة بيانات التفضيلات. يرجى التحقق من تنسيق البيانات المخزنة."
    },
    fetch_preferences_error: {
        en: "Failed to process communication preferences:",
        ar: "فشل في معالجة تفضيلات الاتصال:"
    }
};

export const PROFILE_UI_STRINGS = {
    edit_profile: {
        en: "Edit Profile",
        ar: "تعديل الملف الشخصي"
    },
    title_section: {
        en: "Title",
        ar: "اللقب"
    },
    name_section: {
        en: "Name",
        ar: "الاسم"
    },
    surname_section: {
        en: "Surname",
        ar: "الكنية"
    },
    email_address_section: {
        en: "Email Address",
        ar: "عنوان البريد الإلكتروني"
    },
    phoneNumber_section: {
        en: "Phone",
    },
    my_addresses_section: {
        en: "My Addresses",
    },
    billing_address_section: {
        en: "Billing Address",
        ar: "عنوان الفواتير"
    },
    shipping_address_section: {
        en: "Delivery Address",
        ar: "عنوان الشحن"
    },
    my_preferences_section: {
        en: "My Preferences",
        ar: "تفضيلاتي"
    },
    no_data_display: {
        en: "No data to display",
        ar: "لا توجد بيانات للعرض"
    },
    no_address: {
        en: "No Address",
    },
    no_billing_address: {
        en: "No Billing Address",
        ar: "لا يوجد عنوان فواتير"
    },
    no_shipping_address: {
        en: "No Default Address",
        ar: "لا يوجد عنوان افتراضي"
    },
    no_profile_preferences: {
        en: "No preferences available",
        ar: "لا توجد تفضيلات متاحة"
    },
    add_new: {
        en: "Add New",
        ar: "أضف جديد"
    },
    edit: {
        en: "Edit",
        ar: "تحرير"
    },
    default: {
        en: "Default",
        ar: "افتراضي"
    },
    email_note:{
        en: "Tresori has captured this email id from login option you have choosen google/apple. You will be receiving tresori notifications on your registered email id.",
        ar: "افتراضي"
    }
};

export const ADDRESS_MESSAGES = {
    failed_fetch_data: {
        en: "Failed to fetch address data",
        ar: "فشل في جلب بيانات العنوان"
    },
    failed_fetch_user_data: {
        en: "Failed to fetch user Data",
        ar: "فشل في جلب بيانات المستخدم"
    },
    please_log_in: {
        en: "Please log in to continue.",
        ar: "الرجاء تسجيل الدخول للمتابعة."
    },
    failed_load_addresses: {
        en: "Failed to load addresses. Please try again later.",
        ar: "فشل تحميل العناوين. يرجى المحاولة لاحقاً."
    },
    failed_remove_addresses: {
        en: "Failed to remove addresses. Please try again later.",
        ar: "فشل في إزالة العناوين. يرجى المحاولة لاحقاً."
    },
    address_removed_success: {
        en: "Address removed successfully",
        ar: "تم إزالة العنوان بنجاح"
    }
};

export const ADDRESS_CARD_UI_STRING = {
    shipping_address_title: {
        en: "Delivery Address",
        ar: "عنوان الشحن"
    },
    add_new: {
        en: "Add New",
        ar: "إضافة جديد"
    },
    saved_addresses: {
        en: "saved addresses",
        ar: "العناوين المحفوظة"
    },
    edit: {
        en: "Edit",
        ar: "تعديل"
    },
    remove: {
        en: "Remove",
        ar: "إزالة"
    },
    no_address_selected: {
        en: "No address selected for editing",
        ar: "لم يتم تحديد عنوان للتحرير"
    },
    default_label: {
        en: "Default",
        ar: "افتراضي"
    },
    default_label_billing: {
        en: "Default Billing",
        ar: "الفواتير الافتراضية"
    },
    default_label_shipping: {
        en: "Default Delivery",
        ar: "الشحن التلقائي"
    },
    street: {
        en: "Street:",
        ar: "الشارع:"
    },
    city: {
        en: "City:",
        ar: "المدينة:"
    },
    state_province_area: {
        en: "Emirates:",
        ar: "الدولة/المقاطعة/المنطقة:"
    },
    zip_code: {
        en: "Zip code:",
        ar: "الرمز البريدي:"
    },
    country: {
        en: "Country:",
        ar: "البلد:"
    },
    telephone: {
        en: "Telephone:",
        ar: "هاتف:"
    },
    set_as_default_billing: {
        en: "Set as default billing",
        ar: ""
    },
    set_as_default_shipping: {
        en: "set as default delivery",
        ar: ""
    },
};

export const VALIDATION_CONSTANTS = {
    required: "This field is required",
    fill_all_required_fields: "Please fill all the required fields",
    telephone: {
        number_must_be_integer: {
            en: 'Telephone number must be numeric.',
            ar: 'رقم الهاتف يجب أن يكون رقمياً.'
        },
        maxLength: 10,
        tooLong: "Telephone must not exceed 10 digits."
    },
    name: {
        maxLength: 30,
        invalidCharacters: "Name must not contain special characters or numbers.",
        tooLong: "Name must not exceed 30 characters."
    },
    street: {
        maxLength: 100,
        tooLong: "Street must not exceed 100 characters.",
        invalidCharacters: "Street must not contain special characters."
    },
    city: {
        maxLength: 30,
        tooLong: "City must not exceed 30 characters.",
        invalidCharacters: "City must not contain special characters."
    },
    state: {
        maxLength: 30,
        tooLong: "State must not exceed 30 characters.",
        invalidCharacters: "State must not contain special characters."
    },
    zip_code: {
        postcode_numeric_error: {
            en: "Zip/Postal Code must be numeric.",
            ar: "يجب أن يكون الرمز البريدي رقميًا."
        },
        postcode_invalid_error: {
            en: "Provided Zip/Postal Code seems to be invalid. Example: 123456. If you believe it is the right one you can ignore this notice.",
            ar: "يبدو أن الرمز البريدي المقدم غير صالح. مثال: 123456. إذا كنت تعتقد أنه صحيح، يمكنك تجاهل هذا الإشعار."
        },
        maxLength: 6,
        tooLong: "Zip Code must not exceed 6 digits.",
        invalidCharacters: "Postal code must be numeric and not exceed 6 digits."

    },
}

export const INPUT_FIELDS_LABELS = {
    title: "Title",
    firstname: "First Name",
    lastname: "Last Name",
    surname: "Surname",
    street: "Street",
    country: "Country",
    state: "State",
    zip_code: "Zip Code",
    telephone: "Telephone",
}

export const INPUT_REQUIRED_FIELDS_LABELS = {
    firstname: "First Name *",
    lastname: "Last Name *",
    surname: "Surname *",
    city: "City *",
    street: "Street *",
    country: "Country *",
    state: "State/Province/Area *",
    zip_code: "Zip Code *",
    telephone: "Telephone *",
}

export const INPUT_FIELDS_PLACEHOLDERS = {
    firstname: "First Name",
    lastname: "Last Name",
    street: "Street",
    city: "City",
    country: "Country",
    state: "State",
    zip_code: "Zip Code",
    telephone: "Telephone No",
}


import React, { useEffect, useState } from "react";
import * as S from "../../organisms/employee-cart/styles/styles";
import { totalsSummaryType } from "../../organisms/employee-cart/types/types";
import { SUBTOTAL_LABEL, TAX_AND_FEE_LABEL, TOTAL_LABEL } from "../../../lib/constant";
import horizontalLine from "../../../globals/svg/horizontal-line.svg";
import { formatPriceAndCurrency, getStaticConstantValue } from "../../../helpers/utlis";



const TotalsSummary: React.FC<totalsSummaryType> = ({subtotal,tax,grandTotal,couponAmount,couponName}) => {
    const lineComponent = <img className="horizontal-line" src={horizontalLine}></img>;
    return (
    <S.Content>
      <div className="cart-summary">
        <div className="cart-subtotal">
            <div className="label">
                {SUBTOTAL_LABEL}
            </div>
            <div className="value">
            {formatPriceAndCurrency(subtotal)}
            </div>
        </div>
       {lineComponent}
        <div className="cart-subtotal">
            <div className="label">
                {TAX_AND_FEE_LABEL}
            </div>
            <div className="value">
            {formatPriceAndCurrency(tax)}
            </div>
        </div>
       {lineComponent}
        {couponName && <>
            <div className="cart-subtotal">
            <div className="label">
                {couponName}
            </div>
            <div className="value">
                -{formatPriceAndCurrency((couponAmount ? couponAmount*(-1) : 0))}
            </div>
        </div>
        {lineComponent}
        </>}
        <div className="cart-total">
            <div className="label">
                {TOTAL_LABEL}
            </div>
            <div className="value">
            {formatPriceAndCurrency(grandTotal)}
            </div>
        </div>
    </div>  
    </S.Content>
  );
};

export default TotalsSummary;

import styled from 'styled-components';
import { MainContentProps } from '../types/types';

export const MainContent = styled.div<MainContentProps>`
  height: var(--main-content-height);
  overflow-y: auto;
  box-sizing: border-box; /* Ensures padding is included in the height calculation */

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge, and Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
`;


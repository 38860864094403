interface TokenData {
  id: string;
  value: string | null;
}

interface ManageIndexedDBOptions {
  key: string;
  value?: string;
  toDelete?: boolean;
}

export function manageIndexedDB(
  options: ManageIndexedDBOptions
): Promise<void> {
  const { key, value = "", toDelete = false } = options;

  return new Promise((resolve, reject) => {
    const request = indexedDB.open("userDatabase", 1);

    request.onupgradeneeded = (event: IDBVersionChangeEvent) => {
      const db = (event.target as IDBOpenDBRequest).result;
      if (!db.objectStoreNames.contains("userStore")) {
        db.createObjectStore("userStore", { keyPath: "id" });
      }
    };

    request.onsuccess = (event: Event) => {
      const db = (event.target as IDBOpenDBRequest).result;
      const transaction = db.transaction(["userStore"], "readwrite");
      const store = transaction.objectStore("userStore");

      if (toDelete) {
        const deleteRequest = store.delete(key);
        deleteRequest.onsuccess = () => {
          resolve();
        };

        deleteRequest.onerror = () => {
          reject(deleteRequest.error);
        };
      } else {
        const data: TokenData = { id: key, value };
        const putRequest = store.put(data);
        putRequest.onsuccess = () => {
          resolve();
        };

        putRequest.onerror = () => {
          reject(putRequest.error);
        };
      }
    };

    request.onerror = () => {
      reject(request.error);
    };
  });
}

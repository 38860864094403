import { getStaticConstantValue } from "../../../../helpers/utlis";

const EMPLOYEE_CART_API_URL = (quote_id: string) =>
  `${process.env.REACT_APP_API_URL}carts/${quote_id}`;

const CART_UPDATE_API_URL = (quote_id: string) =>
  `${process.env.REACT_APP_API_URL}carts/${quote_id}/items`;

const CART_DELETE_ITEMS_API_URL = (quote_id: string, item_id: number) =>
  `${process.env.REACT_APP_API_URL}carts/${quote_id}/items/${item_id}`;

const CART_LOCK_API_URL = (quote_id: string, value: number) =>
  `${process.env.REACT_APP_API_URL}carts/${quote_id}/cart-lock/${value}`;

const ADD_TO_CART_API_URL = (quote_id: string) =>
  `${process.env.REACT_APP_API_URL}carts/${quote_id}/items`;

const SEARCH_API_URL = (searchTerm: string) =>
`${process.env.REACT_APP_API_URL}products?searchCriteria[filter_groups][0][filters][0][field]=website_id&searchCriteria[filter_groups][0][filters][0][value]=${getStaticConstantValue('website_id')}&searchCriteria[filter_groups][1][filters][0][field]=status&searchCriteria[filter_groups][1][filters][0][value]=1&searchCriteria[filter_groups][2][filters][0][field]=name&searchCriteria[filter_groups][2][filters][0][value]=%25${searchTerm}%25&searchCriteria[filter_groups][2][filters][0][condition_type]=like&searchCriteria[filter_groups][2][filters][1][field]=sku&searchCriteria[filter_groups][2][filters][1][value]=%25${searchTerm}%25&searchCriteria[filter_groups][2][filters][1][condition_type]=like&searchCriteria[filter_groups][3][filters][0][field]=readiness&searchCriteria[filter_groups][3][filters][0][value]=1&searchCriteria[filter_groups][4][filters][0][field]=visibility&searchCriteria[filter_groups][4][filters][0][value]=4`


const GET_REALTED_PRODUCT_API_URL = (searchedSku:string)=>
   `${process.env.REACT_APP_API_URL}products?searchCriteria[filter_groups][0][filters][0][field]=sku&searchCriteria[filter_groups][0][filters][0][value]=${searchedSku}&searchCriteria[filter_groups][0][filters][0][condition_type]=in`

const CART_ADVANCE_PAYMENT_SELECTION_API_URL = (
  quote_id: string,
  payment_option: string
) =>
  `${process.env.REACT_APP_API_URL}carts/${quote_id}/payment-percent/${payment_option}`;

const PRODUCT_URL = (sku: string) => `/product/${sku}`;

const CART_CONSTANTS = {
  cart: {
    en: "CART",
  },
  select:{
    en:"Select",
  },
  shipping_fee: {
    en: "Delivery Fee",
  },
  sub_total: {
    en: "Sub Total",
  },
  estimating_tax: {
    en: "Tax",
  },
  discount: {
    en: "Offer",
  },
  total: {
    en: "Total",
  },
  advance_pay: {
    en: "Advance Payment",
  },
  advance_payment_slab:{
    en:"Advance Payment Slab",
  },
  balance_due: {
    en: "Balance Due",
  },
  checkout: {
    en: "Proceed to checkout",
  },
  approve_nd_submit: {
    en: "Approve & Submit",
  },
  proceed: {
    en: "Proceed",
  },
  unlock_cart: {
    en: "Unlock Cart",
  },
  empty_cart: {
    en: "No items in the cart!!",
  },
  cart_is_locked: {
    en: "Cart is Locked, please unlock it first",
  },
  payment_slab_selection_success: {
    en: "Payment Slab Selected Successfully",
  },
  payment_slab_selection_failed: {
    en: "Payment Slab Selection Failed",
  },
  order_submission_success: {
    en: "Order Approved and Submitted Successfully",
  },
  order_submission_failed: {
    en: "Order submission failed",
  },
  cart_unlocked_success: {
    en: "Cart unlocked successfully",
  },
  cart_unlock_failed: {
    en: "Failed to unlock the cart",
  },
  please_select_valid_cart: {
    en: "Please select a valid cart.",
  },
  select_payment_percentage: {
    en: "Select Payment Percentage",
  },
  item_deleted: {
    en: "Item Deleted Successfully",
  },
  item_added_to_cart: {
    en: "Product Added to cart successfully"
  },
  add_component_label:{
    en:"Add Components"
  }
  
};
const CART_API_ERRORS = {
  item_removal_error: {
    en: "Item removal Failed!!",
  },
  cart_update_error: {
    en: "Cart Update Failed!!",
  },
  search_error: {
    en: "Search Failed",
  },
  add_to_cart_error: {
    en: "Add to cart failed"
  }
};

export const TOGGLE_BUTTON_LABELS = {
  show: {
    en: "See Child Components",
  },
  hide: {
    en: "Hide Child Components",
  },
};
export {
  EMPLOYEE_CART_API_URL,
  CART_CONSTANTS,
  CART_UPDATE_API_URL,
  CART_DELETE_ITEMS_API_URL,
  CART_LOCK_API_URL,
  CART_ADVANCE_PAYMENT_SELECTION_API_URL,
  SEARCH_API_URL,
  ADD_TO_CART_API_URL,
  PRODUCT_URL,
  GET_REALTED_PRODUCT_API_URL,
  CART_API_ERRORS,
};

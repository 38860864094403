import React, { useEffect, useState } from "react";
import * as S from "./styles";
 
const ToggleSwitch: React.FC<{label:string ,onchangeHandler:(value:boolean)=>void, value:boolean,isLocked?:boolean}> = ({label,onchangeHandler, value,isLocked=false}) => {
  const [isEnable, setIsEnable] =useState<boolean>(false);
  const onToggleChange = ()=>{
    if(isLocked)return
    var value= !isEnable;
    setIsEnable(isEnable => !isEnable)
    onchangeHandler(value);
}
  useEffect(()=>{
  setIsEnable(value);
  },[value])
  
  return (
    <S.View>
    <div className="container">
      <div className="toggle-switch">
        <input type="checkbox" className="checkbox"
               name={label} id={label} checked={isEnable} onChange={onToggleChange} />
        <label className="label" htmlFor={label}>
          <span className="inner" />
          <span className="switch" />
        </label>
      </div>
    </div>
    </S.View>
  );
};
 
export default ToggleSwitch;
import React, { useState, useEffect, useCallback } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import * as S from './styles/styles';
import { CancelModalProps } from './types/types';
import { ReactComponent as Cross } from "../../../globals/svg/remove_wishlist_item.svg";
import crossIcon from '../../../globals/svg/cross-icon.svg';
import { OTP_SEND_MSG, REASON_FOR_CANCELLATION, REASON_FOR_CANCELLATION_PLACEHOLDER, REF_NO_CANCELLATION_PLACEHOLDER, REF_NO_FOR_CANCELLATION } from '../../../lib/constant';

const OTPModalPopUp: React.FC<CancelModalProps> = ({ isOpen,
  onClose,
  onSubmit}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const [otp, setOtp] = useState(Array(6).fill(''));

  const handleChange = (e:any, index:any) => {
    const newOtp = [...otp];
    const value = e.target.value;

    if (/^[0-9]$/.test(value) || value === '') {
    newOtp[index] = value; 
    setOtp(newOtp);

    if (value && index < 5) {
        const nextInput = document.getElementById(`otp-input-${index + 1}`);
        if (nextInput) {
        nextInput.focus();
        }
    }
    }
}

const otpSubmitHandler = () =>{
        onSubmit(otp?.join(""));
    
}

  return (
    <S.Content>
      <Dialog open={isOpen} onClose={onClose}>
      <S.Content><div className='title-section'>
        <DialogTitle>Enter OTP</DialogTitle>
        <img className="login-logo"  onClick={onClose} src={crossIcon}></img>
        </div>
        <DialogContent className='dialog-content'>
        <div className="otp-confirmation-sender">
                <div className="otp-send-msg">{OTP_SEND_MSG}</div>
                <div className="otp-input-section">
                    {otp.map((value, index) => (
                        <input
                        key={index}
                        id={`otp-input-${index}`}
                        type="text"
                        value={value}
                        maxLength={1} // Limit input to 1 character
                        onChange={(e) => handleChange(e, index)}
                        className="otp-input"
                        pattern="[0-9]*"
                        />
                    ))}
                </div>
           </div>
        </DialogContent>
        
        <button className="cancel-return-btn" onClick={otpSubmitHandler}>Verify OTP</button>
        
        </S.Content>
      </Dialog>
    </S.Content>
  );
};

export default OTPModalPopUp;
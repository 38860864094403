import CircularProgress from "@mui/material/CircularProgress";
import styled from "styled-components";

export const Content = styled.div`

  .container {
    margin: 20px;
    display: flex;
    flex-direction: column;
  }
  
  .card-container {
    display: block;
    flex-direction: column; 
    gap: 20px;
    align-items: center; 
  }

  .button-section{
    display:flex;
    column-gap:13px;
  }
  
  .card {
    cursor: pointer;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    width: 100%; // Set width to 100% for column layout
    margin-bottom: 20px; // Add space between cards
    transition: transform 0.2s;
  }

    .customer-card {
        background-color: #fff;
        border: 1px solid #ddd;
        border-radius: 8px;
        box-shadow: 0 2px 4px rgba(0,0,0,0.1);
        margin-bottom: 10px; /* Consistent margin for all cards */
        padding: 16px; /* Consistent padding for all cards */
        /* Ensure all cards have the same width */
        width: calc(100% - 32px); /* 16px padding on each side */
    }

    .customer-card {
        position: relative;
    }

    .customer-info {
        display: flex;
        flex-direction: row; 
        align-items: center; 
        justify-content: space-between;
    }
  
  .card:hover {
    transform: translateY(-5px);
  }
  
  .cardContent {
    padding: 16px;
  }
  

  h3, p {
    margin: 0; /* Reset margins for these elements */
    padding: 4px 0; /* Add some padding for visual comfort */
  }
  
  p {
    color: #666;
    font-size: 14px;
  }
  
  .view-cart-button {
    background-color: var(--sand-button-color);
    color: white;
    font-size: 12px;
    border: none;
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
    cursor: pointer;
  }
  
  .view-cart-button:hover {
    background-color: var(--sand-button-color-on-hover);
  }

  .alphabetical-list ul {
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    list-style-type: none;
    padding: 0;
    z-index: 10;
    right: 10px;
  }

  .alphabetical-list li button {
    display: block;
    padding: 5px;
    cursor: pointer;
    background: transparent;
    border: none;
    color: #333;
  }

  // Style for search input
  input[type="text"] {
    margin-bottom: 20px;
    padding: 8px;
    width: calc(100% - 16px);
    border-radius: 4px;
    border: 1px solid #ccc;
  }
`;

export const Loader = styled(CircularProgress)`
  align-self: center;
`;
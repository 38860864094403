import { useEffect, useState, useCallback } from 'react';
import { doActionGet } from '../../../../../helpers/httpRequest';
import apiList, { USER_TYPE } from '../../../../../lib/constant';
import { Order } from '../types';
import { fetchCustomerEmail } from '../orderHelpers';

const useFetchOrders = (customer_id = '', order_id = '') => {
    const [orders, setOrders] = useState<Order[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    const fetchOrders = useCallback(async () => {
        try {
            setIsLoading(true);
            let response;
            if (customer_id) {
                response = await doActionGet({
                    url: apiList.getOrderBysEmployee(customer_id, order_id),
                    userType: USER_TYPE.Employee
                });
            } else {
                const userEmail = await fetchCustomerEmail();
                response = await doActionGet({
                    url: apiList.getOrders(order_id, userEmail),
                    userType: USER_TYPE.Customer
                });
            }
            setOrders(order_id ? [customer_id ? response?.data?.items?.[0] : response?.data] : response?.data?.items);
        } catch (error) {
            console.error("Error fetching orders:", error);
        } finally {
            setIsLoading(false);
        }
    }, [customer_id, order_id]);

    useEffect(() => { fetchOrders(); }, [fetchOrders]);

    const refreshOrders = useCallback(() => { fetchOrders(); }, [fetchOrders]);

    return { orders, isLoading, refreshOrders };
};

export default useFetchOrders;

import { useContext, useEffect, useState } from "react";
import * as S from "./styles/styles";
import apiList, { ALERT_STATUS, APP_NOTIFICATION_HEADING, APP_NOTIFICATION_SUB_HEADING, DATA_UPDATED_SUCCESSFULLY, MyAccount, NOTIFICATION_HEADING_LABEL, NO_NOTIFICATION_EXISTS, OPEN_REQUEST_HEADING, SEEN_COUNT_LABEL, SHOW_MORE_LABEL, SOMETHING_WENT_WRONG, TOTAL_COUNT_LABEL, UNSEEN_COUNT_LABEL, USER_TYPE } from "../../../lib/constant";
import { doActionGet, doActionPost, doActionPut } from "../../../helpers/httpRequest";
import { useNotification } from "../../../hooks/useNotification";
import { getLocalStorage, setLocalStorage } from "../../../helpers/localStorageUtil";
import { getTimeAgoString } from "./helper";
import { Action, Notifications } from "./types/types";
import { useAuth } from "../../../Context/AuthContext";
import { useNavigate } from "react-router-dom";
import { APPROVAL_PENDING_ORDER_ROUTE_PATH, ASSISTANCE_PATH, REQUEST_ASSISTANCE_PATH } from "../../../routes/routes-constant";
import { useGlobalState } from "../../../Context/GlobalStateContext";
import notifLogo from './../../../globals/svg/notifIcon.svg';
import { requestPushPermission } from "../../../helpers/NotificationHandler/subscribeNotification";
import { DataContext } from "../../../Context/AppContext";
import { ACTION_TYPE } from "../../../Context/Constant";

// const dummyNotification = {"count":16,"seen_count":0,"notifications":[
//     {
//       "message_id": "1",
//       "title": "New Order #9364",
//       "content": "Your order id 9364 has been placed . visit my orders to review",
//       "actions": {
//         "accept": {
//           "url": "assistance/inquiry/7",
//           "method": "PUT",
//           "payload": {
//             "inquiry": {
//               "action": "accept"
//             }
//           }
//         },
//         "reject": {
//           "url": "assistance/inquiry/7",
//           "method": "PUT",
//           "payload": {
//             "inquiry": {
//               "action": "reject"
//             }
//           }
//         }
//       },
//       "seen": 0,
//       "type": "assistance",
//       "created_at": "19/06/2024 11:00:00"
//     },
//     {
//       "message_id": "2",
//       "title": "New Order #9362",
//       "content": "Your order id 9362 has been placed . visit my orders to review",
//       "actions": {
//         "accept": {
//           "url": "assistance/inquiry/7",
//           "method": "PUT",
//           "payload": {
//             "inquiry": {
//               "action": "accept"
//             }
//           }
//         }
//       },
//       "seen": 1,
//       "type": "payment",
//       "created_at": "19/06/2024 10:38:00"
//     },
//     {
//       "message_id": "3",
//       "title": "New Order #9363",
//       "content": "Your order id 9364 has been placed . visit my orders to review",
//       "actions": {
//         "accept": {
//           "url": "assistance/inquiry/7",
//           "method": "PUT",
//           "payload": {
//             "inquiry": {
//               "action": "accept"
//             }
//           }
//         }
//       },
//       "seen": 0,
//       "type": "assistance",
//       "created_at": "18/06/2024 21:38:00"
//     },
//     {
//       "message_id": "4",
//       "title": "New Order #9364",
//       "content": "Your order id 9364 has been placed . visit my orders to review",
//       "actions": {
//         "accept": {
//           "url": "assistance/inquiry/7",
//           "method": "PUT",
//           "payload": {
//             "inquiry": {
//               "action": "accept"
//             }
//           }
//         }
//       },
//       "seen": 0,
//       "type": "payment",
//       "created_at": "15/06/2024 20:38:00"
//     },
//     {
//       "message_id": "5",
//       "title": "New Order #9365",
//       "content": "Your order id 9364 has been placed . visit my orders to review",
//       "actions": {
//         "accept": {
//           "url": "assistance/inquiry/7",
//           "method": "PUT",
//           "payload": {
//             "inquiry": {
//               "action": "accept"
//             }
//           }
//         },
//         "reject": {
//           "url": "assistance/inquiry/7",
//           "method": "PUT",
//           "payload": {
//             "inquiry": {
//               "action": "reject"
//             }
//           }
//         }
//       },
//       "seen": 0,
//       "type": "assistance",
//       "created_at": "19/06/2024 11:00:00"
//     },
//     {
//       "message_id": "6",
//       "title": "New Order #9366",
//       "content": "Your order id 9364 has been placed . visit my orders to review",
//       "actions": {
//         "accept": {
//           "url": "assistance/inquiry/7",
//           "method": "PUT",
//           "payload": {
//             "inquiry": {
//               "action": "accept"
//             }
//           }
//         }
//       },
//       "seen": 0,
//       "type": "payment",
//       "created_at": "19/06/2024 10:38:00"
//     },
//     {
//       "message_id": "7",
//       "title": "New Order #9367",
//       "content": "Your order id 9364 has been placed . visit my orders to review",
//       "actions": {
//         "accept": {
//           "url": "assistance/inquiry/7",
//           "method": "PUT",
//           "payload": {
//             "inquiry": {
//               "action": "accept"
//             }
//           }
//         }
//       },
//       "seen": 0,
//       "type": "assistance",
//       "created_at": "18/06/2024 21:38:00"
//     },
//     {
//       "message_id": "8",
//       "title": "New Order #9368",
//       "content": "Your order id 9364 has been placed . visit my orders to review",
//       "actions": {
//         "accept": {
//           "url": "assistance/inquiry/7",
//           "method": "PUT",
//           "payload": {
//             "inquiry": {
//               "action": "accept"
//             }
//           }
//         }
//       },
//       "seen": 0,
//       "type": "payment",
//       "created_at": "15/06/2024 20:38:00"
//     }, {
//       "message_id": "9",
//       "title": "New Order #9369",
//       "content": "Your order id 9364 has been placed . visit my orders to review",
//       "actions": {
//         "accept": {
//           "url": "assistance/inquiry/7",
//           "method": "PUT",
//           "payload": {
//             "inquiry": {
//               "action": "accept"
//             }
//           }
//         },
//         "reject": {
//           "url": "assistance/inquiry/7",
//           "method": "PUT",
//           "payload": {
//             "inquiry": {
//               "action": "reject"
//             }
//           }
//         }
//       },
//       "seen": 0,
//       "type": "assistance",
//       "created_at": "19/06/2024 11:00:00"
//     },
//     {
//       "message_id": "10",
//       "title": "New Order #93610",
//       "content": "Your order id 9364 has been placed . visit my orders to review",
//       "actions": {
//         "accept": {
//           "url": "assistance/inquiry/7",
//           "method": "PUT",
//           "payload": {
//             "inquiry": {
//               "action": "accept"
//             }
//           }
//         }
//       },
//       "seen": 0,
//       "type": "payment",
//       "created_at": "19/06/2024 10:38:00"
//     },
//     {
//       "message_id": "11",
//       "title": "New Order #93611",
//       "content": "Your order id 9364 has been placed . visit my orders to review",
//       "actions": {
//         "accept": {
//           "url": "assistance/inquiry/7",
//           "method": "PUT",
//           "payload": {
//             "inquiry": {
//               "action": "accept"
//             }
//           }
//         }
//       },
//       "seen": 0,
//       "type": "assistance",
//       "created_at": "18/06/2024 21:38:00"
//     },
//     {
//       "message_id": "12",
//       "title": "New Order #9312",
//       "content": "Your order id 9364 has been placed . visit my orders to review",
//       "actions": {
//         "accept": {
//           "url": "assistance/inquiry/7",
//           "method": "PUT",
//           "payload": {
//             "inquiry": {
//               "action": "accept"
//             }
//           }
//         }
//       },
//       "seen": 0,
//       "type": "payment",
//       "created_at": "15/06/2024 20:38:00"
//     }, {
//       "message_id": "13",
//       "title": "New Order #93613",
//       "content": "Your order id 9364 has been placed . visit my orders to review",
//       "actions": {
//         "accept": {
//           "url": "assistance/inquiry/7",
//           "method": "PUT",
//           "payload": {
//             "inquiry": {
//               "action": "accept"
//             }
//           }
//         },
//         "reject": {
//           "url": "assistance/inquiry/7",
//           "method": "PUT",
//           "payload": {
//             "inquiry": {
//               "action": "reject"
//             }
//           }
//         }
//       },
//       "seen": 0,
//       "type": "assistance",
//       "created_at": "19/06/2024 11:00:00"
//     },
//     {
//       "message_id": "14",
//       "title": "New Order #93614",
//       "content": "Your order id 9364 has been placed . visit my orders to review",
//       "actions": {
//         "accept": {
//           "url": "assistance/inquiry/7",
//           "method": "PUT",
//           "payload": {
//             "inquiry": {
//               "action": "accept"
//             }
//           }
//         }
//       },
//       "seen": 0,
//       "type": "payment",
//       "created_at": "19/06/2024 10:38:00"
//     },
//     {
//       "message_id": "15",
//       "title": "New Order #93615",
//       "content": "Your order id 9364 has been placed . visit my orders to review",
//       "actions": {
//         "accept": {
//           "url": "assistance/inquiry/7",
//           "method": "PUT",
//           "payload": {
//             "inquiry": {
//               "action": "accept"
//             }
//           }
//         }
//       },
//       "seen": 0,
//       "type": "assistance",
//       "created_at": "18/06/2024 21:38:00"
//     },
//     {
//       "message_id": "16",
//       "title": "New Order #93616",
//       "content": "Your order id 9364 has been placed . visit my orders to review",
//       "actions": {
//         "accept": {
//           "url": "assistance/inquiry/7",
//           "method": "PUT",
//           "payload": {
//             "inquiry": {
//               "action": "accept"
//             }
//           }
//         }
//       },
//       "seen": 0,
//       "type": "payment",
//       "created_at": "15/06/2024 20:38:00"
//     }

//   ]};

const NotificationListing = () => {
    const { showAlert } = useNotification();
    const [notificationList, setNotificationList] = useState<Notifications[]>([]);
    const [totalCount, setTotalCount] = useState(0);
    const [seenCount, setSeenCount] = useState(0);
    const { isUserLoggedIn, isEmployeeLoggedIn } = useAuth();
    const navigate = useNavigate();
    const [endOffSet, setEndOffSet] = useState(4);
    const { notificationListData, setNotificationListData } = useGlobalState();
    const { state, dispatch } = useContext(DataContext);
    const { notification_data } = state

    const getAllNotification = () => {
        if (process.env.REACT_APP_GET_ALL_PUSH_NOTIFICATIONS_API) {
            const payload = {
                "user_id": JSON.parse(getLocalStorage("cognitoId")),
                "start_offset": 0,
                "limit": endOffSet
            }
            doActionPost({
                url: process.env.REACT_APP_GET_ALL_PUSH_NOTIFICATIONS_API,
                data: payload
            })?.then(async (resp: any) => {
                if (resp?.data?.notifications && resp?.data?.notifications?.length > 0) {
                    setNotificationList(resp?.data?.notifications);
                    // setLocalStorage("notificationList",JSON.stringify(resp?.data?.notifications));
                }
                setTotalCount(resp?.data?.count);
                setSeenCount(resp?.data?.seen_count);
                setNotificationListData(resp?.data);
                const notificationPayload: ({ [key: string]: boolean }) = {}
                resp?.data?.notifications?.forEach((notification: { message_id: string | number; seen: string; }) => { notificationPayload[notification.message_id] = (notification.seen === '0') })
                dispatch({ type: ACTION_TYPE.set_notification_data, payload: notificationPayload });
                // setLocalStorage("notificationList",JSON.stringify(resp?.data));
            }
            ).catch((error: any) => {
                console.log("error msg", error);
                showAlert(SOMETHING_WENT_WRONG, ALERT_STATUS.error);
            })

            //   setNotificationList(dummyNotification?.notifications);
            //     setTotalCount(dummyNotification?.count);
            //     setSeenCount(dummyNotification.seen_count);
            //     setLocalStorage("notificationList",JSON.stringify(dummyNotification?.notifications));
        }
    }

    // useEffect(()=>{
    //     if(getLocalStorage("notificationList")){
    //         console.log("in 402");
    //         // setNotificationList(JSON.parse(getLocalStorage("notificationList")));
    //     }
    // },[getLocalStorage("notificationList")])

    useEffect(() => {
        if (notificationListData?.notifications) {
            setNotificationList(notificationListData?.notifications);
        }
        if (notificationListData?.count) {
            setTotalCount(Number(notificationListData?.count));
        }
        if (notificationListData?.seen_count) {
            setSeenCount(Number(notificationListData?.seen_count));
        }
    }, [notificationListData]); // Empty dependency array to run once after initial render


    useEffect(() => {
        if (getLocalStorage("cognitoId")) getAllNotification();
    }, [getLocalStorage("cognitoId")])

    const handleClick = (action: Action, notification: Notifications) => {
        if (action?.method && action?.url) {
            const apiMethod = action?.method.toUpperCase() === "PUT" ? doActionPut : doActionPost;
            apiMethod({
                url: apiList.setNotificationAction(action?.url),
                data: action?.payload ?? JSON.stringify(action?.payload),
                userType: USER_TYPE.Employee
            })?.then((resp: any) => {
                showAlert(DATA_UPDATED_SUCCESSFULLY, ALERT_STATUS.success);
            }
            ).catch((error: any) => {
                console.error("error msg", error);
                showAlert(SOMETHING_WENT_WRONG, ALERT_STATUS.error);
            }).finally(() => {
                seenNotificationhandler(notification);
            })
        }
    }


    const seenNotificationhandler = (notificationData: Notifications) => {
        dispatch({ type: ACTION_TYPE.set_notification_data, payload: { ...notification_data, [notificationData.message_id]: false } });
        //  const updatedNotificationList=   notificationList?.map((notification:Notifications) => 
        //           notification.message_id === notificationData?.message_id
        //             ? { ...notification, seen: 1 } 
        //             : notification
        //         );
        //         setNotificationList(updatedNotificationList);
        //         let notificationListData = getLocalStorage("notificationList")? JSON.parse(getLocalStorage("notificationList")):[];
        //         setLocalStorage("notificationList",JSON.stringify({...notificationListData,notifications:updatedNotificationList}));
        //setLocalStorage("notificationList",JSON.stringify(updatedNotificationList));
        //setSeenCount(count=>count-1);
        if (process.env.REACT_APP_UPDATE_NOTIFICATION_API) {
            doActionPut({
                url: process.env.REACT_APP_UPDATE_NOTIFICATION_API,
                data:
                {
                    "message_id": notificationData?.message_id
                }
            })?.then((resp: any) => {
                console.log("Data seen request sent successfully");
            }
            ).catch((error: any) => {
                console.error("error msg", error);
            })
        }
        if (notificationData?.type === "assistance" && isUserLoggedIn) {
            navigate(REQUEST_ASSISTANCE_PATH);
        }
        if (notificationData?.type === "assistance" && isEmployeeLoggedIn) {
            navigate(ASSISTANCE_PATH);
        }
        if (notificationData?.type === "payment" && isEmployeeLoggedIn) {
            navigate(APPROVAL_PENDING_ORDER_ROUTE_PATH);
        }
        if ((notificationData?.type === "order" || notificationData?.type === "invoice" || notificationData?.type === "shipping") && isUserLoggedIn) {
            navigate(`${MyAccount}`, { state: { tab: 'orders' } });
        }
    };

    const offsetHandler = () => {
        setEndOffSet(endOffSet => endOffSet + 4);
    }

    return (
        <S.Content>
            {notificationList?.length === 0 && <div className="notification-page">{NO_NOTIFICATION_EXISTS}</div>}
            {notificationList?.length > 0 && <><div className="notification-page">
                <div className="notication-heading">{NOTIFICATION_HEADING_LABEL}</div>
                {/* <div className="notication-counts">
                    <div className="count-title">{TOTAL_COUNT_LABEL} : {totalCount}</div>
                    <div className="count-title">{SEEN_COUNT_LABEL} : {seenCount}</div>
                    <div className="count-title">{UNSEEN_COUNT_LABEL} : {totalCount - seenCount}</div>
                </div> */}
            </div>
                {/* <div className="horizontal-line"></div> */}
                <div className="notification-section">
                    {notificationList?.slice(0, endOffSet)?.map((notification: Notifications) => {
                        return (
                            <>
                                <div className={`notif-section ${Number(notification?.seen) === 1 ? "seen" : "unseen"}`} onClick={() => { seenNotificationhandler(notification) }}>
                                    <div className="notif-title"><div className="notif-title">{Number(notification?.seen) === 0 ? <img className="img-section" src={notifLogo} /> : <div></div>}{notification?.title} </div>{notification?.created_at && <div className="notif-title">{getTimeAgoString(notification?.created_at)}</div>}</div>
                                    <div className={`notif-message-${Number(notification?.seen) === 1 ? "seen" : "unseen"}`}>{notification?.content}</div>
                                    <div className="action-section">
                                        {notification?.actions && Object.keys(notification?.actions).map((key) => (
                                            <button className="action-button" key={key} onClick={() => handleClick(notification?.actions[key], notification)}>
                                                {key?.toUpperCase()}
                                            </button>
                                        ))}
                                    </div>
                                </div>
                                <div className="horizontal-line"></div>
                            </>
                        )
                    })}
                </div>
                {(totalCount > endOffSet) &&
                    <div className="show-more-button-section">
                        <a href="#" className="show-more-link" onClick={() => { offsetHandler() }}>
                            {SHOW_MORE_LABEL}
                        </a>
                    </div>
                }</>}
            <div className="notification-req-section">
                <div className="notif-req-heading">{APP_NOTIFICATION_HEADING}</div>
                <div className='notif-later-text' onClick={() => { requestPushPermission(getLocalStorage('cognitoId'), true) }}>{OPEN_REQUEST_HEADING}</div>
                <div className="notif-later-sub-text">{APP_NOTIFICATION_SUB_HEADING}</div>
            </div>
        </S.Content>
    )
}
export default NotificationListing; 
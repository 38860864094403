import React, { ReactNode, useEffect, useState } from 'react';
import * as S from "./styles/thumbnailStyles";
import { getVideoThumbnail } from './common/helpers';

interface VideoThumbnailProps {
  url: string;
  children: ReactNode;
}

const VideoThumbnail: React.FC<VideoThumbnailProps> = ({ url, children }) => {
  const isYoutubeVideo = url.includes('youtube.com') || url.includes('youtu.be');
  const [thumbnailUrl, setThumbnailUrl] = useState<string | null>(null);

  useEffect(() => {
    const fetchThumbnail = async () => {
      if (isYoutubeVideo) {
        const match = url.match(/[?&]v=([^&]+)/);
        const videoID = match ? match[1] : null;
        setThumbnailUrl(videoID ? `https://img.youtube.com/vi/${videoID}/hqdefault.jpg` : '');
      } else {
        try {
          const thumbnailUrl = await getVideoThumbnail(url, 1);
          setThumbnailUrl(String(thumbnailUrl));
        } catch (err) {
          console.error(err, 'Video thumbnail load failed')
        }
      };
    }
    fetchThumbnail();
  }, [url, isYoutubeVideo]);

  return (
    <S.Content>
      <div className="thumbnail-container">
        {thumbnailUrl ? (
          <img src={thumbnailUrl} alt="Video Thumbnail" />
        ) : (
          <div className="thumbnail-placeholder" />
        )}
        <div className="play-button">
          {children}
        </div>
      </div>
    </S.Content>
  );
};

export default VideoThumbnail;

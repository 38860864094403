import styled, { css } from 'styled-components';
import { InputFieldProps } from '../types/types';

export const InputContainer = styled.div<{inputType?: string}>`
  display: flex;
  flex-direction: column;
  // padding: 0 30px;
  // margin-bottom: 15px;

  ${({ inputType }) => (inputType === 'checkbox' || inputType === 'radio') && css`
    flex-direction: row;
    align-items: center;
  `}
`;

export const Label = styled.label<{inputType?: string}>`
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  line-height: 24px;
  color: var(--grey-text);
  ${({ inputType }) => (inputType === 'checkbox' || inputType === 'radio') ? css`margin-left: 10px;` : css`margin-bottom: 5px;`}
`;

export const Input = styled.input<InputFieldProps>`
  padding: 10px;
  border: 1px solid ${props => props.hasError ? '#D8000C' : '#DADADA'};

  // Handling focus state
  &:focus {
    outline: none;
    border: 1px solid ${props => props.hasError ? '#D8000C' : '#A0A0A0'};
  }

  border-radius: 5px;
  ${({ type }) => (type === 'checkbox' || type === 'radio') && css`
    padding: 0;
    margin-right: 10px;
    width: auto;
  `}
`;


export const ErrorText = styled.span`
  font-size: 12px;
  color: #D8000C;
  margin-top: 5px;
`;
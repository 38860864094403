import styled, { css } from "styled-components";

export const Content = styled.div<any>`
  ${(p: any) => {
    return css`
      .addToCartContainer {
        display: flex;
        flex-direction: column;
      }
      .qtyPrice {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 20px;
        align-items: center;
      }
      .disabled {
        pointer-events: none;
        opacity: 0.5;
      }
      .fadeOut {
        opacity: 0.5;
      }
      .qtyPrice .qty {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        color: #424242;
        text-align: center;
        font-family: Poppins;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        width: 30%;
      }
      .price {
        color: #6f6855;
        text-align: right;
        font-family: Poppins;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 110%;
      }
    `;
  }}
`;

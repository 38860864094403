import styled, { css } from "styled-components";

export const Content = styled.div<any>`
  ${(p:any) => {
    return css`
    
    .order-success-section{
      position: absolute;
      width: 100%;
      top: 50%;
      left: 50%;
      transform: translateX(-50%)translateY(-50%);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-content: center;
      text-align: center;
    }

    .order-success-icon {
      display: flex;
      justify-content: center;      
    }

    .order-success-icon img{
      width: 150px;
      height: 150px;
    }

    .details-section {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 13px;
    }

    .sub-heading {
      padding: 0px 16px;
    }

    .success-main-heading{
      margin-bottom: 0px;
      font-weight:500;
      font-size:24px;
      line-height:26.4px;
      color:var(--sand-button-color);
      text-align:center;
      // margin-bottom:13px;
    }

    .success-sub-heading{
      font-weight:500;
      font-size:12px;
      line-height:20px;
      color:var(--sand-button-color);
      text-align:center;
    }

    .order-success-text{
      font-weight:600;
      font-size:24px;
      line-height:36px;
    }

    .checkout-footer{
        position: absolute;
        bottom: 0;
        width: 100%;
    }

    .back-to-menu {
        background:#FFFFFF;
        position: sticky;
        bottom: 0px;
        left: 0;
        height:32px;
        // width:170px;
        padding: 0px 16px;
        border:1px solid #FFFFFF;
        margin-bottom:34px;
        margin-right: auto;
        text-align: center;
        margin-left: auto;
    }

    .back-to-menu button {
      color: var(--white);
      text-align: center;
      font-family: Montserrat;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      background: rgb(111, 104, 85);
      border-radius: 100px;
      border: none;
      padding: 5px;
      width: 100%;
      height:50px;
      align-self: center;
    }

    .order-id{
      font-weight:600;
      font-size: 15px;
      line-height: 20px;
      // text-decoration: underline;  
    }
    `
}}
`

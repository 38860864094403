import { useContext } from 'react';
import { NotificationContext } from '../providers/NotificationProvider';
import { USE_NOTIFICATION_HOOK_ERROR } from '../lib/constant';

export const useNotification = () => {
    const context = useContext(NotificationContext);
    if (context === undefined) {
        throw new Error(USE_NOTIFICATION_HOOK_ERROR);
    }
    return context;
};

import styled, { css } from "styled-components";

export const Content = styled.div<any>`
  ${(p:any) => {
    return css`
    .employee-footer {
        height:71px;
        width:100%;
        border:1px solid var(--white);
        outline:1px solid var(--white);
        margin-right: auto;
        text-align: center;
        margin-left: auto;
    }

    .employee-footer button {
        cursor: pointer;
        width: 100%;
        background:var(--sand-button-color);
        padding:10px 50px;
        border-radius:100px;
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
        color:var(--white);
        border:none;
        height: 44px;
    }
    
    .employee-footer button:disabled {
        color:var(--soft-grey);
        background:var(--light-grey);
    }
    `
}}
`

import styled, { css } from "styled-components";

export const Content = styled.div<any>`
  ${(p: any) => {
    return css`
      .add-to-cart-container {
        display: flex;
        padding: 16px 0px;
        gap: 10px;
        background: rgb(255, 255, 255);
        box-shadow: rgba(111, 104, 85, 0.1) 0px -4px 10px 0px;
        position: fixed;
        bottom: calc(var(--footer-height));
        z-index: 1000;
        width: 100vw;
        -webkit-box-pack: center;
        justify-content: center;
        flex-direction: column;
        align-items: center;
      }
      .add-to-cart-btn {
        width: 370px;
        padding: 7px 48px;
        border-radius: 100px;
        background: #6f6855;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.05);
        width: 80vw;
        border: none;
      }
      .add-to-cart-text {
        color: #fff;
        text-align: center;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.48px;
      }
      .fadeOut {
        opacity: 0.5;
        pointer-events: none;
      }
      .out-of-stock-text {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 15px;
        color: #1c1c1c;
      }
    `;
  }}
`;

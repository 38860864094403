import { useState } from "react";
import { ARABIC_PRIVACY_POLICY, ARABIC_TRANSLATION_TEXT, LOGIN_TERMS_AND_CONDITIONS_TEXT } from "../../../../lib/constant";
import ToggleSwitch from "../../../atoms/toggleSwitch/ToggleSwitch";
import { LOGIN_CONSTANTS } from "../constant";
import { PrivacyPolicy } from "../styles/styles";

const LoginPrivacyPolicy = () => {
    const [isEnable,setIsEnable] = useState(false);
    
    const toggleSwitchHandler=(isEnable:boolean)=>{
        setIsEnable(isEnable);
      }

    return (
        <>
            <PrivacyPolicy>
            <div className="toggle-switch-container">
                <div className="lang-label">EN</div>
                <ToggleSwitch  label={"Orders"} onchangeHandler={toggleSwitchHandler} value={isEnable}/>
                <div className="lang-label">AR</div>
            </div>
            <div className="heading">{!isEnable?LOGIN_CONSTANTS.privacy_policy.en:<div className="arabic-heading">{ARABIC_PRIVACY_POLICY}</div>}</div>
            <p className="text-content">{!isEnable?LOGIN_TERMS_AND_CONDITIONS_TEXT:ARABIC_TRANSLATION_TEXT}</p>
            </PrivacyPolicy>
        </>
    );
}

export default LoginPrivacyPolicy;
export const ACTION_TYPE = {
  set_variant: "SET_VARIANT",
  set_current_PDP: "SET_CURRENT_PDP",
  increment_cart_item_count: "INCEREMENT_CART_ITEM_COUNT",
  decrement_cart_item_count: "DECEREMENT_CART_ITEM_COUNT",
  set_item_count: "SET_ITEM_COUNT",
  set_wishlist_data: "SET_WISHLIST_DATA",
  show_wishlist_icon: "SHOW_WISHLIST_ICON",
  set_notification_data: "SET_NOTIFICATION_DATA",
};

export const AUTH_CONSTANTS = {
  auth_error: {
    en: "useAuth must be used within an AuthProvider",
  },
};

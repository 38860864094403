import React, { useEffect, useState } from "react";
import Card from "../../molecules/ProductCard/ProductCard";
import { ReactComponent as SearchIcon } from "../../../globals/images/icons/search-icon.svg";
import * as S from "./styles/styles";
import NoProdutFounds from "../../molecules/NotFound/NoProductFound";
import { doActionPost, doActionGet, doActionPut, doActionDelete } from "../../../helpers/httpRequest";
import apiList, { ALERT_STATUS, USER_TYPE, QUOTE_ITEMS, SOMETHING_WENT_WRONG } from "../../../lib/constant";
import { PLP_CONSTANT } from "./constant";
import { Loading } from "../../atoms/loading/Loading";
import { getLocalStorage, setLocalStorage } from "../../../helpers/localStorageUtil";
import { CartItemResponse, Product } from "./types/types";
import { MOLECULES_CONSTANT } from "../../molecules/Constant";
import { useNavigate } from "react-router";
import { EMPLOYEE_CART_PAGE_ROUTE_PATH, EMPLOYEE_LANDING_PAGE_ROUTE_PATH } from "../../../routes/routes-constant";
import { getStaticConstantValue, isEmptyString } from "../../../helpers/utlis";
import { useNotification } from "../../../hooks/useNotification";

const ListingPage = () => {
  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const [products, setProducts] = useState<Product[]>([]);
  const [productsToDisplay, setProductsToDisplay] = useState<Product[]>([]);
  const [isAPICallRunning, setIsAPICallRunning] = useState<boolean>(false);
  const [itemsAdded, setItemsAdded] = useState<number>(0);
  const [selectedCategory, setSelectedCategory] = useState<string>('');
  const [categories, setCategories] = useState<{ id: string, name: string }[]>([]);
  const navigate = useNavigate();
  const { showAlert } = useNotification();

  const sortProductsByQuantity = (productsArray: Product[]) => {
    return [...productsArray].sort((a, b) => (b.quantity || 0) - (a.quantity || 0));
  }

  const filterProduct = (productsToFilter = products) => {
    const lowerCaseSearchVal = searchKeyword.toLocaleLowerCase();
    const searchedProducts = productsToFilter.filter(
      (item) => item?.name?.toLocaleLowerCase().includes(lowerCaseSearchVal) ||
        item?.sku?.toLocaleLowerCase()?.includes(lowerCaseSearchVal)
    );

    const cartItems = JSON.parse(getLocalStorage('cartItems') || '[]');
    const newSearchProducts = searchedProducts.map((item) => {
      const existingItem = cartItems.find((cartItem: CartItemResponse) => cartItem.sku === item.sku);
      return existingItem
        ? { ...item, quantity: existingItem.qty, item_id: existingItem.item_id }
        : item;
    });

    const totalQuantity = cartItems.reduce((sum: number, item: CartItemResponse) => sum + item.qty, 0);
    setItemsAdded(totalQuantity);

    return sortProductsByQuantity(newSearchProducts);
  };

  const getDataFromAPI = async () => {
    setIsAPICallRunning(true);
    try {
      const categoryId = getStaticConstantValue("cafateria_category");
      if (isEmptyString(categoryId)) {
        throw new Error("No category id found");
      }
      const plpURL = apiList.getAllCafeteriaProducts + categoryId;
      const response = await doActionGet({ url: plpURL, userType: USER_TYPE.Employee });
      if (response?.status === 403) {
        navigate(EMPLOYEE_LANDING_PAGE_ROUTE_PATH);
        return;
      }
      if (response) {
        const responseItems = response.data?.["items"] || [];
        setProducts(responseItems);
        setCategories(response.data.categories || []);
        filterProductsByCategory(selectedCategory, responseItems);
      }

    } catch (error) {
      console.error("API Error", error);
      if ((error as any)?.response?.status === 403) {
        navigate(EMPLOYEE_LANDING_PAGE_ROUTE_PATH);
      }
    } finally {
      setIsAPICallRunning(false);
    }
  };

  const filterProductsByCategory = (categoryId: string, productsToFilter = products) => {
    let filteredProducts = categoryId === ''
      ? productsToFilter
      : productsToFilter.filter((product) =>
        product.extension_attributes.category_links.some(
          (link) => link.category_id === categoryId
        )
      );

    setProductsToDisplay(filterProduct(filteredProducts));
  };

  const handleCategoryChange = (categoryId: string) => {
    setSelectedCategory(categoryId);
    filterProductsByCategory(categoryId);
  };

  const handleQuantity = async ({ sku, add ,item}: { sku: string; add: boolean ,item:any}) => {
    const quoteId = getLocalStorage('quoteId');
    if (add) {
      await addProductToCart(quoteId, sku,item);
    } else {
      await decreaseQuantityFromCart(quoteId, sku);
    }
    await getDataFromAPI();
  };

  const updateProductInLocalStorage = (sku: string, add: boolean, updatedData: CartItemResponse | null, isDelete = false) => {
    let cartItems = JSON.parse(getLocalStorage('cartItems') || '[]');
    if (isDelete) {
      cartItems = cartItems.filter((item: CartItemResponse) => item.sku !== sku);
    } else {
      const existingItemIndex = cartItems.findIndex((item: CartItemResponse) => item.sku === sku);
      if (existingItemIndex > -1) {
        if (add) {
          cartItems[existingItemIndex].qty++;
        } else {
          cartItems[existingItemIndex].qty = Math.max(0, cartItems[existingItemIndex].qty - 1);
          if (cartItems[existingItemIndex].qty === 0) {
            cartItems.splice(existingItemIndex, 1);
          }
        }
      } else if (updatedData) {
        cartItems.push(updatedData);
      }
    }
    setLocalStorage('cartItems', JSON.stringify(cartItems));
  };

  const addProductToCart = async (quoteId: string, sku: string,item:any) => {
    setIsAPICallRunning(true);
    try {
      let bundleOptions = item?.extension_attributes?.bundle_product_options?.length>0?{
        "option_id": item?.extension_attributes?.bundle_product_options[0]?.option_id,
        "option_selections":item?.extension_attributes?.bundle_product_options[0]?.product_links?.length>0? item?.extension_attributes?.bundle_product_options[0]?.product_links?.map((link:any)=>{return link?.id}):[]
      }:{};
      const response = await doActionPost({
        url: apiList.getCartDetails + quoteId + "/" + QUOTE_ITEMS,
        data: {
          "cartItem": {
            "sku": sku,
            "qty": 1,
            "quote_id": quoteId,
            "product_option": {
                                "extension_attributes": {
                                    "bundle_options": [bundleOptions],
                                }
                              }
          }
        },
        userType: USER_TYPE.Employee
      });
      updateProductInLocalStorage(sku, true, response?.data);
    } catch (error) {
      console.log("error msg", error);
    } finally {
      setIsAPICallRunning(false);
    }
  };

  const decreaseQuantityFromCart = async (quoteId: string, sku: string) => {
    const cartItems = JSON.parse(getLocalStorage('cartItems') || '[]');
    const item = cartItems.find((item: CartItemResponse) => item.sku === sku);
    if (!item || item.qty === 0) return;

    setIsAPICallRunning(true);
    try {
      if (item.qty > 1) {
        await doActionPut({
          url: apiList.getCartDetails + quoteId + "/" + QUOTE_ITEMS + item.item_id,
          data: {
            "cartItem": {
              "item_id": item.item_id,
              "sku": sku,
              "qty": item.qty - 1,
              "quote_id": quoteId
            }
          },
          userType: USER_TYPE.Employee
        });
      } else {
        await doActionDelete({
          url: apiList.getCartDetails + quoteId + "/" + QUOTE_ITEMS + item.item_id,
          userType: USER_TYPE.Employee
        });
      }
      updateProductInLocalStorage(sku, false, null, item.qty === 1);
    } catch (error) {
      console.log("error msg", error);
      showAlert(SOMETHING_WENT_WRONG, ALERT_STATUS.error);
    } finally {
      setIsAPICallRunning(false);
    }
  };

  const handleCheckout = () => {
    navigate(EMPLOYEE_CART_PAGE_ROUTE_PATH);
  };

  const fetchCartDetails = async () => {
    try {
      setIsAPICallRunning(true);
      const cartResponse: any = await doActionPost({
        url: apiList.getCartDetails,
        userType: USER_TYPE.Employee
      });
      const quoteId = cartResponse?.data;
      setLocalStorage('quoteId', quoteId);
      const cartItemResponse = await doActionGet({
        url: apiList.getCartDetails + quoteId + "/" + QUOTE_ITEMS,
        userType: USER_TYPE.Employee
      });
      setLocalStorage('cartItems', JSON.stringify(cartItemResponse?.data));
    } catch (error) {
      console.error("error", error);
      showAlert(SOMETHING_WENT_WRONG, ALERT_STATUS.error);
    } finally {
      setIsAPICallRunning(false);
    }
  };

  useEffect(() => {
    if (isEmptyString(getLocalStorage('quoteId'))) {
      fetchCartDetails();
    }
    getDataFromAPI();
  }, []);

  useEffect(() => {
    filterProductsByCategory(selectedCategory);
  }, [products, searchKeyword, selectedCategory]);

  return (
    <S.Content isReversed={false}>
      <div className="PLP_main_div">
        <div className="search-input-container">
          <SearchIcon className='search-icon' />
          <input
            type="text"
            className="search-input"
            placeholder="Search for products"
            value={searchKeyword}
            onChange={(e) => setSearchKeyword(e.target.value)}
          />
        </div>
        <div className="category-filters">
          <button
            className={`category-button ${!selectedCategory ? 'active' : ''}`}
            onClick={() => handleCategoryChange('')}
          >
            All Categories
          </button>

          {categories.map((category) => (
            <button
              key={category.id}
              className={`category-button ${selectedCategory === category.id ? 'active' : ''}`}
              onClick={() => handleCategoryChange(category.id)}
            >
              {category.name}
            </button>
          ))}
        </div>

        <div className="listing_header">
          <h3 className="text_1">
            {categories.find(category => category.id === selectedCategory)?.name || 'All Categories'}
          </h3>
        </div>

        <div className="item_container">
          {!isAPICallRunning && productsToDisplay.length < 1 && (
            <NoProdutFounds />
          )}
          {isAPICallRunning && <div className="loading_container"> <Loading /></div>}
          {productsToDisplay.map((item: any) => (
            <div className="card-column" key={item?.sku}>
              <Card
                cardkey={item}
                handleQuantity={handleQuantity}
                item={item}
              />
            </div>
          ))}
        </div>
      </div>
      {productsToDisplay.length !== 0 && (
        <div className="view-order-btn-container">
          <button
            className={itemsAdded < 1 ? "view-order-button" : "view-order-button view-order-button-with-items-added"}
            disabled={itemsAdded < 1}
            onClick={handleCheckout}
          >
            {itemsAdded > 0 ? <span className="items-added">{itemsAdded} {PLP_CONSTANT.Items_added.en}</span> : ''}
            {MOLECULES_CONSTANT.view_order.en}
          </button>
        </div>
      )}
    </S.Content>
  );
};

export default ListingPage;
const ASSISTANCE_TEXT_CONSTANTS = {
  assistance: {
    en: "Assistance ",
  },
  arrival: {
    en: "Assistant Arrival Tracker ",
  },
  personalized_assistance: {
    en: "Personalized Assistance  ",
  },
  assistance_message1: {
    en: "We're delighted to help you with any queries or concerns you may have.",
  },
  assistance_message2: {
    en: "To ensure you receive the best assistance possible, we kindly ask you to click the confirmation button below.",
  },
  track_message: {
    en: "Please proceed to our coffee lounge, where a customer assistant will be with you shortly to address your queries and concerns. ",
  },
  wait: {
    en: "Searching for a store representative for your request.",
  },
  help_button: {
    en: "I Need Help!",
  },
  track_button: {
    en: "Track your assistance",
  },
  refresh_button: {
    en: "Refresh",
  },
  assistant_name: {
    en: "Your assistant will be: ",
  },
  enquiries: {
    en: "Previous enquiries: ",
  },
  current_enquiry: {
    en: "Current enquiry : ",
  },
  pending_enquiry: {
    en: "Pending Enquiries: ",
  },
};

const ENQUIRY_ACTIONS = {
  ASSIGN: "assign",
  ACCEPT: "accept",
  CLOSE: "close",
};

const ENQUIRY_STATUSES = {
  NEW: "new",
  WORKING: "working",
  CLOSE: "close",
};
export { ASSISTANCE_TEXT_CONSTANTS, ENQUIRY_ACTIONS, ENQUIRY_STATUSES };

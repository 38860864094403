import React, { useEffect } from 'react';
import * as S from './styles/styles';
import WelcomeMessage from './welcome-message';
import { useNavigate } from 'react-router-dom';
import { LOGIN_ROUTE_PATH, SCAN_ROUTE_PATH } from '../../../routes/routes-constant';
import BottomLogoInGrey from '../../../globals/images/Logo/BottomLogoInGrey.svg';
import TopLogo from '../../../globals/images/Logo/TopLogo.svg';
import { setPageToFullHeight } from '../../../helpers/style';

const WelcomeSplashScreen: React.FC = () => {

    const navigate = useNavigate();

    const handleComplete = () => {
        navigate(LOGIN_ROUTE_PATH);
    };

    useEffect(() => {
        setPageToFullHeight(true);
        return(()=>{
          setPageToFullHeight(false)
        })
      }, []);
    return (
        <S.Content>
            <img src={TopLogo} alt="Top Logo" className="top-logo" />
            <div className="welcome-message-button-container">
                <WelcomeMessage onCompleted={handleComplete} />
            </div>
            {/* <img src={BottomLogoInGrey} alt="Bottom Logo" className="bottom-logo" /> */}
        </S.Content>
    );
};

export default WelcomeSplashScreen;

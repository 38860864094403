import styled, { css } from "styled-components";

export const Content = styled.div<any>`
  ${(p: any) => {
    return css`

.PLP_main_div{
  padding:20px;
  background: var(--white);
}
.PLP_title{
  font-size: 24px; 
    line-height:36px;
    font-weight:600;
    color: #424242;
}

.search-input-container {
        position: relative;
        width: 100%;
        display: flex;
        align-items: center;
        height: 40px;
        margin: 20px 0px;
      }

      .search-icon {
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
        width: 16px;
        height: 16px;
        color: #6F6855;
        pointer-events: none;
      }

      .search-input {
        flex-grow: 1;
        box-sizing: border-box;
        width: 100%;
        padding: 10px 40px;
        border: 1px solid #ccc;
        border-radius: 40px;
        font-size: 16px;
        outline: none;
        gap: 10px;
      }

      .search-input::placeholder {
        font-weight: 400;
        font-size: 15px;
        line-height: 15px;
      }

.input_search{
    background:#FBFBFB;
    width: 100%;
    border: none;
    height: 50px;
    border-radius: 100px;
    margin-bottom: 10px;
    font-size: 14px; 
    line-height:21px;
    font-weight:500;
    padding:0 25px;
    box-sizing: border-box;
}
.input_search:focus{
    boder:none;
    outline:none;
}
.search_bar {
    position: relative;
}
.search_bar .search-icon {
    position: absolute;
    top: 50%;
    left:0;
    transform: translateY(-60%);
    fill: #999;
    width: 16px;
    height: 20px;
}
.search-icon svg{
    width:16px !important;
    height:20px !important;
    circle{
      stroke:#B9B9B9;
    }
    path{
      fill:#B9B9B9;
    }
  }
.search_bar .clear-icon{
    position: absolute;
    top: 50%;
    right:10px;
    transform: translateY(-60%);
    fill: #999;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    
}
.search_bar .disable svg{
    path {
        fill:#B9B9B9;
      }
}
.clear-icon svg{
    width:13px !important;
    height:18px !important;
    circle{
      stroke:#B9B9B9;
    }
    path{
        fill:#000000;
    }
  }
.listing_header{
    display:flex;
    justify-content: space-between;
    align-items: center;
    height:36px;
}
.text_2{
    font-size:12px;
    line-height:18px;
    font-weight:500;
    color: #B9B9B9;
}
.text_1{
    font-size:24px;
    line-height:26.4px;
    font-weight:500;
    color: var(--dark-black);
}

.category-filters {
  display: flex;
  gap: 8px;
  margin-bottom: 16px;
  height: 35px;
  overflow-x: auto; /* Enables horizontal scrolling */
  white-space: nowrap; /* Prevents buttons from wrapping to the next line */
  padding: 10px 0; /* Optional: Adds padding to the top and bottom */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}

.category-filters::-webkit-scrollbar {
  display: none;  /* Safari and Chrome */
}
  
.category-button {
  padding: 10px;
  border: 1px solid #ddd;
  background-color: var(--cultured);
  color: var(--sand);
  cursor: pointer;
  border-radius: 30px;
  font-size: 10px;
  font-weight: 500;
  line-height: 15px;
  gap: 10px;
}

.category-button.active {
  background-color: var(--sand);
  color: var(--cultured);
}

.card-column{
    display:flex;
}
.item_container{   
    display:grid;
    grid-template-columns: repeat(auto-fit, minmax(calc(50% - 1rem), 1fr));
    column-gap:2rem;
    row-gap:2rem;
    margin-top:1rem;
    margin-bottom:60px;
    }
    .item_card{
     flex:45%;
     overflow:hidden;
     height:250px;
     display:flex;
     flex-direction: column;
     gap:10px;
    }
.product_section{
    padding:10px;
    height:200px;
    background: #FBFBFB;
    padding:10px;
    border-radius: 30px;
    box-sizing: border-box;
  }
.image_section{
    width: 100%;
    height: 100px;
    box-sizing: border-box;
    margin-bottom:10px;
    position:relative;
    .fade_layer{
        position:absolute;
        width:100%;
        height:100%;
        background-color: rgba(0, 0, 0, 0.6);
        border-radius: 30px;
        top:0;
        display: flex;
        align-items: center;
        justify-content: center;
        color:#FFFF
    }

}
.product_img{
    width:100%;
    height:100%;
    border-radius: 30px;
    object-fit:contain;
}

.loading_container{
    position:absolute;
}
.error-msg{
    color:var(--red);
}

.details_section{
  color: #424242;

}
.details_name,.details_sku,.details_price{
    margin: 0px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size:14px;
    line-height:21px;
    font-weight:600;
}
    .details_name{
     font-size:14px;
    line-height:18.2px;
    font-weight:500;
    }
.details_price{
    color: #6F6855;
    margin-top: 10px;

}
.details_sku{
    font-size:12px;
    line-height:18px;
    font-weight:500;
    color: #B9B9B9;

}

.button_section{
    height: 28px;
    border-radius: 4px;
    border: 0.8px solid var(--sand);
    display: flex;
    justify-content: space-between;
    margin: 10px 0px;
    align-items: center;  
    padding: 0 10px;
}

.remove_quantity_button:disabled{
    color:#DADEDF;
    pointer-events:none;
}
.remove_quantity_button, .add_quantity_button{
    font-weight: 700;
    line-height: 13px;
    border: none;
    background: none;
    font-size: 11px;
    color: var(--sand);
    height:13px;
    width:7px;
}

.remove_quantity_button {
    display: flex;
    justify-content: flex-end;
}

.add_quantity_button {
    display: flex;
    justify-content: flex-start;
}
.add_quantity_button:disabled {
     color:#DADEDF;
}

.value_div{
    height:18px;
    width:18px;
    font-size: 14px;
    line-height: 18.2px;
    font-weight: 500;
    color: var(--sand);
}
.moveToCartBtn{
    background:#FFFFFF;
    position: sticky;
    bottom: 0px;
    left: 0;
    height:71px;
    width:100%;
    border:1px solid #FFFFFF;
    outline:1px solid #FFFFFF;
    border-top: 1px;
}
.moveToCartBtn button{
    cursor: pointer;
    width:100%;
    background:#6F6855;
    padding:10px 50px;
    border-radius:100px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    color:#FFFFFF;
    border:none;
    height: 44px;
}
.moveToCartBtn button:disabled {
    color:#B9B9B9;
    background:#F6F6F6;
}

.view-order-button {
        opacity:50%;
        cursor: pointer;
        width: 95%;
        background:var(--sand-button-color);
        padding:7px 48px;
        border-radius:100px;
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
        color:var(--white);
        border:none;
        height: 44px;
}

.view-order-button-with-items-added {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 7px 17px 7px 7px;
    opacity:100%;
}

.items-added {
    padding: 8px 16px;
    gap: 10px;
    border-radius: 100px;
    background: #DDD7BE;
    font-weight: 500;
    font-size: 14px;
    line-height: 18.2px;
    color: var(--sand);
}
}
.view-order-btn-container{        display: flex;
        padding: 16px 0px;
        gap: 10px;
        background: rgb(255, 255, 255);
        box-shadow: rgba(111, 104, 85, 0.1) 0px -4px 10px 0px;
        position: fixed;
        bottom: calc(var(--footer-height));
        z-index: 1000;
        width: 100vw;
        -webkit-box-pack: center;
        justify-content: center;
        flex-direction: column;
        align-items: center;}
    `;
  }}
`;

import { styled } from '@mui/system';
import { Box, Button, Divider, Grid, SxProps, Theme } from '@mui/material';

export const LogoLinksContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
});

export const FullWidthBox = styled(Box)({
  width: '100%',
});

export const FullWidthButton = styled(Button)({
  backgroundColor: '#6F6855',
  height: '35px',
  width: '100%',
});

export const LogoContainer = styled(Box)({
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  });

export const LogoImage = styled('img')({
    width: '150px',
    height: '150px',
    cursor: 'pointer',
  });

export const ConditionalDivider = styled(Divider)(({ theme }) => ({
  marginY: theme.spacing(2),
  width: '100%',
}));

export const boxStyle: SxProps<Theme> = {
  width: '100%',
};

export const gridItemStyle: SxProps<Theme> = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export const dividerStyle: SxProps<Theme> = {
  my: 2,
  width: '100%',
};

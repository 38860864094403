import React, { useState, useEffect, useMemo } from 'react';
import { Request, StoreUsers } from './types/types';
import { doActionGet, doActionPut } from '../../../../helpers/httpRequest';
import * as S from './styles/styles';
import { ENQUIRY_DETAILS_PATH } from '../../../../routes/routes-constant';
import apiList, { ALERT_STATUS, USER_TYPE } from '../../../../lib/constant';
import { useNotification } from '../../../../hooks/useNotification';
import { CUSTOMER_LISTING_CONSTANTS, CUSTOMER_LISTING_MESSAGES, tabMap } from './constant';
import { getLocalStorage } from '../../../../helpers/localStorageUtil';
import { FormControl, Select, MenuItem, Tab, Tabs, SelectChangeEvent } from '@mui/material';
import { ENQUIRY_STATUSES } from '../../assistance/constants';
import { getStaticConstantValue, isManager } from '../../../../helpers/utlis';
import useEmployeeEnquiries from './hooks/useEmployeeEnquiries';
import { ReactComponent as RequestIcon } from './../../../../globals/svg/request.svg'
import { useNavigate } from 'react-router-dom';

const SpecialOrderCustomerList: React.FC = () => {
    const currentEmployee = { email: getLocalStorage('employee_email') ?? '', role: getStaticConstantValue("requested_by") }
    const [searchTerm, setSearchTerm] = useState('');
    const { showAlert } = useNotification();
    const [selectedEmployee, setSelectedEmployee] = useState<{ [key: number]: string }>({});
    const [employees, setEmployees] = useState<StoreUsers[]>([]);
    const { customersRequests, refetchCustomersRequests, requestsLoaded } = useEmployeeEnquiries(currentEmployee);
    const [closeReason, setCloseReason] = useState("");
    const [showReasonOrAssignBox, setShowReasonOrAssignBox] = useState<{ action: 'assign' | 'close' | ''; display_id: string; }>({ action: '', display_id: '' });
    const navigate = useNavigate();
    const [value, setValue] = useState<0 | 1 | 2>(0);

    const fetchEmployees = async () => {
        try {
            const response = await doActionGet({
                url: apiList.getStoreUsers,
                userType: USER_TYPE.Employee
            });
            if (response && response.data) {
                setEmployees(response.data);
            }
        } catch (error) {
            console.error("Error fetching employees:", error);
            showAlert(CUSTOMER_LISTING_MESSAGES.fetch_employee_failed.en, ALERT_STATUS.error);
        }
    };

    useEffect(() => {
        fetchEmployees();
    }, []);

    const filteredRequests = useMemo(() => {
        if (!isManager(currentEmployee.role)) return customersRequests
        return customersRequests?.filter(request => request.status === tabMap[value])
    }, [currentEmployee.role, customersRequests, value]);

    const filteredCustomersRequests = searchTerm
        ? filteredRequests?.filter(request =>
            request?.customer_name?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
            request?.display_id?.toLowerCase()?.includes(searchTerm.toLowerCase()))
        : filteredRequests;

    const groupedCustomersRequests: ({ [key: string]: Request[] }) = filteredCustomersRequests.reduce((groups, request) => {
        const firstLetter = request?.customer_name[0].toUpperCase();
        if (!groups[firstLetter]) groups[firstLetter] = []
        groups[firstLetter].push(request);
        return groups;
    }, {} as { [key: string]: Request[] });
    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: 0 | 1 | 2) => { setValue(newValue); };

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setSearchTerm(value);
    };

    const handleAssignOrCloseClick = (action: 'assign' | 'close' | '', request: Request) => {
        if ((action === 'close') && (showReasonOrAssignBox.display_id !== request.display_id)) setCloseReason('')

        if (action !== showReasonOrAssignBox.action) setShowReasonOrAssignBox({ action, display_id: request.display_id })
        else setShowReasonOrAssignBox(pre => ({ action, display_id: ((pre.display_id === request?.display_id) ? '' : request?.display_id) }))
    }
    const handleNonAssignAction = async (request: Request) => {
        if (closeReason === '') {
            showAlert(CUSTOMER_LISTING_MESSAGES.close_reason.en, ALERT_STATUS.error);
            return;
        }
        window?.dataLayer?.push({
            event: isManager(currentEmployee.role) ? "change_status_close" : "close_task",
            [isManager(currentEmployee.role) ? "close_data" : "assign_data"]: {
                employee: {
                    email: "",
                    firstName: "",
                    lastName: "",
                },
                request_id: request?.display_id,
                customer_id: request?.customer_name,
            },
            status: ENQUIRY_STATUSES.CLOSE,
            cognito_id: (getLocalStorage("cognitoId") ?? "").replaceAll('\"', ''),
            emp_type: currentEmployee.role
        });

        const payload = {
            inquiry: {
                action: 'close',
                action_notes: closeReason
            }
        };

        try {
            const response = await doActionPut({
                url: `${apiList.getEnquiryById(request?.id)}`,
                data: payload,
                userType: USER_TYPE.Employee
            });

            if (response && response.data) {
                refetchCustomersRequests()
                showAlert(CUSTOMER_LISTING_MESSAGES.status_update_success.en, ALERT_STATUS.success);
            }
        } catch (error) {
            console.error("Error updating status:", error);
            showAlert(CUSTOMER_LISTING_MESSAGES.status_update_failed.en, ALERT_STATUS.error);
        }
    };

    const handleAssign = async (request: Request) => {
        const employee = employees?.find(emp => emp?.email === selectedEmployee[request?.id]);
        if (!employee) {
            showAlert(CUSTOMER_LISTING_MESSAGES.select_employee.en, ALERT_STATUS.error);
            return;
        }
        window?.dataLayer?.push({
            event: isManager(currentEmployee.role) ? "assign_to_employee" : "assign_task_to",
            assign_data: {
                employee: {
                    email: employee?.email,
                    firstName: employee?.firstname,
                    lastName: employee?.lastname,
                },
                user_id: employee?.user_id,
                request_id: request?.display_id,
                customer_id: request?.customer_name,
            },
            status: ENQUIRY_STATUSES.WORKING,
            emp_type: currentEmployee.role,
            cognito_id: (getLocalStorage("cognitoId") ?? "").replaceAll('\"', ''),
        });

        const payload = {
            inquiry: {
                action: "assign",
                extension_attributes: {
                    additional_info: "",
                    assigned_user_email: employee?.email,
                    assigned_user_id: employee?.user_id
                }
            }
        };

        try {
            const response = await doActionPut({
                url: `${apiList.getEnquiryById(request?.id)}`,
                data: payload,
                userType: USER_TYPE.Employee
            });

            if (response && response.data) {
                refetchCustomersRequests()
                showAlert(CUSTOMER_LISTING_MESSAGES.assign_inquiry_success.en, ALERT_STATUS.success);
            }
        } catch (error) {
            console.error("Error assigning inquiry:", error);
            showAlert(CUSTOMER_LISTING_MESSAGES.assign_inquiry_success.en, ALERT_STATUS.error);
        }
    };
    const reasonList = getStaticConstantValue('sign_off_reasons')?.split(",");

    const handleReasonChange = (event: SelectChangeEvent) => {
        const reasonVal = event.target.value;
        setCloseReason(reasonVal);
    };

    return (
        <S.Content>
            <div className="container">
                <div className="customer-list-container">
                    <div className='title'>{CUSTOMER_LISTING_CONSTANTS.customer_list_label.en}</div>
                    <input
                        type="text"
                        placeholder={CUSTOMER_LISTING_CONSTANTS.search_bar_placeholder.en}
                        value={searchTerm}
                        onChange={handleSearch}
                    />
                    {isManager(currentEmployee.role) && <Tabs
                        value={value}
                        onChange={handleTabChange}
                        variant="fullWidth"
                        centered
                        className="tab-container"
                    >
                        <Tab label="Pending" className={`tab-label ${value === 0 ? 'tab-label-selected' : ''}`} />
                        <Tab label="In Progress" className={`tab-label ${value === 1 ? 'tab-label-selected' : ''}`} />
                        <Tab label="Completed" className={`tab-label ${value === 2 ? 'tab-label-selected' : ''}`} />
                    </Tabs>}
                    <div className="card-container">
                        {filteredCustomersRequests.length === 0 ? (
                            <div className="no-customer-found">
                                {requestsLoaded ? CUSTOMER_LISTING_CONSTANTS.no_customer_found_label.en : ''}
                            </div>
                        ) : (
                            Object.entries(groupedCustomersRequests).map(([letter, requests]) => (
                                <div key={letter} id={`section-${letter}`} className="alphabet-section">
                                    {requests?.map((request) => (
                                        <div key={request?.display_id} className="customer-card">
                                            <div className="customer-info">
                                                <div className='info' >
                                                    <RequestIcon />{
                                                        (request.status !== ENQUIRY_STATUSES.CLOSE) ? <p>{`${request?.customer_name} is requesting assistance`}</p> :
                                                            <p> {`${request?.customer_name}'s assistance request`}</p>
                                                    }

                                                </div>
                                                <div className={`actions-container ${(request.status !== ENQUIRY_STATUSES.CLOSE) ? 'not-close' : ''}`}>
                                                    <p className={`view-request ${(request.status !== ENQUIRY_STATUSES.CLOSE) ? 'not-close' : ''}`} onClick={() => { navigate(ENQUIRY_DETAILS_PATH, { state: { data: request } }) }}>View Request</p>
                                                    {(request.status !== ENQUIRY_STATUSES.CLOSE) && <div className='actions'>
                                                        <p onClick={() => { handleAssignOrCloseClick('assign', request) }}>Transfer</p>
                                                        <button onClick={() => { handleAssignOrCloseClick('close', request) }}>Close</button>
                                                    </div>}
                                                </div>
                                                {(request?.display_id === showReasonOrAssignBox.display_id) ? (showReasonOrAssignBox.action === 'assign') ?
                                                    <div className='assign-container'>
                                                        <FormControl size="small" fullWidth>
                                                            <Select
                                                                value={selectedEmployee[request?.id] || ''}
                                                                onChange={(e) => setSelectedEmployee(prev => ({ ...prev, [request.id]: e.target.value }))}
                                                                displayEmpty
                                                                className="select-ellipsis"
                                                                renderValue={(value) => (
                                                                    <div className="select-ellipsis">
                                                                        {value || 'Select Employee'}
                                                                    </div>
                                                                )}
                                                            >
                                                                <MenuItem value="" disabled>Select Employee</MenuItem>
                                                                {employees.map((employee) => (
                                                                    <MenuItem key={employee.user_id} value={employee.email}>
                                                                        {employee.email}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                        <button className='assign-button' onClick={() => handleAssign(request)}>Assign</button>
                                                    </div>
                                                    : (showReasonOrAssignBox.action === 'close')
                                                        ? <div className='close-action'>
                                                            {/* <input
                                                                type="text"
                                                                placeholder={CUSTOMER_LISTING_CONSTANTS.reason_placeholder.en}
                                                                value={closeReason}
                                                                onChange={(e => setCloseReason(e.target.value))}
                                                            /> */}
                                                            <div className="dropdown-container">
                                                                <FormControl fullWidth size='small'>
                                                                    <Select className="select-input-field" displayEmpty labelId="country-select-label" id="reason" name="reason" value={closeReason} onChange={handleReasonChange}
                                                                        inputProps={{ 'aria-label': 'Without label' }}
                                                                        renderValue={(selected) => {
                                                                            if (selected) {
                                                                                return <span>{closeReason}</span>;
                                                                            }
                                                                            return "Please provide request closure reason";
                                                                        }}
                                                                    >
                                                                        {/* <MenuItem disabled value="">
                                                                            <span>{ADDRESS_FORM_UI_STRINGS.actions.selectCountry.en}</span>
                                                                        </MenuItem> */}
                                                                        {reasonList?.length > 0 ? (
                                                                            reasonList?.map(reason => (
                                                                                <MenuItem key={reason} value={reason}>{reason}</MenuItem>
                                                                            ))
                                                                        ) : (
                                                                            <MenuItem value="" disabled>
                                                                                Loading...
                                                                            </MenuItem>
                                                                        )}
                                                                    </Select>
                                                                </FormControl>
                                                            </div>
                                                            <button className='assign-button' onClick={() => { handleNonAssignAction(request) }}>{CUSTOMER_LISTING_CONSTANTS.close_button_label.en}</button>
                                                        </div>
                                                        : null : null}

                                            </div>
                                        </div>

                                    ))}
                                </div>
                            ))
                        )}
                    </div>
                </div>
            </div >
        </S.Content >
    );
};

export default SpecialOrderCustomerList;
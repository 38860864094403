export const EMPLOYEE_DASHBOARD_CONSTANTS = {
    section_1_heading: {
        en: "Employee Dashboard"
    },
    section_2_heading: {
        en: "Training Resources"
    },
    document_heading: {
        en: "Documents"
    },
    video_heading: {
        en: "Videos"
    }
}

export const EMPLOYEE_ROLE_CONSTANTS = {
    cafeteria_manager: {
        en: "Cafeteria Manager"
    },
    store_manager: {
        en: "Store Manager"
    }
}

export const EMPLOYEE_CTA_CONSTANTS = {
    cafeteria_flow: {
        en: "Manage Cafeteria"
    },
    normal_flow: {
        en: "Furniture/Lifestyle"
    }
}

export const TRAINING_RESOURCES_CONSTANTS = {
    no_data_found: {
        en: "No data received from the server."
    },
    failed_to_fetch: {
        en: "Failed to fetch resources. Please try again later."
    },
    no_docs_found: {
        en: "No documents available."
    },
    no_videos_found: {
        en: "No videos available."
    },
    failed_to_fetch_categories: {
        en: "Failed to fetch categories"
    }
}
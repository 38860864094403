import React, { useState } from "react";
import * as S from "./styles/styles";
import { progressBar } from "../../../lib/constant";
import { progressBarType } from "./types/types";
import { useNavigate } from "react-router-dom";

const CheckoutProgressBar: React.FC<progressBarType> = ({ id, title }) => {
  const [currentId, setCurrentId] = useState<number>(id);
  const navigate = useNavigate();

  const radioButtonChangeHandler = (value: string) => {
    setCurrentId(Number(value));
  };

  return (
    <S.Content>
      <div className="progress-bar-container">
        <div className="progress-bar-first-line">
          {progressBar.length > 0 &&
            progressBar.map((item: progressBarType, index: number) => (
              <React.Fragment key={item?.id}>
                <input
                  className="custom-radio"
                  onClick={() => {
                    item.path && navigate(item.path);
                  }}
                  type="radio"
                  id={String(item?.id)}
                  value={item?.id}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    radioButtonChangeHandler(e.target.value)
                  }
                  checked={currentId === item.id}
                  disabled={item.id > currentId}
                />
                {index !== progressBar.length - 1 && <hr className="line" />}
              </React.Fragment>
            ))}
        </div>
        <div className="progress-bar-second-line">
          {progressBar.length > 0 &&
            progressBar.map((item: progressBarType) => (
              <div
                key={item?.id}
                className={
                  item.id > currentId
                    ? "progress-bar-faded-title"
                    : "progress-bar-title"
                }
              >
                {item.title}
              </div>
            ))}
        </div>
      </div>
    </S.Content>
  );
};

export default CheckoutProgressBar;

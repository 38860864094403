import { WISHLIST_CONSTANT } from "./Constant";
import * as S from "./style/style";
import WishlistCard from "./wishlistCard";
import { useContext, useEffect, useState } from "react";
import { doActionGet, doActionDelete } from "../../../helpers/httpRequest";
import { ReactComponent as Cart } from "../../../globals/svg/cart_wl.svg";
import { doActionPost } from "../../../helpers/httpRequest";
import apiList, { ALERT_STATUS, USER_TYPE } from "../../../lib/constant";
import { getLocalStorage, setLocalStorage } from "../../../helpers/localStorageUtil";
import { Loading } from "../../atoms/loading/Loading";
import { useNotification } from "../../../hooks/useNotification";
import { addItemToCart_gtm } from "../../../helpers/GTM";
import { DataContext } from "../../../Context/AppContext";
import { ACTION_TYPE } from "../../../Context/Constant";


interface WishlistOptions {
  [key: string]: string;
}
type WishlistItem = {
  id: number;
  product: {
    name: string;
    price: number;
    sku: string;
    extension_attributes?: {
      wishlist_options: Array<{ label: string; value: string }>;
    };
  };
};
type item = {
  id: number;
  product: {
    name: string;
    sku: string;
    price: string;
    custom_attributes?: [{ attribute_code: string; value: string }];
  };
};
const WishListPage = () => {
  const [wishlist, setWishlist] = useState<any>([]);
  const [isAPICallRunning, setIsAPICallRunning] = useState<boolean>(false);
  const [isAllSelected, setIsAllSelected] = useState<boolean>(false);
  const { showAlert } = useNotification();
  const { dispatch } = useContext(DataContext);

  useEffect(()=>{
    if(!getLocalStorage('user_quoteId')){
      setQuoteId();
    }
  },[])

  const setQuoteId = async () => {
    const quoteIdResponse = await doActionPost({ url: apiList.quoteIdURL, userType: USER_TYPE.Customer });
    const quoteId = quoteIdResponse?.data;
    if (quoteId) {
      setLocalStorage("user_quoteId", quoteId);
    } else {
      console.error("Quote ID is undefined or null.");
    }
  }
  const handleSelectAll = () => {
    setIsAllSelected((val) => !val);
  };
  const clearWishlist = () => {
    doActionDelete({ url: apiList.wishlist, userType: USER_TYPE.Customer })?.then((response) => {
      if (response?.data) {
        setWishlist([])
        dispatch({ type: ACTION_TYPE.set_wishlist_data, payload: [] });
        showAlert(WISHLIST_CONSTANT.del_items_msg.en, ALERT_STATUS.success);
      }
    })
      ?.catch((err) => {
        console.error("API Error", err);
        showAlert(WISHLIST_CONSTANT.del_item_err_msg.en, ALERT_STATUS.error);
      })
  }

  const updateWishList = () => {
    setIsAPICallRunning(true);
    doActionGet({ url: apiList.wishlist, userType: USER_TYPE.Customer })
      ?.then((response: any) => {
        const itemList = response?.data?.items
        if (itemList) {
          setWishlist(itemList);
          dispatch({ type: ACTION_TYPE.set_wishlist_data, payload: itemList.map((item: { id: any; product: { sku: any; }; }) => ({ id: item?.id, sku: item?.product?.sku })) });
        }
      })
      ?.catch((err) => {
        console.error("API Error", err);
      })
      ?.finally(() => {
        setIsAPICallRunning(false);
      });
  };

  const moveProductToCart = async (ids: number[]) => {
    setIsAPICallRunning(true);
    const user_QuoteId = getLocalStorage("user_quoteId");
    if (!user_QuoteId) {
      console.error("No user quote id found");
      showAlert(WISHLIST_CONSTANT.Add_to_cart_err_msg.en, ALERT_STATUS.error);
      setIsAPICallRunning(false);
      return;
    }
    const payload: { quote_id: string; itemIds?: number[] } = {
      quote_id: user_QuoteId,
      itemIds: ids,
    };
    const products: { name: any; price: any; sku: any; }[] = []
    wishlist?.forEach((item: WishlistItem) => {
      if (!Boolean(ids?.length) || ids.includes(item?.id)) {
        const { name, price, sku } = item["product"];
        const variantDetails: WishlistOptions = {};
        item["product"]?.["extension_attributes"]?.["wishlist_options"]?.forEach(
          (item: { [x: string]: string }) => {
            variantDetails[item?.["label"]] = item?.["value"];
          }
        );
        let formatedItem = {
          name,
          price,
          sku,
          ...variantDetails,
        };
        products.push(formatedItem);
      }
    })
    doActionPost({
      url: apiList.wishlist + "/move",
      data: payload,
      userType: USER_TYPE.Customer,
    })
      ?.then((resp: any) => {
        if (resp?.status === 200) {
          if (products.length > 0) dispatch({ type: ACTION_TYPE.increment_cart_item_count, payload: { count: products.length } })
          addItemToCart_gtm(products)
          updateWishList()
          showAlert(isAllSelected ? WISHLIST_CONSTANT.Add_all_to_cart_msg.en : WISHLIST_CONSTANT.Add_to_cart_msg.en, ALERT_STATUS.success);
        } else {
          throw new Error("Unable to add the item in the cart");
        }
      })
      .catch((error: any) => {
        console.error("error msg", error);
        showAlert(WISHLIST_CONSTANT.Add_to_cart_err_msg.en, ALERT_STATUS.error);
      })
      .finally(() => {
        setIsAPICallRunning(false);
      });
  };

  const deleteItemFromWishList = (id: number | undefined) => {
    setIsAPICallRunning(true);
    const url = apiList.wishlist + `/${id}`;
    doActionDelete({ url, userType: USER_TYPE.Customer })
      ?.then((response: any) => {
        if (response) {
          updateWishList()
          showAlert(WISHLIST_CONSTANT.del_item_msg.en, ALERT_STATUS.success);
        } else {
          throw new Error("Unable to remove the item");
        }
      })
      ?.catch((err) => {
        console.error("API Error", err);
        showAlert(WISHLIST_CONSTANT.del_item_err_msg.en, ALERT_STATUS.error);
      })
      ?.finally(() => {
        setIsAPICallRunning(false);
      });
  };

  useEffect(() => {
    updateWishList();
  }, []);

  return (
    <>
      {isAPICallRunning && (
        <div className="loading_container">
          <Loading />
        </div>
      )}
      <S.Content>

        <section className="wishlist_header">
          {wishlist?.length > 0 && (
            <div className="section_all">
              <span className="custom-checkbox-container" onClick={handleSelectAll}>
                <span className="checkbox-label"> {WISHLIST_CONSTANT.Select_all.en}</span>
                <label htmlFor="customCheckbox" className={`checkbox-indicator ${isAllSelected ? 'checked' : ''}`}></label>
                <input
                  type="checkbox"
                  id="customCheckbox"
                  className="custom-checkbox"
                  checked={isAllSelected}
                  onClick={handleSelectAll}
                />
              </span>
              {isAllSelected && (<>
                <button
                  onClick={() => moveProductToCart([])}
                  className="add_all_to_cart_btn"
                >
                  <Cart />
                  <span className="text">{WISHLIST_CONSTANT.Add_all.en}</span>
                </button>
                <button
                  onClick={() => { clearWishlist() }}
                  className="add_all_to_cart_btn"
                >
                  <Cart />
                  <span className="text">{WISHLIST_CONSTANT.Remove_all.en}</span>
                </button></>
              )}
            </div>
          )}
        </section>
        {(wishlist?.length > 0 || isAPICallRunning) ? (
          <section className="wishList_container">
            {wishlist.map((item: item) => {
              const { name, price, sku, custom_attributes = [] } = item?.product
              return (<WishlistCard
                key={item.id}
                id={item.id}
                name={name}
                price={price}
                img={custom_attributes?.find(item => item.attribute_code === 'image')?.value}
                sku={sku}
                moveToCart={moveProductToCart}
                deleteItemFromWishList={deleteItemFromWishList}
              />)
            })}
          </section>
        ) : (
          <span className="no-item-text">
            {WISHLIST_CONSTANT.no_item_found_mg.en}
          </span>
        )}
      </S.Content>
    </>
  );
};

export default WishListPage;

import React, { useState, useEffect, useCallback } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { ReactComponent as ProjectorIcon } from "./../../../../globals/svg/projector.svg";
import { io, Socket } from 'socket.io-client';
import * as S from './styles/styles';
import { useNotification } from '../../../../hooks/useNotification';
import { ALERT_STATUS } from '../../../../lib/constant';
import { ProjectOnBigScreenProps, ScreenStatus } from './types/types';
import { PROJECT_SCREEN_CONSTANTS } from './constants';

const ProjectOnBigScreen: React.FC<ProjectOnBigScreenProps> = ({ productData }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedScreen, setSelectedScreen] = useState<string | null>(null);
  const [socket, setSocket] = useState<Socket | null>(null);
  const { showAlert } = useNotification();
  const [screenStatus, setScreenStatus] = useState<ScreenStatus>({
    screen1: 'offline',
    screen2: 'offline',
    screen3: 'offline'
  });
  const screens = ['screen1', 'screen2', 'screen3'];
  const [isConnected, setIsConnected] = useState(false);

  const updateScreenStatus = useCallback((update: Partial<ScreenStatus>) => {
    setScreenStatus(prevStatus => ({ ...prevStatus, ...update } as ScreenStatus));
  }, []);

  const webSocketServerUrl = process.env.REACT_APP_WEB_SOCKET_SERVER_END_POINT || ''

  useEffect(() => {
    const newSocket = io(webSocketServerUrl, {
      path: '/socket',
      transports: ["polling"],
      reconnection: true,
      reconnectionAttempts: Infinity,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
      timeout: 20000,
    });

    newSocket.on('connect', () => {
      console.log('Connected to server');
      setIsConnected(true);
      newSocket.emit('get_screen_status');
    });

    newSocket.on('disconnect', () => {
      console.log('Disconnected from server');
      setIsConnected(false);
    });

    setSocket(newSocket);

    newSocket.on('screen_status_update', (update: Partial<ScreenStatus>) => {
      updateScreenStatus(update);
    });

    newSocket.on('error', (error) => {
      console.error('Socket error:', error);
      showAlert('Unable to make a connection to Big Screen', ALERT_STATUS.error);
    });

    newSocket.emit('get_screen_status');

    return () => {
      newSocket.disconnect();
    };
  }, [updateScreenStatus]);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleScreenSelection = (screenId: string) => {
    if (screenStatus[screenId] === 'available') {
      setSelectedScreen(screenId);
      sendProductToBigScreen(screenId);
      // closeModal();
    } else if (screenStatus[screenId] === 'presenting') {
      showAlert(PROJECT_SCREEN_CONSTANTS.in_presenting.en, ALERT_STATUS.info);
    } else {
      showAlert(PROJECT_SCREEN_CONSTANTS.disconnected.en, "warning");
    }
  };

  const sendProductToBigScreen = (screenId: string) => {
    if (socket) {
      socket.emit('send_to_bigscreen', { screenId, productData });
    }
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'available': return 'success';
      case 'presenting': return 'secondary';
      case 'offline': return 'primary';
      // default: return 'default';
    }
  };

  return (
    <S.Content>
      <Button variant="contained" color="primary" className="project-btn" onClick={openModal}>
        {/* <ProjectorIcon className="project-icon" /> */}
        Project on Big Screen
      </Button>
      <Dialog open={isModalOpen} onClose={closeModal}>
        <DialogTitle>Select a Big Screen</DialogTitle>
        <DialogContent className='dialog-content'>
          {!isConnected && <>
            <S.DisconnectedMessage>{PROJECT_SCREEN_CONSTANTS.reconnect.en}</S.DisconnectedMessage>
          </>
          }
          {screens.map((screenId) => (
            <Button
              key={screenId}
              variant="outlined"
              onClick={() => handleScreenSelection(screenId)}
              fullWidth
              style={{ marginBottom: '10px' }}
              color={getStatusColor(screenStatus[screenId])}
            >
              {`BigScreen ${screenId.slice(-1)} - ${screenStatus[screenId] || 'Unknown'}`}
            </Button>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </S.Content>
  );
};

export default ProjectOnBigScreen;
import { cartItemResponse } from "../../components/organisms/employee-cart/types/types";
import { getStaticConstantValue } from "../utlis";

declare global {
  interface Window {
    dataLayer: any[];
  }
}

const currencyCode = getStaticConstantValue("currency_code");

export const addItemToCart_gtm = (products: any) => {
  if (!products || Object.keys(products).length === 0) {
    console.warn("Unable to add product to data layer(GMT),Invalid product");
    return;
  }
  window?.dataLayer?.push({ ecommerce: null });
  window?.dataLayer?.push({
    event: "addToCart",
    ecommerce: {
      currencyCode,
      add: {
        products,
      },
    },
  });
};

export const outOfStock_gtm = (products: any) => {
  if (!products || Object.keys(products).length === 0) {
    console.warn("Unable to add product to data layer(GMT),Invalid product");
    return;
  }
  window?.dataLayer?.push({ ecommerce: null });
  window?.dataLayer?.push({
    event: "out_of_stock",
    ecommerce: {
      currencyCode,
      add: {
        products,
      },
    },
  });
};
export const skipVideo_gtm = () => {
  const videoInfo = JSON.parse(
    sessionStorage.getItem("pdp_video_info") ?? "{}"
  );
  if (Object.keys(videoInfo).length) {
    const { currentTime, duration, title, videoUrl } = videoInfo;
    const videoPercent = (currentTime / duration) * 100;
    window?.dataLayer?.push({
      event: "skip_video",
      eventModel: {
        video_current_time: currentTime,
        video_duration: duration,
        video_percent: videoPercent,
        video_provider: "youtube",
        video_title: title,
        video_url: videoUrl,
        visible: true,
      },
    });
    sessionStorage.removeItem("pdp_video_info");
  }
};

export const removeItemFromCart_gtm = (
  products: { name: string; price: number; id: number }[]
) => {
  window?.dataLayer?.push({ ecommerce: null });
  window?.dataLayer?.push({
    event: "remove_from_cart",
    ecommerce: {
      currencyCode,
      remove: { products },
    },
  });
};

export const removeAllItemFromCart_gtm = (product: any) => {
  window?.dataLayer?.push({ ecommerce: null });
  window?.dataLayer?.push({
    event: "remove_from_cart",
    ecommerce: {
      currencyCode: currencyCode,
      remove: {
        products: [...product],
      },
    },
  });
};

export const updateCart_gtm = (items: any) => {
  window?.dataLayer?.push({ ecommerce: null });
  window?.dataLayer?.push({
    event: "update_cart",
    ecommerce: {
      items,
    },
  });
};
export const viewCart_gtm = (items: any) => {
  window?.dataLayer?.push({ ecommerce: null });
  window?.dataLayer?.push({
    event: "view_cart",
    ecommerce: {
      items,
    },
  });
};

export const addToWishList_gtm = (product: any, variantDetails: any) => {
  if (!product || Object.keys(product).length === 0) {
    console.warn("Unable to add product to data layer(GMT),Invalid product");
    return;
  }
  const { name, price, sku } = product || {};

  window?.dataLayer?.push({ ecommerce: null });
  window?.dataLayer?.push({
    event: "add_to_wishlist",
    ecommerce: {
      currencyCode: currencyCode,
      add: {
        products: [
          {
            name,
            id: sku,
            price,
            ...variantDetails,
            //category:"..?",     //Not available as of now, will added once required
            quantity: 1,
          },
        ],
      },
    },
  });
};
export const login_gtm = (
  method: String,
  customer_data: any,
  user_encrypted_email: string | null,
  communication_preferences: any
) => {
  window?.dataLayer?.push({
    event: "login",
    user_encrypted_email: user_encrypted_email,
    customer_data: customer_data,
    method,
    communication_preferences,
  });
};
export const productDetails_gtm = (product: any) => {
  window?.dataLayer?.push({
    event: "view_item",
    currency: currencyCode,
    items: product,
  });
};
export const cart_gtm = (product: any) => {
  window?.dataLayer?.push({
    event: "view_cart",
    currency: currencyCode,
    items: [...product],
  });
};

export const qr_view_item_gtm = (sku: string) => {
  window?.dataLayer?.push({
    event: "qr_view_item",
    currency: currencyCode,
    scanned_sku: sku,
  });
};

export const checkout_gtm = (
  value: number,
  coupon: string,
  items: cartItemResponse[]
) => {
  window?.dataLayer?.push({
    event: "begin_checkout",
    currency: currencyCode,
    value: value,
    coupon: coupon,
    items: items,
  });
};

export const checkout_shipping_info_gtm = (
  value: number,
  coupon: string,
  shipping_method: string,
  items: cartItemResponse[]
) => {
  window?.dataLayer?.push({
    event: "add_shipping_info",
    currency: currencyCode,
    value: value,
    coupon: coupon,
    shipping_tier: shipping_method,
    items: items,
  });
};

export const checkout_payment_info_gtm = (
  value: number,
  coupon: string,
  payment_type: string,
  items: cartItemResponse[]
) => {
  window?.dataLayer?.push({
    event: "add_payment_info",
    currency: currencyCode,
    value: value,
    coupon: coupon,
    payment_type,
    items: items,
  });
};

export const checkout_second_payment_info_gtm = (
  value: number,
  orderId: string,
  payment_type: string,
) => {
  window?.dataLayer?.push({
    event: "add_second_payment_info",
    currency: currencyCode,
    value: value,
    orderId:orderId,
    payment_type,
  });
};

export const secondPaymentCompletion_info_gtm = (
  value: number,
  orderId: string,
  payment_type: string,
) => {
  window?.dataLayer?.push({
    event: "final_payment_completion",
    currency: currencyCode,
    value: value,
    orderId:orderId,
    payment_type,
  });
};


export const checkout_purchase_gtm = (
  value: number,
  coupon: string,
  payment_type: string,
  shippingMethod: string,
  items: cartItemResponse[],
  orderId: string
) => {
  window?.dataLayer?.push({
    event: "purchase",
    currency: currencyCode,
    value: value,
    coupon: coupon,
    payment_type,
    shipping_tier: shippingMethod,
    items: items,
    orderId: orderId,
  });
};

export const add_payment_approval_info = (
event_name:string,
employee_email:string,
order_id:number,
payment_method:string,
order_amount:number,
paid_amount:number,
emp_role:string
) => {
  window?.dataLayer?.push({
    event:event_name,
    data: {
       employee: {
         email:employee_email,
       },
       Order_id:order_id,
       payment_method:payment_method,
       order_amount:order_amount,
       paid_amount:paid_amount,
    },
    emp_type:emp_role,
    });
};
import React, { useEffect, useState } from "react";
import * as S from "./styles/styles";
import OpenCash from "./openCash";
import CloseCash from "./closeCash";
import apiList, { ALERT_STATUS, SOMETHING_WENT_WRONG, USER_TYPE } from "../../../lib/constant";
import { doActionGet } from "../../../helpers/httpRequest";
import { useNotification } from "../../../hooks/useNotification";
import { getLocalStorage } from "../../../helpers/localStorageUtil";
import { useNavigate } from "react-router-dom";
import { EMPLOYEE_LOGIN_ROUTE_PATH } from "../../../routes/routes-constant";
import { getStaticConstantValue } from "../../../helpers/utlis";

const CashRegistery: React.FC = () => {
    const [currentStatus,setCurrentStatus] = useState("");
    const [employeeId,setEmployeeId] = useState("");
    const [shiftId ,setShiftId] = useState("");
    const { showAlert } = useNotification();
    const navigate= useNavigate();
    
    useEffect(() =>{
      const isEmployeeLoggedIn = getLocalStorage('access-token');
      if (!isEmployeeLoggedIn) {
        navigate(EMPLOYEE_LOGIN_ROUTE_PATH);
      }
    },[])
    useEffect(()=>{
      newShiftGetter();
    },[])

    const newShiftGetter=()=>{
      const employeeEmail = getLocalStorage('employee_email');
      if(employeeEmail){
        doActionGet({
          url: apiList.getCurrentRegisteryStatus(employeeEmail),
          userType:USER_TYPE.Employee
        })?.then((resp: any) => {
          console.log(resp);
         if(resp?.data && resp?.data.length>0){
            setEmployeeId(resp?.data[0]?.emp_id);
            setCurrentStatus(resp?.data[0]?.status?.toUpperCase());
            setShiftId(resp?.data[0]?.shift_id);
         }
        }).catch((error: any) => {
          console.log("error msg", error);
          showAlert(SOMETHING_WENT_WRONG, ALERT_STATUS.error);
        })
      }
    }

    const statusChangeHandler=(status:string)=>{
      newShiftGetter();
    }
   useEffect(()=>{
    setCurrentStatus(getLocalStorage('shiftStatus'));
   },[getLocalStorage('shiftStatus')])

    return (
    <S.Content>
        <div className="employee-cash-registery">
          <div className="registery-new-heading">{"Hi "+getStaticConstantValue("employee_firstname")+" "+ getStaticConstantValue("employee_lastname")}</div>
        {currentStatus==="CLOSED" &&<OpenCash employeeId={employeeId} statusChangeHandler={statusChangeHandler}/>}
        {currentStatus==="OPEN" &&<CloseCash employeeId={employeeId} shiftId={shiftId} statusChangeHandler={statusChangeHandler}/>}
        </div>
    </S.Content>
  );
};

export default CashRegistery;

import { Navigate, useNavigate } from "react-router-dom";
import { CAFETERIA_CASH_REGISTERY, EMPLOYEE_LOGIN_ROUTE_PATH, EMPLOYEE_PROFILE_PATH, LOGIN_ROUTE_PATH, PLP_CAFETERIA_MENU_PAGE_ROUTE_PATH } from "./routes-constant";
import { getLocalStorage } from "../helpers/localStorageUtil";
import { isCafeteriaManager } from "../helpers/utlis";


export const GuestRoute = ({ children }: any) => {
    return  (
      children
    )
  };

  type AuthorizedRouteProps = {
    children: any;
  };
  
  export const EmployeeAuthorizedRoute = ({ children }: AuthorizedRouteProps) => {
    const color= "#FFFFFF"
    document.body.style.backgroundColor = color;
    const isEmployeeLoggedIn = getLocalStorage('access-token');
  
  
    if (!isEmployeeLoggedIn) {
      return <Navigate to={EMPLOYEE_LOGIN_ROUTE_PATH} />;
    }
  
    if (isCafeteriaManager()) {
    
      if (window.location.pathname !== EMPLOYEE_PROFILE_PATH) {
      
        const shiftStatus = getLocalStorage('shiftStatus');
  
        if (shiftStatus !== 'OPEN') {
          return <Navigate to={CAFETERIA_CASH_REGISTERY} replace />;
        }
      }
    }
  
    return <>{children}</>;
  };

  export const CustomerAuthorizedRoute = ({ children }: AuthorizedRouteProps) => {
    const color= "#F8F7F2"
    document.body.style.backgroundColor = color;
    const isCustomerLoggedIn = getLocalStorage('user-token');
     return isCustomerLoggedIn ? (
       children
     ) : (
       <Navigate to={{ pathname: LOGIN_ROUTE_PATH }} />
     );
   }
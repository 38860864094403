
export const SCANNER_CONSTANTS = {
    Scanner_title: {
        en: "Scan Product QR"
    }
}

export const SCANNER_ERROR_MESSAGES = {
    unexpected_error: {
        en: "An unexpected error occurred"
},
    setup_error: {
        en: 'Error setting up the scanner'
    },
    barcode_capture_failed: {
        en: 'Error during barcode capture'
    }

}
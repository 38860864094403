import axios from 'axios';
import { performCustomerLogout, performEmployeeLogout } from './authUtils';
import { getLocalStorage } from './localStorageUtil';
import { getStaticConstantValue } from './utlis';
import { AlertColor } from '@mui/material';
import { AccountInfo, IPublicClientApplication } from '@azure/msal-browser';
import apiList, { ALERT_STATUS, SESSION_EXPIRED } from '../lib/constant';

interface ShowAlert {
  (message: string, type?: AlertColor | undefined): void;
}


export const httpAxiosInterceptor = (msalInstance: IPublicClientApplication, accounts: AccountInfo[], showAlert: ShowAlert, setLoading: (loading: boolean) => void) => {

  //Request Interceptors
  axios.interceptors.request.use(function (config) {
    setLoading(true);
    if (getStaticConstantValue("store_code") && !config.url?.includes(getStaticConstantValue("store_code")) && config?.url !== apiList?.userLogin && config?.url !== apiList?.employeeLogin) {
      config.url = config.url?.replace("V1/", getStaticConstantValue("store_code") + "/V1/");
    }
    return config;
  }, function (error) {
    setLoading(false);
    return Promise.reject(error);
  });

  //Response Interceptors
  axios.interceptors.response.use(
    (response) => {
      setLoading(false);
      return response;
    },
    (error) => {
      setLoading(false);
      if (error.response && error.response.status === 401) {
        const userToken = getLocalStorage('user-token');
        const accessToken = getLocalStorage('access-token');

        if (userToken) performCustomerLogout(showAlert);
        else if (accessToken) performEmployeeLogout(showAlert);
        else performCustomerLogout(showAlert);

        showAlert(SESSION_EXPIRED.en, ALERT_STATUS.error);

      } else if (error.request) {
        console.log('No response received');
      } else {
        console.log('Error', error.message);
      }
      return Promise.reject(error);
    }
  );
};


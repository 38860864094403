import React, { CSSProperties } from "react";
import { PulseLoader } from 'react-spinners'; // Ensure this is used
import * as S from "./styles";

import logoSvg from '../../../globals/images/Logo/BottomLogo.png';
const override: CSSProperties = {
  opacity: 1,
  width: "20px",
  height: "29px",
};

export const Loading: React.FC = () => {
  return (
    <S.View>
      <div className="loader-content">
      <img className="bottom-logo" src={logoSvg}/>
        {/* Optionally add the PulseLoader if needed */}
        {/* <PulseLoader color="#716853" cssOverride={override} /> */}
      </div>
    </S.View>
  );
};

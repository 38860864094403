import styled, { css } from "styled-components";

export const Content = styled.div<any>`
  ${(p:any) => {
    return css`
    background-color: #FFFFFF;
    .payment-method-section {
        // height: 100vh;
    }

    .print-section{
         display: flex;
        flex-direction: row;
        align-items: baseline;
    }
        .tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 202px;
  background-color: #6F6855;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  font-size:12px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

    .payment-section{
        padding: 16px;
        gap: 16px;
    }

    .payment-heading{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .payment-heading-content{
        font-weight: 500;
        font-size: 16px;
        line-height: 17.6px;
        color: var(--sand);
    }

    .method-code{
        width:16px;
        height:16px;
        accent-color:grey;
    }

    .payment-methods {
        display: flex;
        flex-direction: row;
        gap: 16px;
    }

    .payment-code-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 177px;
        height: 60px;
        border: 1px solid #B6B2A6;
        border-radius: 4px;
        background-color: #FFFFFF;
        cursor: pointer;
        transition: background-color 0.3s ease, color 0.3s ease;
        color: var(--silver-foil);; /* Default icon and text color */
    }

    .payment-code-section.selected {
        background-color: #6F6855;
        color: #FFFFFF;
    }

    .payment-code-section.selected .item-label {
        font-family: 'Poppins';
        font-weight: 500;
        font-size: 10px;
        line-height: 15px;
        text-align: center;
        margin-top: 4px;
        color: inherit; /* Inherit the color based on parent */
    }

    .payment-icon img {
        width: 32px;
        height: 32px;
        color: var(--silver-foil);
        fill: currentColor; /* Ensure the SVG uses currentColor */
        stroke: currentColor; /* For SVGs using stroke */
    }

    .payment-code-section.selected .payment-icon img {
        filter: invert(100%); /* Inverts the icon color to white */
    }

    .payment-icon {
        position: relative;
        width: 32px;
        height: 32px;
    }

    .item-label {
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
        font-size: 10px;
        line-height: 15px;
        text-align: center;
        color: #6F6855;
        margin-top: 4px;
    }

    .input-box-content{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .input-box{
        width:100%;
          height:32px;
          padding-top:1.5px;
          padding-left:8px;
          background-color:var(--cultured);
          border:1px solid var(--silver-foil);
          border-radius: 20px;
      }
    
      .input-box::placeholder {
        font-weight: 500;
        font-size: 10px;
        line-height: 15px;
      }

      input-box:focus, input:focus{
        outline: none;
    }
    
    .subtotal-reference-and-order-section {
        padding: 16px;
        gap: 80px;
    }

    .subtotal-reference-container {
        gap: 24px;
    }

    .subtotal-container {
        gap: 24px;
    }

    .subtotal-section {
        border-top: 1px solid var(--silver-foil);
        border-bottom: 1px solid var(--silver-foil);
        display: flex;
        flex-direction: column;
        padding: 16px 0px;
        gap: 16px;
    }

    .total-section {
        padding: 16px 0px;
        gap: 16px;
    }

    .subtotal, .tax-and-fee, .total {
        display: grid;
        grid-template-columns: 4fr 1fr 3fr;
    }

    .subtotal-label, .tax-and-fee-label, .subtotal-value, .tax-and-fee-value  {
        font-weight: 400;
        font-size 16px;
        line-height: 20.8px;
    }

    .total-label {
        font-weight: 600;
        font-size 16px;
        line-height: 24px;
    }

    .total-value, .tax-and-fee-value, .subtotal-value {
        color: var(--sand);
        display: flex;
        justify-self: flex-end;
        align-self: center;
    }

    .receipt{
        margin-top:28px;
        height: 30px;
    }

    .order-receipt-section{
        display: flex;
        justify-content: end;
    }

    .receipt-toggler-section{
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    .receipt-toggler-section .payment-heading {
        gap: 16px;
    }

    .receipt-toggler-section .payment-heading-content {
        font-weight: 500;
        font-size: 18px;
        line-height: 19.8px;
        color: var(--dark-black);
    }
    
    .email-section {
        margin-top: 16px;
    }

    .checkout-footer{
        // position: sticky;
        // bottom: 0;
        // width: 100%;
        margin-top:190px;
        padding: 20px;
    }
    `
  }}
`
import React, { useEffect, useState } from "react";
import * as S from "../styles/styles";
import OrderFailureIcon from '../../../../globals/svg/order-failure-icon.svg';
import { CONTINUE_SHOPPING_LABEL, FINAL_ORDER_MESSAGE_INITIAL_TEXT, FINAL_ORDER_MESSAGE_LAST_TEXT, ORDER_DELIVERY_TRACKING_INITIAL_TEXT, ORDER_DELIVERY_TRACKING_LAST_TEXT, ORDER_FAILURE_SUB_HEADING, ORDER_FAILURE_TEXT, THANK_YOU_TEXT, TRY_AGAIN_LABEL } from "../../../../lib/constant";
import { useLocation, useNavigate } from "react-router-dom";
import CheckoutButton from "../../../atoms/checkout-button/CheckoutButton";
import { CHECKOUT_PAYMENT_PATH } from "../../../../routes/routes-constant";

const OrderFailure: React.FC = () => {
  const location = useLocation();
  const [errorMsg , setErrorMsg] = useState("");
  const navigate = useNavigate();

  useEffect(()=>{
       if(location.state?.failureMsg){
        setErrorMsg(location.state?.failureMsg);
       }
  },[location.state])

    return (
    <S.Content >
        <div className="order-success">
        <img  src={OrderFailureIcon} alt="Order Failure Logo" className="order-success-logo" />
        <div className="success-main-heading">{ORDER_FAILURE_TEXT}</div>
        <div className="failure-sub-heading">{errorMsg}</div>
        </div>
        {/* Footer section */}
        <div className="checkout-continue-button">
            <CheckoutButton isDisabled={false} label={TRY_AGAIN_LABEL} checkoutHandler={()=>{navigate(CHECKOUT_PAYMENT_PATH)}}/>
             </div>
    </S.Content>
  );
};

export default OrderFailure;

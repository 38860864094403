import { Address, Country, EncryptionAttribute } from "../types/types";

export const getAttribute = (attributes: EncryptionAttribute[] | undefined, code: string): string => {
    return attributes?.find(attr => attr.attribute_code === code)?.value || '';
};

export const updateSingleAddressWithDecryptedData = (address: Address, selectedAddressId: number, type: 'Billing' | 'Shipping') => {

    return {
        ...address,
        firstname: getAttribute(address.extension_attributes?.address_encryption_attribute_data, 'firstname'),
        lastname: getAttribute(address.extension_attributes?.address_encryption_attribute_data, 'lastname'),
        street: address.extension_attributes?.address_encryption_attribute_data?.find(attr => attr.attribute_code === 'street')?.value.split(',') || [''],
        telephone: getAttribute(address.extension_attributes?.address_encryption_attribute_data, 'telephone'),
        default_billing: type === 'Billing' ? address.id === selectedAddressId : address.default_billing,
        default_shipping: type === 'Shipping' ? address.id === selectedAddressId : address.default_shipping
    }
};

export const updateAllAddressesWithDecryptedData = (addresses: Address[], selectedAddressId: number, type: 'Billing' | 'Shipping') => {

    const updatedAddresses = addresses.map(address => ({
        ...address,
        firstname: getAttribute(address.extension_attributes?.address_encryption_attribute_data, 'firstname'),
        lastname: getAttribute(address.extension_attributes?.address_encryption_attribute_data, 'lastname'),
        street: address.extension_attributes?.address_encryption_attribute_data?.find(attr => attr.attribute_code === 'street')?.value.split(',') || [''],
        telephone: getAttribute(address.extension_attributes?.address_encryption_attribute_data, 'telephone'),
        default_billing: type === 'Billing' ? address.id === selectedAddressId : address.default_billing,
        default_shipping: type === 'Shipping' ? address.id === selectedAddressId : address.default_shipping,
    }))

    return updatedAddresses;

};

export const updateDefaultAddressData = (address: Address, selectedAddressId: number, type: 'Billing' | 'Shipping') => {

    return {
        ...address,
        default_billing: type === 'Billing' ? address.id === selectedAddressId : address.default_billing,
        default_shipping: type === 'Shipping' ? address.id === selectedAddressId : address.default_shipping
    }
};

export const getCountryName = (countryList:Country[], countryId: string |undefined) => {
    return (countryList as Country[]).filter((item: Country) => item.id === countryId);

};


import styled, { css } from "styled-components";

export const Content = styled.div<any>`
  ${(p:any) => {
    return css`
    .progress-bar-container{
        margin-bottom:24px;
    }
    .progress-bar-first-line{
        display:flex;
    }
    label {
        margin-bottom: 10px;
        cursor: pointer;
        position: relative; /* Make the label position relative */
      }
      
      .line {
      margin-top: 12px;
        height: 1px;
        width: 50%; /* Adjust the width of the line as needed */
        background-color: var(--sku-font-color);
      }
      .progress-bar-first-line{
        margin-left: 14%;
        margin-right: 16%;
      }
      .progress-bar-second-line{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        text-align: center;
      }
      .progress-bar-title{
        font-weight:500;
        font-size:10px;
        line-height:15px;
        color:#6F6855;
      }
      .progress-bar-faded-title{
        font-weight:500;
        font-size:10px;
        line-height:15px;
        color: var(--sku-font-color);
      }
      .step-code{
        accent-color:grey;
      }
        .custom-radio:checked{
        accent-color:var(--sand-button-color);
        border:1px solid ;
        height:20px;
        width:20px;
  }
        .custom-radio{
   width:20px;
   height:20px;
    accent-color:var(--sku-font-color);
    border:2px solid var(--sku-font-color);
  }
    `
}}
`

import { useState } from "react";
import { ARABIC_TERMS_AND_CONDITIONS, ARABIC_TRANSLATION_TEXT, LOGIN_TERMS_AND_CONDITIONS_TEXT } from "../../../../lib/constant";
import ToggleSwitch from "../../../atoms/toggleSwitch/ToggleSwitch";
import { LOGIN_CONSTANTS } from "../constant";
import { TermsAndCondition } from "../styles/styles";

const LoginTermsAndCondition = () => {
    const [isEnable,setIsEnable] = useState(false);
    
    const toggleSwitchHandler=(isEnable:boolean)=>{
        setIsEnable(isEnable);
      }

    return (
        <>
            <TermsAndCondition>
                <div className="toggle-switch-container">
                <div className="lang-label">EN</div>
                <ToggleSwitch  label={"Orders"} onchangeHandler={toggleSwitchHandler} value={isEnable}/>
                <div className="lang-label">AR</div>
                </div>
                <div className="heading">{!isEnable?LOGIN_CONSTANTS.terms_conditions.en:<div className="arabic-heading">{ARABIC_TERMS_AND_CONDITIONS}</div>}</div>
                <p className="text-content">{!isEnable?LOGIN_TERMS_AND_CONDITIONS_TEXT:ARABIC_TRANSLATION_TEXT}</p>
            </TermsAndCondition>
        </>
    );
}

export default LoginTermsAndCondition;
import { signOut } from "aws-amplify/auth";
import { AxiosError } from "axios";
import { doActionGet } from "../../../../../../helpers/httpRequest";
import { getLocalStorage, removeLocalStorage } from "../../../../../../helpers/localStorageUtil";
import apiList, { ALERT_STATUS, USER_TYPE } from "../../../../../../lib/constant";
import { LOGIN_ROUTE_PATH } from "../../../../../../routes/routes-constant";
import { PROFILE_MESSAGES } from "../../constants";
import { CustomerData, NavigateFunction, ShowAlert } from "../../types/types";
import { API_CALL_MESSAGES } from "../constants";
import { manageIndexedDB } from "../../../../../../helpers/indexedDB";

export const getCustomerData = async (showAlert: ShowAlert, navigate: NavigateFunction): Promise<CustomerData | null> => {
    const config = {
        url: apiList.customerDetails,
        userType: USER_TYPE.Customer
    };

    try {
        const response = await doActionGet(config);
        if (response?.data) {
            return response.data;
        } else {
            return null;
        }
    } catch (error) {
        const axiosError = error as AxiosError; // Type assertion to handle errors from Axios
        console.error(API_CALL_MESSAGES.fetch_user_data_fail.en, axiosError.message);
        showAlert(axiosError.response?.status === 401 ? PROFILE_MESSAGES.login_required.en : PROFILE_MESSAGES.fetch_fail.en, axiosError.response?.status === 401 ? ALERT_STATUS.info : ALERT_STATUS.error);
        if (axiosError.response?.status === 401) {
            signOut();
            const token = getLocalStorage('user-token');
            if (token) {
                removeLocalStorage('user-token');
                manageIndexedDB({ key: 'userToken', toDelete: true })
            } else {
                navigate(LOGIN_ROUTE_PATH);
            }
        }
        return null;
    }
}

export const getCountries = async (showAlert?: ShowAlert) => {
    try {
        const response = await doActionGet({ url: apiList.getCountries });
        if (response && response.data) {
            return response.data;
        } else {
            throw new Error(API_CALL_MESSAGES.fetch_fail.en);
        }
    } catch (error) {
        const axiosError = error as AxiosError;
        if (axiosError.response && axiosError.response.status === 500) {
            showAlert?.(API_CALL_MESSAGES.internal_server_error.en, ALERT_STATUS.error);
        } else {
            showAlert?.(API_CALL_MESSAGES.fetch_countries_fail.en, ALERT_STATUS.error);
        }
        console.error('Error fetching countries:', axiosError);
    }
};
export function capitalizeFirstLetter(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export const calculateTaxTotal = (order: any) => {
    return order?.items?.reduce((total: number, item: any) => total + item?.tax_amount, 0) ?? 0;
}

export const calculateSubTotal = (order: any) => {
    return order?.items?.reduce((total: number, item: any) => total + item?.base_row_total_incl_tax, 0) ?? 0;
}

export const getDisplayStatus = (status: string): string => {
    switch (status) {
      case 'pending':
        return 'New';
      case 'preparing':
        return 'In preparation';
      case 'complete':
        return 'Served';
      default:
        return status; // fallback to original status if unknown
    }
  };
import React from "react";
import * as S from "./styles/styles";


const EmployeeFooter: React.FC<{ isDisabled: boolean, checkoutHandler: () => void, label: string }> = ({ isDisabled = false, checkoutHandler, label }) => {

  return (
    <S.Content>
      <div className="employee-footer">
        <button disabled={isDisabled} onClick={checkoutHandler}>{label}</button>
      </div>
    </S.Content>

  );
};

export default EmployeeFooter;

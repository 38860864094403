import React from 'react';
import * as S from "./styles"
import { ModalButton, ModalContent, ModalMessage, ModalOverlay } from './styles';

type ConfirmationModalProps = {
  isOpen: boolean;
  message: string;
  onConfirm: () => void;
  onCancel: () => void;
};


const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  isOpen,
  message,
  onConfirm,
  onCancel,
}) => {
  return (
    <ModalOverlay isOpen={isOpen}>
      <ModalContent>
        <ModalMessage>{message}</ModalMessage>
        <div>
        <ModalButton onClick={onConfirm}>Yes</ModalButton>
        <ModalButton onClick={onCancel}>No</ModalButton>
        </div>
      </ModalContent>
    </ModalOverlay>
  );
};

export default ConfirmationModal;

import { Address, Country } from "../../types/types";

export const initialFormData: Address = {
    id: undefined,
    customer_id: undefined,
    street: [''],
    city: '',
    region: {
        region_code: '',
        region: '',
        region_id: undefined
    },
    postcode: '',
    country_id: '',
    region_id: undefined,
    telephone: '',
    firstname: '',
    lastname: '',
    default_shipping: false,
    default_billing: false
};

export interface myAddressFormProps{
    addressType: String;
    countryData:Country[];
    isNewAddressExpand: boolean;
    setIsNewAddressExpand:(value: boolean)=> void;
    setDefaultBillingAddressDetails?:(address: Address | undefined)=>void;
    setDefaultShippingAddressDetails?:(address: Address | undefined)=>void;
    setIsExpanded:(value: boolean)=> void; 
    newAddressSaveHandler:(formData:Address)=>Address;
}
import React, { useState, useEffect } from 'react';
import { WelcomeMessageProps } from './types/types';
import { LANGUAGES_IN_DIFFERENT_LANGUAGES } from '../../../lib/constant';

const languages = LANGUAGES_IN_DIFFERENT_LANGUAGES;

const WelcomeMessage: React.FC<WelcomeMessageProps> = ({ onCompleted }) => {
  const [messageIndex, setMessageIndex] = useState(0);

  useEffect(() => {
    if (messageIndex >= languages.length - 1) {

      /*If last message has been displayed, then prepare to navigate */
      const timeoutId = setTimeout(() => {
        onCompleted();
      }, 1000); /* Delay for the last message */
      
      return () => clearTimeout(timeoutId);
    }

    const intervalId = setInterval(() => {
      setMessageIndex(prevIndex => prevIndex + 1);
    }, 1000); /* Change messages every second */

    console.log(intervalId);
    

    return () => clearInterval(intervalId);
  }, [messageIndex, onCompleted]);

  return <button className='welcome-message-button'>{languages[messageIndex]}</button>;
};

export default WelcomeMessage;

import { useEffect, useState } from 'react';
import * as S from "./styles/styles";
import ImageViewer from './ImageViewer';
import BasicInfo from './BasicInfo';
import { getAttributes } from './common/helpers';
import VariantAccordion from './variantAccordion';
import ProductQuantity from './ProductQuantity';
import { AttributeValue, Variant } from './variantAccordion/types';
import { CarouselMedia, VariantCombinationArray } from './common/types';
import { useAppContext } from '../../../Context/AppContext';
import { ACTION_TYPE } from '../../../Context/Constant';
import { skipVideo_gtm } from '../../../helpers/GTM';
import AddToCart from './AddToCart';
import ProjectOnBigScreen from './project-on-big-screen';
import { useAuth } from '../../../Context/AuthContext';
import { ALLOWED_EMPLOYEE_TO_SEE_PROJECT_SCREEN_CTA } from '../../../lib/constant';
import { getStaticConstantValue } from '../../../helpers/utlis';

interface ProductPageProps {
    sku: string;
    basicInfo: any;
    variants: Variant[]
    selectedMediaId: string;
    attributes: AttributeValue[][];
    variantCombinations: VariantCombinationArray;
    media: CarouselMedia[];
    setSelectedMediaId: React.Dispatch<React.SetStateAction<string>>;
    setShowProduct: React.Dispatch<React.SetStateAction<boolean>>;
    setMedia: React.Dispatch<React.SetStateAction<CarouselMedia[]>>;
    filters: { [key: string]: string };
    setFilters: React.Dispatch<React.SetStateAction<{ [key: string]: string }>>;
    user: string;
    specialOrderQuoteId: string;
}

const ProductPage: React.FC<ProductPageProps> = ({
    sku,
    basicInfo,
    variants,
    selectedMediaId,
    attributes,
    variantCombinations = [],
    media = [],
    setSelectedMediaId,
    setMedia,
    setShowProduct,
    filters,
    setFilters,
    user,
    specialOrderQuoteId
}) => {
    const { state, dispatch } = useAppContext()
    const [quantity, setQuantity] = useState(1);
    const { isEmployeeLoggedIn , isUserLoggedIn } = useAuth();
    const [showProjectOnScreenCTA, setShowProjectOnScreenCTA] = useState(false);

    const priceArray = variantCombinations?.map(variant => variant?.price)
    let price = priceArray?.length ? Math.min(...priceArray) : (basicInfo?.price ?? 0)
    let maxQuantity, parentData = { option_id: '', option_value: '' }
    const { name, custom_attributes } = basicInfo ?? {}

    if (!variants?.length) maxQuantity = basicInfo?.extension_attributes?.saleable_quantity ?? basicInfo?.extension_attributes?.stock_item?.qty ?? 0
    else if ((Object.keys(filters) ?? []).length === variants?.length) {
        const currentValueSet = Object.values(filters).map(v => v === '-1' ? 'any-' : v + '-').join('')
        const filteredVariantCombination = variantCombinations?.filter(vC => vC.valueSet === currentValueSet)?.[0]
        price = (filteredVariantCombination?.price ?? price)
        maxQuantity = (filteredVariantCombination?.qty ?? 0)
        parentData = {
            "option_id": basicInfo?.extension_attributes?.configurable_product_options?.filter((v: { label: string; }) => v.label === 'Configurable Variant')?.[0]?.attribute_id ?? ''
            , "option_value": filteredVariantCombination?.id

        }
    }
    const accordionAttributes = getAttributes(["use_and_care", "shipping_notes"], custom_attributes)
    const basicAttributes = getAttributes(["storytelling_video", "short_description", "three_dim_image_path"], custom_attributes)
    price= basicInfo?.type_id==="bundle" ? basicInfo?.extension_attributes?.bundle_price:price;
    maxQuantity=basicInfo?.type_id==="bundle" ? (basicInfo?.extension_attributes?.saleable_quantity?basicInfo?.extension_attributes?.saleable_quantity:basicInfo?.extension_attributes?.stock_item.max_sale_qty):maxQuantity;
    useEffect(() => {
        dispatch({ type: ACTION_TYPE.set_variant, payload: {} })
        const footer = document.getElementsByClassName("footer")[0] as HTMLElement;
        if (footer) {
            footer.style.display = "flex";
        }
    }, [])

    useEffect(() => {
        skipVideo_gtm()
    }, [])

    useEffect(() => {
        const shouldShowProjectOnScreenCTA = isEmployeeLoggedIn && ALLOWED_EMPLOYEE_TO_SEE_PROJECT_SCREEN_CTA.includes(getStaticConstantValue('requested_by'))
            && !!basicAttributes?.three_dim_image_path;
        setShowProjectOnScreenCTA(shouldShowProjectOnScreenCTA);
    }, [isEmployeeLoggedIn, getStaticConstantValue('requested_by')]);

    useEffect(() => {

        if ((Object.keys(filters) ?? []).length === variants?.length) {
            const dispatchData = getAttributeIds(filters, variants, parentData)
            dispatch({ type: ACTION_TYPE.set_variant, payload: dispatchData })
            dispatch({ type: ACTION_TYPE.show_wishlist_icon, payload: true })

            const currentValueSet = Object.values(filters).map(v => v + '-').join('')
            const filteredVariantCombination = variantCombinations?.filter(vC => vC.valueSet === currentValueSet)?.[0]
            dispatch({ type: ACTION_TYPE.set_current_PDP, payload: { ...state?.current_pdp, price: price, variantSku: filteredVariantCombination?.variant_sku ?? '' } })

            if (filteredVariantCombination?.image_url) {
                if (!media.some(image => image.id === ('variant' + filteredVariantCombination?.id))) {
                    setMedia(images => ([...images, {
                        id: 'variant' + filteredVariantCombination.id,
                        type: 'image',
                        url: `${(process.env.REACT_APP_PDP_IMAGES_BASE_URL ?? '') + filteredVariantCombination.image_url}`,
                    }]))
                }
                setSelectedMediaId('variant' + filteredVariantCombination.id)
            }

            const container = document.getElementsByClassName('container')[0];
            if (container) container.scrollTop = 0;

        } else {
            dispatch({ type: ACTION_TYPE.show_wishlist_icon, payload: (basicInfo?.type_id === "simple") })
            dispatch({ type: ACTION_TYPE.set_variant, payload: {} })
        }
    }, [dispatch, filters, filters.length, media, media.length, price, setMedia, setSelectedMediaId, variantCombinations?.length, variants?.length])



    const getAttributeIds = (filters: { [key: string]: string; }, data: any[], parentData: { [key: string]: string }) => {
        let variantID: { [key: string]: string } = {};
        const variantName: { [key: string]: string } = {};

        for (const key in filters) {
            if (filters.hasOwnProperty(key)) {
                const selectedVariant = data.find(item => item.code === key);
                const attributeId = selectedVariant?.attribute_id;
                const valueIndex = filters[key];
                if (valueIndex === '*') continue
                const attributeLabel = selectedVariant?.options.find((item: { value_index: string; }) => item?.value_index === valueIndex)?.label;

                if (attributeId !== undefined && valueIndex !== undefined) {
                    variantID[`${attributeId}`] = valueIndex;
                }
                if (attributeLabel !== undefined) {
                    variantName[`${key}`] = attributeLabel;
                }
            }
        }
        variantID = { ...variantID, [parentData.option_id]: parentData.option_value }
        return { variantID, variantName };
    }

    return (<S.Content>
        <div className="container">
            <div className="image-carousel">
                <ImageViewer media={media} selectedMediaId={selectedMediaId} />
            </div>
            <div className="product-data">
                {showProjectOnScreenCTA && (<ProjectOnBigScreen productData={{
                    sku
                }} />)
                }
                <BasicInfo media={media} name={name} basicAttributes={{ ...basicAttributes }} setSelectedMediaId={setSelectedMediaId} setShowProduct={setShowProduct} />
                <VariantAccordion variants={variants} attributes={attributes}
                    accordionAttributes={accordionAttributes}
                    filters={filters}
                    setFilters={setFilters}
                >
                    <ProductQuantity price={price} maxQuantity={maxQuantity} quantity={quantity} setQuantity={setQuantity} />
                </VariantAccordion>
                {(isUserLoggedIn || (isEmployeeLoggedIn && specialOrderQuoteId)) && <AddToCart basicInfo={basicInfo} sku={sku ?? ''} isSimple={basicInfo?.type_id === 'simple'} name={name} filters={filters} variants={variants} price={price} maxQuantity={maxQuantity} user={user} special_order_quote_id={specialOrderQuoteId} parentData={parentData} quantity={quantity} />}
            </div>
        </div>
    </S.Content>)
};

export default ProductPage;
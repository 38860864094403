export const WISHLIST_CONSTANT = {
  my_wish_list: {
    en: "My Wish List",
    ar: "أضف إلى السلة",
  },
  Add_all: {
    en: "Add all ",
    ar: "أضف إلى السلة",
  },
  Remove_all: {
    en: "Remove All",
    ar: "أضف إلى السلة",
  },
  Select_all: {
    en: "Select all",
    ar: "أضف إلى السلة",
  },
  Add_to_cart: {
    en: "Add to cart",
    ar: "أضف إلى السلة",
  },
  Add_to_cart_msg: {
    en: "Product successfully added to the cart",
    ar: "أضف إلى السلة",
  },
  Add_all_to_cart_msg: {
    en: "All products successfully added to the cart",
    ar: "أضف إلى السلة",
  },
  Add_to_cart_err_msg: {
    en: "Error occured while adding product to the cart",
    ar: "أضف إلى السلة",
  },
  del_item_msg: {
    en: "Product successfully removed from the wishlist",
    ar: "أضف إلى السلة",
  },
  del_items_msg: {
    en: "Products successfully removed from the wishlist",
    ar: "أضف إلى السلة",
  },
  del_item_err_msg: {
    en: "Error occured while removing the product from the wishlist",
    ar: "أضف إلى السلة",
  },
  no_item_found_mg: {
    en: "No item in wish list",
    ar: "أضف إلى السلة",
  },
};

import React from "react";
import * as S from "./styles/styles";
import CheckoutHeader from "./checkout-header";
import { CHECKOUT_FIRST_STEP_HEADING, progressBar } from "../../../lib/constant";
import CheckoutProgressBar from "../../molecules/checkout-progress-bar";
import CheckoutAddressStep from "./checkoutAddressStep";


const CustomerCheckout: React.FC = () => {
   
    return (
    <S.Content>
        <div className="customer-checkout">
            <CheckoutHeader headerText={CHECKOUT_FIRST_STEP_HEADING}/>
            <CheckoutProgressBar id={1} title={progressBar[0].title}/>
            <CheckoutAddressStep/>
        </div>
    </S.Content>
  );
};

export default CustomerCheckout;

import React from 'react';
import { List, ListItem, ListItemText, Link, Typography } from '@mui/material';
import { FileOpen } from '@mui/icons-material';
import { DocumentListProps } from '../../../types/types';
import * as S from './styles/styles';
import { TRAINING_RESOURCES_CONSTANTS } from '../../../constant';

const DocumentList: React.FC<DocumentListProps> = ({ resources }) => {
  const buildUrl = (filePath: string) => {
    return `${process.env.REACT_APP_TRAINING_RESOURCES_URL}${filePath}`;
  };

  const getFileName = (filePath: string) => {
    return filePath?.split('/').pop();
  };

  return (
    <S.Content>
      {resources?.length === 0 ? (
        <Typography>{TRAINING_RESOURCES_CONSTANTS.no_docs_found.en}</Typography>
      ) : (
        <List>
          {resources?.map((doc, index) => (
            <ListItem key={index} className="listItem">
              <ListItemText
                primary={<span className='title'><FileOpen className="iconStyle" /> { doc?.file_path && getFileName(doc?.file_path)}</span>}
                secondary={
                  <span className="document-list">
                    {doc?.file_path && (
                      <Link
                        className="file-link"
                        href={buildUrl(doc?.file_path)}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span className='view-cta'>view</span>
                      </Link>
                    )}
                    {doc?.external_url && (
                      <Link
                        className="file-link"
                        href={doc?.external_url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span className='link'>Link : </span>{doc?.external_url}
                      </Link>
                    )}
                  </span>
                }
              />
            </ListItem>
          ))}
        </List>
      )}
    </S.Content>
  );
};

export default DocumentList;

//Employee Screen Constants
export const EMPLOYEE_SCREEN_CONSTANTS = {

    employee_screen_title: {
        "en": "login with your corporate email",
        "ar": "تسجيل الدخول باستخدام البريد الإلكتروني لشركتك"
    },
    employee_screen_button_content: {
        "en": "Login",
        "ar": "حساب MS"
    }
}

export const COGNITO_ERROR = {
    temp_cred_fetch_failed: {
        en : "Failed to fetch temporary credentials:"
    },
    error_exchanging_token: {
        en: "Error exchanging ID token for AWS credentials:"
    },
    token_expired: {
        en: "Token expired"
    }
}
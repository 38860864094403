import styled, { css } from "styled-components";

export const Content = styled.div<any>`
  ${(p: any) => {
    return css`
      .image-container {
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        gap: 4px;
        flex-direction: row;
        flex-wrap: wrap;
        margin-left: 16px;
        justify-content: flex-start;
        max-width: fit-content;
      }

      .button-container {
        margin-left: 16px;
        display: flex;
        width: 370px;
        align-items: center;
        align-content: center;
        gap: 4px;
        flex-wrap: wrap;
        justify-content: space-around;
        border: none;
        justify-content: flex-start;
      }
      .variant-image {
        display: flex;
        padding: 4px;
        flex-direction: column;
        align-items: center;
        gap: 8px;
        flex: 1 0 0;
      }
      .swatch-image {
        width: 60px;
        height: auto;
        flex: 1 0 0;
        border-radius: 4px;
      }
      .image-name {
        color: #6f6855;
        font-family: Poppins;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin: 0 0;
      }
      .selected {
        border-radius: 4px;
        border: 1px solid #6f6855;
      }
      .button {
        display: flex;
        width: 120.667px;
        padding: 5px;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        flex-shrink: 0;
        border-radius: 4px;
        background: #e3e2de;
        border: none;
      }
      .button.selected {
        border: 1px solid #6f6855;
        background: white;
      }

      .buttonText {
        color: #6f6855;
        font-family: Poppins;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      .accordion-title-text {
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.8px;
        color: black;
      }
      .accordion-text {
        color: #6f6855;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
        letter-spacing: 0.48px;
        margin-left: 16px;
      }
      .variant-title-text {
        color: #1c1c1c;
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 110%;
      }
      .accordionSummaryData {
        display: flex;
        -webkit-box-align: center;
        align-items: flex-start;
        flex-direction: column;
      }
      .selectedVariantOption {
        font-size: 11px;
        font-weight: 500;
      }
    `;
  }}
`;

import { ALERT_STATUS, DONT_HAVE_ACCOUNT_SUBHEADING, HAVE_ACCOUNT_SUBHEADING, LOGIN_TITLE, LOGIN_WITH_EMAIL_LABEL, LOGIN_WITH_PHONE_LABEL, SELECT_PHONE_CODE, SEND_OTP_LABEL, SIGN_UP_HEADING } from "../../../../lib/constant";
import * as S from "../styles/styles";
import logo from '../../../../globals/images/tresori-golden-logo.jpg';
import OrFrame from '../../../../globals/svg/orFrame.svg';
import { useEffect, useState } from "react";
import { LOGIN_WITH_OTP_PATH, OTP_CONFIRMATION_PATH } from "../../../../routes/routes-constant";
import { useNavigate } from "react-router-dom";
import { FormControl, MenuItem, Select, TextField } from "@mui/material";
import { autoSignIn, signIn, signUp  } from "aws-amplify/auth";
import { useNotification } from "../../../../hooks/useNotification";
import { Loading } from "../../../atoms/loading/Loading";
import OtpConfirmation from "../otp-confirmation";
import { CountryCode, getCountries, getCountryCallingCode } from "libphonenumber-js";
import { generatePassword } from "../../../../helpers/authUtils";
import backArrow from '../../../../globals/svg/circleBackArrow.svg';


const SignUp = () => {
    const [otpSent,setOtpSent] = useState(false);
    const { showAlert } = useNotification();
    const [fname,setFname] = useState("");
    const [lname,setLname] = useState("");
    const navigate = useNavigate();
    const [selectedTitle, setSelectedTitle] = useState<string>('Mr');
    const TITLES = [
        { value: 'Mr', label: 'Mr' },
        { value: 'Miss', label: 'Miss' },
        { value: 'Other', label: <em>Other...</em> }
    ];
    const [loading,setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [emailError, setEmailError] = useState('');
    const [phoneError, setPhoneError] = useState('');
    const [countryCodes,setCountryCodes] = useState<string[]>();
    const [phoneCode,setPhoneCode] = useState("+971");
  
    useEffect(()=>{
      const countries: CountryCode[] = getCountries() as CountryCode[];
      let  countryCodesData: string[] = [];
  
      countries.forEach((country: CountryCode) => {
        if(!countryCodesData.includes(`+${getCountryCallingCode(country)}`)){
          countryCodesData.push(`+${getCountryCallingCode(country)}`);
        }
      });
      setCountryCodes(countryCodesData);
    },[])
    
    const validateEmail = (email: string) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    };
  
    const validatePhoneNumber = (phone: string) => {
      const phoneRegex = /^[0-9]{10}$/;
      return phone.trim().match(phoneRegex) !== null;
    };

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setEmail(e.target.value);
      setEmailError(''); 
    };
  
    const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if(!isNaN(Number(e.target.value))){
        setPhoneNumber(e.target.value);
        setPhoneError(''); 
      }
    };
  
    const handleEmailBlur = () => {
      if (email && !validateEmail(email)) {
        setEmailError('Please enter a valid email');
      }
    };
  
    const handlePhoneBlur = () => {
      if (phoneNumber && !validatePhoneNumber(phoneNumber)) {
        setPhoneError('Please enter a valid 10-digit phone number');
      }
    };
  
    const replaceAtTheRate = (email: string): string => {
         return email?.replace("@","_");
    };

    const submitButtonHandler=async()=>{
      try{
        if(phoneNumber && phoneCode===""){
          showAlert(SELECT_PHONE_CODE, ALERT_STATUS.error);
        }
        else if(fname && lname && email && emailError==="" && phoneError===""){
          const password = window.self.crypto.randomUUID();
          const payloadOptions = {
            userAttributes: {
                email,
                name: fname,
                family_name: lname,
                ...(phoneNumber && { phone_number: `${phoneCode}${phoneNumber}` })
            },
            autoSignIn: { 
                SignInOptions: { authFlowType: "CUSTOM_WITHOUT_SRP" } 
            }
        };
          setLoading(true);
          const result = await signUp({
              username :replaceAtTheRate(email),
              password:generatePassword(),
              options: payloadOptions
            });
          
          if(result?.isSignUpComplete){
            await  sendOtpHandler();
          }
          setLoading(false);
      }
      else{
          showAlert("Please enter all required field",ALERT_STATUS.error);
      }
      }
      catch (err:any) {
        setLoading(false);
        showAlert(err?.message, ALERT_STATUS.error);
        console.log("User signed up error", err);
    }
   
        
    }

    const sendOtpHandler =async()=>{
        try{
                setLoading(true);
                const response = await signIn({ 
                    username:email,
                    options: {
                        clientMetadata: {
                          "Testingname":"Anshika"
                        }, // Optional, an object of key-value pairs which can contain any key and will be passed to your Lambda trigger as-is.
                        authFlowType:"CUSTOM_WITHOUT_SRP"
                      }
                 });
               setLoading(false);
               if(response?.nextStep?.signInStep==="CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE"){
                setOtpSent(true);
               }
            
        }
        catch (err:any) {
            setLoading(false);
            showAlert(err?.message, ALERT_STATUS.error);
            console.log("User is not signed in", err);
        }
       
        //navigate(OTP_CONFIRMATION_PATH)
    }

    const backHandler = ()=>{
      navigate(-1);
    } 
    
    
    return (
        <S.Content>
             {loading && <Loading/>}
            <div className="login-container">
            <img className="back-arrow" onClick={backHandler} src={backArrow}></img>
            <div className="login-container-header">
                <img className="login-logo" src={logo}></img>
                <>
                <h3 className="login-title">
                    {SIGN_UP_HEADING}
                </h3>
                </>
            </div>
           {!otpSent?<> <div className="login-button-container login-content-section">
                <div className="first-title-row">
                {/* <FormControl fullWidth className="title-dropdown" size='small'>
                        <Select
                            labelId="title-label"
                            id="title"
                            name="title"
                            value={selectedTitle}
                            className="input-field title-field"
                            onChange={(e) => setSelectedTitle(e.target.value )}
                        >
                            {TITLES?.map((option) => (
                                <MenuItem key={option?.value} value={option?.value}>
                                    {option?.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl> */}
                   <div> <input
                        type="text"
                        value={fname}
                        onChange={(e) => setFname(e.target.value)}
                        className="input-field name-field first-field"
                        placeholder="First Name*"
                    />
                    </div>
                    <div>
                        <input
                        type="text"
                        value={lname}
                        onChange={(e) => setLname(e.target.value)}
                        className="input-field name-field"
                        placeholder="Last Name *"
                        />
                    </div>
                </div>
               <input
                type="text"
                value={email}
                onChange={handleEmailChange}
                onBlur={handleEmailBlur}
                className="input-field"
                placeholder="Email*"
                />
                {emailError && <p className="error-signup-msg">{emailError}</p>}
                <div className="phone-input-container">
                <FormControl fullWidth className="title-dropdown" size="small">
                <S.StyledAutocomplete
                  options={countryCodes ?? []} 
                  value={phoneCode} 
                  onChange={(
                    event: React.SyntheticEvent<Element, Event>,
                    value: unknown
                  ) => {
                    setPhoneCode(value as string ?? ""); 
                  }}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      size="small"
                    
                    />
                  )}
                />
              </FormControl>

              <input
                type="text"
                className="input-fields"
                value={phoneNumber}
                onChange={handlePhoneChange}
                 onBlur={handlePhoneBlur}
                placeholder={LOGIN_WITH_PHONE_LABEL}
              />
              </div>
              {phoneError && <p className="error-msg">{phoneError}</p>}</div>
            
            <div className="subtext-container">
                {HAVE_ACCOUNT_SUBHEADING}<span className="subtext-heading" onClick={()=>{navigate(LOGIN_WITH_OTP_PATH)}}>{LOGIN_TITLE}</span>
            </div>
            <div className="submit-button-container">
                <button className="submit-button" disabled={false} onClick={submitButtonHandler} id="login-gmail">{SEND_OTP_LABEL}</button>
            </div></>:<OtpConfirmation username={email}/>}
          </div>
        </S.Content>
    );
}

export default SignUp;



import axios from "axios";
import { getAPIToken } from "./localStorageUtil";
import { SESSION_EXPIRED } from "../lib/constant";

const instance = axios.create();
interface Filter {
  field: string;
  value: string;
  conditionType: string;
}

interface FilterGroup {
  filters: Filter[];
}

interface SearchCriteria {
  filterGroups: FilterGroup[];
}

interface Config {
  url: string;
  data?: any;
  userType?: string;
  headers?: Record<string, string>;
  params?: {
    searchCriteria: SearchCriteria;
  };
}

export const handleUnauthorized = (error: { response: { status: number; }; }) => {
  if (error.response && error.response.status === 401) {
      console.log(SESSION_EXPIRED.en);
  } else {
      // Rethrow the error for other status codes
      throw error;
  }
};

export function doActionGet(config: Config) {
  if (config) {
    return axios({
      url: config.url,
      method: "GET",
      headers: {
        ...(config.userType
          ? { Authorization: "Bearer " + getAPIToken(config.userType) }
          : {}),
        "content-type": "application/json",
        ...config.headers
      },
      data: config.data,
      params: config.params
    }).catch(error => {
      handleUnauthorized(error);
    });
  }
}

export function doActionPost(config: Config) {
  if (config) {
    return axios({
      url: config.url,
      data: config.data,
      method: "POST",
      headers: {
        ...(config.userType
          ? { Authorization: "Bearer " + getAPIToken(config.userType) }
          : {}),
        "content-type": "application/json",
        ...config.headers
      },
    }).catch(error => {
      handleUnauthorized(error);
    });
  }
}

export function doActionPut(config: Config) {
  if (config) {
    return axios({
      url: config.url,
      method: "PUT",
      data: config.data,
      headers: {
        ...(config.userType
          ? { Authorization: "Bearer " + getAPIToken(config.userType) }
          : {}),
        "content-type": "application/json",
        ...config.headers
      },
    }).catch(error => {
      handleUnauthorized(error);
    });
  }
}

export function doActionDelete(config: Config) {
  if (config) {
    return axios({
      url: config.url,
      method: "DELETE",
      headers: {
        ...(config.userType
          ? { Authorization: "Bearer " + getAPIToken(config.userType) }
          : {}),
        "content-type": "application/json",
        ...config.headers
      },
    }).catch(error => {
      handleUnauthorized(error);
    });
  }
}

export default instance;

export const breakpoints: { [key: string]: number } = {
    mobileAndUpMin: 128,
    mobileAndUp: 320,
    smallMobileAndUp: 375,
    largeMobileAndUp: 430,
    largeMobile:460,
    tabletPortraitUp: 606,
    largeMobileAndUp2: 576,
    tabletPortraitAndUp: 768,
    tabletPortraitAndUp2: 835,
    tabletLandscapeAndUp: 992,
  }
  
  export const media = {
    mobileAndUpMin: `(min-width: ${breakpoints.mobileAndUpMin}px)`,
    mobileAndUp: `(min-width: ${breakpoints.mobileAndUp}px)`,
    mobileAndUpMax: `(max-width: ${breakpoints.mobileAndUp}px)`,
    mobile1AndUp: `(max-width: ${breakpoints.mobileAndUp - 20}px)`, // 300px
    mobileMax: `(max-width: ${breakpoints.tabletPortraitAndUp - 1}px)`, //767px
    smallMobileAndUp: `(min-width: ${breakpoints.smallMobileAndUp}px)`,
    smallMobileMax: `(max-width: ${breakpoints.smallMobileAndUp}px)`,
    smallMobileAndUpMax: `(max-width: ${breakpoints.smallMobileAndUp + 1}px)`, //376px
    smallMobileAndUpMin: `(min-width: ${breakpoints.smallMobileAndUp + 1}px)`, //376px
    smallMobileAndUpMax2: `(max-width: ${breakpoints.smallMobileAndUp + 5}px)`, //380px
    largeMobileAndUp: `(min-width: ${breakpoints.largeMobileAndUp}px)`,
    largeMobileAndUpMax: `(max-width: ${breakpoints.largeMobileAndUp}px)`,
    largeMobileAndUpMax2: `(max-width: ${breakpoints.largeMobileAndUp - 2}px)`, //424px
    largeMobileAndUpMax3: `(max-width: ${breakpoints.largeMobileAndUp - 1}px)`, //425px
    largeMobileAndUpMin: `(min-width: ${breakpoints.largeMobileAndUp - 1}px)`, //425px
    largeMobile: `(max-width: ${breakpoints.largeMobile}px)`,
    largeMobileAndUp2Max: `(max-width: ${breakpoints.largeMobileAndUp2}px)`,
    largeMobileAndUp2Max2: `(max-width: ${breakpoints.tabletPortraitUp + 34}px)`, //640px
    largeMobileMin: `(min-width: ${breakpoints.tabletPortraitAndUp + 1}px)`, //769
    tabletPortraitUp: `(min-width: ${breakpoints.tabletPortraitAndUp}px)`,
    tabletPortraitUp2: `(min-width: ${breakpoints.tabletPortraitAndUp + 16}px)`, //784px
    tabletPortraitUpMax1: `(max-width: ${breakpoints.tabletPortraitAndUp - 1}px)`, //605
    tabletPortraitAndUp: `(min-width: ${breakpoints.tabletPortraitAndUp}px)`,
    tabletPortraitAndUpMax: `(max-width: ${breakpoints.tabletPortraitAndUp}px)`,
    tabletPortraitAndUpMax2: `(max-width: ${breakpoints.tabletPortraitAndUp + 4}px)`, //772px
    tabletPortraitAndUpMax3: `(max-width: ${breakpoints.tabletPortraitAndUp - 12}px)`, //756px
    tabletPortraitAndUp2: `(min-width: ${breakpoints.tabletPortraitAndUp2}px)`,
    tabletPortraitAndUp2Max: `(max-width: ${breakpoints.tabletPortraitAndUp2}px)`,
    tabletPortraitAndUp2Max2: `(max-width: ${breakpoints.tabletPortraitAndUp2 - 1
      }px)`, //834px
    tabletPortraitMax: `(max-width: ${breakpoints.tabletLandscapeAndUp - 1}px)`, //991px
    tabletPortraitMin: `(min-width: ${breakpoints.tabletLandscapeAndUp - 1}px)`, //991px min
    tabletPortraitMax2: `(max-width: ${breakpoints.tabletLandscapeAndUp - 2}px)`, //990px
    tabletLandscapeAndUp: `(min-width: ${breakpoints.tabletLandscapeAndUp}px)`,
    tabletLandscapeAndUpMax: `(max-width: ${breakpoints.tabletLandscapeAndUp}px)`,
    desktopAndUp: `(min-width: ${breakpoints.desktopAndUp}px)`,
    desktopAndUpMax: `(max-width: ${breakpoints.desktopAndUp}px)`,
    desktopMin: `(min-width: ${breakpoints.desktopAndUp + 1}px)`, //1025
    desktopMax2: `(max-width: ${breakpoints.desktopAndUp + 1}px)`, //1025
    desktop2Max: `(max-width: ${breakpoints.desktopAndUp - 1}px)`, //1023
    desktopMin2: `(min-width: ${breakpoints.desktopAndUp + 2}px)`, //1026
    desktopMin1: `(max-width: ${breakpoints.desktopAndUp + 1}px)`, //1025
    desktop1Min: `(min-width: ${breakpoints.desktopMin1 + 1}px)`, //1026
    desktop2Min: `(min-width: ${breakpoints.desktopAndUp - 1}px)`, //1023
    desktopMax: `(max-width: ${breakpoints.smallDesktopAndUp - 1}px)`, //1199px
    smallDesktopAndUp1: `(min-width: ${breakpoints.smallDesktopAndUp1}px)`,
    smallDesktopAndUp1Max: `(max-width: ${breakpoints.smallDesktopAndUp1}px)`,
    smallDesktopAndUp: `(min-width: ${breakpoints.smallDesktopAndUp}px)`,
    smallDesktopAndUpMax: `(max-width: ${breakpoints.smallDesktopAndUp}px)`,
    smallDesktop2Min: `(min-width: ${breakpoints.smallDesktopAndUp + 1}px)`, //1201px
    smallDesktopAndUp2: `(min-width: ${breakpoints.smallDesktopAndUp2}px)`,
    smallDesktopAndUp2Max: `(max-width: ${breakpoints.smallDesktopAndUp2}px)`,
    smallDesktopAndUp3: `(min-width: ${breakpoints.smallDesktopAndUp3}px)`,
    smallDesktopAndUp4: `(max-width: ${breakpoints.smallDesktopAndUp4}px)`,
    smallDesktopAndUp3Max: `(max-width: ${breakpoints.smallDesktopAndUp3}px)`,
    mediumDesktopMin: `(min-width: ${breakpoints.smallDesktopAndUp2 + 1}px)`, //1281px
    mediumDesktopMin2: `(min-width: ${breakpoints.mediumDesktopAndUp + 1}px)`, //1401px
    mediumDesktopAndUp: `(min-width: ${breakpoints.mediumDesktopAndUp}px)`,
    mediumDesktopAndUpMax: `(max-width: ${breakpoints.mediumDesktopAndUp}px)`,
    mediumDesktopAndUp2: `(min-width: ${breakpoints.mediumDesktopAndUp2}px)`,
    mediumDesktopAndUp2Max: `(max-width: ${breakpoints.mediumDesktopAndUp2}px)`,
    mediumDesktopAndUp3: `(min-width: ${breakpoints.mediumDesktopAndUp3}px)`,
    mediumDesktopAndUp3Max: `(max-width: ${breakpoints.mediumDesktopAndUp3}px)`,
    mediumDesktopAndUp4: `(min-width: ${breakpoints.mediumDesktopAndUp4}px)`,
    mediumDesktopAndUp4Min: `(min-width: ${breakpoints.mediumDesktopAndUp4 + 1
      }px)`, //1441
    mediumDesktopAndUp4Max: `(max-width: ${breakpoints.mediumDesktopAndUp4}px)`,
    mediumDesktopAndUp5: `(min-width: ${breakpoints.mediumDesktopAndUp5}px)`,
    mediumDesktopAndUp5Max: `(max-width: ${breakpoints.mediumDesktopAndUp5 - 1
      }px)`, //1519
    largeDesktopMax: `(min-width: ${breakpoints.mediumDesktopAndUp3 - 1}px)`, //1679px
    largeDesktopMax2: `(max-width: ${breakpoints.mediumDesktopAndUp3 - 1}px)`, //1679px max
    largeDesktopMin: `(min-width: ${breakpoints.mediumDesktopAndUp3 + 1}px)`, //1681px
    largeDesktop: `(min-width: ${breakpoints.largeDesktop}px)`,
    maxWidthAndUp: `(min-width: ${breakpoints.maxWidthAndUp}px)`,
    maxWidthAndUpMax: `(max-width: ${breakpoints.maxWidthAndUp}px)`,
    wrapperMaxWidth: `(min-width: ${breakpoints.wrapperMaxWidth}px)`,
    wrapperMaxWidthMax: `(max-width: ${breakpoints.wrapperMaxWidth - 1}px)`, //1919
    wrapperMaxWidth2: `(min-width: ${breakpoints.wrapperMaxWidth + 1}px)`, //1921
    largeMobile460: `(max-width: ${breakpoints.largeMobileAndUp - 6}px)`, //460
    minWidth1901: `(min-width: ${breakpoints.maxWidthAndUp + 1}px)`, //1901
    extraLargeMax: `(max-width: ${breakpoints.extraLarge}px)`,
    extraLargeMin: `(min-width: ${breakpoints.extraLarge}px)`,
  }
  
import React, { useEffect, useState } from "react";
import DeliveryAddress from "./deliveryAddress";
import BillingAddress from "./billingAddress";
import apiList, { ALERT_STATUS, SOMETHING_WENT_WRONG, USER_TYPE } from "../../../../../../lib/constant";
import { doActionDelete, doActionGet, doActionPost } from "../../../../../../helpers/httpRequest";
import { useNotification } from "../../../../../../hooks/useNotification";
import { Address, CustomerDetails } from "../../types/types";
import { useNavigate } from "react-router-dom";
import * as S from './styles/styles';
import { fetchAddresses, fetchCustomerDetails } from "../../helpers/dataProvider";
import { DATA_PROVIDER_MESSAGES } from "../../helpers/constants";
import { AxiosError } from "axios";
import { MY_ADDRESS_MESSAGES } from "../constants";
import { defaultBillingShippingAddress_gtm, removeAddress_gtm } from "../../../../../../helpers/GTM/profileSectionGTM";
import { ADDRESS_MESSAGES } from "../../constants";
import { updateDefaultAddressData } from "../../helpers/utils";
import { REMOVE_ACTION_CONSTANTS } from "../../shared-components/constants";
import { Loader } from "../../styles/styles";

const MyAddressListPage: React.FC = () => {
  const [isAPICallRunning, setIsAPICallRunning] = useState(false);
  const { showAlert } = useNotification();
  const [addresses, setAddresses] = useState<Address[]>([]);
  const [customerDetails, setCustomerDetails] = useState<CustomerDetails>();
  const [isLoading, setIsLoading] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [selectedShipId, setSelectedShipId] = useState(0);
  const [selectedBillToId, setSelectedBillToId] = useState(0);
  const [isBillingSame, setIsBillingSame] = useState<Boolean>(true);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      await fetchCountryList();
    }
    fetchData();
    getAddressList();
    getCustomerDetails();
  }, []);

  const getCustomerDetails = async () => {
    setIsLoading(true);
    const customerData = await fetchCustomerDetails(showAlert, navigate);
    if (customerData) {
      setCustomerDetails(customerData);
    } else {
      showAlert(DATA_PROVIDER_MESSAGES.fetch_user_data_fail.en, ALERT_STATUS.error);
    }
    setIsLoading(false);
  };

  const getAddressList = async () => {
    setIsLoading(true);
    const addressData = await fetchAddresses(showAlert, navigate);
    if (addressData) {
      setAddresses(addressData);
    } else {
      showAlert(DATA_PROVIDER_MESSAGES.fetch_addresses_fail.en, ALERT_STATUS.error);
    }
    setIsLoading(false);
  };

  const fetchCountryList = async () => {
    setIsAPICallRunning(true);
    doActionGet({
      url: apiList.getCountries,
    })?.then((resp: any) => {
      console.log("country ", resp?.data);
      setCountryList(resp?.data);
    }
    ).catch((error: any) => {
      console.log("error msg", error);
      showAlert(SOMETHING_WENT_WRONG, ALERT_STATUS.error);
    }).finally(() => {
      setIsAPICallRunning(false);
    })
  }

  const selectShipToHandler = (selectedId: Number) => {
    setSelectedShipId(Number(selectedId));
  }

  const selectBillToHandler = (selectedId: Number) => {
    setSelectedBillToId(Number(selectedId));
  }

  const onBillingSameAsShippingChangeHandler = (isSame: Boolean) => {
    setIsBillingSame(isSame);
  }

  const newShippingAddressSaveHandler = (formData: Address): any => {
    setIsAPICallRunning(true);
    let payload = {
      address: {
        ...formData,
        id: undefined, // Ensure no ID is set for a new address
        region: {
          region_id: formData?.region?.region_id || 0,
          region: formData?.region?.region || '',
          region_code: formData?.region?.region_code || ''
        },
        region_id: formData?.region?.region_id || 0,
        default_billing: false,
        default_shipping: true
      }
    };
    doActionPost({ url: apiList.customerAddress, userType: USER_TYPE.Customer, data: payload })?.then((resp) => {
      addresses.push(resp?.data);
      setSelectedShipId(Number(resp?.data?.id));
      return resp?.data;
    })?.catch((error: any) => {
      console.log("error msg", error);
      showAlert(SOMETHING_WENT_WRONG, ALERT_STATUS.error);
      return false;
    }).finally(() => {
      setIsAPICallRunning(false);
    })
  }
  const newBillingAddressSaveHandler = (formData: Address): any => {

    setIsAPICallRunning(true);
    let payload = {
      address: {
        ...formData,
        id: undefined, // Ensure no ID is set for a new address
        region: {
          region_id: formData?.region?.region_id || 0,
          region: formData?.region?.region || '',
          region_code: formData?.region?.region_code || ''
        },
        region_id: formData?.region?.region_id || 0,
        default_billing: false,
        default_shipping: true
      }
    };

    console.log(payload);
    doActionPost({ url: apiList.customerAddress, userType: USER_TYPE.Customer, data: payload })?.then((resp) => {

      addresses.push(resp?.data);
      setSelectedBillToId(Number(resp?.data?.id));
      return resp?.data;
    })?.catch((error: any) => {
      console.log("error msg", error);
      showAlert(SOMETHING_WENT_WRONG, ALERT_STATUS.error);
      return false;
    }).finally(() => {
      setIsAPICallRunning(false);
    })
  }

  const handleSetAsDefaultShipping = (currentAddress: Address) => {
    if (currentAddress && currentAddress?.id) {
      handleSetAsDefaultAddress(currentAddress?.id, 'Shipping');
    } else {
      console.error(MY_ADDRESS_MESSAGES.no_address_selected_shipping);
    }
  }

  const handleSetAsDefaultBilling = (currentAddress: Address) => {
    if (currentAddress && currentAddress?.id) {
      handleSetAsDefaultAddress(currentAddress?.id, 'Billing');
    } else {
      console.error(MY_ADDRESS_MESSAGES.no_address_selected_billing);
    }
  }

  const handleSetAsDefaultAddress = async (selectedAddressId: number, type: 'Billing' | 'Shipping') => {

    const addressToUpdate = addresses?.find(address => address?.id === selectedAddressId);
    if (!addressToUpdate) {
      showAlert(MY_ADDRESS_MESSAGES.address_not_found.en, ALERT_STATUS.error);
      return;
    }

    // Update only the relevant default field based on the type
    const updatedAddress = updateDefaultAddressData(addressToUpdate, selectedAddressId, type);
    // const updatedAddressesLocally = updateAllAddressesWithDecryptedData(addresses, selectedAddressId, type);

    await updateAddresses(updatedAddress, addresses, type);
  };

  const updateAddresses = async (updatedAddress: Address, updatedAddressesLocally: Address[], type?: 'Billing' | 'Shipping') => {
    const payload = {
      address: updatedAddress
    };

    try {
      await doActionPost({ url: apiList.customerAddress, userType: USER_TYPE.Customer, data: payload });

      //Call The Default Billing Shipping Address GTM Events
      if (type === 'Billing' || type === 'Shipping') {
        const { extension_attributes, ...filteredAddress } = payload.address;
        defaultBillingShippingAddress_gtm(filteredAddress);
      }
      setAddresses(updatedAddressesLocally);
      showAlert(`${type} ${MY_ADDRESS_MESSAGES.address_update_success.en}`, ALERT_STATUS.success);
    } catch (error) {
      const axiosError = error as AxiosError;
      console.error(`Failed to update ${type} address:`, axiosError.message);
      showAlert(axiosError.response?.status === 401 ? ADDRESS_MESSAGES.please_log_in.en : `Failed to set default ${type?.toLowerCase()} address. Please try again later.`, ALERT_STATUS.error);
    }
  };

  const handleEditAddress = (currentAddress: Address) => {
    if (currentAddress) {
      navigate(`/edit-address/${currentAddress.id}`, {
        state: {
          currentAddressDetails: currentAddress,
          allAddresses: addresses,
          customerDetails: customerDetails,
          actionType: 'Edit',
          isEditing: true
        }
      });
    } else {
      console.error(MY_ADDRESS_MESSAGES.no_address_selected_edit.en);
    }
  };

  const handleRemoveAddress = async (addressId: number | undefined) => {

    const addressToRemove = addresses?.find(addr => addr?.id === addressId);

    // Check if the address is set as default billing or shipping
    if (addressToRemove) {
      if (addressToRemove.default_billing && addressToRemove.default_shipping) {
        showAlert(REMOVE_ACTION_CONSTANTS.check_for_default.en, ALERT_STATUS.info);
        return;
      } else if (addressToRemove.default_billing) {
        showAlert(REMOVE_ACTION_CONSTANTS.check_for_default_billing.en, ALERT_STATUS.info);
        return;
      } else if (addressToRemove.default_shipping) {
        showAlert(REMOVE_ACTION_CONSTANTS.check_for_default_shipping.en, ALERT_STATUS.info);
        return;
      }
    }

    const updatedAddresses = addresses.filter(addr => addr.id !== addressId);

    // If only one address is left, set it as both default shipping and billing
    if (updatedAddresses.length === 1) {
      updatedAddresses[0].default_billing = true;
      updatedAddresses[0].default_shipping = true;
    }

    try {

      await doActionDelete({ url: apiList.deleteCustomerAddress + `/${addressId}`, userType: USER_TYPE.Customer });

      if (updatedAddresses.length === 1) {
        await updateAddresses(updatedAddresses[0], updatedAddresses);
      }
      else {
        setAddresses(updatedAddresses);
      }
      //Call The Remove Address GTM Events
      if (addressToRemove) {
        const { extension_attributes, ...filteredAddress } = addressToRemove;
        removeAddress_gtm(filteredAddress);
      }

      showAlert(ADDRESS_MESSAGES.address_removed_success.en, ALERT_STATUS.success);
    } catch (error) {
      const axiosError = error as AxiosError; // Type assertion to handle errors from Axios
      console.error('Failed to fetch user Data:', axiosError.message);
      showAlert(axiosError.response?.status === 401 ? ADDRESS_MESSAGES.please_log_in.en : ADDRESS_MESSAGES.failed_remove_addresses.en, axiosError.response?.status === 401 ? 'info' : ALERT_STATUS.error);
    }
  };

  return (
    <>
      {isLoading ? (
        <S.LoaderContainer>
          <Loader size={25} />
        </S.LoaderContainer>
      ) : (
        <S.Content>
          <BillingAddress addressList={addresses} selectedBillToId={selectedBillToId} onChange={selectBillToHandler} countryData={countryList} onBillingSameAsShippingChangeHandler={onBillingSameAsShippingChangeHandler} newAddressSaveHandler={newBillingAddressSaveHandler} handleDefaultBilling={handleSetAsDefaultBilling} handleEditAddress={handleEditAddress} handleRemoveAddress={handleRemoveAddress} />
          <DeliveryAddress addressList={addresses} selectedShipToId={selectedShipId} onChange={selectShipToHandler} countryData={countryList} newAddressSaveHandler={newShippingAddressSaveHandler} handleDefaultShipping={handleSetAsDefaultShipping} handleEditAddress={handleEditAddress} handleRemoveAddress={handleRemoveAddress} />
        </S.Content>)}
    </>
  );
};

export default MyAddressListPage;
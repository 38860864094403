import React, { useEffect, useState } from "react";
import * as S from "../styles/styles";
import { BANKTRANSFER_CODE, FREE_CODE, PAYMENT_MODE_LABEL, PAYMENT_REFERENCE_CODE_PLACEHOLDER } from "../../../../lib/constant";
import lineIcon from "../../../../globals/svg/line.svg";
import CreditCardIcon from '../../../../globals/images/icons/credit-card.svg';
import CashIcon from '../../../../globals/images/icons/money.svg';
import PayLaterIcon from '../../../../globals/images/icons/paylater.svg';
import { PaymentMethodCode, PaymentMethodProps, PaymentMethodsType } from "../types/types";
import CheckoutInput from "../checkout-input";
import { getLocalStorage } from "../../../../helpers/localStorageUtil";

const PaymentMethods: React.FC<PaymentMethodProps> = ({ paymentMethods, selectedValue, setSelectedValue, codeChangeHandler, paymentRefNumberChangeHandler }) => {
    // const [selectedValue, setSelectedValue] = useState<string>("");
    const [isZeroAmountCheckout, setIsZeroAmountCheckout] = useState<boolean>(false);
    const [availablePaymentMethod, setAvailablePaymentMethod] = useState<PaymentMethodsType[]>([]);
    const iconMap: Record<PaymentMethodCode, string> = {
        paylater: PayLaterIcon,
        cashondelivery: CashIcon,
        purchaseorder: CreditCardIcon,
        free:PayLaterIcon
      };

    useEffect(() => {
        if (getLocalStorage('grand_total') === "0") {
            setIsZeroAmountCheckout(true);
        }
    }, [getLocalStorage('grand_total')])

    const handleRadioChange = (itemCode: string) => {
        setSelectedValue(itemCode);
        codeChangeHandler(itemCode);
    }

    useEffect(() => {
        if (paymentMethods.length > 0 && !isZeroAmountCheckout) {
            let revPaymentMethods = paymentMethods?.slice().reverse();
            setAvailablePaymentMethod(revPaymentMethods);
            setSelectedValue(revPaymentMethods[0].code);
            codeChangeHandler(revPaymentMethods[0].code);
        }
        else {
            if(paymentMethods?.find((paymentmethod)=>{return paymentmethod?.code === FREE_CODE})){
                setSelectedValue(FREE_CODE);
                codeChangeHandler(FREE_CODE);
                setAvailablePaymentMethod(paymentMethods?.filter((paymentmethod)=>{return paymentmethod?.code === FREE_CODE}));
            }
            
        }
    }, [paymentMethods])

    // const paymentReferenceNumberHandler = (value: string) => {
    //     paymentRefNumberChangeHandler(value);
    // } 

    return (
        <S.Content className="payment-section">
            <div className="payment-heading">
                <h5 className="payment-heading-content">{PAYMENT_MODE_LABEL}</h5>
            </div>
            <div className="payment-methods">
                {availablePaymentMethod.length > 0 &&
                    availablePaymentMethod.map(
                        (item: PaymentMethodsType, index: number) => {
                            const isSelected = item.code === selectedValue;
                            return (
                                <div
                                    className={`payment-code-section ${isSelected ? 'selected' : ''}`}
                                    key={index}
                                    onClick={() => handleRadioChange(item.code)}
                                >
                                    <div className="payment-icon">
                                        <img src={iconMap[item.code]} alt={item.title}/>
                                    </div>
                                    <div className="item-label">{item.title}</div>
                                </div>
                            );
                        }
                    )}
            </div>
        </S.Content>
    );
};

export default PaymentMethods;

import React, { useEffect, useState } from "react";
import * as S from "../styles/styles";
import { Loading } from "../../../atoms/loading/Loading";
import CheckoutHeader from "../checkout-header";
import apiList, { ALERT_STATUS, CHECKOUT_FIRST_STEP_HEADING, DELIVERY_OPTION_ADDRESS_HEADING, DELIVERY_OPTION_SUB_HEADING, PROCEED_TO_CHECKOUT_BUTTON_LABEL, SOMETHING_WENT_WRONG, USER_TYPE, progressBar } from "../../../../lib/constant";
import CheckoutProgressBar from "../../../molecules/checkout-progress-bar";
import { useNotification } from "../../../../hooks/useNotification";
import { doActionGet, doActionPost } from "../../../../helpers/httpRequest";
import { getLocalStorage, setLocalStorage } from "../../../../helpers/localStorageUtil";
import { deliveryMethodProps } from "../types/types";
import { formatPriceAndCurrency, getStaticConstantValue } from "../../../../helpers/utlis";
import CheckoutButton from "../../../atoms/checkout-button/CheckoutButton";
import { Address } from "../../my-account/my-profile/types/types";
import { CHECKOUT_ORDER_CONFIRMATION_PAGE } from "../../../../routes/routes-constant";
import { useNavigate } from "react-router-dom";
import { getAttributeDataBasedOnCode, gtmDataConstructor } from "../helper";


const DeliveryMethod: React.FC = () => {
    const [isAPICallRunning,setIsAPICallRunning] =useState(false);
    const { showAlert } = useNotification();
    const [availableShipmentMethods, setAvailableShipmentMethods] =useState<deliveryMethodProps[]>([]);
    const [selectedCarrierCode , setSelectedCarrierCode] =useState<string>("");
    const [selectedMethodCode,setSelectedMethodCode] =useState<string>("");
    const navigate = useNavigate();

    useEffect(()=>{
        if(getLocalStorage("deliveryCarrierCode")){
            setSelectedCarrierCode(getLocalStorage("deliveryCarrierCode"));
            setSelectedMethodCode(getLocalStorage("deliveryCarrierCode"));
        }
    },[getLocalStorage])
    
  useEffect(()=>{
        const shippingAddressData = getLocalStorage("shippingAddress");
        const shipmentData =JSON.parse(shippingAddressData);
        const shipmentPayload={
            "region": shipmentData?.region?.region,
            "region_id": shipmentData?.region?.region_id,
            "region_code": shipmentData?.region?.region_code,
            "country_id": shipmentData?.country_id ,
            "street": (shipmentData?.extension_attributes?.address_encryption_attribute_data &&shipmentData?.extension_attributes?.address_encryption_attribute_data.length>0)?[getAttributeDataBasedOnCode(shipmentData?.extension_attributes?.address_encryption_attribute_data,"street")[0].value]:shipmentData?.street,
            "postcode": shipmentData?.postcode,
            "city": shipmentData?.city,
            "telephone": (shipmentData?.extension_attributes?.address_encryption_attribute_data &&shipmentData?.extension_attributes?.address_encryption_attribute_data.length>0)?getAttributeDataBasedOnCode(shipmentData?.extension_attributes?.address_encryption_attribute_data,"telephone")[0].value:shipmentData?.telephone,
        }
        setIsAPICallRunning(true);
        doActionPost({
          url: apiList.estimateShippingAPI,
          data:
          {
            "address": shipmentPayload
          },
          userType:USER_TYPE.Customer
        })?.then((resp: any) => {
           setAvailableShipmentMethods(arrangeShippingOptions(resp?.data));
        }
        ).catch((error: any) => {
          console.error("error msg", error);
          showAlert(SOMETHING_WENT_WRONG, ALERT_STATUS.error);
        }).finally(()=>{
          setIsAPICallRunning(false);
        })
  },[])

    const checkoutHandler=()=>{
        const shippingAddressData = getLocalStorage("shippingAddress");
        const billingAddressData = getLocalStorage("billingAddress");
        setIsAPICallRunning(true);
        doActionPost({
            url: apiList.setShippingInformation,
            data:
            {
              "addressInformation": {
                "shipping_address":payloadConstructor(JSON.parse(shippingAddressData)),
                "billing_address":payloadConstructor(JSON.parse(billingAddressData)),
                "shipping_carrier_code": selectedCarrierCode,
                "shipping_method_code": selectedMethodCode
              }
            },
            userType:USER_TYPE.Customer
          })?.then((resp: any) => {
             setLocalStorage("grand_total",JSON.stringify(resp?.data?.totals?.grand_total));
             gtmDataConstructor("add_shipping_info");
             navigate(CHECKOUT_ORDER_CONFIRMATION_PAGE)
          }
          ).catch((error: any) => {
            console.log("error msg", error);
            showAlert(SOMETHING_WENT_WRONG, ALERT_STATUS.error);
          }).finally(()=>{
            setIsAPICallRunning(false);
          })
    } 
    const  radioButtonChangeHandler=(item:deliveryMethodProps)=>{
        setSelectedCarrierCode(item.carrier_code);
        setLocalStorage("deliveryCarrierCode",item.carrier_code);
        setSelectedMethodCode(item.method_code);

    }

    const payloadConstructor = (address:any) =>{

        let region =address.region;
        delete address.region;
        delete address.default_shipping;
        delete address.default_billing;
        delete address?.isBillingSameAsShipping;
        address.customer_id=null;
        address.region= region?.region;
        address.region_id=region?.region_id;
        address.region_code = region?.region_code;
        if(address?.extension_attributes?.address_encryption_attribute_data && address?.extension_attributes?.address_encryption_attribute_data.length>0){
          address.firstname =getAttributeDataBasedOnCode(address?.extension_attributes?.address_encryption_attribute_data,"firstname")[0].value;
          address.lastname = getAttributeDataBasedOnCode(address?.extension_attributes?.address_encryption_attribute_data,"lastname")[0].value;
          address.street=[getAttributeDataBasedOnCode(address?.extension_attributes?.address_encryption_attribute_data,"street")[0].value];
          address.telephone=getAttributeDataBasedOnCode(address?.extension_attributes?.address_encryption_attribute_data,"telephone")[0].value;  
        }
      
        delete address.extension_attributes;
        if(address?.id===Number(getLocalStorage("selectedShipmentAddressId"))||(getLocalStorage("newCreatedBillingAddress")?address?.id===JSON.parse(getLocalStorage("newCreatedBillingAddress"))?.id :true)){
            delete address.id;
        }
        return address;
    }

    const arrangeShippingOptions = (options: deliveryMethodProps[]): deliveryMethodProps[] => {
      return options?.sort((a, b) => {
          if (a?.method_code === "clickandcollectshipping") return -1;
          if (b?.method_code === "clickandcollectshipping") return 1;
          return 0;
      });
  };
  

    return (
    <S.Content>
          {isAPICallRunning && <Loading />}
          <div className="customer-checkout">
            <CheckoutHeader headerText={DELIVERY_OPTION_ADDRESS_HEADING}/>
            <CheckoutProgressBar id={1} title={progressBar[0].title}/>
            <div className="page-delivery-sub-heading"></div>
            {availableShipmentMethods?.length>0 && 
                availableShipmentMethods.map((item:deliveryMethodProps, index: number)=>{
                    return (
                      <React.Fragment key={item?.carrier_code}>
                    <div className={item.carrier_code===selectedCarrierCode ?"shipment-section":""}>
                    <div className="shipment-method-section">
                          <div className="address-radio">
                            <input className="custom-radio" type="radio" id="address-radio-button" value={item?.carrier_code} onChange={(e:React.ChangeEvent<HTMLInputElement>)=>radioButtonChangeHandler(item)} checked={item?.carrier_code===selectedCarrierCode}></input>
                          </div>
                          <div className="shipment-method-title-section">
                            <div className="shipment-content">
                                <div className="shipment-method-title"> {item?.method_title}</div>
                                <div className="shipment-method-sub-title">{item?.extension_attributes?.delivery_message}</div>
                            </div>
                            <div className="shipment-method-amount">{formatPriceAndCurrency(item?.amount)}</div>
                          </div>
                    </div>
                     <hr className="item-line"/>
                     </div>
                     </React.Fragment>
                    )
                })    
            }
            <div className="checkout-continue-button">
            <CheckoutButton isDisabled={selectedCarrierCode==""} label={PROCEED_TO_CHECKOUT_BUTTON_LABEL} checkoutHandler={checkoutHandler}/>
             </div>

        </div>
    </S.Content>
  );
};

export default DeliveryMethod;

import React, { useEffect, useContext,useState } from 'react';
import { getHashParams, getStaticConstantValue, isCafeteriaManager, isManager, isStoreManager, setStaticConstants } from '../../../helpers/utlis';
import { EMPLOYEE_SCREEN_CONSTANTS } from './constant';
import * as S from './styles/styles';
import { Loading } from '../../atoms/loading/Loading';
import { ASSISTANCE_PATH, CAFETERIA_CASH_REGISTERY, LOGIN_ROUTE_PATH, MANAGE_ORDER, NOTIFICATION_PERMISSION_PATH } from '../../../routes/routes-constant';
import { useNavigate } from 'react-router-dom';
import TopLogo from '../../../globals/images/Logo/TopLogo.svg';
import BottomLogo from '../../../globals/images/Logo/BottomLogo.png';
import { performCustomerLogout } from '../../../helpers/authUtils';
import { doActionPost, doActionGet } from '../../../helpers/httpRequest';
import { getLocalStorage, removeLocalStorage, setLocalStorage, clearPreviousLoginData } from '../../../helpers/localStorageUtil';
import { setPageToFullHeight } from '../../../helpers/style';
import apiList, { LOGIN_FAILED, ALERT_STATUS, USER_TYPE, AUTHENTICATION_SUCCESS, AUTHENTICATION_FAILURE, AUTHENTICATION_ERROR, QUOTE_ITEMS, SOMETHING_WENT_WRONG } from '../../../lib/constant';
import { DecodedToken, EmployeePayload } from './types/types';
import { useAuth } from '../../../Context/AuthContext';
import { useNotification } from '../../../hooks/useNotification';
import { jwtDecode } from 'jwt-decode';
import { requestPushPermission } from '../../../helpers/NotificationHandler/subscribeNotification';
import { Login_URL } from '../../../awsEmployeeConfig';
import { manageIndexedDB } from '../../../helpers/indexedDB';
import { ACTION_TYPE } from '../../../Context/Constant';
import { DataContext } from '../../../Context/AppContext';

const EmployeeLogin: React.FC = () => {
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [idToken, setIdToken] = useState<string | null>(null);
  const { isEmployeeLoggedIn, loginEmployee } = useAuth();
  const { showAlert } = useNotification();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [cognitoId,setCognitoId] =useState("");
  const [showLoggedInButton, setShowLoggedInButton] = useState(true);
  const { dispatch } = useContext(DataContext);
  const [isNotifPermission,setIsNotifPermission] = useState(false);
  useEffect(()=>{
    if(window?.Notification?.permission==="granted" || window?.Notification?.permission==="denied"){
      setIsNotifPermission(true);
    }
    },[])
    
  useEffect(() => {
    const tokens = getHashParams();

    if (tokens.access_token && tokens.id_token) {
      localStorage.setItem('msadAccessToken', tokens.access_token);
      localStorage.setItem('id-token', tokens.id_token);
      setAccessToken(tokens.access_token);
      setIdToken(tokens.id_token);
      setShowLoggedInButton(false);
      // exchangeIdTokenForAWSCredentials(tokens.id_token, tokens.access_token);
    } else {
      const storedAccessToken = localStorage.getItem('msadAccessToken');
      const storedIdToken = localStorage.getItem('id-token');
      if (storedAccessToken && storedIdToken) {
        setAccessToken(storedAccessToken);
        setIdToken(storedIdToken);
      }
    }
  }, []);

  //If already logged In, navigate employee to Dashboard
  // useEffect(() => {

  //   if (isEmployeeLoggedIn && getLocalStorage('access-token')) {
  //     if (isStoreManager()) navigate(MANAGE_ORDER)
  //       else if(isCafeteriaManager()) navigate(CAFETERIA_CASH_REGISTERY)
  //       else navigate(ASSISTANCE_PATH)
  //   }
  // }, [isEmployeeLoggedIn, idToken]);

  useEffect(() => {

    //verify Login with backend and get the access token
    const getAccessToken = async () => {
      if (idToken) {
        const decodedData: DecodedToken = jwtDecode(idToken);

        const employeeData = decodedData;

        const employeeDetails: EmployeePayload = {
          employee: {
            email: employeeData?.email,
            firstname: employeeData?.given_name,
            lastname: employeeData?.family_name,
            cognito_user_id: employeeData?.sub,
            username: employeeData?.name.match(/^([^@]+)/)[1],
            role: employeeData['custom:assignedroles'] || ""

          }
        };

        setLocalStorage('employee_email', employeeData?.email);

        // Auhenticate Employee on Magento Server
        try {

          const response = await doActionPost({
            url: apiList.employeeLogin,
            data: employeeDetails
          });
          if (response && response?.data) {
            clearPreviousLoginData("Customer")
            setLocalStorage('access-token', response?.data);
            let baseUrl = process.env.REACT_APP_API_URL
            if (getStaticConstantValue("store_code") && !baseUrl?.includes(getStaticConstantValue("store_code"))) {
              baseUrl = baseUrl?.replace("V1/", getStaticConstantValue("store_code") + "/V1/");
            }
            manageIndexedDB({ key: 'accessToken', value: response?.data })
            manageIndexedDB({ key: 'baseUrl', value: baseUrl })
            manageIndexedDB({ key: 'userToken', toDelete: true })
            loginEmployee();
            setCognitoId(employeeData?.sub);
            // addWebSocketSubscription(employeeData?.sub);
            setLocalStorage("cognitoId", JSON.stringify(employeeData?.sub));
            await setStaticConstants(USER_TYPE.Employee)
            await fetchCartDetails()
            // if (isStoreManager()) navigate(MANAGE_ORDER)
            if(isCafeteriaManager()) {
              await getShiftStatus();
              navigate(CAFETERIA_CASH_REGISTERY)
            }
            else{
              setLocalStorage("notification-section","yes");
              if(isNotifPermission){
                await requestPushPermission(getLocalStorage('cognitoId'));
                if (isStoreManager()) navigate(ASSISTANCE_PATH)
                  else if(isCafeteriaManager()) {
                    await getShiftStatus();
                    navigate(CAFETERIA_CASH_REGISTERY); 
                  }
                  else navigate(ASSISTANCE_PATH)
               }
              else{
                navigate(NOTIFICATION_PERMISSION_PATH);
              }
            }
            // else navigate(ASSISTANCE_PATH)
            showAlert(AUTHENTICATION_SUCCESS, ALERT_STATUS.success);
          } else {
            showAlert(AUTHENTICATION_FAILURE, ALERT_STATUS.error);
          }
        } catch (error) {
          setShowLoggedInButton(true);
          showAlert(AUTHENTICATION_ERROR, ALERT_STATUS.error);
          console.error(AUTHENTICATION_ERROR, error);
        }
      }
    }

    getAccessToken();

  }, [idToken]);

  useEffect(() => {
    const handleNavigation = async () => {
      if (isEmployeeLoggedIn && !showLoggedInButton && getStaticConstantValue('requested_by')) {
        if (isCafeteriaManager()) {
          await getShiftStatus();
          navigate(CAFETERIA_CASH_REGISTERY); 
        } else {
          if(isNotifPermission){
            await requestPushPermission(getLocalStorage('cognitoId'));
            if (isStoreManager()) navigate(ASSISTANCE_PATH)
              else if(isCafeteriaManager()) {
                await getShiftStatus();
                navigate(CAFETERIA_CASH_REGISTERY); 
              }
              else navigate(ASSISTANCE_PATH)
           }
          else{
            navigate(NOTIFICATION_PERMISSION_PATH);
          }
        }
      }
    };

    handleNavigation();
  }, [isEmployeeLoggedIn, showLoggedInButton, navigate,getStaticConstantValue('requested_by')]); // Add necessary dependencies

  useEffect(() => {
    if (getLocalStorage('user-token')) {
      removeLocalStorage('user-token');
      manageIndexedDB({ key: 'userToken', toDelete: true })
      performCustomerLogout();
    }
  }, [getLocalStorage('user-token')])

  // useEffect(() => {
  //   if (cognitoId && getLocalStorage('swScope')) {
  //     requestPushPermission(cognitoId);
  //   }
  // }, [cognitoId, getLocalStorage('swScope')])

  const handleEmployeeLogin = async () => {
    try {

      //Redirect to MS Login page
      window.location.href = Login_URL;

    } catch (error) {
      console.error(LOGIN_FAILED, error);
      showAlert(LOGIN_FAILED, ALERT_STATUS.error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchCartDetails = async () => {
    try {
      setIsLoading(true);
      const cartResponse: any = await doActionPost({
        url: apiList.getCartDetails,
        userType: USER_TYPE.Employee
      });
      const quoteId = cartResponse?.data;
      setLocalStorage('quoteId', quoteId);
      const cartItemResponse = await doActionGet({
        url: apiList.getCartDetails + quoteId + "/" + QUOTE_ITEMS,
        userType: USER_TYPE.Employee
      })
      setLocalStorage('cartItems', JSON.stringify(cartItemResponse?.data));
      setLocalStorage('productSyncToPlp', "false");
      let totalQty = 0;
      cartItemResponse?.data?.length>0 && cartItemResponse?.data?.map((cartItem:any)=>{
        totalQty += Number(cartItem?.qty);
      })
      dispatch({ type: ACTION_TYPE.increment_cart_item_count, payload: { count:totalQty } })
    }
    catch (error) {
      console.error(LOGIN_FAILED, error);
      showAlert(LOGIN_FAILED, ALERT_STATUS.error);
    } finally {
      setIsLoading(false);
    }
  }

  const getShiftStatus = async() =>{
    const employeeEmail = getLocalStorage('employee_email');
    if(employeeEmail){
      doActionGet({
        url: apiList.getCurrentRegisteryStatus(employeeEmail),
        userType:USER_TYPE.Employee
      })?.then((resp: any) => {
       if(resp?.data && resp?.data.length>0){
          setLocalStorage("shiftStatus",resp?.data[0]?.status?.toUpperCase());
          
       }
      }).catch((error: any) => {
        console.log("error msg", error);
        showAlert(SOMETHING_WENT_WRONG, ALERT_STATUS.error);
      })
    }
  }

  useEffect(() => {
    setPageToFullHeight(true);
    return (() => {
      setPageToFullHeight(false)
    })
  }, []);

  return (
    <S.Content>
      {isLoading && <Loading />}
      <img src={TopLogo} alt="Top Logo" className="top-logo" />
      <div className="ms-account-button-container">
      {showLoggedInButton &&<><div className="title">{EMPLOYEE_SCREEN_CONSTANTS.employee_screen_title.en}</div>
         <button className="ms-account-button" onClick={handleEmployeeLogin}>
          {EMPLOYEE_SCREEN_CONSTANTS.employee_screen_button_content.en}
        </button></> }
      </div>
      <img onClick={() => navigate(LOGIN_ROUTE_PATH)} src={BottomLogo} alt="Bottom Logo" className="bottom-logo" />
    </S.Content>
  );
};

export default EmployeeLogin;

import { CartItem, CartTotal } from "./types";

export const getCostInfo = (info: CartTotal[] = []) => {
  let costs: { [key: string]: number } = {};

  info.forEach((item) => {
    if (item.code) {
      const key = String(item.code) ?? "";
      costs[key] = item.value ?? 0;
    }
  });

  return costs;
};

export const getFormattedItems = (items: CartItem[]) => {
  const formattedItems = items.map(item => {
    const attributes = item?.extension_attributes?.attribute_info?.reduce((acc: Record<string, string>, attr) => {
      const label = attr.label ?? '';
      const key = label.replace(/\s/g, '_');  // Normalize the label into a key

      if (key) {
        acc[key] = attr.value ? attr.value : '';
      }
      return acc;
    }, {});

    return {
      'id': `${item.item_id}`,
      'name': `${item.name}`,
      'price': `${item.price}`,
      'quantity': `${item.qty}`,
      ...attributes
    }
  });

  return formattedItems;
}
export const LOGOUT_CONSTANTS = {

    logout_success: {
        "en": "Logged Out Successfully",
        "ar": "تم تسجيل الخروج بنجاح"
    },
    logout_failed: {
        "en": "Logout Failed",
        "ar": "فشل تسجيل الخروج"
    },
    already_logged_out: {
        "en": "You are already logged out",
        "ar": "لقد قمت بتسجيل الخروج بالفعل"
    }
}

export const LOGOUT_UI_CONSTANTS = {
    logout_label: {
        en: "Logout",
        ar: "تسجيل خروج",
    }
}
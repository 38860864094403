import styled from "styled-components";

export const Content = styled.div`
  background: white;
  height: 80vh;
  .enquiries {
    display: flex;
    align-items: center;
    padding: 20px;
    .title {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 110%;
      color: #6f6855;
    }
  }
    .select-input-field{
  font-size:12px;
  }
  .dropdown-container{
     margin-bottom: 20px;
    width: 100%;
    border-radius: 4px;
  }
  .enquiry-container {
    display: flex;
    flex-direction: column;
    padding: 20px;
    justify-content: space-between;
    height: calc(70vh - var(--footer-height));
    .details {
      .sub-title {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 130%;
        letter-spacing: 0.03em;
        color: #1c1c1c;
      }
      .request-container {
        box-shadow: 0px 4px 18px rgba(111, 104, 85, 0.1);
        border-radius: 10px;
        padding: 10px;
        .detail {
          display: flex;
          flex-direction: row;
          align-items: center;
          line-height: 0px;
          p {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            color: #b6b2a6;
          }
          span {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            color: #6f6855;
            padding-left: 10px;
          }
        }
      }
      .btn-container {
        display: flex;
        justify-content: space-around;
        margin-top: 20px;
        button {
          padding: 8px 16px;
          background: #6f6855;
          color: white;
          box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.05);
          border-radius: 10px;
          border: none;
          min-width: 150px;
          &.order-btn {
            background: white;
            color: #6f6855;
            border: 1px solid #6f6855;
          }
        }
      }
    }
  }

  .containers {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 20px 0;

    .action-container {
      .btn-actions {
        display: flex;
        justify-content: space-around;
        margin-top: 20px;
        button {
          padding: 7px 50px;
          border: 1px solid #6f6855;
          filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.05));
          border-radius: 100px;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          letter-spacing: 0.03em;
          color: #6f6855;
          margin: 0 2px;
          background: white;
          &.close-btn {
            background: #6f6855;
            color: white;
            border: none;
          }
        }
      }
    }
  }
  .assign-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 16px;
    gap: 16px;
    background: rgb(255, 255, 255);
    border-radius: 10px;
    width: 100%;
  }
  .select-ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 12px;
  }

  .select-ellipsis .MuiSelect-select {
    display: block;
    width: 100%;
  }
  .assign-button {
    background-color: var(--sand-button-color);
    color: white;
    font-size: 12px;
    border: none;
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
    cursor: pointer;
  }
  .close-action {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-top: 20px;
  }
  input[type="text"] {
    margin-bottom: 20px;
    padding: 8px;
    width: calc(100% - 16px);
    border-radius: 4px;
    border: 1px solid #ccc;
  }
`;

import styled from "styled-components";

export const Content = styled.div`
  background: white;

  .container {
    padding: 22px;
    display: flex;
    flex-direction: column;
  }

  .select-input-field{
  font-size:12px;
  }
  .dropdown-container{
     margin-bottom: 20px;
    width: 100%;
    border-radius: 4px;
  }

  .title {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 110%;
    padding: 20px 0;
  }

  .card-container {
    display: block;
    flex-direction: column;
    gap: 20px;
    align-items: center;
  }

  .card {
    cursor: pointer;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 100%;
    margin-bottom: 20px;
    transition: transform 0.2s;
  }

  .customer-card {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
    padding: 16px;
    width: calc(100% - 32px);
  }

  .customer-card {
    position: relative;
  }

  .customer-info {
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: flex-start;
    -webkit-box-pack: justify;
    justify-content: flex-start;

    .info {
      display: flex;

      p {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 130%;
        letter-spacing: 0.03em;
        color: #1c1c1c;
        padding: 2px 10px;
      }
    }

    .actions-container {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      padding-top: 10px;

      &.not-close{
      justify-content: space-between;
      }

      .actions {
        display: flex;
        justify-content: flex-end;
        width: 100%;

        p {
          padding: 4px 0px;
          gap: 10px;
          border-bottom: 1px solid #6f6855;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 10px;
          line-height: 15px;
          color: #6f6855;
        }

        button {
          padding: 4px 16px;
          gap: 10px;
          background: #6f6855;
          border-radius: 40px;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 10px;
          line-height: 15px;
          color: #ffffff;
          border: none;
          margin-left: 20px;
        }
      }
    }

    .close-action {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      padding-top: 20px;
    }

    .assign-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 16px;
      gap: 16px;
      background: rgb(255, 255, 255);
      border-radius: 10px;
      width: 100%;
    }
  }

  .card:hover {
    transform: translateY(-5px);
  }

  .cardContent {
    padding: 16px;
  }

  h3,
  p {
    margin: 0;
    padding: 4px 0;
  }

  p {
    color: #666;
    font-size: 14px;
  }

  .view-cart-button,
  .assign-button {
    background-color: var(--sand-button-color);
    color: white;
    font-size: 12px;
    border: none;
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
    cursor: pointer;
  }

  .view-cart-button:hover,
  .assign-button:hover {
    background-color: var(--sand-button-color-on-hover);
  }

  .alphabetical-list ul {
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    list-style-type: none;
    padding: 0;
    z-index: 10;
    right: 0px;
  }

  .alphabetical-list li button {
    display: block;
    padding: 5px;
    cursor: pointer;
    background: transparent;
    border: none;
    color: #333;
  }

  input[type="text"] {
    margin-bottom: 20px;
    padding: 8px;
    width: calc(100% - 16px);
    border-radius: 4px;
    border: 1px solid #ccc;
  }

  .select-ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 12px;
  }

  .select-ellipsis .MuiSelect-select {
    display: block;
    width: 100%;
  }

  .view-request {
    padding: 4px 0px;
    gap: 10px;
    border-bottom: 1px solid #6f6855;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 15px;
    color: #6f6855;
    text-align: center;
    &.not-close{
    width: 30%;
    }
  }
          .tab-container {
        padding-bottom: 20px;
        & .MuiTabs-indicator {
          position: absolute;
          height: 4px;
          bottom: 4px;
          background: rgb(111, 104, 85);
          border-radius: 0px 0px 6px 6px;
          transform: rotate(180deg) translateX(-55%);
          width: 15% !important;
}}
                .tab-label {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 130%;
        letter-spacing: 0.03em;
        text-transform: none;
        &.tab-label-selected {
          color: #1c1c1c;
          font-weight: 500;
        }
      }

`;

import styled, { css } from "styled-components";

export const Content = styled.div<any>`
  ${(p:any) => {
    return css`
    padding: 16px;
  .dialog-content{
    padding: 20px 5px !important;
  }
    .project-btn {
        width: 100%;
        border-radius: 40px;
        display: flex;
        align-items: center;
    }
    .project-icon {
        width: 24px;
        height: 24px;
        color: #FFFFFF;
        fill: currentColor; /* Ensure the SVG uses currentColor */
        stroke: currentColor; /* For SVGs using stroke */
    }
    .title-section{
       display: flex;
       justify-content: space-between;
       align-items: flex-start;
    }
    .card-holder-heading{
    font-size: 14px;
    margin-bottom: 3px;
    }
      .second-content-row{
    font-weight:500;
    font-size:14px;
    line-height:21px;
    color:var(--sku-font-color);
    margin-bottom:10px;
   }
    .input-box{
        width: 95%;
        margin-top: 13px;
        padding: 10px;
        flex-direction: column;
        border-radius: 4px;
        border: 1px solid var(--Soft-Sand, #B6B2A6);
        background: var(--White, #FFF);
        font-size: 14px;
    font-weight: 500;
    line-height: 18.2px;
          }
    .input-box:focus{
  outline:none;
}  
  .transaction-input-box{
    font-weight:500;
    font-size:10px;
    line-height:15px;
    color:#B6B2A6;
    }
.bank-input{
    margin-top:16px;
    }
    .cancel-return-btn {
                width: 200px;
    height: 32px;
    border: 1px solid rgb(111, 104, 85);
    background: rgb(111, 104, 85);
    filter: drop-shadow(rgba(0, 0, 0, 0.05) 0px 0px 4px);
    border-radius: 15px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    text-align: center;
    color: white;
    /* margin-left: 2px; */
    /* margin-right: 6px; */
    margin-left: auto;
    display: flex;
    justify-content: center;
    /* text-align: center; */
    align-items: center;
    margin-right: auto;
    margin-bottom: 20px;
    padding-top: initial;
            }
 .otp-confirmation-sender{
    display:flex;
    justify-content:center;
     margin-top:24px;
     flex-direction:column;
     margin-bottom:30px;
    }
      .otp-send-msg{
      font-weight: 500;
      font-size: 14px;
      line-height: 18.2px;
      color: #1C1C1C;
      text-align:center;
      margin-bottom:30px;
    }
    .otp-input-section{
      display:flex;
      justify-content:space-between;
      column:gap:2px;
    }
    .otp-input{
    text-align:center;
    width:41.2px;
      height: 40px;
      border-radius: 10px;
      border: 0.5px solid #b6b2a6;
      background-color:#FFFFFF;
      font-weight: 500;
      font-size: 10px;
      line-height: 15px;
      color: #1C1C1C;
      
      outline:none;
    }
 `
}}
`
export const DisconnectedMessage = styled.div`
        text-align: center;
        font-size: 12px;
        padding: 20px;
        color: #ff4444; /* Red for a warning */
        font-weight: 500;
`

export const LoadingIndicator = styled.div`
        border: 2px solid #f3f3f3; /* Light gray border */
        border-top: 2px solid #3498db; /* Blue top border */
        border-radius: 50%;
        width: 12px;
        height: 12px;
        animation: spin 1s linear infinite;
        }

        @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);   

        }
`
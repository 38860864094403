import styled from "styled-components";
import CircularProgress from "@mui/material/CircularProgress";

export const Content = styled.div`
  background: var(--background-light);
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;

  /* Billing Address Title CSS */
  .billing-address-title {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.05em;
    color: var(--text-dark);
    padding: 15px 30px;
  }
`;

export const Loader = styled(CircularProgress)`
  align-self: center;
`;

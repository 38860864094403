import { styled } from '@mui/system';
import { Box, Divider, DialogContent, Grid, SxProps, Theme } from '@mui/material';

export const Root = styled(Box)(({ theme }) => ({
  fontFamily: 'Poppins, sans-serif',
  display: 'flex',
  flexDirection: 'column',
  height: '100vh',
  padding: theme.spacing(2),
}));

export const LogoLinksContainer = styled(Box)({
  flex: 1,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const StyledDivider = styled(Divider)(({ theme }) => ({
  margin: theme.spacing(2, 0),
}));

export const TresoriAppsContainer = styled(Box)(({ theme }) => ({
  flex: 2,
  padding: theme.spacing(2),
}));

export const TrainingMaterialContainer = styled(Box)(({ theme }) => ({
  flex: 2,
  padding: theme.spacing(2),
}));

export const GridItem = styled(Grid)({
  display: 'flex',
  justifyContent: 'center',
});

export const DividerVertical = styled(Divider)({
  height: '100%',
  width: 'auto',
  alignSelf: 'center',
});

export const DividerHorizontal = styled(Divider)(({ theme }) => ({
  height: 'auto',
  width: '100%',
  margin: theme.spacing(2, 0),
}));

export const StyledDialogContent = styled(DialogContent)({
  padding: 0,
});

export const VideoPlayerContainer = styled('div')({
  width: '100%',
});

export const VideoPlayerElement = styled('video')({
  width: '100%',
});

export const HeadingContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '0.5rem'
});

export const iconStyle: SxProps<Theme> = {
  mr: 2,
  color: 'var(--sand-button-color)'
};

export const getDividerStyles = (isMobile: boolean): SxProps<Theme> => ({
  height: isMobile ? 'auto' : '100%',
  width: isMobile ? '100%' : 'auto',
  alignSelf: 'center',
  my: isMobile ? 2 : 0,
});

export const dialogStyle: SxProps<Theme> = {
 padding: 0
};


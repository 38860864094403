import { Accordion, AccordionSummary } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ASSISTANCE_PATH, PLP_CAFETERIA_MENU_PAGE_ROUTE_PATH } from "../../../routes/routes-constant";

const EmployeeHomePage: React.FC = () => {
  const navigate = useNavigate();
  return (
    <>
      <Accordion onChange={(event) => event.preventDefault()}>
        <AccordionSummary onClick={() => navigate(PLP_CAFETERIA_MENU_PAGE_ROUTE_PATH)} expandIcon={<></>}>
          <div className="accordionText">Cafeteria</div>
        </AccordionSummary>
      </Accordion>
      <Accordion onChange={(event) => event.preventDefault()}>
        <AccordionSummary onClick={() => navigate(ASSISTANCE_PATH)} expandIcon={<></>}>
          <div className="accordionText">Assistance</div>
        </AccordionSummary>
      </Accordion>
    </>
  );
};

export default EmployeeHomePage;

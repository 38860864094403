export const PLP_CONSTANT={
    add_to_cart: {
      "en": "Add to Cart",
      "ar": "أضف إلى السلة"
    },
    checkout: {
      "en": "Checkout",
      "ar": "الدفع"
    },
    welcome_message: {
      "en": "Welcome to our store!",
      "ar": "مرحبًا بك في متجرنا!"
    },
    SelectNCheckout:{
      "en": "Select & Checkout",
      "ar": "مرحبًا بك في متجرنا!"
    },
    Your_Item:{
      "en": "Your Item",
      "ar": "مرحبًا بك في متجرنا!"
    },
    Items_added:{
      "en": " Items Added",
      "ar": "مرحبًا بك في متجرنا!"
    },
    loading_long:{
      "en":"........................Loading.....",
      "ar": "مرحبًا بك في متجرنا!"
    },
    Move_to_cart:{
      "en":"Item has been added to the cart successfully ",
      "ar": "مرحبًا بك في متجرنا!"
    },
    Qty_updated_in_cart:{
      "en":"Item's quantity has been updated. ",
      "ar": "مرحبًا بك في متجرنا!"
    },
    Item_removed_from_cart:{
      "en":"Item has been removed from cart. ",
      "ar": "مرحبًا بك في متجرنا!"
    },
  }
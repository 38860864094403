import { AttributeValue } from "../variantAccordion/types"

const getCustomAttrribute = (attributesList: {
    "attribute_code": string,
    "value": string
}[], attributeName: string) => {
    return attributesList?.filter(attribute => attribute?.attribute_code === attributeName)?.[0]?.value ?? ''
}

export const getAttributes = (attributesList: string[], attributesData: {
    "attribute_code": string,
    "value": string
}[]) => attributesList?.map(attribute =>
    ({ [attribute]: getCustomAttrribute(attributesData, attribute) })).reduce((acc, obj) => {
        const [key] = Object.keys(obj);
        acc[key] = obj[key];
        return acc;
    }, {})

function generateSubsets(filters: { [key: string]: string }, variantCodes: string[]): { [key: string]: string }[] {
    let subsets: { [key: string]: string }[] = [{}];
    let currentObj: { [key: string]: string } = {};

    for (let i = 0; i < variantCodes.length; i++) {
        const key = variantCodes[i];
        if (key in filters) {
            currentObj[key] = filters[key];
            subsets.push({ ...currentObj });
        }
    }
    return subsets;
}

export const getAllowedVariantList = (attributes: AttributeValue[][], filters: { [key: string]: string }, variantCodes: string[]) => {
    if (!Boolean(variantCodes?.length)) return {}
    const filterSubsets = generateSubsets(filters, variantCodes);
    let result: { [key: string]: string[] } = {}

    filterSubsets.forEach((subset, index) => {
        let allowedVariantList: { [key: string]: string[] } = {};
        const filteredAttributes = attributes.filter(set => {
            return Object.keys(subset).every(filterKey => {
                const filterValue = subset[filterKey];
                return set.some(attr => attr.attribute_code === filterKey && attr.value === String(filterValue));
            });
        });

        filteredAttributes.forEach(attribute => {
            attribute.forEach(attr => {
                if (!allowedVariantList[attr.attribute_code]) {
                    allowedVariantList[attr.attribute_code] = [];
                }
                allowedVariantList[attr.attribute_code].push(attr.value);
            });
        });
        result[variantCodes[index]] = allowedVariantList[variantCodes[index]]

    });
    return result
};

export const getVideoThumbnail = (videoUrl: string, thumbnailTime = 1) => {
    return new Promise((resolve, reject) => {
        const video = document.createElement('video');
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');

        video.src = videoUrl;
        video.crossOrigin = 'anonymous';

        video.addEventListener('loadeddata', () => {
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            video.currentTime = thumbnailTime;
        });

        video.addEventListener('seeked', () => {
            context?.drawImage(video, 0, 0, canvas.width, canvas.height);
            const thumbnailDataUrl = canvas.toDataURL('image/png');
            resolve(thumbnailDataUrl);
        });

        video.addEventListener('error', () => {
            reject(new Error('Failed to load video'));
        });
    });
};


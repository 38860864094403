import React, { useState } from 'react';
import * as S from "./styles/styles";
import apiList, { ALERT_STATUS, MAY_BE_LATER_TEXT, NOTIFICATION_PERMISSION_HEADING, NOTIFICATION_PERMISSION_SUBHEADING, SOMETHING_WENT_WRONG, USER_TYPE, YES_NOTIFY_ME_LABEL } from '../../../lib/constant';
import { requestPushPermission, subscribeToPushNotifications } from '../../../helpers/NotificationHandler/subscribeNotification';
import { getLocalStorage, setLocalStorage } from '../../../helpers/localStorageUtil';
import { ASSISTANCE_PATH, CAFETERIA_CASH_REGISTERY, MANAGE_ORDER, SCAN_ROUTE_PATH } from '../../../routes/routes-constant';
import { PDP_REDIRECT_PATH } from '../login/constant';
import { useNavigate } from 'react-router-dom';
import { isCafeteriaManager, isStoreManager } from '../../../helpers/utlis';
import crossIcon from '../../../globals/svg/cross-icon.svg';
import { Loader } from '../my-account/my-profile/styles/styles';
import { doActionGet } from '../../../helpers/httpRequest';
import { useNotification } from '../../../hooks/useNotification';

const NotificationPermission: React.FC = () => {
const pdp_redirect_sku = sessionStorage.getItem('pdp_redirect_sku');
const navigate = useNavigate();
const [isLoading,setIsLoading]= useState(false);
const { showAlert } = useNotification();

const getShiftStatus = async() =>{
  const employeeEmail = getLocalStorage('employee_email');
  if(employeeEmail){
    doActionGet({
      url: apiList.getCurrentRegisteryStatus(employeeEmail),
      userType:USER_TYPE.Employee
    })?.then((resp: any) => {
      console.log(resp);
     if(resp?.data && resp?.data.length>0){
        setLocalStorage("shiftStatus",resp?.data[0]?.status?.toUpperCase());
        
     }
    }).catch((error: any) => {
      console.log("error msg", error);
      showAlert(SOMETHING_WENT_WRONG, ALERT_STATUS.error);
    })
  }
}

const laterClickHandler= async() =>{
    if (getLocalStorage('access-token')) {
        if (isStoreManager()) navigate(ASSISTANCE_PATH)
          else if(isCafeteriaManager()) {
            await getShiftStatus();
            navigate(CAFETERIA_CASH_REGISTERY); 
            navigate(CAFETERIA_CASH_REGISTERY)}
          else navigate(ASSISTANCE_PATH)
  }
  else{
    if (pdp_redirect_sku) {
      window.location.href= PDP_REDIRECT_PATH(pdp_redirect_sku);
      sessionStorage.removeItem('pdp_redirect_sku')
    }
    else window.location.href=SCAN_ROUTE_PATH;
  }
}


const notificationHandler=async()=>{
  await requestPushPermission(getLocalStorage('cognitoId'));
     if (getLocalStorage('access-token')) {
          if (isStoreManager())  navigate(ASSISTANCE_PATH)
            else if(isCafeteriaManager()) {
              await getShiftStatus();
              navigate(CAFETERIA_CASH_REGISTERY); 
              navigate(CAFETERIA_CASH_REGISTERY)}
            else navigate(ASSISTANCE_PATH)
    }
    else{
      if (pdp_redirect_sku) {
        navigate(PDP_REDIRECT_PATH(pdp_redirect_sku));
        sessionStorage.removeItem('pdp_redirect_sku')
      }
      else navigate(SCAN_ROUTE_PATH);
    }

}

  return (
    <S.Content >
      {isLoading && <Loader/>}
        <div className="notif-permission-page">
        <div className='notification-cross-icon'><img className="login-logo"  onClick={laterClickHandler} src={crossIcon}></img></div>
        <div className='notif-content-section'>
        <div className='notif-permission-heading'>{NOTIFICATION_PERMISSION_HEADING}</div>
        <div className='notif-permission-subheading'>{NOTIFICATION_PERMISSION_SUBHEADING}</div>
        <div className="button-box">
            <button type="submit"  onClick ={notificationHandler} disabled={false} className="received-button-label">{YES_NOTIFY_ME_LABEL}</button>
        </div>
        <div className='notif-later-text' onClick={laterClickHandler}>{MAY_BE_LATER_TEXT}</div>
        </div>
        </div>
    </S.Content>
  );
};

export default NotificationPermission;

import { useEffect, useState } from "react";
import { doActionGet } from "../helpers/httpRequest";
import { getLocalStorage, setLocalStorage } from "../helpers/localStorageUtil";
import { USER_TYPE } from "../lib/constant";
import { getTotalValue } from "../components/organisms/customer-checkout/helper";
import { CART_API_URL } from "../components/organisms/cart/constants";

const useFetchData = (url: string, userType?: string, quoteIdReq: boolean = true) => {
    const [data, setData] = useState();
    const [isLoading, setLoading] = useState(false)
    const [isError, setIsError] = useState(false)

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true)
                if (USER_TYPE.Customer === userType) {
                    if ((quoteIdReq && !getLocalStorage("user_quoteId"))) {
                        throw Error("user_quoteId not found")
                    }
                }
                const response = await doActionGet({
                    url,
                    ...(userType ? { userType } : {})
                });
                setData(response?.data);
                if (url === CART_API_URL) {
                    setLocalStorage("cartData", JSON.stringify(response?.data));
                    setLocalStorage("grand_total", JSON.stringify(getTotalValue(response?.data?.extension_attributes?.cart_total, "grand_total")?.value));
                }
            } catch (error) {
                console.error("Error fetching data:", error);
                setIsError(true)
            }
            finally {
                setLoading(false)
            }
        };
        fetchData()
    }, [quoteIdReq, url, userType]);

    return { isLoading, isError, data };
};

export default useFetchData;
import { useLocation, useNavigate } from 'react-router-dom';
import { doActionGet, doActionPut } from '../../../../../helpers/httpRequest';
import apiList, { ALERT_STATUS, USER_TYPE } from '../../../../../lib/constant';
import { ASSISTANCE_PATH, CUSTOMER_ORDERS_ROUTE_PATH, SPECIAL_ORDER_CUSTOMER_CART_SCREEN_ROUTE_PATH } from '../../../../../routes/routes-constant';
import { CUSTOMER_LISTING_CONSTANTS, CUSTOMER_LISTING_MESSAGES } from '../constant';
import * as S from './styles';
import { ReactComponent as BackArrow } from "./../../../../../globals/svg/back-arrow.svg";
import { getLocalStorage } from '../../../../../helpers/localStorageUtil';
import { getStaticConstantValue, isManager } from '../../../../../helpers/utlis';
import { ENQUIRY_STATUSES } from '../../../assistance/constants';
import { useEffect, useState } from 'react';
import { useNotification } from '../../../../../hooks/useNotification';
import { StoreUsers } from '../types/types';
import { FormControl, Select, MenuItem, SelectChangeEvent } from '@mui/material';

const EnquiryDetails: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const customerRequest = location.state?.data ?? {};
    const currentEmployee = { email: getLocalStorage('employee_email') ?? '', role: getStaticConstantValue("requested_by") }
    const { showAlert } = useNotification();
    const [selectedEmployee, setSelectedEmployee] = useState('');
    const [employees, setEmployees] = useState<StoreUsers[]>([]);
    const [showInputBox, setShowInputBox] = useState<'close' | 'assign' | ''>('');
    const [closeReason, setCloseReason] = useState("");
    const [actionPerformed, setActionPerformed] = useState(false)
    const { customer_name, display_id, customer_id } = customerRequest

    const handleViewCart = async () => {
        let quoteId;
        const response = await doActionGet({ url: apiList.getAssistanceCustomerCart + '/' + customerRequest?.id, userType: USER_TYPE.Employee });
        if (response && response.data) {
            quoteId = response.data.id;
        }
        navigate(SPECIAL_ORDER_CUSTOMER_CART_SCREEN_ROUTE_PATH, { state: { quoteId, role: currentEmployee.role, customerEmail: customerRequest?.customer_name } });
    };

    const closeRequest = async () => {
        if (!closeReason) {
            showAlert(CUSTOMER_LISTING_MESSAGES.close_reason.en, ALERT_STATUS.error);
            return;
        }
        window?.dataLayer?.push({
            event: isManager(currentEmployee.role) ? "change_status_close" : "close_task",
            [isManager(currentEmployee.role) ? "close_data" : "assign_data"]: {
                employee: {
                    email: "",
                    firstName: "",
                    lastName: "",
                },
                request_id: customerRequest?.display_id,
                customer_id: customerRequest?.customer_name,
            },
            status: ENQUIRY_STATUSES.CLOSE,
            cognito_id: (getLocalStorage("cognitoId") ?? "").replaceAll('\"', ''),
            emp_type: currentEmployee.role
        });

        const payload = {
            inquiry: {
                action: 'close',
            }
        };

        try {
            const response = await doActionPut({
                url: `${apiList.getEnquiryById(customerRequest?.id)}`,
                data: payload,
                userType: USER_TYPE.Employee
            });

            if (response && response.data) {
                setActionPerformed(true)
                setShowInputBox('')
                showAlert(CUSTOMER_LISTING_MESSAGES.status_update_success.en, ALERT_STATUS.success);
            }
        } catch (error) {
            console.error("Error updating status:", error);
            showAlert(CUSTOMER_LISTING_MESSAGES.status_update_failed.en, ALERT_STATUS.error);
        }
    };

    const handleAssign = async () => {
        const employee = employees?.find(emp => emp?.email === selectedEmployee);
        if (!employee) {
            showAlert(CUSTOMER_LISTING_MESSAGES.select_employee.en, ALERT_STATUS.error);
            return;
        }
        window?.dataLayer?.push({
            event: isManager(currentEmployee.role) ? "assign_to_employee" : "assign_task_to",
            assign_data: {
                employee: {
                    email: employee?.email,
                    firstName: employee?.firstname,
                    lastName: employee?.lastname,
                },
                user_id: employee?.user_id,
                request_id: customerRequest?.display_id,
                customer_id: customerRequest?.customer_name,
            },
            status: ENQUIRY_STATUSES.WORKING,
            emp_type: currentEmployee.role,
            cognito_id: (getLocalStorage("cognitoId") ?? "").replaceAll('\"', ''),
        });

        const payload = {
            inquiry: {
                action: "assign",
                extension_attributes: {
                    additional_info: "",
                    assigned_user_email: employee?.email,
                    assigned_user_id: employee?.user_id
                }
            }
        };

        try {
            const response = await doActionPut({
                url: `${apiList.getEnquiryById(customerRequest?.id)}`,
                data: payload,
                userType: USER_TYPE.Employee
            });

            if (response && response.data) {
                setActionPerformed(true)
                setShowInputBox('')
                showAlert(CUSTOMER_LISTING_MESSAGES.assign_inquiry_success.en, ALERT_STATUS.success);
            }
        } catch (error) {
            console.error("Error assigning inquiry:", error);
            showAlert(CUSTOMER_LISTING_MESSAGES.assign_inquiry_success.en, ALERT_STATUS.error);
        }
    };

    const fetchEmployees = async () => {
        try {
            const response = await doActionGet({
                url: apiList.getStoreUsers,
                userType: USER_TYPE.Employee
            });
            if (response && response.data) {
                setEmployees(response.data);
            }
        } catch (error) {
            console.error("Error fetching employees:", error);
            showAlert(CUSTOMER_LISTING_MESSAGES.fetch_employee_failed.en, ALERT_STATUS.error);
        }
    };

    useEffect(() => {
        fetchEmployees();
    }, []);

    const reasonList = getStaticConstantValue('sign_off_reasons')?.split(",");

    const handleReasonChange = (event: SelectChangeEvent) => {
        const reasonVal = event.target.value;
        setCloseReason(reasonVal);
    };

    return <S.Content>
        <div className='enquiries' onClick={() => navigate(ASSISTANCE_PATH)}>
            <BackArrow />
            <div className='title'>{CUSTOMER_LISTING_CONSTANTS.all_request.en}</div>
        </div>
        <div className='enquiry-container' >
            <div className='details'>
                <div className='sub-title'>Customer is requesting assistance</div>
                <div className='request-container'>
                    <div className='detail'>
                        <p>Request No:</p>
                        <span>{display_id}</span>
                    </div>
                    <div className='detail'>
                        <p>Name:</p>
                        <span>{customer_name}</span>
                    </div>
                    <div className='btn-container'>
                        <button onClick={handleViewCart}>View Cart</button>
                        <button className='order-btn' onClick={() => { navigate(CUSTOMER_ORDERS_ROUTE_PATH, { state: { data: customer_id } }) }}>Order History</button>
                    </div>
                </div>

            </div>
            {(customerRequest?.status !== 'close') && !actionPerformed && <div className='containers'>
                <div className='action-container'>
                    <div className='btn-actions'>
                        <button onClick={() => { setShowInputBox(pre => (pre === 'assign') ? '' : 'assign') }}>Transfer</button>
                        <button className='close-btn' onClick={() => { setShowInputBox(pre => (pre === 'close') ? '' : 'close') }}>Resolve Request</button>
                    </div>
                </div>
                {(showInputBox === 'assign') && <div className='assign-container'>
                    <FormControl size="small" fullWidth>
                        <Select
                            value={selectedEmployee || ''}
                            onChange={(e) => setSelectedEmployee(e.target.value)}
                            displayEmpty
                            className="select-ellipsis"
                            renderValue={(value) => (
                                <div className="select-ellipsis">
                                    {value || 'Select Employee'}
                                </div>
                            )}
                        >
                            <MenuItem value="" disabled>Select Employee</MenuItem>
                            {employees.map((employee) => (
                                <MenuItem key={employee.user_id} value={employee.email}>
                                    {employee.email}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <button className='assign-button' onClick={handleAssign}>Assign</button>
                </div>}
                {(showInputBox === 'close') &&
                    <div className='close-action'>
                         <div className="dropdown-container">
                                                                <FormControl fullWidth size='small'>
                                                                    <Select className="select-input-field" displayEmpty labelId="country-select-label" id="reason" name="reason" value={closeReason} onChange={handleReasonChange}
                                                                        inputProps={{ 'aria-label': 'Without label' }}
                                                                        renderValue={(selected) => {
                                                                            if (selected) {
                                                                                return <span>{closeReason}</span>;
                                                                            }
                                                                            return "Please provide request closure reason";
                                                                        }}
                                                                    >
                                                                        {/* <MenuItem disabled value="">
                                                                            <span>{ADDRESS_FORM_UI_STRINGS.actions.selectCountry.en}</span>
                                                                        </MenuItem> */}
                                                                        {reasonList?.length > 0 ? (
                                                                            reasonList?.map(reason => (
                                                                                <MenuItem key={reason} value={reason}>{reason}</MenuItem>
                                                                            ))
                                                                        ) : (
                                                                            <MenuItem value="" disabled>
                                                                                Loading...
                                                                            </MenuItem>
                                                                        )}
                                                                    </Select>
                                                                </FormControl>
                                                            </div>
                        <button className='assign-button' onClick={closeRequest}>{CUSTOMER_LISTING_CONSTANTS.close_button_label.en}</button>
                    </div>}
            </div>}
        </div>
    </S.Content >
}
export default EnquiryDetails;
import { checkout_shipping_info_gtm, checkout_gtm, checkout_payment_info_gtm, checkout_purchase_gtm } from "../../../helpers/GTM";
import { requestAssistance_gtm } from "../../../helpers/GTM/assistanceGTM";
import { doActionGet, doActionPost } from "../../../helpers/httpRequest";
import { getLocalStorage, removeLocalStorage } from "../../../helpers/localStorageUtil";
import apiList, { USER_TYPE } from "../../../lib/constant";
import { cartItemResponse, totalsType } from "../employee-cart/types/types";
import { Address, EncryptionAttribute } from "../my-account/my-profile/types/types";
import { availablePaymentMethodType, countryListProps, paymentMethodProps } from "./types/types";

export const getAttributeDataBasedOnCode=(encryptionData:EncryptionAttribute[]|undefined,attributecode:string)=>{
    if(!encryptionData){
     return [];
    }
     return encryptionData?.filter((item:EncryptionAttribute,index:number)=>{
         return item.attribute_code==attributecode;
     })
 }

 export const getCountryName = (countryList:countryListProps[], countryId: string |undefined) => {
    return (countryList as countryListProps[]).filter((item: countryListProps) => item.id === countryId);

};

export const gtmDataConstructor = (eventName:string,paymentMethodName="",orderId="")=>{
    const cartData =JSON.parse(getLocalStorage("cartData"));
    let items =cartData?.items;
    let value= Number(getLocalStorage("grand_total"));
    let coupon = cartData?.extension_attributes?.coupon_string 
    ?? getLocalStorage('coupon_string') 
    ?? "";
    let shippingMethod= getLocalStorage("deliveryCarrierCode");
    if(eventName==="begin_checkout"){
        checkout_gtm(value,coupon,items);
    }
    if(eventName==="add_shipping_info"){
        checkout_shipping_info_gtm(value,coupon,shippingMethod,items)
    }
    if(eventName==="add_payment_info"){
        checkout_payment_info_gtm(Number(getGrandTotalValue()),coupon,paymentMethodName,items)
    }
    if(eventName==="purchase"){
        checkout_purchase_gtm(value,coupon,paymentMethodName,shippingMethod,items,orderId)
    }

}

export const getTotalValue=(cartTotals:any,code:string) =>{
    let totalItem = cartTotals?.filter((item:totalsType)=>{
        return item.code===code;
    });
    return totalItem?.length>0?totalItem[0]:[];
  }


 export const clearCartLocalStorage=()=>{
    removeLocalStorage("user_quoteId");
    removeLocalStorage("billingAddress");
    removeLocalStorage("shippingAddress");
    removeLocalStorage("selectedShipmentAddressId");
    removeLocalStorage("newCreatedBillingAddress");
    removeLocalStorage("newCreatedAddress");
    removeLocalStorage("deliveryCarrierCode");
    removeLocalStorage("cartItems");
    removeLocalStorage("coupon_string");
    removeLocalStorage("grand_total");
    removeLocalStorage("notification-section");
 }

 export const getGrandTotalValue=()=>{
    const cartData =JSON.parse(getLocalStorage("cartData"));
    let advancePaymentSelection = cartData?.extension_attributes?.selected_advance_payment_option??0;
    let cartSubTotalData= (Number(getLocalStorage("grand_total"))>0 && advancePaymentSelection>0)?((Number(getLocalStorage("grand_total"))*advancePaymentSelection)/100).toFixed(2):Number(getLocalStorage("grand_total"));
    return  cartSubTotalData ?cartSubTotalData:0;
 }


 export const getPaymentMethodTitle = (availablePaymentMethodList:availablePaymentMethodType[],code:string) =>{
    const selectPayment= availablePaymentMethodList?.filter((method:availablePaymentMethodType)=>{
        return method.code === code ;
    } )
    return selectPayment?.length>0 ?selectPayment[0].title:"";
 }

export const raiseAssistanceRequest = async()=>{
    const response = await doActionGet({ url: apiList.getEnquiries, userType: USER_TYPE.Customer });
    const enquiryData= response?.data?.items && response?.data?.items?.length>0 ?response?.data?.items.filter((req:any)=>req?.status!=="close"):[];
    if(enquiryData && enquiryData?.length==0){
        const response = await doActionPost({ url: apiList.createEquiry, userType: USER_TYPE.Customer });
        requestAssistance_gtm({ request_ID: response?.data});
    }
}
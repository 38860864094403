import * as S from "./styles/basicInfo";
import DOMPurify from 'isomorphic-dompurify';
import { ReactComponent as DesignerStoryIcon } from '../../../globals/svg/designerStory.svg'
import { PDP_CONSTANTS } from "./common/constants";
import { CarouselMedia } from "./common/types";
import { useEffect, useRef, useState } from "react";
import VideoThumbnail from "./VideoThumbnail";

interface Props {
    media: CarouselMedia[];
    setSelectedMediaId: React.Dispatch<React.SetStateAction<string>>;
    setShowProduct: React.Dispatch<React.SetStateAction<boolean>>;
    name: string;
    basicAttributes: { [key: string]: string };
}

const BasicInfo: React.FC<Props> = ({ media, name, setSelectedMediaId, basicAttributes, setShowProduct }) => {
    const [readMore, setReadMore] = useState(false)
    const [isClamped, setIsClamped] = useState(false);
    const descriptionRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const checkOverflow = () => {
            if (descriptionRef.current) {
                const element = descriptionRef.current;
                const isOverflowing = element.scrollHeight > element.clientHeight;
                setIsClamped(isOverflowing);
            }
        };
        checkOverflow();

        window.addEventListener('resize', checkOverflow);
        return () => window.removeEventListener('resize', checkOverflow);
    }, [basicAttributes?.short_description]);

    const handleToggleReadMore = () => { setReadMore(pre => !pre); };

    return (
        <S.Content>
            <div className="basicContainer">
                <>
                    <div className="name-wishlist">
                        <div className="name">{name ?? ''}</div>
                    </div>
                </>
                <div className="thumbnailContainer">
                    {media?.map((item, index) => (
                        <div className="thumbnail" key={index} onClick={() => { setSelectedMediaId(String(item.id)) }}>
                            {item.type === 'image' ? <img className="thumbnailImage" src={item.url} alt={index + ''} /> :
                                <VideoThumbnail url={item.url} >
                                    <DesignerStoryIcon />
                                </VideoThumbnail>}
                        </div>
                    ))}
                </div>
                <div ref={descriptionRef}
                    className={`description ${readMore ? 'expanded' : ''}`}
                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(basicAttributes?.short_description) }}
                ></div>
                {isClamped && (
                    <span className="read-more-link" onClick={handleToggleReadMore}>
                        {readMore ? 'Read Less...' : 'Read More...'}
                    </span>
                )}
                {basicAttributes?.storytelling_video && (<div className="designerStory">
                    <div className="storyVideo">{PDP_CONSTANTS.designer_story}</div>
                    <DesignerStoryIcon onClick={() => { setShowProduct(false) }} />
                </div>)}
            </div>
        </S.Content >
    );
};

export default BasicInfo;
import styled from "styled-components";

export const Content = styled.div`
  .thumbnail-container {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 40px;
  }

  .thumbnail-container img {
    width: 100%;
    height: 40px;
    display: block;
  }

  .thumbnail-placeholder {
    width: 100%;
    height: 100%;
    background-color: black;
    position: absolute;
    top: 0;
    left: 0;
    display: block;
  }

  .play-button {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 64px;
    height: 64px;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

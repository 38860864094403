import { useEffect, useState } from 'react';
import './App.css';
import Header from './components/molecules/header';
import Routes from './routes/routes';
import { BrowserRouter } from 'react-router-dom';
import { GlobalStyles } from './globals/styles/globalStyles';
import Footer from './components/molecules/footer';
import { DataProvider } from './Context/AppContext';
import { useMsal } from '@azure/msal-react';
import { httpAxiosInterceptor } from './helpers/httpRequestInterceptors';
import { useNotification } from './hooks/useNotification';
import { useAuth } from './Context/AuthContext';
import { Loading } from './components/atoms/loading/Loading';
import MainContainer from './components/molecules/main-container';
import { setDynamicHeights } from './helpers/utlis';
import WebSocketManager from './components/organisms/web-socket-manager';
import { ErrorBoundary } from "react-error-boundary";
import StoreManagerNavigationBar from './components/molecules/navigation-bar/store-manager-navigation-bar/StoreManagerNavigationBar';
import CafeManagerNavigationBar from './components/molecules/navigation-bar/cafe-manager-navigation-bar/CafeManagerNavigationBar';
import EmployeeNavigationBar from './components/molecules/navigation-bar/employee-navigation-bar/EmployeeNavigationBar';

function App() {
  const { isUserLoggedIn, isEmployeeLoggedIn } = useAuth();
  const { instance, accounts } = useMsal();
  const { showAlert } = useNotification();
  const [loading, setLoading] = useState<boolean>(false);

  httpAxiosInterceptor(instance, accounts, showAlert, setLoading); //Intercept the request and the response while HTTP Request

  //calculate the height of the header, footer and main container dynamically
  useEffect(() => {
    setDynamicHeights(isUserLoggedIn, isEmployeeLoggedIn); // Set heights on initial load
    return () => {
      window.removeEventListener('resize', () => setDynamicHeights(isUserLoggedIn, isEmployeeLoggedIn)); // Cleanup on unmount
    };
  }, [isUserLoggedIn, isEmployeeLoggedIn]);

  useEffect(() => {
    const apiUrl = process.env.REACT_APP_API_URL;
    (window as any).REACT_APP_API_URL = apiUrl;
    const script = document.createElement('script');
    script.src = '/script.js';
    script.async = true;
    document.body.appendChild(script);
  }, []);

  return (
    <BrowserRouter>
      {loading && <Loading />}
      <DataProvider>
        <GlobalStyles />
        <ErrorBoundary onError={(err:any) => { console.error(err) }} fallback={<p>⚠️Something went wrong</p>}>
          <Header />
          <MainContainer>
            <Routes></Routes>
          </MainContainer>
          {isUserLoggedIn && <Footer />}
          {isEmployeeLoggedIn && (<CafeManagerNavigationBar />)}
          {isEmployeeLoggedIn && (<StoreManagerNavigationBar />)}
          {isEmployeeLoggedIn && (<EmployeeNavigationBar />)}
        </ErrorBoundary>
        <WebSocketManager />
      </DataProvider>
    </BrowserRouter>

  );
}

export default App;
import React from "react";

const Home: React.FC = () => {
 
  return (
    <>
    <h2>Home page after login</h2>
    </>
  );
};

export default Home;

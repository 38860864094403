import React, { useEffect, useState } from "react";
import * as S from "../customer-checkout/styles/styles";
import apiList, { ALERT_STATUS, BANK_TRANSFER_LABEL, CASH_ON_DELIVERY_CODE, CHECKOUT_LAST_STEP_HEADING, CHECQUE_LABEL, CHEQUE_PURCHASE_ORDER_CODE, PAYMENT_METHOD, PAYMENT_METHOD_SUB_HEADING, PURCHASE_ORDER_CODE, SOMETHING_WENT_WRONG, STRIPE_PAYMENT_METHOD_CODE, USER_TYPE, WANT_SPLIT, WANT_SUB_SPLIT } from "../../../lib/constant";
import { CHECKOUT_PAYMENT_CARD_FORM_PATH } from "../../../routes/routes-constant";
import CustomerCardListing from "../customer-checkout/payment-method/card-listing";
import NetBanking from "../customer-checkout/payment-method/net-banking";
import { availablePaymentMethodType, paymentIconMappingType } from "../customer-checkout/types/types";
import { getPaymentMethodTitle, gtmDataConstructor } from "../customer-checkout/helper";
import { useNotification } from "../../../hooks/useNotification";
import checkmo from '../../../globals/svg/checkmo.svg';
import stripe_payments from "../../../globals/svg/stripe_payments.svg";
import purchaseorder from "../../../globals/svg/purchaseorder.svg";
import { useLocation } from "react-router-dom";
import Cash from "../customer-checkout/payment-method/cash";
import CustomerPaymentForm from "../customer-checkout/payment-method/payment-form";
import { doActionGet } from "../../../helpers/httpRequest";
import { Loading } from "../../atoms/loading/Loading";
import { checkout_second_payment_info_gtm } from "../../../helpers/GTM";
import chequeIcon from "../../../globals/svg/chequeIcon.svg";
import selectedChequeIcon from "../../../globals/svg/selectedChequeIcon.svg";
import selectedCheckmo from '../../../globals/svg/selectedCheckmo.svg';
import selectedstripe_payments from "../../../globals/svg/selectedstripe_payments.svg";
import selectedPurchaseorder from "../../../globals/svg/selectedPurchaseorder.svg";
import CheckoutHeader from "../customer-checkout/checkout-header";
import Cheque from "../customer-checkout/payment-method/cheque";
import { FormControl, MenuItem, Select } from "@mui/material";
import { CART_CONSTANTS } from "../employee-special-order-cart/special-cart/constant";
import { getStaticConstantValue } from "../../../helpers/utlis";


const CustomerPendingPayment: React.FC = () => { 
    const [isAPICallRunning,setIsAPICallRunning] =useState(false);
    const { showAlert } = useNotification();
    const [availablePaymentMethod,setAvailablePaymentMethod] =useState<availablePaymentMethodType[]>([]);
    const [selectedPaymentMethod,setSelectedPaymentMethod] = useState<String>(STRIPE_PAYMENT_METHOD_CODE);
    const location =useLocation();
    const [wantSplit,setWantSplit] = useState(false);
    const paymentSlabPercentage = getStaticConstantValue("customer_split_payment")?.split(",").map(value => ({
      value: value.toString(),
      label: `${value} %`
  }));
  const [selectedAdvancePaymentOption, setSelectedAdvancePaymentOption] = useState('');
    const paymentMethodCodeAndIconMapping = [
      { key: CASH_ON_DELIVERY_CODE, value: checkmo},
      { key: STRIPE_PAYMENT_METHOD_CODE, value: stripe_payments },
      { key: PURCHASE_ORDER_CODE, value: purchaseorder },
      {key:CHEQUE_PURCHASE_ORDER_CODE, value:chequeIcon}
    ];

    const paymentMethodSelectedCodeAndIconMapping = [
      { key: CASH_ON_DELIVERY_CODE, value: selectedCheckmo},
      { key: STRIPE_PAYMENT_METHOD_CODE, value: selectedstripe_payments },
      { key: PURCHASE_ORDER_CODE, value: selectedPurchaseorder },
      {key:CHEQUE_PURCHASE_ORDER_CODE, value:selectedChequeIcon}
    ];

    function modifyPaymentMethods(arr:availablePaymentMethodType[]) {
      let purchaseOrderExists = false;
      for (const item of arr) {
          if (item.code === PURCHASE_ORDER_CODE) {
              purchaseOrderExists = true;
              item.title = BANK_TRANSFER_LABEL;
          }
      }
      if (purchaseOrderExists) {
          arr.push({
              "code": CHEQUE_PURCHASE_ORDER_CODE,
              "title": CHECQUE_LABEL
          });
      }
      return arr;
  }
  
    useEffect(()=>{
        setIsAPICallRunning(true);
        doActionGet({
          url: apiList.getPendingPaymentMethodList,
          userType:USER_TYPE.Customer
        })?.then((resp: any) => {
          let availablePaymentMethod = resp?.data ? resp?.data?.reverse() :[];
          setAvailablePaymentMethod(modifyPaymentMethods(availablePaymentMethod));
        }
        ).catch((error: any) => {
          console.log("error msg", error);
          showAlert(SOMETHING_WENT_WRONG, ALERT_STATUS.error);
        }).finally(()=>{
          setIsAPICallRunning(false);
        })
    },[])
    
    const paymentMethodSelector =(code:string)=>{
        checkout_second_payment_info_gtm(location?.state?.totalDue , location?.state?.orderId, code);
        setSelectedPaymentMethod(code);
      }   

      // Function to retrieve value based on key
    function getValueByKey(arr:paymentIconMappingType[], key:string) {
        return arr.find(item => item.key === key)?.value;
      }

      const paymentComponents: { [key: string]: React.ReactNode } = {
        [STRIPE_PAYMENT_METHOD_CODE]: (
            <>
                <CustomerCardListing isSecondPayment  methodTitle={getPaymentMethodTitle(availablePaymentMethod,STRIPE_PAYMENT_METHOD_CODE)} paid_percent={selectedAdvancePaymentOption}/>
                {location.pathname === CHECKOUT_PAYMENT_CARD_FORM_PATH && (
                    <CustomerPaymentForm isSecondPayment  methodTitle={getPaymentMethodTitle(availablePaymentMethod,STRIPE_PAYMENT_METHOD_CODE)} paid_percent={selectedAdvancePaymentOption}/>
                )}
            </>
        ),
        [PURCHASE_ORDER_CODE]: <NetBanking isSecondPayment  methodTitle={getPaymentMethodTitle(availablePaymentMethod,PURCHASE_ORDER_CODE)} paid_percent={selectedAdvancePaymentOption}/>,
        [CASH_ON_DELIVERY_CODE]: <Cash isSecondPayment   methodTitle={getPaymentMethodTitle(availablePaymentMethod,CASH_ON_DELIVERY_CODE)} paid_percent={selectedAdvancePaymentOption}/>,
        [CHEQUE_PURCHASE_ORDER_CODE]: <Cheque isSecondPayment   methodTitle={getPaymentMethodTitle(availablePaymentMethod,CHEQUE_PURCHASE_ORDER_CODE)} paid_percent={selectedAdvancePaymentOption}/>,
    };
    return (
    <S.Content>
         {isAPICallRunning && (
        <div className="loading_container">
          <Loading />
        </div>
      )}
        <div>
        <CheckoutHeader headerText={CHECKOUT_LAST_STEP_HEADING}/>
            {availablePaymentMethod && availablePaymentMethod.length>0 &&
            <>
            <div className="split-section-box"><div className="address-check-section split-box-section">
          <label className="split-text">{WANT_SPLIT}</label>
          <input className="gift-checkbox" type="checkbox" checked={wantSplit}
          onChange={()=>{setWantSplit((prev)=>!prev)}}/>
          </div>
          <div className="split-sub-text">{WANT_SUB_SPLIT}</div>
           {wantSplit && <div className="payment-slab">
            <div className="payment-label split-text">{CART_CONSTANTS.advance_payment_slab.en}</div>
            <FormControl fullWidth className="payment-slab-dropdown" size='small'>
                <Select
                    displayEmpty
                    labelId="title-label"
                    id="advance-payment-slab"
                    name="advance-payment-slab"
                    value={selectedAdvancePaymentOption || ''}
                    onChange={(e) => { setSelectedAdvancePaymentOption(e.target.value); }}
                    variant="outlined"
                    inputProps={{ 'aria-label': 'Without label' }}
                    renderValue={(selected) => {
                        if (!selected) {
                            return <span>{CART_CONSTANTS.select_payment_percentage.en}</span>;
                        }
                        return paymentSlabPercentage?.find((r:any) => r.value === selected)?.label || CART_CONSTANTS.select_payment_percentage.en;
                    }}
                >
                    <MenuItem disabled value="">
                        <span>{CART_CONSTANTS.select_payment_percentage.en}</span>
                    </MenuItem>
                    {paymentSlabPercentage?.map((option:any) => (
                        <MenuItem key={option?.value} value={option?.value}>
                            {option?.label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>}
        </div>
            <div className="customer-payment-section">
            <div className="payment-method-list-section">
                {availablePaymentMethod.map((item:availablePaymentMethodType,index:number)=>{
                    return (
                      <React.Fragment key={item?.code}>
                    <div className={selectedPaymentMethod===item.code ? "payment-method-section-selected":"payment-method-section"} onClick={()=>{ paymentMethodSelector(item.code)}}>
                    <div className="code-image"><img  src={getValueByKey(selectedPaymentMethod===item.code?paymentMethodSelectedCodeAndIconMapping:paymentMethodCodeAndIconMapping,item.code)} alt="PaymentIcon" className="payment-icon-image" /></div>
                          <div className="code-title">{item.title}</div>
                          
                    </div>
                    </React.Fragment>
                    )
                })}
            </div>
            </div>
            </>
             }
            {paymentComponents[selectedPaymentMethod.toString() ] || null}
          
        </div>
    </S.Content>
  );
};

export default CustomerPendingPayment;

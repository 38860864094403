import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { NotificationProvider } from './providers/NotificationProvider';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './authConfig';
import theme from './globals/styles/theme';
import { ThemeProvider } from '@mui/material';
import { CountryProvider } from './providers/CountryProvider';
import { AuthProvider } from './Context/AuthContext';
import { GlobalStateProvider } from './Context/GlobalStateContext';

//microsoft AD Configuration
const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  // <React.StrictMode>
  <GlobalStateProvider>
    <ThemeProvider theme={theme}>
      <NotificationProvider>
        <CountryProvider>
          <AuthProvider>
            <MsalProvider instance={msalInstance}>
              <App />
            </MsalProvider>
          </AuthProvider>
        </CountryProvider>
      </NotificationProvider>
    </ThemeProvider>
  </GlobalStateProvider>
  // </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

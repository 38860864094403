import React, { useEffect, useState } from 'react';
import * as S from './styles/styles';
import { doActionPut } from '../../../../helpers/httpRequest';
import apiList, { ALERT_STATUS, USER_TYPE } from '../../../../lib/constant';
import { CustomerProfileData, Preferences, PreferencesLabels } from './types/types';
import { useLocation, useNavigate } from 'react-router-dom';
import { FormControl, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import { useNotification } from '../../../../hooks/useNotification';
import { AxiosError } from 'axios';
import { PROFILE_MESSAGES, PROFILE_UI_STRINGS, VALIDATION_CONSTANTS } from './constants';
import { getStaticConstantValue } from '../../../../helpers/utlis';
import { getCustomerData } from './helpers/api-calls/apiCalls';
import { updatePersonalInfo_gtm } from '../../../../helpers/GTM/profileSectionGTM';
import { confirmUserAttribute, sendUserAttributeVerificationCode, updateUserAttribute } from 'aws-amplify/auth';
import { Loading } from '../../../atoms/loading/Loading';
import OTPModalPopUp from '../../../atoms/otp-modal';
import { CHECKOUT_ORDER_CONFIRMATION_PAGE } from '../../../../routes/routes-constant';
import { CountryCode, getCountries, getCountryCallingCode } from 'libphonenumber-js';

const EditProfilePage: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { showAlert } = useNotification();
    const [customerDetails, setCustomerDetails] = useState<CustomerProfileData>({
        firstName: '',
        lastName: '',
        phoneNumber: '',
        email: '',
        prefix: '',
        communicationPreferences: [],
    });
    const TITLES = [
        { value: 'Mr', label: 'Mr' },
        { value: 'Miss', label: 'Miss' },
        { value: 'Other', label: <em>Other...</em> }
    ];

    const [userData, setUserData] = useState<CustomerProfileData>();
    const [preferenceLabels, setPreferenceLabels] = useState<PreferencesLabels>({});
    const [userPreferences, setUserPreferences] = useState<Preferences>({});
    const [loading,setLoading] = useState(false);
    const [isModalOpen,setIsModalOpen] = useState(false);
    const [payloadData,setPayloadData] = useState<any>();
    const [phoneCode,setPhoneCode] = useState("+971");
    const [countryCodes,setCountryCodes] = useState<string[]>();
    useEffect(()=>{
        const countries: CountryCode[] = getCountries() as CountryCode[];
        
        let  countryCodesData: string[] = [];
    
        countries.forEach((country: CountryCode) => {
          if(!countryCodesData.includes(`+${getCountryCallingCode(country)}`)){
            countryCodesData.push(`+${getCountryCallingCode(country)}`);
          }
        });
        setCountryCodes(countryCodesData);
      },[])
    useEffect(()=>{
        if(location?.state?.fromCheckoutPage){
            showAlert("Please update your profile details before proceeding to order placement", ALERT_STATUS.info);
        }

    },[location?.state?.fromCheckoutPage])
    useEffect(() => {
        fetchCommunicationPreferences();
    }, []);

    useEffect(() => {
        if (Object.keys(preferenceLabels).length > 0) {
            fetchData();
        }
    }, [preferenceLabels]);

    const fetchCommunicationPreferences = async () => {
        try {
            const prefs = getStaticConstantValue('communication_preference') || {};
            // const labels: PreferencesLabels = {};
            // if ('32' in prefs) labels[32] = 'Messages'
            // if ('33' in prefs) labels[33] = 'Email'
            // console.log("lab",labels,prefs);
            setPreferenceLabels(prefs);
        } catch (error) {
            console.error(PROFILE_MESSAGES.fetch_preferences_error.en, error);
            showAlert(PROFILE_MESSAGES.fetch_preferences_fail.en, ALERT_STATUS.error);
        }

    };

    const parsePhoneNumber = (phone: string) => {
        if (phone.includes('-')) {
          const [code, number] = phone.split('-');
          setPhoneCode(code);
         return number;
        } else {
            setPhoneCode('');
           return phone;
        }
      };

    const fetchData = async () => {
        const customerData = await getCustomerData(showAlert, navigate);
        if (customerData) {
            const commPrefs = customerData.custom_attributes?.find((attr: { attribute_code: string; }) => attr.attribute_code === 'communication_preference')?.value.split(',') || [];
            const phoneNumber = customerData.custom_attributes?.find((attr: { attribute_code: string; }) => attr.attribute_code === 'phone_number')?.value ?? '';
            const preferences = Object.keys(preferenceLabels).reduce<Record<string, boolean>>((acc, code) => {
                acc[code] = commPrefs.includes(code);
                return acc;
            }, {});
            setUserPreferences(preferences)
            const { firstname, lastname, email, prefix } = customerData
            setCustomerDetails({
                firstName: firstname,
                lastName: lastname,
                email,
                prefix,
                phoneNumber:parsePhoneNumber(phoneNumber)
            });

            setUserData({
                firstName: firstname,
                lastName: lastname,
                email,
                prefix,
                phoneNumber:parsePhoneNumber(phoneNumber),
                preferences
            });

        }
    };


    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent) => {
        const target = event.target as HTMLInputElement;
        const value = target.value;
        const name = target.name;

        const isValidName = (name: string) => /^[A-Za-z\s]{1,20}$/.test(name);
        const isValidPhoneNumber = (phoneNumber: string) => /^[+0-9]{0,15}$/.test(phoneNumber);
        if (name === 'phoneNumber') { if (isValidPhoneNumber(value)) setUserData(userData => ({ ...userData, [name]: value })) }
        else if (value.trim() === '') setUserData(userData => ({ ...userData, [name]: value }))
        else if (name === 'firstName' || name === 'lastName') {
            if (isValidName(value)) setUserData(userData => ({ ...userData, [name]: value }))
        } else setUserData(userData => ({ ...userData, [name]: value }))
    }

    const handlePreferenceChange = (code: string, checked: boolean) => {
        setUserPreferences(prev => ({
            ...prev,
            [code]: checked
        }));
    };

    const constructPreferencesString = () => {
        const selectedPreferences = Object.entries(userPreferences)
            .filter(([code, isChecked]) => isChecked)
            .map(([code]) => code);
        return selectedPreferences.join(',');
    };

    const constructPreferencesWithLabels = () => {
        const selectedPreferences = Object.entries(userPreferences)
            .filter(([code, isChecked]) => isChecked)
            .map(([code]) => preferenceLabels[code]);

        return selectedPreferences.join(', ');
    };

    const handleSaveDetails = async () => {
        if(!userData?.prefix && location?.state?.fromCheckoutPage){
            showAlert(VALIDATION_CONSTANTS.fill_all_required_fields, ALERT_STATUS.error);
            return;
        }
        if (!userData?.firstName || !userData?.lastName) {
            showAlert(VALIDATION_CONSTANTS.fill_all_required_fields, ALERT_STATUS.error);
            return;
        }
        const isCustomerEdited = (
            (userData.firstName !== customerDetails.firstName) ||
            (userData.lastName !== customerDetails.lastName) ||
            (userData.prefix !== customerDetails.prefix) ||
            (userData.phoneNumber !== customerDetails.phoneNumber)
        );
        const { firstName, lastName, prefix, phoneNumber } = userData
        const payload = {
            customer: {
                email: customerDetails.email,
                firstname: firstName,
                lastname: lastName,
                prefix,
                "customAttributes": [
                    {
                        "attribute_code": "phone_number",
                        "value":phoneNumber? phoneCode+"-"+phoneNumber:"",
                    },
                    {
                        "attribute_code": "communication_preference",
                        "value": constructPreferencesString()
                    },
                    {
                        "attribute_code": "is_customer_edited",
                        "value": isCustomerEdited ? 1 : 0
                    }]
            },
        };

        try {
            setPayloadData(payload)
            if(phoneNumber && phoneNumber.length!==10){
                showAlert("Invalid phone no format", ALERT_STATUS.error);
            }
           else if(userData.phoneNumber !== customerDetails.phoneNumber){
                try{
                    setLoading(true);
                    const cognitoUpdateResp = await updateUserAttribute({userAttribute:{
                        attributeKey:"phone_number",
                        value:phoneCode+phoneNumber||""
                    }})
                    console.log("cognito resp",cognitoUpdateResp);
                    const cognitoUpdateRespcode = await sendUserAttributeVerificationCode({userAttributeKey:"phone_number"});
                    console.log("cognito send resp",cognitoUpdateRespcode);
                    setIsModalOpen(true);
                    setLoading(false);
                }
                catch (err:any) {
                    setLoading(false);
                    showAlert(err?.message, ALERT_STATUS.error);
                    console.log("User Attribute Save Error", err);
                }
               
                // const confirmationSendReso = await confirmUserAttribute({userAttributeKey:"phone_number",confirmationCode:""});
                // console.log("cognito confirm resp",confirmationSendReso);
               
            }
            else{
                await sendPayloadData(payload);
           }
           
        } catch (error) {
            const axiosError = error as AxiosError;
            console.error('Failed to update user Data:', axiosError.message);
            showAlert(axiosError.response?.status === 401 ? PROFILE_MESSAGES.login_required.en : PROFILE_MESSAGES.update_fail_generic.en, axiosError.response?.status === 401 ? ALERT_STATUS.info : ALERT_STATUS.error);
        }
    };

    const sendPayloadData=async(payload:any)=>{
        try{
            const response = await doActionPut({ url: apiList.customerDetails, userType: USER_TYPE.Customer, data: payload });
        if (response && response.data) {
            updatePersonalInfo_gtm({ ...payload, communication_preference: constructPreferencesWithLabels() });
            showAlert(PROFILE_MESSAGES.update_success.en, ALERT_STATUS.success);
            fetchData();
        } else {
            throw new Error(PROFILE_MESSAGES.update_fail_no_data.en);
        }
        if(location?.state?.fromCheckoutPage){
            navigate(CHECKOUT_ORDER_CONFIRMATION_PAGE);
        }
        } catch (err:any) {
            setLoading(false);
            showAlert(err?.message, ALERT_STATUS.error);
        }
        
    }


    
    const otpSubmitHandler=async(otp:string)=>{
        try{
            setIsModalOpen(false);
            const otpConfirmationResp = await confirmUserAttribute({userAttributeKey:"phone_number",confirmationCode:otp})
            console.log("otp confirmation resp",otpConfirmationResp);
            await sendPayloadData(payloadData);
        }
        catch (err:any) {
            setLoading(false);
            showAlert(err?.message, ALERT_STATUS.error);
            console.log("Error in otp submit", err);
        }
    }

    return (
        <S.Content>
            {loading && <Loading/>}
            <OTPModalPopUp isOpen={isModalOpen} onClose={()=>setIsModalOpen(false)} onSubmit={otpSubmitHandler}/>
            <div className="editProfileTitle">{PROFILE_UI_STRINGS.edit_profile.en}</div>
            <div className="edit-profile-area">
                <div className='field-area'>
                    <span className='field-title'>{PROFILE_UI_STRINGS.title_section.en}</span>
                    <FormControl fullWidth className="title-dropdown" size='small'>
                        <Select
                            labelId="title-label"
                            id="title"
                            name="title"
                            value={userData?.prefix || ''}
                            onChange={(e) => setUserData({ ...userData, prefix: e.target.value })}
                        >
                            {TITLES?.map((option) => (
                                <MenuItem key={option?.value} value={option?.value}>
                                    {option?.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
                <div className='field-area'>
                    <span className='field-title'>{PROFILE_UI_STRINGS.name_section.en}</span>
                    <div className='name-fields'>
                        <input
                            type="text"
                            name="firstName"
                            value={userData?.firstName}
                            onChange={handleInputChange}
                            className='input-field name-part'
                        />
                        <input
                            type="text"
                            name="lastName"
                            value={userData?.lastName}
                            onChange={handleInputChange}
                            className='input-field name-part'
                        />
                    </div>
                </div>

                <div className='field-area'>
                    <span className='field-title'>{PROFILE_UI_STRINGS.email_address_section.en}</span>
                    <input
                        type="text"
                        name="email"
                        value={customerDetails?.email}
                        onChange={handleInputChange}
                        className='input-field non-editable'
                        disabled={true}
                    />
                    <span className='field-span'>{PROFILE_UI_STRINGS.email_note.en}</span>
                </div>
                <div className='field-area'>
                    <span className='field-title'>{PROFILE_UI_STRINGS.phoneNumber_section.en}</span>
                    <div className="phone-input-container">
                        <FormControl fullWidth className="phone-code-dropdown" size="small">
                            <S.StyledAutocomplete
                            options={countryCodes ?? []} 
                            value={phoneCode} 
                            onChange={(
                                event: React.SyntheticEvent<Element, Event>,
                                value: unknown
                            ) => {
                                setPhoneCode(value as string ?? "");
                            }}
                            renderInput={(params: any) => (
                                <TextField
                                {...params}
                                variant="outlined"
                                size="small"
                                
                                />
                            )}
                            />
                        </FormControl>

                    <input
                        type="text"
                        name="phoneNumber"
                        value={userData?.phoneNumber}
                        onChange={handleInputChange}
                        //className='input-field'
                        className='input-field phone-field'
                    />
                    </div>
                </div>
                <div className='field-area'>
                    <span className='field-title'>{PROFILE_UI_STRINGS.my_preferences_section.en}</span>
                    <p className='preference-msg'>I want to receive communication through following channels</p>
                    {Object.keys(preferenceLabels).length > 0 ? (
                        <div className="checkbox-container">
                            {Object.entries(preferenceLabels).map(([code, label]) => (
                                <div key={code} className="checkbox-wrapper">
                                    <input
                                        id={code}
                                        type="checkbox"
                                        className="custom-checkbox"
                                        name={code}
                                        checked={!!userPreferences[code]}
                                        onChange={(e) => handlePreferenceChange(code, e.target.checked)}
                                    />
                                    <label htmlFor={code} className="checkbox-label">
                                        {label}
                                    </label>
                                </div>
                            ))}
                        </div>) : (
                        <div className='non-editable-mode'>
                            <span>{PROFILE_UI_STRINGS.no_profile_preferences.en}</span>
                        </div>
                    )}
                </div>
                <div className="save-profile-button-container">
                    <button className="save-profile-button" onClick={handleSaveDetails}>Save</button>
                </div>
            </div>
        </S.Content >
    );
};

export default EditProfilePage;

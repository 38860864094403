import { useEffect, useRef } from 'react';
import { YOUTUBE_VIDEO_URL } from './constants';

declare global {
  interface Window {
    YT: any;
    onYouTubeIframeAPIReady: () => void;
  }
}

const YoutubeVideoPlayer = ({ videoId, setIsVideoLoaded, fullScreen = true }: { videoId: string, setIsVideoLoaded: React.Dispatch<React.SetStateAction<boolean>>, fullScreen?: boolean }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const playerRef = useRef<any>(null);

  useEffect(() => {
    const container = containerRef.current;

    if (!container) return;

    const existingIframe = container?.querySelector('iframe');
    if (existingIframe) return;

    const iframe = document.createElement('iframe');
    const videoParams: { [key: string]: number } = {
      autoplay: fullScreen ? 1 : 0,
      mute: 1,
      controls: 1,
      loop: 1,
      enablejsapi: 1,
      rel: 0
    };
    const params = new URLSearchParams();
    for (const key in videoParams) {
      params.append(key, String(videoParams[key]));
    }
    let url = new URL(YOUTUBE_VIDEO_URL(videoId));
    url.search = params.toString();
    iframe.src = url.toString();
    if (fullScreen) iframe.allow = 'autoplay';
    iframe.setAttribute('donotallowfullscreen', '');
    iframe.style.cssText = `position:absolute;top:0;left:0;width:${fullScreen ? '100%' : '100vw'};height:${fullScreen ? '100%' : '89%'};border:none;`;
    container.appendChild(iframe);

    const handleResize = () => {
      iframe.style.width = `${window.innerWidth}px`;
      iframe.style.height = `${window.innerHeight}px`;
    };

    window.addEventListener('resize', handleResize);

    const onPlayerReady = (event: any) => {
      playerRef.current = event.target;
      setIsVideoLoaded(true)
    };

    const onYouTubeIframeAPIReady = () => {
      playerRef.current = new window.YT.Player(iframe, {
        events: {
          onReady: onPlayerReady
        }
      });
    };

    if (window.YT) {
      onYouTubeIframeAPIReady();
    } else {
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      const firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag?.parentNode?.insertBefore(tag, firstScriptTag);
      window.onYouTubeIframeAPIReady = onYouTubeIframeAPIReady;
    }

    return () => {
      if (playerRef.current) {
        try {
          const player = playerRef.current;
          const videoData = player.getVideoData();
          const currentTime = player.getCurrentTime();
          const duration = player.getDuration();
          sessionStorage.setItem('pdp_video_info', JSON.stringify({ title: videoData.title, duration, currentTime, videoUrl: iframe.src }));
        }
        catch (error) { console.error(error, 'Saving video details failed') }
      }
      window.removeEventListener('resize', handleResize);
    };
  }, [videoId]);

  return <div ref={containerRef} id="video-container"></div>;
};

export default YoutubeVideoPlayer;

import styled, { css } from "styled-components";
import PatternInGrey from '../../../../globals/images/Background/PatternInGrey.svg';
import { media } from "../../../../helpers/breakpoints";

export const Content = styled.div`
  height: 100vh;
  width: 100%;
  background-image: url(${PatternInGrey});
  background-size: cover; /* Ensures image covers the entire container */
  background-position: center; /* Centers the image horizontally and vertically */
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--white);
  overflow: hidden;

  .top-logo {
    position: absolute;
    top: 2rem;
    left: 50%;
    transform: translateX(-50%);
    width: calc(8.125rem * 2);
  }

  .ms-account-button {
    display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 7px 52px;
      gap: 40px;

      width: 370px;
      height: 50px;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      /* identical to box height */
      letter-spacing: 0.03em;

      /* White */
      color: #FFFFFF;

      background: #6F6855;
      border-radius: 100px;
      border: none;

      flex: none;
      order: 1;
      align-self: stretch;
      flex-grow: 0;
      @media screen and ${media.smallMobileAndUpMax2}{
        width:300px;
      }
      @media screen and ${media.largeMobileAndUpMax2}{
        width:270px;
      }
  }

  .title {
    width: 15.25rem;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 1.125rem;
    color: #4E4E4E;
    margin-bottom: 1rem;
  }

  .ms-account-button-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    position: absolute;
    top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    // width: 22rem;
  }

  .bottom-logo {
    position: absolute;
    width: 2rem;
    height: 2rem;
    bottom: 2.5rem;
    left: 2.5rem;
  }
`;

import React from "react";
import { ReactComponent as Cart } from "../../../globals/svg/cart_wl.svg";
import { ReactComponent as Cross } from "../../../globals/svg/remove_wishlist_item.svg";
import { WISHLIST_CONSTANT } from "./Constant";
import { formatPriceAndCurrency, getStaticConstantValue } from "../../../helpers/utlis";

interface wishlistcardProp {
  id: number;
  name?: string;
  price?: string;
  img?: string | undefined;
  sku?: string;
  moveToCart: (ids: number[]) => void;
  deleteItemFromWishList: (id: number | undefined) => void;
}

const WishlistCard: React.FC<wishlistcardProp> = ({
  name,
  price,
  img,
  sku,
  id,
  moveToCart,
  deleteItemFromWishList,
}) => {
  const productImageUrl = img ? ((process.env.REACT_APP_PDP_IMAGES_BASE_URL ?? "") + img) : (getStaticConstantValue("material_placeholder") ?? '')

  return (
    <div className="wishlist_card">
      <div className="image_section">
        <img
          src={productImageUrl}
          alt="img"
        />
        <div className="cross_icon" onClick={() => deleteItemFromWishList(id)}>
          <Cross />
        </div>
      </div>
      <div className="details_section">
        <div className="name-price">
          <p className="name">{name}</p>
          <p className="price">{`${formatPriceAndCurrency(Number(price))}`}</p>
        </div>
        <p className="category">{sku}</p>
      </div>
      <button className="add_to_cart_btn" onClick={() => moveToCart([id])}>
        <Cart />
        {WISHLIST_CONSTANT.Add_to_cart.en}
      </button>
    </div>
  );
};

export default WishlistCard;

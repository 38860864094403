import React, { useEffect, useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Tabs, Tab, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DocumentList from './document-list/DocumentList';
import VideoList from './video-list/VideoList';
import { TrainingResources } from '../../types/types';
import { doActionGet } from '../../../../../helpers/httpRequest';
import apiList, { USER_TYPE } from '../../../../../lib/constant';
import * as S from './styles';
import { TRAINING_RESOURCES_CONSTANTS } from '../../constant';

const TrainingMaterial: React.FC = () => {
    const [resources, setResources] = useState<TrainingResources[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [expanded, setExpanded] = useState<string | false>(false);
    const [tabValues, setTabValues] = useState<{ [key: string]: number }>({});
    const [categories, setCategories] = useState([]);

    //fetch Training Media Categories
    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await doActionGet({ url: apiList.getTrainingResourcesCategories, userType: USER_TYPE.Employee });

                if (response?.data) {
                    setCategories(response.data);
                }
            } catch (err) {
                setError(TRAINING_RESOURCES_CONSTANTS.failed_to_fetch_categories.en);
                console.error('Error fetching resources categories:', err);
            }
        }

        fetchCategories();
    }, []);

    useEffect(() => {
        const config = {
            url: apiList.getTrainingResources,
            userType: USER_TYPE.Employee
        };

        const fetchResources = async () => {
            try {
                const response = await doActionGet(config);
                if (response?.data) {
                    setResources(response.data.items);
                } else {
                    setError(TRAINING_RESOURCES_CONSTANTS.no_data_found.en);
                }
            } catch (err) {
                setError(TRAINING_RESOURCES_CONSTANTS.failed_to_fetch.en);
                console.error('Error fetching resources:', err);
            }
        };

        fetchResources();
    }, []);

    const handleAccordionChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleTabChange = (category: string) => (event: React.ChangeEvent<{}>, newValue: number) => {
        setTabValues({ ...tabValues, [category]: newValue });
    };

    return (
        <S.Content>
            {error ? (
                <Typography color="error">{error}</Typography>
            ) : (
                categories?.map((category) => (
                    <Accordion key={category} expanded={expanded === category} onChange={handleAccordionChange(category)}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} className="accordion-summary">
                            <Typography>{category}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Tabs
                                value={tabValues[category] || 0}
                                onChange={handleTabChange(category)}
                                variant="fullWidth"
                                centered
                                className="tab-container"
                            >
                                <Tab label="Documents" className="tab" />
                                <Tab label="Videos" className="tab" />
                            </Tabs>
                            <div className="tabs-content">
                                <TabPanel value={tabValues[category] || 0} index={0} className="tab-panel">
                                    <DocumentList resources={resources?.filter(resource => resource.category === category && resource.type === "Document")} />
                                </TabPanel>
                                <TabPanel value={tabValues[category] || 0} index={1} className="tab-panel">
                                    <VideoList resources={resources?.filter(resource => resource.category === category && resource.type === "Video")} />
                                </TabPanel>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                ))
            )}
        </S.Content>
    );
};

const TabPanel: React.FC<{ children: React.ReactNode; value: number; index: number; className?: string }> = ({ children, value, index, className }) => {
    return (
        <div role="tabpanel" hidden={value !== index} className={className}>
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
};

export default TrainingMaterial;
import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import apiList, { ADD_AMOUNT_HEADING, ADD_COMMENT_LABEL, ALERT_STATUS, ARE_YOU_SURE_WANT_TO_CLOSE_THE_DAY, CASH_AMOUNT_LABEL, CLOSE_CASH_REGISTERY, CLOSING_CASH_LABEL, CONFIRM_CLOSE_CASH_LABEL, CURRENCY_DENOMINATION_MISMATCH_ERROR, currencyDenomination, DATE_LABEL, DETAILS_HEADING, DETAILS_SUB_HEADING, OPEN_CASH_REGISTERY, OPENING_AMOUNT_LABEL, RECEIVED_CASH_BUTTON_LABEL, REMARK_LABEL, SOMETHING_WENT_WRONG, SUMMARY_HEADING, TOTAL_CREDIT_SALES_LABEL, TOTAL_SALES_OF_DAY, USER_TYPE } from "../../../lib/constant";
import { doActionGet, doActionPost } from "../../../helpers/httpRequest";
import { useNotification } from "../../../hooks/useNotification";
import { formatPriceAndCurrency, getStaticConstantValue } from "../../../helpers/utlis";
import ConfirmationModal from "../../atoms/confirmation-modal/ConfirmationModal";
import { setLocalStorage } from "../../../helpers/localStorageUtil";
import { CurrencyValues } from "./types/types";
import ReactToPrint from "react-to-print";
import * as S from "./styles/styles";
import PrintFooter from "../../atoms/print-footer/PrintFooter";
import PrintHeader from "../../atoms/print-header/PrintHeader";
const CloseCash: React.FC<{employeeId:string,shiftId:string,statusChangeHandler:(status:string)=>void}> = ({employeeId,shiftId,statusChangeHandler}) => {
    const [value, setValue] = useState<number | string>("");
    const { showAlert } = useNotification();
    const [reportData,setReportData] = useState<any>([]);
    const [showModal,setShowModal] = useState(false);
    const currency_symbol = getStaticConstantValue('currency_symbol');
    const componentRef = useRef<any>(null);
    const buttonRef = React.useRef<HTMLDivElement>(null);
    const [totalSalesOfTheDay ,setTotalSalesOfTheDay] = useState<number>(0);
    const [remark ,setRemark] = useState("");
    const [values, setValues] = useState<CurrencyValues>({
      '1000': '',
      '500': '',
      '200': '',
      '100': '',
      '50': '',
      '10': '',
      'Dirham': '',
      'Fils': ''
    });
    const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);

    const calculateTotal = (): number => {
      return Object.keys(values).reduce((total, key) => {
        
        const denomination = parseFloat(key);
        const quantity = parseFloat(values[key]) || 0;

        if (!isNaN(denomination)) {
          return total + denomination * quantity;
        }
        else if(key==="Dirham" || key==="Fils"){
          return total + quantity;
        }
        return total;
      }, 0);
    };
   
    useEffect(() => {
      const total = calculateTotal();
      if(reportData?.length>0){
        (total !== Number(reportData[0]?.total_cash_amount +reportData[0]?.initial_cash))?setIsButtonDisabled(true):setIsButtonDisabled(false);
      }
    }, [values,reportData]);
  
   
    const handleCurrencyChange = (e: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      setValues({
        ...values,
        [name]: value
      });
    };
  
    useEffect(()=>{
        if(employeeId){
          doActionGet({
            url: apiList.getShiftReport(Number(employeeId)),
            userType:USER_TYPE.Employee
          })?.then((resp: any) => {
            if(resp?.data && resp?.data?.length>0){
                setTotalSalesOfTheDay(resp?.data[0]?.total_cash_amount +resp?.data[0]?.total_online_amount);
                setReportData(resp.data);
            }
          }).catch((error: any) => {
            console.log("error msg", error);
            showAlert(SOMETHING_WENT_WRONG, ALERT_STATUS.error);
          })
        }
      },[employeeId])

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const inputValue = event.target.value;
      
      // Check if the input is a valid number
      if (!isNaN(Number(inputValue)) || inputValue === "") {
        setValue(inputValue);
      }
    };

    const getCurrentDate = (): string => {
        const today = new Date();
      
        const month = today.getMonth() + 1; // Months are 0-indexed
        const day = today.getDate();
        const year = today.getFullYear();
      
        const hours = today.getHours();
        const minutes = today.getMinutes();
        const seconds = today.getSeconds();
      
        return `${month}/${day}/${year} ${hours}:${minutes}:${seconds}`;
      };

      const closeCashHandler = () =>{
        setShowModal(false);
        doActionPost({
            url: apiList.closeDayCash,
            data:
            {
                "data": {
                    "employeeId": Number(employeeId),
                    "shiftId": Number(shiftId),
                    "remark":remark
                }

            },
            userType:USER_TYPE.Employee
          })?.then((resp: any) => {
            if (buttonRef && buttonRef.current) {
              buttonRef.current.click();
            }
            statusChangeHandler("CLOSED");
            setLocalStorage("shiftStatus","CLOSED");
          })?.catch((error: any) => {
            console.log("error msg", error);
            showAlert(error?.response?.data?.message, ALERT_STATUS.error);
          })
      } 

      const closeButtonCashHandler=()=>{
        if(isButtonDisabled){
          showAlert(CURRENCY_DENOMINATION_MISMATCH_ERROR, ALERT_STATUS.error);
        }
        else{
          setShowModal(true)
        }
      }
  
    return (
        <>
        <ConfirmationModal  isOpen={showModal} message={ARE_YOU_SURE_WANT_TO_CLOSE_THE_DAY} onConfirm={closeCashHandler} onCancel={()=>setShowModal(false)}/>
            <div  ref={componentRef}>
              <style>{S.globalPrintStyles}</style>
              <div className="print-header"><PrintHeader/></div>
              <div className="print-space">
              <div className="registery-new-print-heading">{"Hi "+getStaticConstantValue("employee_firstname")+" "+ getStaticConstantValue("employee_lastname")}</div>
              <div className="registery-heading">{CLOSE_CASH_REGISTERY}</div>
              <div className="cash-summary-section">
                  <div className="add-amount-heading">{SUMMARY_HEADING}</div>
                {reportData?.length>0 && <div className="summary-content-section">
                      <div className="summary-row">
                          <div className="summary-label">{DATE_LABEL}</div>
                          <div className="summary-label">{getCurrentDate()}</div>
                      </div>
                      <div className="summary-row">
                          <div className="summary-label">{OPENING_AMOUNT_LABEL}</div>
                          <div className="summary-label">{formatPriceAndCurrency(reportData[0]?.initial_cash,2)}</div>
                      </div>
                      <div className="summary-row">
                          <div className="summary-label">{CASH_AMOUNT_LABEL}</div>
                        { reportData[0]?.total_cash_amount<0? <div className="summary-label">-{formatPriceAndCurrency((reportData[0]?.total_cash_amount * (-1)),2)}</div>:<div className="summary-label">{formatPriceAndCurrency(reportData[0]?.total_cash_amount,2)}</div>}
                      </div>
                      <div className="summary-row">
                          <div className="summary-label">{TOTAL_CREDIT_SALES_LABEL}</div>
                          { reportData[0]?.total_online_amount<0? <div className="summary-label">-{formatPriceAndCurrency((reportData[0]?.total_online_amount * (-1)),2)}</div>:<div className="summary-label">{formatPriceAndCurrency(reportData[0]?.total_online_amount,2)}</div>}
                      </div>
                      <div className="summary-row">
                          <div className="summary-label">{TOTAL_SALES_OF_DAY}</div>
                          { totalSalesOfTheDay<0? <div className="summary-label">-{formatPriceAndCurrency(( totalSalesOfTheDay* (-1)),2)}</div>:<div className="summary-label">{formatPriceAndCurrency(totalSalesOfTheDay,2)}</div>}
                      </div>
                  </div>}
              </div>
              <div className="add-amount-heading close-amount">{CLOSING_CASH_LABEL}</div>
              <div className="input-box">
                  {reportData[0]?.total_cash_amount +reportData[0]?.initial_cash? <div className="input-section">
                      <span className="currency-symbol-section">{currency_symbol}</span>
                        <input
                          type="text"
                          value={reportData[0]?.total_cash_amount +reportData[0]?.initial_cash}
                          onChange={handleChange}
                          readOnly={true}
                          className="numberInput"
                        />
                      </div>:<></>}
              </div>
    
              <div className="amount-denomination">
              <div className="add-amount-main-heading">{DETAILS_HEADING}</div>
              <div className="add-amount-sub-heading">{DETAILS_SUB_HEADING}</div>
              <div className="currency-denomination">
                {currencyDenomination?.map((denomination) => (
                <div className="amount-desc" key={denomination}>
                  <label  className="amount-label" htmlFor={denomination}>{denomination}</label>
                  <input
                    type="number"
                    id={denomination}
                    name={denomination}
                    value={values[denomination]}
                    onChange={handleCurrencyChange}
                    className="amount-input"
                  />
                </div>
              ))}
              </div>
              <div className="add-amount-heading close-amount">{ADD_COMMENT_LABEL}</div>
              <div className="input-box">
                  {reportData[0]?.total_cash_amount +reportData[0]?.initial_cash? <div className="input-section">
                        <input
                          type="text"
                          value={remark}
                          onChange={(e)=>{setRemark(e.target.value)}}
                          className="commentInput"
                          placeholder="Enter comment"
                        />
                      </div>:<></>}
              </div>
              </div>
              </div>
              <div className="print-header"><PrintFooter/></div>
            </div>
            <ReactToPrint
              trigger={() => <div ref={buttonRef}></div>}
              content={() => componentRef.current}
            />
                <div className="button-box">
                    <button type="submit"  onClick ={closeButtonCashHandler} className={isButtonDisabled?"received-disabled-button-label":"received-button-label"}>{CONFIRM_CLOSE_CASH_LABEL}</button>
                </div>

        </>
  );
};

export default CloseCash;

import React, { useState } from "react";
import * as S from "../styles/styles";
import { ORDER_RECEIPT_INPUT_PLACEHOLDER, ORDER_RECEIPT_LABEL, PRINT_NOTE } from "../../../../lib/constant";
import { OrderReceiptProps } from "../types/types";
import CheckoutInput from "../checkout-input";
import { FormControl, RadioGroup, FormControlLabel, Radio } from "@mui/material";
import InformationIcon from '../../../../globals/svg/information-icon.svg';

const OrderReceipt: React.FC<OrderReceiptProps> = ({ orderReceiptToggleHandler, emailChangeHandler }) => {
    const [selectedValue, setSelectedValue] = useState<string | null>(null)

    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setSelectedValue(value);
        orderReceiptToggleHandler(value === "goGreen");
    };

    const emailFieldChangeHandler = (value: string) => {
        emailChangeHandler(value);
    }

    return (
        <S.Content className="receipt">
            <div className="receipt-toggler-section">
                <div className="payment-heading">
                    <div className="payment-heading-content">{ORDER_RECEIPT_LABEL}</div>
                </div>
                <FormControl component="fieldset">
                    <RadioGroup
                        aria-label="receipt-option"
                        name="receipt-option"
                        value={selectedValue || ''}
                        onChange={handleRadioChange}
                    >
                        <div className="print-section">
                            <FormControlLabel value="print" control={<Radio />} label="Print Receipt" />
                            <div className="tooltip"> <img src={InformationIcon} alt={"Information"}/>
                                <span className="tooltiptext">{PRINT_NOTE}</span>
                            </div>
                        </div>
                        <FormControlLabel value="goGreen" control={<Radio />} label="Go Green" />
                    </RadioGroup>
                </FormControl>
            </div>
            <div className="email-section">
                {selectedValue === "goGreen" && (
                    <CheckoutInput
                        placeHolder={ORDER_RECEIPT_INPUT_PLACEHOLDER}
                        onchangeHandler={emailFieldChangeHandler}
                    />
                )}
            </div>
        </S.Content>
    );
};

export default OrderReceipt;

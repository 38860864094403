import styled, { css } from "styled-components";

export const Content = styled.div<any>`
  ${(p: any) => {
    return css`
    .item-flex-container {
              display: flex;
              align-items: center;
          }
     .item-image {
              width: 75px;
              height: 75px;
              object-fit: cover;
              border-radius: 6px;
          }
     .item-details {
              flex-grow: 1;
              color: var(--grey-text);
              font-family: Poppins;
              font-size: 13px;
              font-style: normal;
              font-weight: 600;
              line-height: 18px;
          }

          .item .price {
              color: var(--grey-text);
          }
      .cart {
        display: flex;
        flex-direction: column;
      }
      .title-text {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: var(--grey-text);
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.8px;
      }

      .remove-all-items-button {
        width: 100%;
        align-self: center; /* Center the button horizontally */
        color: var(--sand-button-color);
        font-size: 12px;
        cursor: pointer;
        margin-top: 10px; /* Space between content and button */
      }
    
      .remove-all-items-button: hover {
        color: var(--sand-button-hover);
      }

      .cart-container {
        display: flex;
        flex-direction: column;
        padding: 15px 30px;
      }
      .item-container {
        padding: 30px 0;
        border-bottom: 2px solid var(--apply-button-color);
      }
      .item {
        display: flex;
        padding: 10px;
        border-radius: 6px;
        background: var(--white);
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
        margin-bottom:16px;
      }
      .item-image {
        width: 75px;
        height: 75px;
        object-fit: cover;
        border-radius: 6px;
      }
      .item-details {
        padding: 0 12px;
        display: flex;
        flex-direction: column;
        width: 50%;
        justify-content: space-around;
        align-items: flex-start;
      }
      .item-details .name {
        color: var(--sand);
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 18.2px;
        margin-bottom:5px;
      }

      .item-details .out-of-stock {
        color: var(--dark-black);
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 17.6px;
      }

      .item-details .sku {
        color: var(--sand);
        font-family: Poppins;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: 15px;
         margin-bottom:5px;
      }
      .item-details .price {
        color: var(--silver-foil);
        font-family: Poppins;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: 15px;
      }
      .item-quantity {
        display: flex;
        flex-direction: column;
        width: 25%;
        justify-content: center;
        justify-content: space-evenly;
        padding: 0 10px;
      }
      .item-quantity svg {
        align-self: center;
      }

      .item-child-container .item-flex-container {
        padding: 10px;
        box-sizing: border-box;
        border-radius: 4px;
        background: var(--white);
        // box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
        box-shadow: 0px 4px 18px rgba(111, 104, 85, 0.1);
        cursor: pointer;
    }

      .quantity-changer {
        display: flex;
        justify-content: space-around;
        border-radius: 4px;
        border: 1px solid rgba(0, 0, 0, 0.3);
      }
      .disabled {
        pointer-events: none;
        opacity: 0.5;
      }
      .quantity-button {
        color: var(--grey-text);
        text-align: right;
        font-family: Poppins;
        font-size: 11px;
        font-style: normal;
        font-weight: 700;
        line-height: 13px;
        align-self: center;
      }
      .quantity-button svg {
        height: 10px;
        width: 10px;
        font-weight: 700px;
      }
      .cost-info {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .cost-info div {
        color: var(--DARK-BLACK);
        height: 21px;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20.8px;
        width: 45%;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      span {
        width: 10%;
        text-align: end;
      }
      .cost-info p {
        color: var(--sand);
        text-align: right;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 17.6px;
        width: 45%;
      }
      
      .total-amount {
        border-top: 1px solid #c6c5c5;
        padding: 30px 0;
        display: flex;
        flex-direction: column;
        gap: 16px;
      }

      .total-amount .cost-info {
        height: 24px;
      }

      .total-amount p {
        color: var(--grey-text);
        text-align: right;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        width: 45%;
      }

      .cost-total {
        text-align: left !important;
      }
    .consent-section{
      margin-bottom:24px;
      display:flex;
      flex-direction:row;
      column-gap:17px;
    }
    .consent-checkbox{
      width:30px;
      height:30px;
    accent-color:var(--input-button-border-color);
    }
    .consent-text{
      font-weight:500;
      font-size:12px;
      line-height:18px;
      color:var(--sku-font-color);
    }
    .moveToCartBtn button:disabled {
      color:#B9B9B9;
      background:#F6F6F6;
  }
    .moveToCartBtn  {
      display:flex;
      justify-content:center;
  }
      .moveToCartBtn button {
        width: 280px;
        height: 50px;
        border-radius: 100px;
        padding: 7px 52px;
        font-weight: 600;
        font-size: 16px;
      }
  .variant-key{
    margin-bottom:20px;
  }
 
      
      .cost-total {
        text-align: left !important;
      }
      button {
        color: var(--white);
        text-align: center;
        font-family: Montserrat;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 25px;
        background: rgb(111, 104, 85);
        border-radius: 8px;
        border: none;
        padding: 5px;
        width: 50%;
        align-self: center;
      }
      .empty-cart {
        padding: 20px;
      }

      .coupon-section .coupon-title{
        font-size: 14px;
        font-weight: 500;
        line-height: 18.2px;
        color: var(--sand);
      }
      .coupon-field-and-button {
        display: grid;
        grid-template-columns: 2fr 0.7fr;
        gap: 16px;
        margin: 15px 0;
      }
      .coupon-button {
        display: flex;
        justify-content: end;
      }
      .coupon-inputbox {
        display: flex;
      }
      .coupon-input {
        font-family: poppins;
        width: 100%;
        height: 40px;
        padding: 0px 16px 0px 16px;
        background-color: var(--white);
        border-radius: 100px;
        border: 1px solid var(--silver-foil);
        color: var(--sand);
        font-weight: 400;
        size: 16px;
        line-height: 20.8px;
      }

      .apply-button {
        width: 100%;
        margin-left: 3%;
        height: 40px;
        padding: 0px 16px 0px 16px;
        border: 1px solid var(--sand);
        border-radius: 100px;
        background-color: var(--sand);
        color: var(--white);
      }
      .apply-button:disabled {
        color: var(--apply-button-disabled-color);
      }
        .item-child-container{
          margin-top: 20px;
          margin-bottom: 20px;
          margin-left: 38px;
          background-color: var(--sku-font-color);
          background: var(--white);
          box-shadow: rgba(0, 0, 0, 0.05) 8px 27px 25px 29px;
      }
    `;
  }}
`;

import styled, { css } from "styled-components";

export const Content = styled.div<any>`
  ${(p:any) => {
    return css`
      .header-content{
        display:flex;
        justify-content:center;
        border-bottom:1px solid #b6b2a6;
        padding:22px;
        
      }  
    
    `
  }}
`

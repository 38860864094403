
import styled, { css } from "styled-components";

export const Content = styled.div<any>`
  ${(p: any) => {
    return css`
      .accordion-text {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 110%;
        color: #1c1c1c;
      }
      .accordion {
        background: #ffffff;
        box-shadow: 0px 4px 18px rgba(111, 104, 85, 0.1);
        margin-top: 10px;
      }
      .profile {
        display: flex;
        padding: 10px;
        justify-content: flex-end;
        margin-top: 10px;
      }
    `;
  }}
`;

import CustomVideoPlayer from "../../molecules/customVideoPlayer";
import { CarouselMedia } from "./common/types";
import * as S from "./styles/carousel";

interface Props {
    media: CarouselMedia[];
    selectedMediaId: string;
}

const ImageViewer: React.FC<Props> = ({ media, selectedMediaId }) => {
    const selectedMedia = selectedMediaId ? (media?.filter(image => image.id === selectedMediaId)?.[0]) : media[0]
    return (
        <S.Content>
            {selectedMedia?.type === 'image' ?
                <div className="image-viewer">
                    <img
                        src={selectedMedia?.url}
                        alt={'carousel-img'}
                    />
                </div>
                :
                <div className={`${selectedMedia?.url?.includes('youtube') ? "image-viewer" : ""}`}>
                    <CustomVideoPlayer videoUrl={selectedMedia?.url} onSkipOrFinish={() => { }} fullScreen={false} />
                </div>
            } </S.Content>
    );
};

export default ImageViewer;
import React, { useEffect } from 'react';
import { Hub } from "aws-amplify/utils";
import { useNotification } from '../../../hooks/useNotification';
import { ALERT_STATUS } from '../../../lib/constant';
import { LOGOUT_CONSTANTS, LOGOUT_UI_CONSTANTS } from './constant';
import { performCustomerLogout } from '../../../helpers/authUtils';
import { Accordion, AccordionSummary } from '../../molecules/accordion/accordionHelpers';
import { useAuth } from '../../../Context/AuthContext';
import * as S from "./styles/styles";

const Logout: React.FC = () => {

    const { showAlert } = useNotification();
    const { logoutUser } = useAuth();

    useEffect(() => {
        const listener = Hub.listen('auth', ({ payload: { event } }) => {
            if (event === 'signedOut') {
                showAlert(LOGOUT_CONSTANTS.logout_success.en, ALERT_STATUS.success);
            }
        });
    }, []);

    const handleLogout = async () => {
        performCustomerLogout(showAlert);
        sessionStorage.removeItem('pdp_redirect_sku');
        sessionStorage.removeItem('videoShown');
        logoutUser();
    };

    return (<S.Content>
        <Accordion onChange={(event) => event.preventDefault()} className="accordion">
            <AccordionSummary onClick={handleLogout} expandIcon={<></>}>
                <div className="accordion-text">{LOGOUT_UI_CONSTANTS.logout_label.en}</div>
            </AccordionSummary>
        </Accordion></S.Content>
    );
};

export default Logout;
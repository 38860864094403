import React from "react";
import * as S from "./styles"
import logo from './../../../globals/svg/tresori-golden-logo.svg';

const PrintHeader: React.FC = () => {
  
  return (
    <S.Content>
          <div className="header-content" >
            <img className="header-app-logo" src={logo} alt="logo" />
          </div>
    </S.Content>
  );
};

export default PrintHeader;

import React, { useEffect, useState } from "react";
import * as S from "./styles/styles";
import coffeeIcon from './../../../globals/images/icons/eat-in-icon.svg';
import takeAwayIcon from './../../../globals/images/icons/take-away-icon.svg';
import { useNavigate } from "react-router-dom";
import DeliveryOption from "./delivery-option";
import apiList, { ALERT_STATUS, APPLY_LABEL, CART_BUTTON_LABEL, COUPONS, COUPON_LABEL, COUPON_SUCCESSFULLY_APPLIED_MSG, COUPON_SUCCESSFULLY_REMOVED_MSG, DELIVERY_INFO, EAT_IN_LABEL, INFORMATION_SUCCESSFULLY_SAVED_MSG, INVALID_COUPON_MSG, PLEASE_CHECK_TERMS_AND_CONDITIONS, PLEASE_FILL_TABLE_NO_BEFORE_PROCEEDING, PRODUCT_QTY_UPDATED_MSG, PRODUCT_REMOVED_SUCCESSFULLY, QUOTE_ITEMS, REMOVE_LABEL, SOMETHING_WENT_WRONG, TAKE_AWAY_LABEL, USER_TYPE, YOUR_ORDER_LABEL } from "../../../lib/constant";
import { getLocalStorage, setLocalStorage } from "../../../helpers/localStorageUtil";
import { doActionPost, doActionDelete, doActionGet, doActionPut } from "../../../helpers/httpRequest";
import { cartItemResponse, totalsSummaryType, totalsType } from "./types/types";
import ItemList from "./item-list";
import TotalsSummary from "../../molecules/totalsSummary/TotalsSummary";
import { Loading } from "../../atoms/loading/Loading";
import { useNotification } from "../../../hooks/useNotification";
import NoProductFound from "../../molecules/NotFound/NoProductFound";
import { EMPLOYEE_CHECKOUT_PAGE_ROUTE_PATH } from "../../../routes/routes-constant";
import EmployeeFooter from "../../molecules/employee-footer";
import { EMPLOYEE_LANDING_PAGE_ROUTE_PATH } from "../../../routes/routes-constant";
import { useParams } from 'react-router-dom';
import { isManager } from "../../../helpers/utlis";
import ConfirmationModal from "../../atoms/confirmation-modal/ConfirmationModal";


const EmployeeCart: React.FC<{ isManageOrder?: boolean }> = ({ isManageOrder = false }) => {
  const navigate = useNavigate();
  const { orderId } = useParams();
  const [eatIn, setEatIn] = useState<boolean>(true);
  const [eatInTableNumber, setEatInTableNumber] = useState<string>("");
  const [isAPICallRunning, setIsAPICallRunning] = useState<boolean>(false);
  const [productList, setProductList] = useState<cartItemResponse[]>([]);
  const [updatedProductList, setUpdatedProductList] = useState<cartItemResponse[]>([]);
  const [couponValue, setCouponValue] = useState<string>("");
  const [cartTotals, setCartTotals] = useState<totalsSummaryType>();
  const { showAlert } = useNotification();
  const [isCouponApplied, setIsCouponApplied] = useState<boolean>(false);
  const [isConsentGiven, setIsConsentGiven] = useState<boolean>(false);
  const [manageOrderDetails, setManageOrderDetails] = useState<any>({});
  const [showModal, setShowModal] = useState(false);

  const deliveryToggleHandler = (val: boolean, tableNote?: string) => {
    setEatIn(val);
    setEatInTableNumber(tableNote ?? "");
    setLocalStorage('EatIn', (val).toString());
    // toggleHandler(1,eatInTableNumber);
  }
  const deliveryTakeInToggleHandler = (val: boolean) => {
    setEatIn(!val);
    setLocalStorage('EatIn', (!val).toString());
    // toggleHandler(2);
  }

  useEffect(() => {
    if (getLocalStorage('EatIn') === "true") {
      setEatIn(true);
    }
    else if (getLocalStorage('EatIn') === "false") {
      setEatIn(false);
    }
  }, [])

  const toggleHandler = (infoType: number, infoNotes?: string) => {
    if(infoType===1 && infoNotes===""){
      showAlert(PLEASE_FILL_TABLE_NO_BEFORE_PROCEEDING, ALERT_STATUS.error);
    }
    else{
      const quoteId = getLocalStorage('quoteId');
      setIsAPICallRunning(true);
      doActionPost({
        url: apiList.getCartDetails + quoteId + "/" + DELIVERY_INFO,
        data:
        {
          "info": {
            "type": infoType,
            "notes": infoNotes
          }
        },
        userType: USER_TYPE.Employee
      })?.then((resp: any) => {
        showAlert(INFORMATION_SUCCESSFULLY_SAVED_MSG, ALERT_STATUS.success);
        //navigation on checkout page;
        navigate(EMPLOYEE_CHECKOUT_PAGE_ROUTE_PATH, { state: { cartTotal: cartTotals } });
      }
      ).catch((error: any) => {
        console.log("error msg", error);
        showAlert(SOMETHING_WENT_WRONG, ALERT_STATUS.error);
      }).finally(() => {
        setIsAPICallRunning(false);
      })
    }
   
  }

  useEffect(() => {
    if (isManageOrder) return
    const quoteId = getLocalStorage('quoteId');
    const fetchData = async () => {
      await fetchCartDetails(quoteId);
    }
    if (quoteId) { fetchData(); }

  }, [getLocalStorage('quoteId')])

  const fetchCartDetails = async (quoteId: string) => {
    setIsAPICallRunning(true);
    doActionGet({
      url: apiList.getCartDetails + quoteId,
      userType: USER_TYPE.Employee
    })?.then((resp: any) => {
      if (resp?.status === 403) {
        navigate(EMPLOYEE_LANDING_PAGE_ROUTE_PATH)
      }
      setProductList(resp?.data?.items);
      updateTotals(resp?.data?.extension_attributes?.cart_total);
      if (resp?.data?.extension_attributes?.coupon_string) {
        setIsCouponApplied(true);
        setCouponValue(resp?.data?.extension_attributes?.coupon_string);
      }
      setLocalStorage('Table Number', resp?.data?.extension_attributes?.delivery_info?.notes ?? "");
    }
    ).catch((error: any) => {
      console.log("error msg", error);
      if (error?.response?.status === 403) {
        navigate(EMPLOYEE_LANDING_PAGE_ROUTE_PATH)
      }
      showAlert(SOMETHING_WENT_WRONG, ALERT_STATUS.error);
    }).finally(() => {
      setIsAPICallRunning(false);
    })
  }



  const qtyHandler = (sku: string, add: boolean, qty: number) => {
    if (isManageOrder) return
    const quoteId = getLocalStorage('quoteId');
    if (add) {
      addProductToCart(quoteId, sku);
    }
    else if (!add && qty !== 1) {
      decreaseProductQtyFromCart(quoteId, sku);
    }
    else if (!add && qty === 1) {
      deleteProductFromCart(quoteId, sku);
    }
  }

  const addProductToCart = (quoteId: string, sku: string) => {
    setIsAPICallRunning(true);
    doActionPost({
      url: apiList.getCartDetails + quoteId + "/" + QUOTE_ITEMS,
      data:
      {
        "cartItem": {
          "sku": sku,
          "qty": 1,
          "quote_id": quoteId
        }
      },
      userType: USER_TYPE.Employee
    })?.then((resp: any) => {
      updateProductList(resp?.data);

      updateTotals(resp?.data?.extension_attributes?.cart_total);
      showAlert(PRODUCT_QTY_UPDATED_MSG, ALERT_STATUS.success);
    }
    ).catch((error: any) => {
      console.log("error msg", error);
      showAlert(SOMETHING_WENT_WRONG, ALERT_STATUS.error);
    }).finally(() => {
      setIsAPICallRunning(false);
    })
  }

  const updateProductList = (updatedItem: cartItemResponse, isDelete: boolean = false, sku: string = "") => {
    if (isDelete) {
      const index = productList.findIndex((item: cartItemResponse) => {
        return item.sku === sku;
      });
      if (index > -1) {
        productList.splice(index, 1);
        setProductList([...productList]);
        setLocalStorage('cartItems', JSON.stringify([...productList]));
      }
    }
    else {
      const newUpdatedProductList = productList.map((item: cartItemResponse) => {
        if (updatedItem.sku === item.sku) {
          item = { ...item, qty: updatedItem?.qty, item_id: updatedItem?.item_id };
        }
        return item;
      })
      setProductList([...newUpdatedProductList])
      setLocalStorage('cartItems', JSON.stringify([...newUpdatedProductList]));
    }
  }

  const couponHandler = () => {
    const quoteId = getLocalStorage('quoteId');
    if (!isCouponApplied) {
      setIsAPICallRunning(true);
      doActionPut({
        url: apiList.getCartDetails + quoteId + "/" + COUPONS + couponValue,
        userType: USER_TYPE.Employee
      })?.then((resp: any) => {
        updateTotals(resp?.data);
        setIsCouponApplied(true);
        showAlert(COUPON_SUCCESSFULLY_APPLIED_MSG, ALERT_STATUS.success);
      }
      ).catch((error: any) => {
        console.log("error msg", error);
        showAlert(INVALID_COUPON_MSG, ALERT_STATUS.error);
      }).finally(() => {
        setIsAPICallRunning(false);
      })
    }
    else {
      setIsAPICallRunning(true);
      doActionDelete({
        url: apiList.getCartDetails + quoteId + "/" + COUPONS,
        userType: USER_TYPE.Employee
      })?.then((resp: any) => {
        updateTotals(resp?.data);
        setIsCouponApplied(false);
        setCouponValue("");
        showAlert(COUPON_SUCCESSFULLY_REMOVED_MSG, ALERT_STATUS.success);
      }
      ).catch((error: any) => {
        console.log("error msg", error);
        showAlert(INVALID_COUPON_MSG, ALERT_STATUS.error);
      }).finally(() => {
        setIsAPICallRunning(false);
      })
    }
  }

  const decreaseProductQtyFromCart = (quoteId: string, sku: string) => {
    var productItems: cartItemResponse[] = [];
    productItems = productList.filter((item: cartItemResponse) => {
      if (item?.sku === sku) {
        return item;
      }
    })
    if (productItems.length > 0 && productItems[0]?.qty > 0) {
      const itemId = productItems.length > 0 ? productItems[0]?.item_id : "";
      const currentQuantity = productItems.length > 0 ? productItems[0]?.qty : 0;
      setIsAPICallRunning(true);
      doActionPut({
        url: apiList.getCartDetails + quoteId + "/" + QUOTE_ITEMS + itemId,
        data:
        {
          "cartItem": {
            "item_id": itemId,
            "sku": sku,
            "qty": currentQuantity ? (currentQuantity > 0 ? currentQuantity - 1 : 0) : 0,
            "quote_id": quoteId
          }
        },
        userType: USER_TYPE.Employee
      })?.then((resp: any) => {
        updateProductList(resp?.data);
        updateTotals(resp?.data?.extension_attributes?.cart_total);
        showAlert(PRODUCT_QTY_UPDATED_MSG, ALERT_STATUS.success);
      }
      ).catch((error: any) => {
        console.log("error msg", error);
        showAlert(SOMETHING_WENT_WRONG, ALERT_STATUS.error);
      }).finally(() => {
        setIsAPICallRunning(false);
      })
    }
  }

  const deleteProductFromCart = (quoteId: string, sku: string) => {
    var productItems: cartItemResponse[] = [];
    productItems = productList.filter((item: cartItemResponse) => {
      if (item?.sku === sku) {
        return item;
      }
    })
    if (productItems.length > 0 && productItems[0]?.qty > 0) {
      const itemId = productItems.length > 0 ? productItems[0]?.item_id : "";
      setIsAPICallRunning(true);
      doActionDelete({
        url: apiList.getCartDetails + quoteId + "/" + QUOTE_ITEMS + itemId,
        userType: USER_TYPE.Employee
      })?.then((resp: any) => {
        updateProductList(resp?.data, true, sku);
        updateTotals(resp?.data);
        showAlert(PRODUCT_REMOVED_SUCCESSFULLY, ALERT_STATUS.success);
      }
      ).catch((error: any) => {
        console.log("error msg", error);
        showAlert(SOMETHING_WENT_WRONG, ALERT_STATUS.error);
      }).finally(() => {
        setIsAPICallRunning(false);
      })
    }
  }


  const updateTotals = (cartTotals: any[]) => {
    if (cartTotals?.length > 0) {
      setCartTotals({
        subtotal: getTotalValue(cartTotals, 'subtotal')?.value,
        tax: getTotalValue(cartTotals, 'tax')?.value,
        grandTotal: getTotalValue(cartTotals, 'grand_total')?.value,
        couponName: getTotalValue(cartTotals, 'discount')?.title,
        couponAmount: getTotalValue(cartTotals, 'discount')?.value
      })
      setLocalStorage("grand_total", getTotalValue(cartTotals, 'grand_total')?.value);
    }
  }

  const getTotalValue = (cartTotals: any, code: string) => {
    var totalItem = cartTotals.filter((item: totalsType) => (item.code === code));
    return totalItem.length > 0 ? totalItem[0] : [];
  }

  const handleCheckout = () => {
    // if(isConsentGiven)
    // {
    if (eatIn) {
      toggleHandler(1, getLocalStorage('Table Number'));
    }
    else {
      toggleHandler(2);
    }
    // }
    // else{
    //   setShowModal(true);
    // }

  }

  const getOrderDetails = async (id: number) => {
    if (typeof id !== "number") {
      console.error("Invalid orderId")
      return
    }
    setIsAPICallRunning(true);
    try {
      const response = await doActionGet({
        url: apiList.manageOrderDetails + id,
        userType: USER_TYPE.Employee,
      })
      if (response?.data) {
        setManageOrderDetails(response?.data)
        setProductList(response?.data?.items);
      }
    } catch (error) {
      console.error(error)
    }
    finally {
      setIsAPICallRunning(false);
    }

  }

  useEffect(() => {
    if (isManageOrder) {
      getOrderDetails(Number(orderId))
    }
  }, [isManageOrder, orderId])

  return (
    <S.Content>
      {isAPICallRunning && <Loading />}
      <ConfirmationModal isOpen={showModal} message={PLEASE_CHECK_TERMS_AND_CONDITIONS} onConfirm={() => setShowModal(false)} onCancel={() => setShowModal(false)} />
      {productList?.length !== 0 && <div className="delivery-section">
        <DeliveryOption isManageOrder={isManageOrder} icon={coffeeIcon} deliveryName={EAT_IN_LABEL} deliveryInputNote={true} deliveryEnable={isManageOrder ? manageOrderDetails?.extension_attributes?.delivery_info?.type === 1 : eatIn} onChangeHandler={deliveryToggleHandler} tableNumber={isManageOrder ? manageOrderDetails?.entity_id : ""} />
        <DeliveryOption isManageOrder={isManageOrder} icon={takeAwayIcon} deliveryName={TAKE_AWAY_LABEL} deliveryInputNote={false} deliveryEnable={isManageOrder ? manageOrderDetails?.extension_attributes?.delivery_info?.type !== 1 : !eatIn} onChangeHandler={deliveryTakeInToggleHandler} />
      </div>}
      <div className="order-item-list-section">
        <h5 className="order-header">{YOUR_ORDER_LABEL}</h5>
        <div className="item-section">
          {productList?.length > 0 && productList.map((item: any, index: number) => {
            return (
              <ItemList
                key={index}
                sku={item.sku}
                qty={isManageOrder ? item.qty_ordered : item.qty}
                name={item.name}
                price={isManageOrder ? item?.row_total_incl_tax : item.price}
                qtyHandler={qtyHandler}
                isManageOrder={isManageOrder}
                inStock={Boolean(item?.extension_attributes?.in_stock ?? true)}
              />)
          })}
          {!isAPICallRunning && productList?.length === 0 && <NoProductFound />}
        </div>
      </div>
      {productList?.length !== 0 && <div className="coupon-section">
        {/* <div className="coupon-label">
          {COUPON_LABEL}
        </div> */}
        <div className="coupon-inputbox">
          <input placeholder={COUPON_LABEL} className="coupon-input" type="text" value={isManageOrder ? manageOrderDetails?.coupon_code : couponValue} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCouponValue(e.target.value)} disabled={isCouponApplied || productList?.length === 0}></input>
        </div>
        <div className="coupon-button">
          <button
            className="apply-button"
            onClick={couponHandler}
            disabled={couponValue === "" || productList?.length === 0 || isManageOrder}
          >
            {isCouponApplied ? REMOVE_LABEL : APPLY_LABEL}
          </button>
        </div>
      </div>}
      <div className="summary-section">
        {cartTotals && <TotalsSummary subtotal={cartTotals?.subtotal} tax={cartTotals.tax} grandTotal={cartTotals.grandTotal} couponAmount={cartTotals.couponAmount} couponName={cartTotals.couponName}></TotalsSummary>}
        {isManageOrder && productList?.length !== 0 && <TotalsSummary subtotal={manageOrderDetails?.subtotal} tax={manageOrderDetails?.tax_amount} couponAmount={manageOrderDetails?.discount_amount} couponName={manageOrderDetails?.coupon_code ? `Offer (${manageOrderDetails?.coupon_code})` : ""} grandTotal={manageOrderDetails?.grand_total}></TotalsSummary>}
      </div>

      {/* {!isManageOrder && (<div className="consent-section">
        <input className="consent-checkbox" type="checkbox" checked={isConsentGiven}
          onChange={() => { setIsConsentGiven(isConsentGiven => !isConsentGiven) }} />
        <label className="consent-text">{TERMS_AND_CONDITIONS_TEXT}</label>
      </div>)} */}
      <div className="cart-footer">
        {productList?.length !== 0 && !isManageOrder && (
          <EmployeeFooter isDisabled={false} label={CART_BUTTON_LABEL} checkoutHandler={handleCheckout} />
        )}
      </div>

      {Boolean(isManager() && manageOrderDetails?.extension_attributes?.employee_id) && (() => {
        const { employee_name, employee_id, employee_email } = manageOrderDetails?.extension_attributes || {};
        return (
          <div className="employee-info">
            <h3>Employee Information</h3>
            <div className="employee-name">{employee_name}</div>
            <div className="employee-email">{employee_email}</div>
            <div className="employee-id">Employee ID : {employee_id}</div>
          </div>
        );
      })()}
    </S.Content>
  );
};

export default EmployeeCart;
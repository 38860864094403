import React, { createContext, useContext, useReducer, ReactNode } from 'react';
import { ACTION_TYPE } from './Constant';


interface DataAction {
  type: string;
  payload: any;
}
interface componentProps {
  children: ReactNode;
}


const initialState: any = {
  cart_item_count: 0,
  show_wishlist_icon: false
};
export const DataContext = createContext<{
  state: any;
  dispatch: React.Dispatch<DataAction>;
}>({
  state: initialState,
  dispatch: () => undefined,
});

export const useAppContext = () => useContext(DataContext);

const dataReducer = (state: any, action: DataAction) => {
  switch (action.type) {
    case ACTION_TYPE.set_variant:
      return { ...state, productVariant: action.payload };
    case ACTION_TYPE.set_current_PDP:
      return { ...state, current_pdp: action.payload }
    case ACTION_TYPE.increment_cart_item_count:
      return { ...state, cart_item_count: (state.cart_item_count + action.payload?.count) }
    case ACTION_TYPE.decrement_cart_item_count:
      return { ...state, cart_item_count: (state.cart_item_count - action.payload?.count) }
    case ACTION_TYPE.set_item_count:
      return { ...state, cart_item_count: action.payload?.count }
    case ACTION_TYPE.set_wishlist_data:
      return { ...state, wishlist_data: action.payload }
    case ACTION_TYPE.show_wishlist_icon:
      return { ...state, show_wishlist_icon: action.payload }
    case ACTION_TYPE.set_notification_data:
      return { ...state, notification_data: action.payload }
    default:
      return state;
  }
};

export const DataProvider: React.FC<componentProps> = ({ children }) => {
  const [state, dispatch] = useReducer(dataReducer, initialState);
  return (
    <DataContext.Provider value={{ state, dispatch }}>
      {children}
    </DataContext.Provider>
  );
};

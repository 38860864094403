import React, { useState } from 'react';
import { List, ListItem, ListItemText, Link, Dialog, DialogContent, Typography } from '@mui/material';
import { PlayCircleOutline } from '@mui/icons-material';
import VideoPlayer from '../../../../../../helpers/VideoPlayer/VideoPlayer';
import { VideoListProps } from '../../../types/types';
import * as S from './styles/styles';
import { TRAINING_RESOURCES_CONSTANTS } from '../../../constant';
import { dialogStyle } from '../../../styles/styles';

const VideoList: React.FC<VideoListProps> = ({ resources }) => {
  const [open, setOpen] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState<string | null>(null);
  const [isExternal, setIsExternal] = useState<boolean>(false);

  const buildUrl = (filePath: string) => {
    return `${process.env.REACT_APP_TRAINING_RESOURCES_URL}${filePath}`;
  };

  const handleOpenExternalUrl = (externalUrl: string | undefined) => {
    if (externalUrl) {
      window.open(externalUrl, '_blank', 'noopener,noreferrer');
      return;
    }
  };

  const handleOpenFilePath = (filePath: string | undefined) => {
    if (filePath) {
      setSelectedVideo(buildUrl(filePath));
      setIsExternal(false);
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedVideo(null);
    setIsExternal(false);
  };

  const getFileName = (filePath: string) => {
    return filePath?.split('/').pop();
  };

  return (
    <S.Content>
      {resources?.length === 0 ? (
        <Typography>{TRAINING_RESOURCES_CONSTANTS.no_videos_found.en}</Typography>
      ) : (
        <>
          <List>
            {resources?.map((video, index) => (
              <ListItem key={index} className="listItem">
                <ListItemText
                  primary={
                    <span className='title'><PlayCircleOutline className="iconStyle" /> {video?.file_path ?  getFileName(video?.file_path) : video?.name}</span>}
                  secondary={
                    <span className="video-list">
                      {video?.file_path && (
                        <Link
                          className="file-link"
                          onClick={() => handleOpenFilePath(video?.file_path)}
                        >
                          <span className='view-cta'>view</span>
                        </Link>
                      )}
                      {video?.external_url && (
                        <Link
                          className="file-link"
                          onClick={() => handleOpenExternalUrl(video?.external_url)}
                        >
                          <span className='link'>Link : </span>{video?.external_url}
                        </Link>
                      )}
                    </span>
                  }
                />
              </ListItem>
            ))}
          </List>
          <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
            <DialogContent className="dialog-content" sx={dialogStyle}>
              {selectedVideo && (
                <VideoPlayer src={selectedVideo} />
              )}
            </DialogContent>
          </Dialog>
        </>
      )}
    </S.Content>
  );
};

export default VideoList;

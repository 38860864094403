import styled from "styled-components";

export const Content = styled.div`
    .tab-container {
        display: flex;
        justify-content: space-between;
    }

    .tab {
        flex: 1;
    }

    .accordion-summary {
        display: flex;
        justify-content: space-between;
    }

    .listItem {
        align-items: flex-start;
    }

    .iconStyle {
        margin-right: 8px;
    }

`
import { Tab, Tabs } from "@mui/material";
import styled, { css } from "styled-components";

export const AppWrapper = styled.div`
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  font-family: 'Poppins';
  .search-input-container {
        position: relative;
        width: 95%;
        display: flex;
        align-items: center;
        height: 40px;
        margin: 20px auto;
      }

      .search-icon {
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
        width: 16px;
        height: 16px;
        color: #6F6855;
        pointer-events: none;
      }

      .search-input {
        flex-grow: 1;
        box-sizing: border-box;
        width: 100%;
        padding: 10px 40px;
        border: 1px solid #ccc;
        border-radius: 40px;
        font-size: 16px;
        outline: none;
        gap: 10px;
      }

      .search-input::placeholder {
        font-weight: 400;
        font-size: 15px;
        line-height: 15px;
      }

.input_search{
    background:#FBFBFB;
    width: 100%;
    border: none;
    height: 50px;
    border-radius: 100px;
    margin-bottom: 10px;
    font-size: 14px; 
    line-height:21px;
    font-weight:500;
    padding:0 25px;
    box-sizing: border-box;
}
.input_search:focus{
    boder:none;
    outline:none;
}
.search_bar {
    position: relative;
}
.search_bar .search-icon {
    position: absolute;
    top: 50%;
    left:0;
    transform: translateY(-60%);
    fill: #999;
    width: 16px;
    height: 20px;
}
.search-icon svg{
    width:16px !important;
    height:20px !important;
    circle{
      stroke:#B9B9B9;
    }
    path{
      fill:#B9B9B9;
    }
  }
.search_bar .clear-icon{
    position: absolute;
    top: 50%;
    right:10px;
    transform: translateY(-60%);
    fill: #999;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    
}
.search_bar .disable svg{
    path {
        fill:#B9B9B9;
      }
}
.clear-icon svg{
    width:13px !important;
    height:18px !important;
    circle{
      stroke:#B9B9B9;
    }
    path{
        fill:#000000;
    }
  }
`;

export const Accordian = styled.div`
  border-radius:10px;
  .main-section{
  width:150px;
  }
  .is-expanded{
  border-top-left-radius:10px;
    border-top-right-radius:10px;
    background-color:#6F6855;
  color:white;
  margin-right:30px;
  font-weight: 600 !important;
  font-size: 16px;
  line-height: 20.8px;
  width:150px;
  }
  .not-expanded{
  border-radius:10px;
  background-color:#6F6855;
  color:white;
  margin-right:30px;
  font-weight: 600 !important;
  font-size: 16px;
  line-height: 20.8px;
  width:150px;
  }
`;

export const FilterTitle = styled.div`
    font-weight: 600 !important;
  font-size: 16px;
  line-height: 20.8px;
  margin-right:10px;
`;

export const Filters = styled.div`
  background-color:#6F6855;
   color:white;
   border-bottom-left-radius:10px;
   border-bottom-right-radius:10px;
    .checkbox-section{
    display:flex;
    flex-direction:column;
    margin-right:30px;
    column-gap:5px;
    margin-left:2px;
      font-weight: 600 !important;
  font-size: 16px;
  line-height: 20.8px;
    }
    .input-box{
   margin-left: 15px;
    margin-right: 13px;
    margin-top: 11px;
    background-color:transparent;
    outline:2px solid #fff;
    accent-color:var(--sand-button-color);
    
    
    }
    .address-list-section{
    width:150px;
    }
    .custom-checkbox{
        width: 16px;
    height: 16px;
    border: 1px solid rgb(111, 104, 85);
    border-radius: 4px;
    appearance: none;
    outline: none;
    cursor: pointer;
    position: relative;
    margin-right: 8px;
    background-color: rgb(255, 255, 255);
    }

     .custom-checkbox:checked::before {
    content: "";
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: rgb(111, 104, 85);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
`;

export const FilterRoot = styled.div`
.address-list-data{
 padding:0 !important;
 position: absolute;
 z-index:1;
}
 
`;

export const StickyHeader = styled.div`
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 1;
  .order-header{
  display:flex;
      justify-content: space-between;
    align-items: center;
        padding-left: 22px;
    padding-right: 18px;
    padding-bottom: 20px;
  }
`;

export const ScrollableContent = styled.div`
  flex: 1;
  overflow-y: auto;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 30px;
  position: absolute;
  width: 430px;
  height: 130px;
  left: 0px;
  top: 0px;
`;

export const StatusBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
  width: 100%;
  height: 82px;
`;

export const Logo = styled.div`
  width: 116px;
  height: 28px;
  background-color: #6F6855;
  /* You'd replace this with an actual logo image */
`;

export const OrderTiles = styled.h2`
  font-weight: 500;
  font-size: 16px;
  line-height: 110%;
  color: #6F6855;
  margin: 0px;
  padding: 16px 30px;
`;

export const OrdersTitle = styled.div`
  .enabled-title{
  font-weight: 500;
  font-size: 16px;
  line-height: 110%;
  color: #6F6855;
  margin: 0px;
  // padding: 16px 30px;
  }
  .disabled-title{
   font-size: 16px;
  line-height: 110%;
  color: #6F6855;
  margin: 0px;
  // padding: 16px 30px;
   font-weight: 500;
   opacity:50%;
  }

`;

export const OrdersCategoryTitle = styled.h2`
  font-weight: 400;
  font-size: 16px;
  line-height: 20.8px;
  color: #1C1C1C;
  margin: 0px;
  padding: 16px 30px;
`;

export const StyledTabs = styled(Tabs)`
  & .MuiTabs-indicator {
    display: flex;
    justify-content: center;
    background-color: transparent;
  }

  & .MuiTabs-indicatorSpan {
    max-width: 64px;
    width: 100%;
    background-color: #6F6855;
    height: 4px;
    border-radius: 6px 6px 6px 6px;
  }
`;

export const StyledTab = styled(Tab)`
  text-transform: none !important;
  font-size: 13px !important;
  font-weight: 500;
  line-height: 19.5px;
  min-width: 92.5px !important;
  padding: 12px 16px !important;
  padding: 8px 0px !important;

  &.Mui-selected {
    // font-weight: bold;
  }
`;

export const TabPanelContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const OrderCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 30px;
  gap: 16px;
  background: #FFFFFF;
  box-shadow: 0px 4px 18px rgba(111, 104, 85, 0.1);
`;

export const OrderHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const OrderTitle = styled.h3`
  font-weight: 500;
  font-size: 18px;
  line-height: 110%;
  color: #1C1C1C;
  margin: 0;
`;

export const OrderDate = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 110%;
  color: #6F6855;
  margin: 0;
`;

export const OrderDetails = styled.div`
   width: 100%;
   display: flex;
   flex-direction: column;
   gap: 8px;
`;

export const OrderDetailsWrapper = styled.div`
  display: flex;
  // justify-content: space-between;
  // align-items: center;
  flex-direction:column;

`;

export const FilterSection = styled.div`
  display: flex;
  flex-direction:row;
  justify-content: space-between;
  margin-right:30px;
`;

export const OrderStatusContainer = styled.div`
  // display: flex;
  // align-items: center;
  .order-status-section{
   display: flex;
  flex-direction:row;
  justify-content: space-between;
  }
.order-employee-section{
margin-top:5px;
margin-bottom:5px;
}
`;

export const OrderStatusLabel = styled.span`
  font-weight: 500;
  margin-right: 5px;
`;

export const OrderStatusValue = styled.span`
  color: #B6B2A6;
`;

export const PaymentStatus = styled.span`
  color: #B6B2A6;
`;

export const OrderSummary = styled.span`
  font-size: 14px;
  color: #6F6855;
  display:flex;
  justify-content:space-between;
`;

export const ProductDetails = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const ProductLabel = styled.h5`
  font-weight: 500;
  font-size: 16px;
  line-height: 110%;
  color: var(--dark-black);
  margin: 0;
`

export const ProductList = styled.div`
  width: 100%;
`;

export const Product = styled.div`
  display: flex;
  gap: 16px;
  padding-bottom: 8px;
  border-bottom: 0.4px solid #B6B2A6;
  margin-bottom: 8px;
`;

export const ProductImage = styled.div`
  width: 80px;
  height: 66px;
  background: url(/api/placeholder/80/66);
  object-fit: cover;
  border-radius: 8px;

  img {
    width: 80px;
    height: 66px;
    background: url(/api/placeholder/80/66);
    object-fit: cover;
    border-radius: 8px;
  }
`;


export const ProductInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ProductName = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  color: #6F6855;
  margin: 0;
`;

export const ProductPrice = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  color: #B6B2A6;
  margin: 4px 0;
`;

export const ProductQuantity = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  color: #6F6855;
  margin: 0;
`;

export const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
`;

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  width: 130px;
  height: 31px;
  font-weight: 500;
  font-size: 10px;
  line-height: 15px;
  text-align: center;
  border-radius: 10px;
  cursor: pointer;
`;

export const PrimaryButton = styled(Button)`
  background: #6F6855;
  color: #FFFFFF;
  border: none;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.05);
`;

export const SecondaryButton = styled(Button)`
  background: #FFFFFF;
  color: #6F6855;
  border: 1px solid #6F6855;
`;

export const SecondaryCancelButton = styled(Button)`
  background: #FFFFFF;
  color: #6F6855;
  border: 1px solid #6F6855;
  border-radius:23px;
`;

export const Content = styled.div<any>`
  ${(p:any) => {
    return css`
      .copy-section{
    font-weight:500;
    font-size:10px;
    line-height:15px;
    color:#6F6855;
    }
    .copy-section {
  display: inline-block;
  position: relative;
}

.copy-section::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 100%;
  border-bottom: 1px solid #000;
  }
    `
}}
`

export const PaginationContent = styled.div<any>`
${(p:any) => {
    return css`
      .paginationContainer{
        justify-content: center;
        display: flex;
        margin-top: 20px;
        margin-bottom:40px;
      }
     `
}}
`

import * as SDCCore from 'scandit-web-datacapture-core';
import * as SDCBarcode from 'scandit-web-datacapture-barcode';
import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { ALERT_STATUS, SCANDIT_CONFIG } from '../lib/constant';
import { useNotification } from '../hooks/useNotification';
import { SCANNER_ERROR_MESSAGES } from '../components/organisms/scannerPage/constant';
import { PRODUCT_PAGE_ROUTE_PATH } from '../routes/routes-constant';
import { qr_view_item_gtm } from './GTM';

const Scanner = () => {
  const navigate = useNavigate();
  const { showAlert } = useNotification();
  const location = useLocation();

  const { user, specialOrderQuoteId } = location?.state ?? {};
 
  useEffect(() => {
    let context;
    let barcodeCapture;
    let camera;

    async function runScanner() {
      try {
        await SDCCore.configure({
          licenseKey: SCANDIT_CONFIG.license_key,
          libraryLocation: SCANDIT_CONFIG.library_location,
          moduleLoaders: [SDCBarcode.barcodeCaptureLoader()],
        });

        context = await SDCCore.DataCaptureContext.create();

        camera = SDCCore.Camera.default;
        await context.setFrameSource(camera);

        const settings = new SDCBarcode.BarcodeCaptureSettings();
        settings.enableSymbologies([
          SDCBarcode.Symbology.Code128,
          SDCBarcode.Symbology.Code39,
          SDCBarcode.Symbology.QR,
          SDCBarcode.Symbology.EAN8,
          SDCBarcode.Symbology.UPCE,
          SDCBarcode.Symbology.EAN13UPCA,
        ]);

        const symbologySetting = settings.settingsForSymbology(SDCBarcode.Symbology.Code39);
        symbologySetting.activeSymbolCounts = [
          7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
        ];

        barcodeCapture = await SDCBarcode.BarcodeCapture.forContext(context, settings);
        await barcodeCapture.setEnabled(false);

        barcodeCapture.addListener({
          didScan: async (barcodeCapture, session) => {
            try {
              await barcodeCapture.setEnabled(false);
              const barcode = session.newlyRecognizedBarcodes[0];
              const symbology = new SDCBarcode.SymbologyDescription(barcode.symbology);
              showResult(barcode.data, symbology.readableName);
              await barcodeCapture.setEnabled(true);
            } catch (error) {
              console.error('Error during barcode capture:', error);
              showAlert(error.message || SCANNER_ERROR_MESSAGES.barcode_capture_failed.en, ALERT_STATUS.error);
            }
          },
        });

        const view = await SDCCore.DataCaptureView.forContext(context);
        view.connectToElement(document.getElementById('data-capture-view'));
        view.addControl(new SDCCore.CameraSwitchControl());

        const barcodeCaptureOverlay = await SDCBarcode.BarcodeCaptureOverlay.withBarcodeCaptureForViewWithStyle(
          barcodeCapture,
          view,
          SDCBarcode.BarcodeCaptureOverlayStyle.Frame
        );

        const viewfinder = new SDCCore.RectangularViewfinder(
          SDCCore.RectangularViewfinderStyle.Square,
          SDCCore.RectangularViewfinderLineStyle.Light
        );

        await barcodeCaptureOverlay.setViewfinder(viewfinder);

        try {
          await camera.switchToDesiredState(SDCCore.FrameSourceState.On);
          await barcodeCapture.setEnabled(true);
        } catch (error) {
          if (error.message === 'Permission denied') {
            showAlert('Camera permission denied. Please allow camera access to use the scanner.', ALERT_STATUS.error);
          } else {
            console.error('Error switching camera state:', error);
            showAlert(error.message || SCANNER_ERROR_MESSAGES.setup_error.en, ALERT_STATUS.error);
          }
        }
      } catch (error) {
        console.error('Error setting up the scanner:', error);
        showAlert(error.message || SCANNER_ERROR_MESSAGES.setup_error.en, ALERT_STATUS.error);
      }
    }

    function showResult(data, symbology) {
      if (data) {
        qr_view_item_gtm(data);
        // if(specialOrderQuoteId && user){
  
          const productSkuPath = data?.split("/product/");
          const productsku = (productSkuPath?.length>0)?productSkuPath[1]:"";
          const productPagePath = PRODUCT_PAGE_ROUTE_PATH.replace(':sku', productsku);
          navigate(productPagePath, { state: { user: user, specialOrderQuoteId: specialOrderQuoteId}});
        // }
        // else{
        //   window.location.href = data;
        // }
       // navigate(productPagePath, { state: { user: user, specialOrderQuoteId: specialOrderQuoteId}});
      }
    }

    runScanner().catch((error) => {
      console.log(error);
      console.error('Unhandled error:', error);
      showAlert(error.message || SCANNER_ERROR_MESSAGES.unexpected_error.en, ALERT_STATUS.error);
    });

    return () => {
      // Clean up resources when the component is unmounted
      const cleanup = async () => {
        try {
          if (camera) {
            try {
              await camera.switchToDesiredState(SDCCore.FrameSourceState.Off);
            } catch (error) {
              // Handle the error if the camera is already in the desired state or any other error
              console.error('Error switching camera state during cleanup:', error);
            }
          }
          if (barcodeCapture) {
            try {
              await barcodeCapture.setEnabled(false);
            } catch (error) {
              // Handle the error if the barcode capture is already disabled or any other error
              console.error('Error disabling barcode capture during cleanup:', error);
            }
          }
          if (context) {
            try {
              await context.dispose();
            } catch (error) {
              // Handle the error if the context is already disposed or any other error
              console.error('Error disposing context during cleanup:', error);
            }
          }
        } catch (cleanupError) {
          console.error('Error during cleanup:', cleanupError);
          showAlert(cleanupError.message || SCANNER_ERROR_MESSAGES.cleanup_error.en, ALERT_STATUS.error);
        }
      };
      cleanup();
    };
  }, [navigate, showAlert]);

  return (
    <div id="data-capture-view"></div>
  );
};

export default Scanner;

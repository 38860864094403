import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#6F6855', // custom color
            dark: '#5a5647' // Darker variant of custom color for the hover state
        },
        common: {
            white: '#F3EDED',
        },
        text: {
            primary: '#4E4E4E',
        }
    },
    components: {//Used in AddressForm Components for Address in My Profile to match the remaining input fields border
        MuiOutlinedInput: { // Target the OutlinedInput component
          styleOverrides: {
            root: {
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                border: '1px solid #A0A0A0', // Custom focus color
              },
            },
          },
        },
      },
});

export default theme;

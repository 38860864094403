import styled, { css } from "styled-components";

export const Content = styled.div<any>`
  ${(p: any) => {
    return css`
      .wishlist-icon {
        padding: 0 16px;
      }

      .active {
        color: #6f6855;
        stroke: black;
        path {
          fill: #6f6855;
        }
      }
      .in-active {
        color: white;
        stroke: #6f6855;
      }
    `;
  }}
`;

import { ENQUIRY_STATUSES } from "../../components/organisms/assistance/constants";

export const requestAssistance_gtm = ({
  request_ID = "",
  email = "",
  cognito_user_id = "",
}) => {
  window?.dataLayer?.push({
    event: "request_assitance",
    assistance: {
      details: {
        email,
        request_ID,
      },
      status: ENQUIRY_STATUSES.NEW,
      cognito_user_id,
    },
  });
};

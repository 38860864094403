import AWS from 'aws-sdk';

export const AWSConfig = {
  region: `${process.env.REACT_APP_COGNITO_REGION}`,
  IdentityPoolId: `${process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID}`,
  AccountId: `${process.env.REACT_APP_COGNITO_ACCOUNT_ID}`,
  RoleArn: `${process.env.REACT_APP_COGNITO_ROLE_ARN}`,
  Logins: {
    [`${process.env.REACT_APP_COGNITO_USER_POOL_RESOURCE_URL}`] : '',
  }, 
};

AWS.config.update({ region: AWSConfig.region });
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  IdentityPoolId: AWSConfig.IdentityPoolId,
  AccountId: AWSConfig.AccountId,
  RoleArn: AWSConfig.RoleArn,
  Logins: AWSConfig.Logins,
});

export const COGNITO_CONFIG = {
  clientId: `${process.env.REACT_APP_COGNITO_MS_AD_CLIENT_ID}`,
  redirectUri: `${process.env.REACT_APP_MS_AD_REDIRECT_URI}`,
  cognitoDomain: `${process.env.REACT_APP_USER_POOL_COGNITO_DOMAIN}`,
  idpIdentifier: `${process.env.REACT_APP_IDP_IDENTIFIER}`
};

export const API_ENDPOINTS = {
  TEMP_CREDENTIALS: `${process.env.REACT_APP_COGNITO_TEMP_CREDENTIALS}`,
};

const cognitoLoginParams = new URLSearchParams({
  response_type: 'token',
  client_id: COGNITO_CONFIG.clientId,
  redirect_uri: COGNITO_CONFIG.redirectUri,
  identity_provider: COGNITO_CONFIG.idpIdentifier,
});

export const Login_URL = `${COGNITO_CONFIG.cognitoDomain}/oauth2/authorize?${cognitoLoginParams.toString()}`;

// Construct the logout URL for AWS Cognito
const cognitoLogoutParams = new URLSearchParams({
  client_id: COGNITO_CONFIG.clientId,
  logout_uri: COGNITO_CONFIG.redirectUri,
  });

export const COGNITO_LOGOUT_URL = `${COGNITO_CONFIG.cognitoDomain}/logout?${cognitoLogoutParams.toString()}`;
export const  microsoftLogoutUri =  `https://login.microsoftonline.com/${process.env.REACT_APP_MS_AD_TENANT_ID}/oauth2/logout`;

export const COGNITO_MSAD_LOGOUT_URL = `${microsoftLogoutUri}?post_logout_redirect_uri=${encodeURIComponent(COGNITO_LOGOUT_URL)}`;

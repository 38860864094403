import React from "react";
import * as S from "../styles/styles";
import { cartItemList } from "../types/types";
import binIcon from '../../../../globals/svg/delete.svg';
import { formatPriceAndCurrency } from "../../../../helpers/utlis";


const ItemList: React.FC<cartItemList> = ({ sku, qty, name, price, qtyHandler, isManageOrder = false, inStock }) => {
  return (
    <S.Content>
      <div className={`cart-list-items ${inStock ? '' : 'no-stock'}`}>
        <div className="item-name-container">
          {!inStock && <span className="out-of-stock">Out of Stock</span>}
          <span className={`item-name ${inStock ? '' : 'no-stock'}`}>{name}</span>
          <div className="item-sku">{sku}</div>
        </div>
        <section className="button_section">
          <button
            className="quantity_button"
            onClick={() => qtyHandler(sku, false, qty)}
            disabled={!inStock || (!isManageOrder && qty === 1)}
          >
            -
          </button>
          <div className="value_div"> {qty}</div>
          <button
            className="quantity_button"
            onClick={() => qtyHandler(sku, true, qty)}
            disabled={!inStock}
          >
            +
          </button>
        </section>
        <h6 className="item-price">{formatPriceAndCurrency(price)}</h6>
        {!isManageOrder && <div className="item-delete"><img className="item-bin" onClick={() => qtyHandler(sku, false, 1)} src={binIcon}></img></div>}
      </div>
    </S.Content>
  );
};

export default ItemList;

import React, { useRef, useEffect } from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import 'videojs-youtube';
import './videojs-icons.css';
import * as S from './styles';

interface VideoPlayerProps {
  src: string;
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({ src }) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);

  // Function to normalize YouTube URLs
  const getYouTubeId = (url: string) => {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = url.match(regExp);
    return match && match[2].length === 11 ? match[2] : null;
  };

  useEffect(() => {
    const youTubeId = getYouTubeId(src);
    const videoType = youTubeId ? 'video/youtube' : undefined;
    const videoSrc = youTubeId ? `https://www.youtube.com/watch?v=${youTubeId}` : src;

    const player = videoRef.current && videojs(videoRef.current, {
      controls: true,
      autoplay: false,
      preload: 'auto',
      techOrder: ['youtube', 'html5'],
      sources: [
        {
          src: videoSrc,
          type: videoType || 'video/mp4',
        },
      ],
    });

    if (player) {
      if (!videoType) {
        player.src([
          { src, type: 'video/mp4' },
          { src, type: 'video/webm' },
          { src, type: 'video/ogg' },
          { src, type: 'video/x-matroska' }, // For .mkv files
        ]);
      }
    }

    return () => {
      if (player) {
        player.dispose();
      }
    };
  }, [src]);

  return (
    <S.Content>
      <div data-vjs-player className="video-container">
        <video ref={videoRef} className="video-js vjs-big-play-centered custom-video-player" />
      </div>
    </S.Content>
  );
};

export default VideoPlayer;

import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as S from './styles/styles';
import { doActionPut } from '../../../../../helpers/httpRequest';
import { removeLocalStorage } from '../../../../../helpers/localStorageUtil';
import apiList, { ALERT_STATUS, USER_TYPE } from '../../../../../lib/constant';
import { useNotification } from '../../../../../hooks/useNotification';
import { Address, CustomerDetails } from '../types/types';
import { LOGIN_ROUTE_PATH, MY_ACCOUNT_ADD_NEW_ADDRESS_PAGE_ROUTE_PATH } from '../../../../../routes/routes-constant';
import { AxiosError } from 'axios';
import { SHIPPING_ADDRESS_MESSAGES, SHIPPING_ADDRESS_UI } from './constants';
import { ADDRESS_MESSAGES } from '../constants';
import { Loading } from '../../../../atoms/loading/Loading';
import { Loader } from '../styles/styles';
import AddressCardWithMenuItems from '../shared-components/address-card/AddressCardWithMenuItems';
import { getAttribute } from '../helpers/utils';
import { fetchAddresses, fetchCustomerDetails } from '../helpers/dataProvider';
import { DATA_PROVIDER_MESSAGES } from '../helpers/constants';
import { getCountries } from '../helpers/api-calls/apiCalls';
import { manageIndexedDB } from '../../../../../helpers/indexedDB';

const ShippingAddressListPage = () => {
    const navigate = useNavigate();
    const { showAlert } = useNotification();

    const [addresses, setAddresses] = useState<Address[]>([]);
    const [customerDetails, setCustomerDetails] = useState<CustomerDetails>();
    const [currentAddress, setCurrentAddress] = useState<Address | null>(null);
    const [countryList, setCountryList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoader, setIsLoader] = useState<Boolean>(false);
    const location = useLocation();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    useEffect(() => {
        getAddressList();
        getCustomerDetails();
        getCountryList();
    }, []);

    useEffect(() => {
        if (location.state?.customerDetails) {
            const customerData = location.state.customerDetails;
            setCustomerDetails(customerData);
        }
    }, [location.state]);

    const getAddressList = async () => {
        setIsLoading(true);
        const addressData = await fetchAddresses(showAlert, navigate);
        if (addressData) {
            setAddresses(addressData);
        } else {
            showAlert(DATA_PROVIDER_MESSAGES.fetch_addresses_fail.en, ALERT_STATUS.error);
        }
        setIsLoading(false);
    };

    const getCustomerDetails = async () => {
        setIsLoading(true);
        const customerData = await fetchCustomerDetails(showAlert, navigate);
        if (customerData) {
            setCustomerDetails(customerData);
        } else {
            showAlert(DATA_PROVIDER_MESSAGES.fetch_user_data_fail.en, ALERT_STATUS.error);
        }
        setIsLoading(false);
    };
    
    const getCountryList = async () => {
        try {
            setIsLoading(false);
            const countryList = await getCountries(showAlert);
            setCountryList(countryList);
        } catch (err) {
            console.log(err);
        } finally {
            setIsLoading(false);
        }

    }


    const handleMenuClick = (event: React.MouseEvent<HTMLElement>, address: Address) => {
        setAnchorEl(event.currentTarget);
        setCurrentAddress(address);
    };

    const handleSetAsDefaultShipping = () => {
        if (currentAddress && currentAddress.id) {
            handleSetAsDefaultAddress(currentAddress.id, 'Shipping');
        } else {
            console.error(SHIPPING_ADDRESS_MESSAGES.no_address_selected_shipping);
        }
    }

    const handleSetAsDefaultBilling = () => {
        if (currentAddress && currentAddress.id) {
            handleSetAsDefaultAddress(currentAddress.id, 'Billing');
        } else {
            console.error(SHIPPING_ADDRESS_MESSAGES.no_address_selected_billing);
        }
    }

    const handleSetAsDefaultAddress = async (selectedAddressId: number, type: 'Billing' | 'Shipping') => {
        
        const updatedAddresses = addresses.map(address => ({
            ...address,
            default_billing: type === 'Billing' ? address.id === selectedAddressId : address.default_billing,
            default_shipping: type === 'Shipping' ? address.id === selectedAddressId : address.default_shipping,
            firstname: getAttribute(address.extension_attributes?.address_encryption_attribute_data, 'firstname'),
            lastname: getAttribute(address.extension_attributes?.address_encryption_attribute_data, 'lastname'),
            street: address.extension_attributes?.address_encryption_attribute_data?.find(attr => attr.attribute_code === 'street')?.value.split(',') || [''],
            telephone: getAttribute(address.extension_attributes?.address_encryption_attribute_data, 'telephone'),
            // extension_attributes: undefined 
        }));

        await updateAddresses(updatedAddresses, type);
    };


    const updateAddresses = async (updatedAddresses: Address[], type: 'Billing' | 'Shipping') => {
        const payload = {
            customer: {
                email: customerDetails?.encrypted_email,
                firstname: customerDetails?.firstname,
                lastname: customerDetails?.lastname,
                addresses: updatedAddresses
                // addresses: updatedAddresses.map(({ extension_attributes, ...rest }) => rest)
            }
        };
        
        try {
            setIsLoader(true);
            await doActionPut({ url: apiList.customerDetails, userType: USER_TYPE.Customer, data: payload });
            setAddresses(updatedAddresses);
            showAlert(`${type} ${SHIPPING_ADDRESS_MESSAGES.address_update_success.en}`, ALERT_STATUS.success);
        } catch (error) {
            const axiosError = error as AxiosError;
            console.error(`Failed to update ${type} address:`, axiosError.message);
            showAlert(axiosError.response?.status === 401 ? ADDRESS_MESSAGES.please_log_in.en : `Failed to set default ${type.toLowerCase()} address. Please try again later.`, ALERT_STATUS.error);
        } finally {
            setIsLoader(false);
        }
    };

    const handleEditAddress = () => {
        if (currentAddress) {
            navigate(`/edit-address/${currentAddress.id}`, {
                state: {
                    currentAddressDetails: currentAddress,
                    allAddresses: addresses,
                    customerDetails: customerDetails,
                    addressType: 'Shipping',
                    isEditing: true
                }
            });
        } else {
            console.error(SHIPPING_ADDRESS_MESSAGES.no_address_selected_edit.en);
        }
    };

    const handleRemoveAddress = async (addressId: number | undefined) => {
    
        const updatedAddresses = addresses.filter(addr => addr.id !== addressId).map(addr => {

            // const { extension_attributes, ...otherAddressDetails } = addr;

            return {
                ...addr,
                firstname: addr.extension_attributes?.address_encryption_attribute_data?.find(attr => attr.attribute_code === 'firstname')?.value || '',
                lastname: addr.extension_attributes?.address_encryption_attribute_data?.find(attr => attr.attribute_code === 'lastname')?.value || '',
                street: addr.extension_attributes?.address_encryption_attribute_data?.find(attr => attr.attribute_code === 'street')?.value.split(',') || [''],
                telephone: addr.extension_attributes?.address_encryption_attribute_data?.find(attr => attr.attribute_code === 'telephone')?.value || '',
            }
        });
        
        const payload = {
            customer: {
                email: customerDetails?.encrypted_email,
                firstname: customerDetails?.firstname,
                lastname: customerDetails?.lastname,
                addresses: updatedAddresses
            }
        };

        try {
            setIsLoader(true);
            await doActionPut({ url: apiList.customerDetails, userType: USER_TYPE.Customer, data: payload });
            setAddresses(updatedAddresses);
            showAlert(ADDRESS_MESSAGES.address_removed_success.en, ALERT_STATUS.success);
        } catch (error) {
            const axiosError = error as AxiosError; // Type assertion to handle errors from Axios
            console.error('Failed to fetch user Data:', axiosError.message);
            showAlert(axiosError.response?.status === 401 ? ADDRESS_MESSAGES.please_log_in.en : ADDRESS_MESSAGES.failed_remove_addresses.en, axiosError.response?.status === 401 ? 'info' : ALERT_STATUS.error);
            if (axiosError.response?.status === 401) {
                removeLocalStorage('user-token');
                manageIndexedDB({ key: 'userToken', toDelete: true })
                navigate(LOGIN_ROUTE_PATH);
            }
        } finally {
            setIsLoader(false);
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <S.Content>
            {isLoader && <Loading />}
            <div className="shipping-address-title">{SHIPPING_ADDRESS_UI.shipping_address_title.en}</div>
            <S.AddNewButton onClick={() => navigate(MY_ACCOUNT_ADD_NEW_ADDRESS_PAGE_ROUTE_PATH, { state: { allAddresses: addresses, customerDetails: customerDetails, addressType: 'Shipping', isEditing: false } })}>{SHIPPING_ADDRESS_UI.add_new.en} +</S.AddNewButton>
            <div className='address-count-container'>
                {addresses.length > 0 && (
                    <div className='address-count'>
                        {addresses.length} {SHIPPING_ADDRESS_UI.saved_addresses.en}
                    </div>
                )}
            </div>
            {isLoading ? <Loader size={25} /> : addresses.map((address, index) => (

                <AddressCardWithMenuItems
                    key={index}
                    address={address}
                    handleMenuClick={handleMenuClick}
                    anchorEl={anchorEl}
                    open={open}
                    handleClose={handleClose}
                    currentAddress={currentAddress}
                    handleEditAddress={handleEditAddress}
                    handleRemoveAddress={handleRemoveAddress}
                    handleSetAsDefaultShipping={handleSetAsDefaultShipping}
                    handleSetAsDefaultBilling={handleSetAsDefaultBilling}
                    addressType='shipping'
                    countryList={countryList}
                />
            ))}
        </S.Content>
    );
};

export default ShippingAddressListPage;
import styled from "styled-components";

export const Content = styled.div`
.menu-item{
  font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 110%;
    color: rgb(28, 28, 28);
}
`
import React, { createContext, useContext, useState, ReactNode } from 'react';
import { getCountryNameById } from '../components/organisms/my-account/my-profile/helpers/dataProvider';

interface CountryContextType {
    countries: { [key: string]: string };
    getCountryName: (countryId: string) => Promise<string>;
}

interface CountryProviderProps {
    children: React.ReactNode;
}

export const CountryContext = createContext<CountryContextType>({
    countries: {},
    getCountryName: async () => 'No Country Found'  // Default function
});

export const CountryProvider: React.FC<CountryProviderProps> = ({ children }) => {
    const [countries, setCountries] = useState<{ [key: string]: string }>({});

    const getCountryName = async (countryId: string): Promise<string> => {
        if (!countries[countryId]) {
            const name = await getCountryNameById(countryId);
            if (name) {
                setCountries(prev => ({ ...prev, [countryId]: name }));
            }
            return name || 'Unknown Country';
        }
        return countries[countryId];
    };

    return (
        <CountryContext.Provider value={{ getCountryName, countries }}>
            {children}
        </CountryContext.Provider>
    );
};

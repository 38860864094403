
import { ReactNode } from "react";
import * as S from "./styles"

const IconWithCount = ({ icon, count }: { icon: ReactNode, count: number }) => {
    return (
        <S.Content>
            <div className="icon-container">
                {icon}
                {count > 0 && <span className="count">{count}</span>}
            </div></S.Content>
    );
};

export default IconWithCount;

import styled, { css } from "styled-components";

export const Content = styled.div<any>`
  ${(p:any) => {
    return css`
    height: var(--main-content-height);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transform: translateY(0); /* Remove the translateY to avoid shifting the container */

    .scan-product-title {
      position: absolute;
      top: 15vh;
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 30px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 0.05em;
      color: var(--text-dark);
    }

      .scanner-container {
        max-width: 600px;
        // width: 250px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: calc(10px + 2vmin);
        color: white;
        max-height:600px;
        // height: 250px;
        margin :auto;
        border-radius: 5px;
        border: 3px solid var(--sand-button-color);
        }

      
        #data-capture-view {
          width: 250px;
          height: 250px;
          margin: auto;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 5px;
          border: 3px solid var(--sand-button-color);
          box-shadow: 0 2px 8px 4px var(--sand-button-color);
        }
     
      }
    `
  }}
`

export const updatePersonalInfo_gtm = (info: any) => {
    window?.dataLayer?.push({
        event: "update_info",
        data: info,
    });
};

export const addNewAddress_gtm = (address: any) => {
    window?.dataLayer?.push({
        event: "adding_address",
        data: address,
    });
};

export const editAddress_gtm = (address: any) => {
    window?.dataLayer?.push({
        event: "edit_address",
        data: address,
    });
};

export const removeAddress_gtm = (address: any) => {
    window?.dataLayer?.push({
        event: "remove_address",
        data: address,
    });
};

export const defaultBillingShippingAddress_gtm = (address: any) => {
    window?.dataLayer?.push({
        event: "default_billing_shipping",
        data: address,
    });
};
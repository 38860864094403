export const INITIAL_PAGE_ROUTE_PATH = "/";
export const LOGIN_ROUTE_PATH = "/login";
export const SIGNUP_ROUTE_PATH = "/sign-up";
export const LOGIN_WITH_OTP_PATH="/otp-login";
export const OTP_CONFIRMATION_PATH="/otp-confirmation";
export const SCAN_ROUTE_PATH = "/scanner";
export const HOME_PAGE_ROUTE_PATH = "/home";
export const MY_QUOTES_PAGE_ROUTE_PATH="/my-quotes";
export const EMPLOYEE_LOGIN_ROUTE_PATH = "/employee-login";
export const PLP_CAFETERIA_MENU_PAGE_ROUTE_PATH = "/plp/cafeteria";
export const EMPLOYEE_LANDING_PAGE_ROUTE_PATH = "/employee-home";
export const TRAINING_RESOURCES_ROUTE_PATH = "/training";
export const EMPLOYEE_CART_PAGE_ROUTE_PATH = "/cafeteria-cart";
export const EMPLOYEE_CHECKOUT_PAGE_ROUTE_PATH = "/cafeteria-checkout";
export const ORDER_CONFIRMATION_PAGE_ROUTE_PATH = "/cafeteria-order-success";
export const CAFETERIA_CASH_REGISTERY = "/cash-registery";
export const EMPLOYEE_PROFILE_PATH = "/employee-profile";
export const FURNITURE_CHECKOUT_PATH = "/checkout-address";
export const REQUEST_ASSISTANCE_PATH = "/request-assistance";
export const ENQUIRY_DETAILS_PATH = "/enquiry-details";
export const ASSISTANCE_PATH = "/assistance";
export const EMPLOYEE_PATH = "/employee";
export const FURNITURE_DELIVERY_PATH = "/checkout-delivery-options";
export const PRODUCT_PAGE_ROUTE_PATH = "/product/:sku";
export const MY_ACCOUNT_ROUTE_PATH = "/my-account";
export const WISHLIST_ROUTE_PATH = "/wishlist";
export const ORDER_DETAILS_ROUTE_PATH = "/order/:orderId";
export const CUSTOMER_ORDER_DETAILS_ROUTE_PATH = "/cutomer-order/:orderId";
export const ORDERS_ROUTE_PATH = "/orders";
export const CUSTOMER_ORDERS_ROUTE_PATH = "/customer-orders";
export const MY_ACCOUNT_MY_ADDRESSES_LIST_PAGE_ROUTE_PATH = "/my-addresses";
export const MY_ACCOUNT_EDIT_PROFILE_PAGE_ROUTE_PATH = "/edit-profile";
export const MY_ACCOUNT_BILLING_ADDRESS_LIST_PAGE_ROUTE_PATH =
  "/billing-address-list";
export const MY_ACCOUNT_ADD_NEW_ADDRESS_PAGE_ROUTE_PATH = "/add-new-address";
export const MY_ACCOUNT_EDIT_ADDRESS_PAGE_ROUTE_PATH =
  "/edit-address/:addressId";
export const MY_ACCOUNT_SHIPPING_ADDRESS_LIST_PAGE_ROUTE_PATH =
  "/shipping-address-list";
export const CART_PAGE_PATH = "/cart";
export const CHECKOUT_ORDER_CONFIRMATION_PAGE = "/checkout-order-summary";
export const ORDER_SUCCESS_PAGE = "/order-success";
export const ORDER_FAILURE_PAGE = "/order-failure";
export const CHECKOUT_PAYMENT_PATH = "/checkout-payment";
export const CHECKOUT_PAYMENT_CARD_FORM_PATH = "/checkout-payment-card-form";
export const SPECIAL_ORDER_CUSTOMER_LIST_SCREEN_ROUTE_PATH =
  "/special-order-customer-list";
export const SPECIAL_ORDER_CUSTOMER_CART_SCREEN_ROUTE_PATH =
  "/special-order-cart";
export const MANAGE_ORDER = "/manage-order";
export const VIEW_ORDER = "/manage-order/:orderId";
export const VIEW_EMPLOYEE_ORDER_DETAILS_ROUTE_PATH = "/employee-order-details"
export const VIEW_EMPLOYEE_ORDER_DETAILS = "/employee-order-details/:orderId"
export const APPROVAL_PENDING_ORDER_ROUTE_PATH = "/pending-orders";
export const EMPLOYEE_DASHBOARD_ROUTE_PATH = "/employee-dashboard";
export const CUSTOMER_PENDING_PAYMENT_ROUTE_PATH = "/pay-pending-amount";
export const NOTIFICATION_LISTING_ROUTE_PATH = "/notification-inbox";
export const LOGIN_TERMS_AND_CONDITION_ROUTE_PATH =
  "/login-terms-and-condition";
export const LOGIN_PRIVACY_POLICY_ROUTE_PATH = "/login-privacy-policy";
export const NOTIFICATION_PERMISSION_PATH = "/notification-permission";

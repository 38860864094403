import React, { useEffect, useState } from "react";
import * as S from "../styles/styles";
import { deliveryOptionProps } from "../types/types";
import lineIcon from "../../../../globals/svg/line.svg";
import ToggleSwitch from "../../../atoms/toggleSwitch/ToggleSwitch";
import { getLocalStorage, setLocalStorage } from "../../../../helpers/localStorageUtil";
import { EAT_IN_LABEL, TABLE_NOTES_PLACEHOLDER } from "../../../../lib/constant";


const DeliveryOption: React.FC<deliveryOptionProps> = ({ icon, deliveryName, deliveryInputNote, deliveryEnable, onChangeHandler, isManageOrder = false, tableNumber = "" }) => {
  const [tableNote, setTableNote] = useState<string>("");

  // const toggleSwitchHandler=(isEnable:boolean)=>{
  //   onChangeHandler(isEnable,tableNote);
  // }

  useEffect(() => {
    setTableNote(getLocalStorage('Table Number'))
    if (isManageOrder) setTableNote(`Tabel Number  ${tableNumber}`)
  }, [getLocalStorage('Table Number'), isManageOrder, tableNumber])

  const handleRadioChange = (isSelected: boolean) => {
    onChangeHandler(isSelected, tableNote);
  };

  return (
    <S.Content>
      <div className={deliveryInputNote ? "delivery-content" : "delivery-content-non-input"}>
        <div className="delivery-input-radio">
          <input
            type="radio"
            id={`radio-${deliveryName}`}
            name="deliveryOption"
            checked={deliveryEnable}
            onChange={() => handleRadioChange(true)}
            disabled={isManageOrder}
          />
        </div>
        <div className="delivery-icon"><img className="header-back-arrow" src={icon}></img></div>
        <div className="delivery-line"><img className="header-back-arrow" src={lineIcon}></img></div>
        <div className="delivery-text">{deliveryName}</div>
        {/* <div className={deliveryInputNote ? "delivery-input-area" : "delivery-hidden-input-area"}><input placeholder={TABLE_NOTES_PLACEHOLDER} className={deliveryInputNote ? "delivery-input" : "delivery-hidden-input"} type="text" value={tableNote} onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setTableNote(e.target.value); setLocalStorage('Table Number', e.target.value) }}></input> </div> */}
        {deliveryInputNote && (
          <div className="delivery-input-area">
            <input
              placeholder={TABLE_NOTES_PLACEHOLDER}
              className="delivery-input"
              type="text"
              value={tableNote}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setTableNote(e.target.value);
                setLocalStorage('Table Number', e.target.value);
              }}
            />
          </div>
        )}
        {/* <div className="delivery-input-toggler"><ToggleSwitch isLocked={isManageOrder} label={deliveryName} onchangeHandler={toggleSwitchHandler} value={deliveryEnable}/></div> */}
      </div>
    </S.Content>
  );
};

export default DeliveryOption;

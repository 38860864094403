import { media } from "../../../helpers/breakpoints"
import styled from "styled-components"

export const View = styled.div`
.container {
    text-align: center;
  }
  .toggle-switch {
    position: relative;
    width: 50px;
  }
  .checkbox {
    display: none;
  }
  .label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 0 solid #bbb;
    border-radius: 20px;
  }
  .inner {
    display: block;
    // width: 200%;
    margin-left: -100%;
    transition: margin 0.2s ease-in 0s;
  }
  .inner:before,
  .inner:after {
    float: left;
    width: 50%;
    height: 25px;
    padding: 0;
    line-height: 36px;
    color: #fff;
    font-weight: bold;
    box-sizing: border-box;
  }
  .inner:before {
    content: "";
    padding-left: 10px;
    background-color: #E3E2DE;
    color: #fff;
  }
  .inner:after {
    content: "";
    padding-right: 10px;
    background-color: #E3E2DE;
    color: #fff;
    text-align: right;
  }
  .switch {
    display: block;
    width: 18px;
    height:18px;
    margin-top: 3.5px;
    background: #6F6855;
    position: absolute;
    top: 0;
    bottom: 0;
    border: 0 solid #bbb;
    border-radius: 20px;
    transition: all 0.3s ease-in 0s;
  }
  .checkbox:checked + .label .inner {
    margin-left: 0;
  }
  .checkbox:checked + .label .switch {
    right: 0px;
  }
`

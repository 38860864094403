import React, { useEffect, useRef, useState } from 'react';
import * as S from './styles/styles';
import { Address, Country } from '../../types/types';
import { ADDRESS_CARD_UI_STRING } from '../../constants';
import { Menu, MenuItem } from '@mui/material';
import { TOGGLE_BUTTON_LABELS } from '../constants';
import { getCountryName } from '../../helpers/utils';

interface AddressCardProps {
    address: Address;
    handleMenuClick: (event: React.MouseEvent<HTMLElement>, address: Address) => void;
    anchorEl: HTMLElement | null;
    open: boolean;
    handleClose: () => void;
    currentAddress: Address | null;
    handleEditAddress?: () => void;
    handleRemoveAddress?: (addressId: number | undefined) => void;
    handleSetAsDefaultShipping?: () => void;
    handleSetAsDefaultBilling?: () => void;
    addressType?: 'billing' | 'shipping';
    isAlreadyDefaultBilling?: boolean;
    isAlreadyDefaultShipping?: boolean;
    canRemove?: boolean;
    countryList: Country[];
}

const AddressCardWithMenuItems: React.FC<AddressCardProps> = ({
    address,
    handleMenuClick,
    anchorEl,
    open,
    handleClose,
    currentAddress,
    handleEditAddress,
    handleRemoveAddress,
    handleSetAsDefaultShipping,
    handleSetAsDefaultBilling,
    addressType,
    canRemove,
    countryList
}) => {
    const [countryName, setCountryName] = useState('');
    const [isExpanded, setIsExpanded] = useState(false);
    const [showReadMore, setShowReadMore] = useState(false);
    const contentRef = useRef<HTMLDivElement>(null);
    const [ isAlreadyDefaultBilling, setIsAlreadyDefaultBilling ] = useState<boolean>();
    const [ isAlreadyDefaultShipping, setIsAlreadyDefaultShipping ] = useState<boolean>();

    useEffect(() => {
        if (address?.default_billing !== undefined) {
            setIsAlreadyDefaultBilling(address.default_billing);
        }
        if (address?.default_shipping !== undefined) {
            setIsAlreadyDefaultShipping(address.default_shipping);
        }
    }, [address?.default_billing, address?.default_shipping]);
    useEffect(() => {
        //To Set The Height of Each Card based on the Content
        const checkContentHeight = () => {
            if (contentRef.current) {
                const fullHeight = contentRef.current.scrollHeight;
                setShowReadMore(fullHeight > 150);
            }
        };

        checkContentHeight();

        window.addEventListener('resize', checkContentHeight);

        return () => window.removeEventListener('resize', checkContentHeight);
    }, [address]); // Add other dependencies if other dynamic content can change height

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <S.AddressCard>
            {
                address?.default_billing && address?.default_shipping ? (
                    // If the address is both default billing and shipping, show "Default" label
                    <S.DefaultLabel>{ADDRESS_CARD_UI_STRING.default_label.en}</S.DefaultLabel>
                ) : (
                    // Otherwise, check for individual cases
                    <>
                        {address?.default_billing && (
                            // If the address is only default billing, show "Billing" label
                            <S.DefaultLabel>{ADDRESS_CARD_UI_STRING.default_label_billing.en}</S.DefaultLabel>
                        )}
                        {address?.default_shipping && (
                            // If the address is only default shipping, show "Shipping" label
                            <S.DefaultLabel>{ADDRESS_CARD_UI_STRING.default_label_shipping.en}</S.DefaultLabel>
                        )}
                    </>
                )
            }

            <S.OptionsIcon onClick={(event) => handleMenuClick(event, address)} />
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
            >
                {handleEditAddress && (
                    <MenuItem onClick={() => {
                        handleClose();
                        handleEditAddress();
                    }}>{ADDRESS_CARD_UI_STRING.edit.en}</MenuItem>
                )}
                {handleRemoveAddress && (
                    <MenuItem onClick={() => {
                        handleClose();
                        handleRemoveAddress(currentAddress?.id);
                    }}
                    disabled={!canRemove}
                    >{ADDRESS_CARD_UI_STRING.remove.en}</MenuItem>
                )}
                {handleSetAsDefaultBilling && (
                    <MenuItem onClick={() => {
                        handleSetAsDefaultBilling();
                        handleClose();
                    }}
                        disabled={currentAddress?.default_billing}
                    >{ADDRESS_CARD_UI_STRING.set_as_default_billing.en}</MenuItem>
                )}
                {handleSetAsDefaultShipping && (
                    <MenuItem onClick={() => {
                        handleSetAsDefaultShipping();
                        handleClose();
                    }}
                        disabled={currentAddress?.default_shipping}
                    >{ADDRESS_CARD_UI_STRING.set_as_default_shipping.en}</MenuItem>
                )}
            </Menu>
            <S.AddressDetailsContainer ref={contentRef} isExpanded={isExpanded} shouldLimitHeight={!isExpanded && showReadMore}>
                <S.CardTitle>{address?.extension_attributes?.address_encryption_attribute_data?.find((attr) => attr.attribute_code === 'firstname')?.value ?? ''} {address?.extension_attributes?.address_encryption_attribute_data?.find((attr) => attr.attribute_code === 'lastname')?.value ?? ''}</S.CardTitle>
                <S.CardText>{ADDRESS_CARD_UI_STRING.street.en} {address?.extension_attributes?.address_encryption_attribute_data?.find((attr) => attr.attribute_code === 'street')?.value ?? ''}</S.CardText>
                <S.CardText>{ADDRESS_CARD_UI_STRING.city.en} {address?.city}</S.CardText>
                <S.CardText>{ADDRESS_CARD_UI_STRING.state_province_area.en} {address?.region?.region}</S.CardText>
                <S.CardText>{ADDRESS_CARD_UI_STRING.zip_code.en} {address?.postcode}</S.CardText>
                <S.CardText>{ADDRESS_CARD_UI_STRING.country.en} {getCountryName(countryList,address?.country_id)[0]?.full_name_locale}</S.CardText>
                <S.CardText>{ADDRESS_CARD_UI_STRING.telephone.en} {address?.extension_attributes?.address_encryption_attribute_data?.find((attr) => attr.attribute_code === 'telephone')?.value ?? ''}</S.CardText>
            </S.AddressDetailsContainer>
            {showReadMore && !isExpanded && (
                <span
                    className="toggle-details-button"
                    onClick={toggleExpand}
                >
                    {TOGGLE_BUTTON_LABELS.show}
                </span>
            )}
            {isExpanded && <span
                className="toggle-details-button"
                onClick={toggleExpand}
            >
                {TOGGLE_BUTTON_LABELS.hide}
            </span>}
        </S.AddressCard>
    );
};

export default AddressCardWithMenuItems;

import styled, { css } from "styled-components";

export const Content = styled.div`
  ${(p: any) => {
    return css`
      padding: 20px;
      .no-item-text {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 110%;
        color: #6f6855;
      }
      .wishlist_header {
        display: flex;
        flex-direction: row;
        margin-bottom: 20px;
        button {
          margin: 0 5px;
        }
        p {
          margin: 0;
          padding: 14px 0px;
          font-size: 16px;
          line-height: 24px;
          font-weight: 400;
          color: #4e4e4e;
        }
        .section_all {
          display: flex;
          align-items: center;
          button {
            padding: 6px 12px;
          }
          input {
            width: 22px;
            height: 22px;
            margin-right: 5px;
          }
          .text {
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;
          }
          .custom-checkbox-container {
            display: flex;
            align-items: center;
            cursor: pointer;
            gap: 8px;
            margin-right: 10px;
            padding: 10px 0;
          }

          .custom-checkbox {
            opacity: 0;
            position: absolute;
          }

          .checkbox-indicator {
            width: 16px;
            height: 16px;
            border: 1px solid #6f6855;
            border-radius: 4px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: background-color 0.2s, border-color 0.2s;
          }

          .checkbox-indicator.checked {
            background-color: white;
          }

          .checkbox-indicator::after {
            content: "";
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: #6f6855;
            visibility: hidden;
          }

          .checkbox-indicator.checked::after {
            visibility: visible;
          }

          .checkbox-label {
            font-family: "Poppins", sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 130%;
            letter-spacing: 0.03em;
            color: #6f6855;
          }

          button:disabled {
            background: #d9d9d9;
          }
        }
      }

      .wishList_container {
        display: flex;
        width: 100%;
        gap: 20px;
        padding: 4px 0px 48px;
        flex-direction: row;
        flex-wrap: wrap;

        .wishlist_card {
          display: flex;
          flex-direction: column;
          align-items: center;

          .image_section {
            width: 177px;
            height: 170px;
            position: relative;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              border-radius: 23px;
            }
            .cross_icon {
              position: absolute;
              right: 0.5rem;
              top: 5px;
            }
          }

          .details_section {
            width: 170px;
            text-align: center;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            p {
              margin: 0;
            }
            .name-price {
              display: flex;
              flex-direction: column;
              flex-wrap: nowrap;
              align-items: center;
              .name {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 21px;
                text-align: center;
                letter-spacing: 0.05em;
                color: #1c1c1c;
                text-overflow: ellipsis;
              }

              .price {
                margin: 0 auto;
                font-family: "Poppins";
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 130%;
                color: #6f6855;
              }
            }

            .category {
              font-size: 12px;
              line-height: 18px;
              font-weight: 500;
              color: #b9b9b9;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }
      .add_to_cart_btn,
      .add_all_to_cart_btn {
        border-radius: 10px;
        background: #6f6855;
        color: #f8f7f2;
        border: none;
        padding: 8px 16px 8px 16px;
        font-size: 14px;
        line-height: 25px;
        font-weight: 500;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 6px;
        width: max-content;
        svg {
          width: 1rem;
          height: 1rem;
          path {
            color: #f8f7f2;
          }
        }
      }
    `;
  }}
`;

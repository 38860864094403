import React from "react";
import * as S from "../styles/styles";
import { checkoutHeaderProps } from "../types/types";
import { BACK_TO_CART_LABEL } from "../../../../lib/constant";
import { useNavigate } from "react-router-dom";
import { CART_PAGE_PATH } from "../../../../routes/routes-constant";

const CheckoutHeader: React.FC<checkoutHeaderProps> = ({headerText}) => {
   const navigate =useNavigate();
   
    return (
    <S.Content>
        <div className="checkout-header">
           <div className="headertext">{headerText}</div> 
           {/* <div className="headertext" onClick={()=>{navigate(CART_PAGE_PATH)}}>{BACK_TO_CART_LABEL}</div>  */}
        </div>
    </S.Content>
  );
};

export default CheckoutHeader;

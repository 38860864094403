import { useCallback, useEffect, useState } from "react";
import { doActionGet, doActionPost } from "../../../helpers/httpRequest";
import apiList, { USER_TYPE } from "../../../lib/constant";
import { ASSISTANCE_TEXT_CONSTANTS, ENQUIRY_STATUSES } from "./constants";
import { CustomerData } from "./types";
import * as S from "./styles/customerStyles";
import { Loading } from "../../atoms/loading/Loading";
import { requestAssistance_gtm } from "../../../helpers/GTM/assistanceGTM";
import { getLocalStorage } from "../../../helpers/localStorageUtil";
import { useNavigate } from "react-router-dom";

function CustomerAssistance() {
    const [enquiryID, setEnquiryID] = useState('')
    const [enquiryData, setEnquiryData] = useState<CustomerData>()
    const [enquiries, setEnquiries] = useState<CustomerData[]>()
    const [isLoading, setIsLoading] = useState(false)
    const [checkStatus, setCheckStatus] = useState(false)
    const navigate = useNavigate();

    const getEnquiryById = useCallback(async (id = '') => {
        try {
            const response = await doActionGet({ url: apiList.getEnquiryById(id ?? enquiryID), userType: USER_TYPE.Customer });
            setEnquiryData(response?.data);
            if (response?.data?.status === ENQUIRY_STATUSES.CLOSE) navigate(0)
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }, [enquiryID]);

    const createEnquiry = async () => {
        try {
            const response = await doActionPost({ url: apiList.createEquiry, userType: USER_TYPE.Customer });
            setEnquiryID(String(response?.data ?? ''))
            const cartData = JSON.parse(getLocalStorage("cartData"));
            const cognito_user_id = cartData?.customer?.custom_attributes?.find((attr: { attribute_code: string; }) => attr.attribute_code === 'cognito_user_id')?.value ?? "";
            const email = cartData?.customer?.email ?? ""
            requestAssistance_gtm({ request_ID: response?.data, cognito_user_id, email })
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    useEffect(() => {
        (async function getEnquiries() {
            setIsLoading(true)
            try {
                const response = await doActionGet({ url: apiList.getEnquiries, userType: USER_TYPE.Customer });
                const items: CustomerData[] = response?.data?.items
                if (items?.length) {
                    setEnquiries(items)
                    if ([ENQUIRY_STATUSES.NEW, ENQUIRY_STATUSES.WORKING].includes(items?.at(-1)?.status ?? '')) {
                        setEnquiryID(String(items?.at(-1)?.id))
                        getEnquiryById(String(items?.at(-1)?.id))
                    }
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setIsLoading(false);
            }
        })();
    }, [getEnquiryById])


    const requestID = enquiryData ? enquiryData?.display_id : enquiries?.filter(enq => String(enq.id) === enquiryID)?.[0]?.display_id
    const refreshData = () => {
        setCheckStatus(true)
        getEnquiryById(enquiryID)
    }
    const pageData = checkStatus ? { title: ASSISTANCE_TEXT_CONSTANTS.arrival.en, text: [ASSISTANCE_TEXT_CONSTANTS.wait.en], buttonText: ASSISTANCE_TEXT_CONSTANTS.refresh_button.en, onClick: () => getEnquiryById(enquiryID), firstLoad: false }
        : enquiryID ? { title: ASSISTANCE_TEXT_CONSTANTS.personalized_assistance.en, text: [ASSISTANCE_TEXT_CONSTANTS.track_message.en], buttonText: ASSISTANCE_TEXT_CONSTANTS.track_button.en, onClick: refreshData, firstLoad: false }
            : { title: ASSISTANCE_TEXT_CONSTANTS.assistance.en, text: [ASSISTANCE_TEXT_CONSTANTS.assistance_message1.en, ASSISTANCE_TEXT_CONSTANTS.assistance_message2.en], buttonText: ASSISTANCE_TEXT_CONSTANTS.help_button.en, onClick: createEnquiry, firstLoad: true }
    const { title, text, buttonText, onClick, firstLoad } = pageData
    const AssistanceComponent = <>
        <div>
            < div className="title" >{title}</div>
            <>{(checkStatus && enquiryData?.representative_name)
                ?
                <>
                    <div className="info">{ASSISTANCE_TEXT_CONSTANTS.assistant_name.en}{enquiryData?.representative_name}</div>
                </>
                : <div>{text.map(msg => <p key={msg} className="message">{msg}</p>)}{requestID && <div>{`Request ID : ${requestID}`}</div>}</div>
            }</>
        </div>
        <button className="assist-button" onClick={onClick}>{buttonText}</button></>

    if (isLoading) return <Loading />
    return <S.Content>
        <div className="assistance-container">
            {AssistanceComponent}
        </div></S.Content>
}

export default CustomerAssistance
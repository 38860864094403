import React, { useEffect, useState } from "react";
import * as S from "../styles/styles";
import CheckoutHeader from "../checkout-header";
import CheckoutProgressBar from "../../../molecules/checkout-progress-bar";
import apiList, { ALERT_STATUS, BANK_TRANSFER_LABEL, CASH_ON_DELIVERY_CODE, CHECKOUT_FIRST_STEP_HEADING, CHECQUE_LABEL, CHEQUE_PURCHASE_ORDER_CODE, PAYMENT_METHOD, PAYMENT_METHOD_SUB_HEADING, PURCHASE_ORDER_CODE, SOMETHING_WENT_WRONG, STRIPE_PAYMENT_METHOD_CODE, USER_TYPE, WANT_SPLIT, WANT_SUB_SPLIT, progressBar } from "../../../../lib/constant";
import { useNotification } from "../../../../hooks/useNotification";
import { doActionGet } from "../../../../helpers/httpRequest";
import { Loading } from "../../../atoms/loading/Loading";
import { availablePaymentMethodType, paymentIconMappingType } from "../types/types";
import CustomerCardListing from "./card-listing";
import selectedCheckmo from '../../../../globals/svg/selectedCheckmo.svg';
import checkmo from '../../../../globals/svg/checkmo.svg'
import selectedstripe_payments from "../../../../globals/svg/selectedstripe_payments.svg";
import stripe_payments from "../../../../globals/svg/stripe_payments.svg";
import selectedPurchaseorder from "../../../../globals/svg/selectedPurchaseorder.svg";
import purchaseorder from "../../../../globals/svg/purchaseorder.svg";
import chequeIcon from "../../../../globals/svg/chequeIcon.svg";
import selectedChequeIcon from "../../../../globals/svg/selectedChequeIcon.svg";
import { useLocation } from "react-router-dom";
import { CHECKOUT_PAYMENT_CARD_FORM_PATH, CHECKOUT_PAYMENT_PATH } from "../../../../routes/routes-constant";
import CustomerPaymentForm from "./payment-form";
import NetBanking from "./net-banking";
import Cash from "./cash";
import { getPaymentMethodTitle, gtmDataConstructor } from "../helper";
import Cheque from "./cheque";
import { CART_CONSTANTS } from "../../employee-special-order-cart/special-cart/constant";
import { FormControl, MenuItem, Select } from "@mui/material";
import useFetchData from "../../../../hooks/useFetchData";
import { CART_API_URL } from "../../cart/constants";
import { CartDetails } from "../../cart/types";
import { getStaticConstantValue } from "../../../../helpers/utlis";


const CustomerCheckoutPayment: React.FC = () => {
    const [isAPICallRunning,setIsAPICallRunning] =useState(false);
    const { showAlert } = useNotification();
    const [availablePaymentMethod,setAvailablePaymentMethod] =useState<availablePaymentMethodType[]>([]);
    const [selectedPaymentMethod,setSelectedPaymentMethod] = useState<String>(STRIPE_PAYMENT_METHOD_CODE);
    const location =useLocation();
    const [wantSplit,setWantSplit] = useState(false);
    const { isLoading, data: cartItems } = useFetchData(CART_API_URL, USER_TYPE.Customer, false);
    const selectedAdvancePayment = (cartItems as unknown as CartDetails)?.extension_attributes?.selected_advance_payment_option ?? '';
    const isCartLocked = (cartItems as unknown as CartDetails)?.extension_attributes?.locked ?? 0;
    const [hasAdvancePaymentOption,setHasAdvancePaymentOption] = useState<number|boolean>(false);
    const paymentSlabPercentage = getStaticConstantValue("customer_split_payment")?.split(",").map(value => ({
      value: value.toString(),
      label: `${value} %`
  }));


  const [selectedAdvancePaymentOption, setSelectedAdvancePaymentOption] = useState('');
    //This will be revisit  once backend work be done
    const paymentMethodCodeAndIconMapping = [
      { key: CASH_ON_DELIVERY_CODE, value: checkmo},
      { key: STRIPE_PAYMENT_METHOD_CODE, value: stripe_payments },
      { key: PURCHASE_ORDER_CODE, value: purchaseorder },
      {key:CHEQUE_PURCHASE_ORDER_CODE, value:chequeIcon}
    ];

    const paymentMethodSelectedCodeAndIconMapping = [
      { key: CASH_ON_DELIVERY_CODE, value: selectedCheckmo},
      { key: STRIPE_PAYMENT_METHOD_CODE, value: selectedstripe_payments },
      { key: PURCHASE_ORDER_CODE, value: selectedPurchaseorder },
      {key:CHEQUE_PURCHASE_ORDER_CODE, value:selectedChequeIcon}
    ];

    useEffect(()=>{
      gtmDataConstructor("add_payment_info",STRIPE_PAYMENT_METHOD_CODE);
    },[])

    useEffect(()=>{
        setHasAdvancePaymentOption(!(Boolean(selectedAdvancePayment) && isCartLocked));
    },[selectedAdvancePayment,isCartLocked])
    
    // Function to retrieve value based on key
    function getValueByKey(arr:paymentIconMappingType[], key:string) {
      const pair = arr.find(item => item.key === key);
      return pair ? pair.value : undefined;
    }

    function modifyPaymentMethods(arr:availablePaymentMethodType[]) {
      let purchaseOrderExists = false;
      for (const item of arr) {
          if (item.code === PURCHASE_ORDER_CODE) {
              purchaseOrderExists = true;
              item.title = BANK_TRANSFER_LABEL;
          }
      }
      if (purchaseOrderExists) {
          arr.push({
              "code": CHEQUE_PURCHASE_ORDER_CODE,
              "title": CHECQUE_LABEL
          });
      }
      return arr;
  }
  

    useEffect(()=>{
      if(!location?.state?.isSecondPayment){
        setIsAPICallRunning(true);
        doActionGet({
          url: apiList.getAvailablePaymentMethods,
          userType:USER_TYPE.Customer
        })?.then((resp: any) => {
          let availablePaymentMethod = resp?.data ? resp?.data?.reverse() :[];
            setAvailablePaymentMethod(modifyPaymentMethods(availablePaymentMethod));
        }
        ).catch((error: any) => {
          console.log("error msg", error);
          showAlert(SOMETHING_WENT_WRONG, ALERT_STATUS.error);
        }).finally(()=>{
          setIsAPICallRunning(false);
        })
      }
    },[])


    const paymentMethodSelector =(code:string)=>{
      gtmDataConstructor("add_payment_info",code);
      setSelectedPaymentMethod(code);
    }

    return (
    <S.Content>
         {isAPICallRunning && <Loading />}
        <div className="customer-checkout">
        { !location?.state?.isSecondPayment &&<> <CheckoutHeader headerText={CHECKOUT_FIRST_STEP_HEADING}/>
            <CheckoutProgressBar id={3} title={progressBar[2].title}/> </>}
           { location.pathname===CHECKOUT_PAYMENT_PATH &&
            <div>
           {hasAdvancePaymentOption && <div className="split-section-box">
              <div className="address-check-section split-box-section">
                <label className="split-text">{WANT_SPLIT}</label>
                <input className="gift-checkbox" type="checkbox" checked={wantSplit}
                onChange={()=>{setWantSplit((prev)=>!prev)}}/>
              </div>
              <div className="split-sub-text">{WANT_SUB_SPLIT}</div>
              {wantSplit && <div className="payment-slab">
              <div className="payment-label split-text">{CART_CONSTANTS.advance_payment_slab.en}</div>
              <FormControl fullWidth className="payment-slab-dropdown" size='small'>
                <Select
                    displayEmpty
                    labelId="title-label"
                    id="advance-payment-slab"
                    name="advance-payment-slab"
                    value={selectedAdvancePaymentOption || ''}
                    onChange={(e) => { setSelectedAdvancePaymentOption(e.target.value); }}
                    variant="outlined"
                    inputProps={{ 'aria-label': 'Without label' }}
                    renderValue={(selected) => {
                        if (!selected) {
                            return <span>{CART_CONSTANTS.select_payment_percentage.en}</span>;
                        }
                        return paymentSlabPercentage?.find((r:any) => r.value === selected)?.label || CART_CONSTANTS.select_payment_percentage.en;
                    }}
                >
                    <MenuItem disabled value="">
                        <span>{CART_CONSTANTS.select_payment_percentage.en}</span>
                    </MenuItem>
                    {paymentSlabPercentage?.map((option:any) => (
                        <MenuItem key={option?.value} value={option?.value}>
                            {option?.label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
              </div>}
            </div>}
            <div className="customer-payment-section">
              <div className="first-address-row first-row">{PAYMENT_METHOD}</div>  
                 {availablePaymentMethod && availablePaymentMethod.length>0 &&
                  <div className="payment-method-list-section">
                      {availablePaymentMethod.map((item:availablePaymentMethodType,index:number)=>{
                          return (
                            <React.Fragment key={item?.code}>
                          <div className={selectedPaymentMethod===item.code ? "payment-method-section-selected":"payment-method-section"} onClick={()=>{ paymentMethodSelector(item.code)}}>
                          <div className="code-image"><img  src={getValueByKey(selectedPaymentMethod===item.code?paymentMethodSelectedCodeAndIconMapping:paymentMethodCodeAndIconMapping,item.code)} alt="PaymentIcon" className="payment-icon-image" /></div>
                          <div className="code-title">{item.title}</div>
                          </div>
                          </React.Fragment>
                          )
                      })}
                  </div>
                  }
            </div>
            </div>
            }
            <>
                  {selectedPaymentMethod === STRIPE_PAYMENT_METHOD_CODE &&   location.pathname===CHECKOUT_PAYMENT_PATH &&<CustomerCardListing isSecondPayment={false} methodTitle={getPaymentMethodTitle(availablePaymentMethod,STRIPE_PAYMENT_METHOD_CODE)} paid_percent={selectedAdvancePayment?String(selectedAdvancePayment):selectedAdvancePaymentOption}/>}
                  {selectedPaymentMethod === PURCHASE_ORDER_CODE && <NetBanking isSecondPayment={false}  methodTitle={getPaymentMethodTitle(availablePaymentMethod,PURCHASE_ORDER_CODE)} paid_percent={selectedAdvancePayment?String(selectedAdvancePayment):selectedAdvancePaymentOption}/>}
                  {selectedPaymentMethod === CASH_ON_DELIVERY_CODE && <Cash isSecondPayment={false}  methodTitle={getPaymentMethodTitle(availablePaymentMethod,CASH_ON_DELIVERY_CODE)} paid_percent={selectedAdvancePayment?String(selectedAdvancePayment):selectedAdvancePaymentOption}/>}
                  {selectedPaymentMethod === CHEQUE_PURCHASE_ORDER_CODE && <Cheque isSecondPayment={false}  methodTitle={getPaymentMethodTitle(availablePaymentMethod,PURCHASE_ORDER_CODE)} paid_percent={selectedAdvancePayment?String(selectedAdvancePayment):selectedAdvancePaymentOption}/>}
                  </>
                  
                  {selectedPaymentMethod === STRIPE_PAYMENT_METHOD_CODE &&  location.pathname===CHECKOUT_PAYMENT_CARD_FORM_PATH && <CustomerPaymentForm isSecondPayment={location?.state?.isSecondPayment}  methodTitle={getPaymentMethodTitle(availablePaymentMethod,STRIPE_PAYMENT_METHOD_CODE)} paid_percent={selectedAdvancePayment?String(selectedAdvancePayment):selectedAdvancePaymentOption}/>}
             
        </div>
    </S.Content>
  );
};

export default CustomerCheckoutPayment;

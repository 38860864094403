import React, { useEffect, useState } from "react";
import * as S from "./styles"
import { CONTINUE_SHOPPING_LABEL } from "../../../lib/constant";


const CheckoutButton: React.FC<{isDisabled:boolean,checkoutHandler:()=>void,label:string}> = ({isDisabled=false,checkoutHandler,label}) => {
  
  return (
    <S.Content>
     <div className={label===CONTINUE_SHOPPING_LABEL?"moveToCartBtn continue-shopping": "moveToCartBtn"}>
            <button disabled={isDisabled} onClick={checkoutHandler}>{label}</button>
      </div>
    </S.Content>

  );
};

export default CheckoutButton;

import React, { useEffect } from 'react';
import { getLocalStorage } from '../../../helpers/localStorageUtil';
import { useAuth } from '../../../Context/AuthContext';
import useWebSocket from '../../../hooks/useWebSocket';

const WebSocketManager = () => {
    const { isUserLoggedIn, isEmployeeLoggedIn } = useAuth();
    const cognitoUserId = ((isUserLoggedIn || isEmployeeLoggedIn)&&getLocalStorage('cognitoId')) ? JSON.parse(getLocalStorage('cognitoId')) : null;

    useWebSocket(cognitoUserId);

    return null;
};

export default WebSocketManager;
export const BILLING_ADDRESS_UI = {
    billing_address_title: {
        en: "Billing Address",
        ar: "عنوان وصول الفواتير"
    },
    add_new: {
        en: "Add New",
        ar: "إضافة جديد"
    },
    saved_addresses: {
        en: "saved addresses",
        ar: "العناوين المحفوظة"
    },
    edit: {
        en: "Edit",
        ar: "تعديل"
    },
    remove: {
        en: "Remove",
        ar: "إزالة"
    },
    no_address_selected: {
        en: "No address selected for editing",
        ar: "لم يتم تحديد عنوان للتحرير"
    },
    default_label: {
        en: "Default",
        ar: "افتراضي"
    },
    street: {
        en: "Street:",
        ar: "الشارع:"
    },
    city: {
        en: "City:",
        ar: "المدينة:"
    },
    state_province_area: {
        en: "Emirates:",
        ar: "الدولة/المقاطعة/المنطقة:"
    },
    zip_code: {
        en: "Zip code:",
        ar: "الرمز البريدي:"
    },
    country: {
        en: "Country:",
        ar: "البلد:"
    },
    telephone: {
        en: "Telephone:",
        ar: "هاتف:"
    }
};

export const BILLING_ADDRESS_MESSAGES = {
    no_default_billing_address: {
        en: "No default billing address found.",
        ar: "لم يتم العثور على عنوان فوترة افتراضي."
    },
    fetch_address_error: {
        en: "Failed to fetch address data.",
        ar: "فشل في جلب بيانات العنوان."
    },
    fetch_customer_info_error: {
        en: "Failed to fetch customer personal info.",
        ar: "فشل في جلب معلومات العميل الشخصية."
    },
    no_address_selected: {
        en: "No address selected for editing",
        ar: "لم يتم تحديد عنوان للتحرير"
    },
    billing_address_title: {
        en: "Billing Address",
        ar: "عنوان وصول الفواتير"
    },
    add_new: {
        en: "Add New",
        ar: "إضافة جديد"
    }
};




import React, { useEffect, useState } from "react";
import { EmployeeLogout } from "../employee-logout/EmployeeLogout";
import { Accordion, AccordionSummary } from "../../molecules/accordion/accordionHelpers";
import { NOTIFICATION_LISTING_ROUTE_PATH } from "../../../routes/routes-constant";
import { useNavigate } from "react-router-dom";
import { LABELS } from "../my-account/constants";
import * as S from "./styles/styles";
import { isCafeteriaManager } from "../../../helpers/utlis";




const EmployeeProfile: React.FC = () => {
   const navigate = useNavigate();
   
    return (
        <S.Content>
        {!isCafeteriaManager() && <div className="accordian-section">
        <Accordion className="accordion">
        <AccordionSummary onClick={() => { navigate(NOTIFICATION_LISTING_ROUTE_PATH) }} expandIcon={<></>} >
            <div className="accordion-text">{LABELS.Notifications}</div>
        </AccordionSummary >
          </Accordion>
        </div>}
        <div className="accordian-section">
        <Accordion onChange={(event) => event.preventDefault()} className="accordion">
            <AccordionSummary expandIcon={<></>}>
                <div className="accordion-text"> <EmployeeLogout /></div>
            </AccordionSummary>
        </Accordion>
        </div>
        </S.Content>
  );
};

export default EmployeeProfile;

import styled, { css } from "styled-components";
import { media } from "../../../../helpers/breakpoints";
import BG from '../../../../globals/images/Background/BG.svg';
export const Content = styled.div<any>`
  ${(p: any) => {
    return css`
    .notification-page{
        padding-left:30px;
        padding-right:30px;
        margin-top:30px;
    }
    .notification-section{
        background: var(--white);
        box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.05);
        border-radius:10px;
        margin-left:30px;
        margin-right:30px;
        padding-bottom:16px;
    }
    .notication-heading{
        font-weight: 500;
        font-size: 16px;
        line-height: 17.6px;
        color:rgba(111, 104, 85, 1);
        margin-bottom:24px;
    }
    .notication-counts{
        display: flex;
        column-gap: 10px;
        justify-content: flex-end;
        font-size: 14px;
    }
    .horizontal-line {
        border-bottom: 1px solid var(--sku-font-color); /* Thickness and color of the line */
        margin-top: 10px; /* Space between the div and the line */
    }
    .notif-section{
       padding:16px;
    }
      .notif-title{
      font-weight:400;
      font-size: 16px;
      line-height:20.8px;
      display:flex;
      justify-content:space-between;
      margin-bottom:4px;
    
      }
      .img-section{
      margin-right:10px;
      }
      .seen{
     opacity:50%;
      }
      .notif-message-seen{
       font-weight:500;
      font-size: 10px;
      line-height:15px;
      color:rgba(182, 178, 166, 1);
      }
       .notif-message-unseen{
       font-weight:500;
      font-size: 10px;
      line-height:15px;
      color:rgba(182, 178, 166, 1);
      }
       .action-button {
    background-color: var(--sand-button-color);
    color: white;
    font-size: 10px;
    font-weight:500;
    line-height:15px;
    border: none;
    padding: 4px 16px 4px 16px;
    border-radius: 40px;
    cursor: pointer;
    
  }
  .action-section{
 margin-top: 11px;
    display: flex;
    column-gap: 21px;
    justify-content: flex-end;

  }
  .action-button:hover {
    background-color: var(--sand-button-color-on-hover);
  }
   .show-more-button-section{
   display:flex;
   justify-content:center;
   margin-top:15px;

   }
   .show-more-link{
   color:black;
   }
   .notif-permission-page{
    height: 100vh;
    width: 100%;
    background-image: url(${BG});
    background-size: cover; /* Ensures image covers the entire container */
    background-position: center; /* Centers the image horizontally and vertically */
    position: relative;
   
    overflow: hidden;
   }
    .notif-content-section{
    margin-top:200px;
     display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    }
    .notification-cross-icon{
        display: flex;
    justify-content: flex-end;
    margin-top: 28px;
    margin-right: 28px;
    }
    .notif-permission-heading{
      font-weight: 500;
      font-size: 24px;
      line-height: 26.4px;
      margin-bottom:24px;
    }
    .notif-permission-subheading{
     font-weight: 400;
      font-size: 16px;
      line-height: 20.8px;
      margin-bottom:92px;
      color:rgba(111, 104, 85, 1);
      width:324px;
      text-align:center;
    }
     .received-button-label{
     width: 338px;
      padding :7px 52px 7px 52px;
      border-radius:100px;
      border:none;
      height:50px;
       font-weight:600;
      font-size:16px;
      line-height:24px;
      color:white;
      background-color:rgba(111, 104, 85, 1);
      }
      .received-button-label:disabled{
      opacity:50%;
      }
      .button-box{
      display:flex;
      justify-content:center;
     margin-bottom:16px;
      }
     .notif-later-text{
      font-weight:600;
      font-size:16px;
      line-height:24px;
      color:rgba(111, 104, 85, 1);
      display: inline-block;
      padding-bottom: 4px;
      border-bottom: 2px solid rgba(111, 104, 85, 1);
     }
      .notif-req-heading{
       font-weight:400;
      font-size:16px;
      line-height:20.8px;
      color:rgba(28, 28, 28, 1);
      margin-bottom:8px;
      }
      .notification-req-section{
       background: var(--white);
        box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.05);
        border-radius:10px;
        padding:16px;
        margin-left:30px;
        margin-right:30px;
        margin-top:30px;
      }
      .notif-later-sub-text{
       font-weight:500;
      font-size:10px;
      line-height:15px;
      color:rgba(182, 178, 166, 1);
      margin-top:8px;
      }
    `
  }}
`

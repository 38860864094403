import React from 'react';
import SpecialOrderCustomerList from './customer-listing';

const EmployeeSpecialOrderCart: React.FC = () => {

  return (
    <>
        <SpecialOrderCustomerList/>
    </>
  );
};

export default EmployeeSpecialOrderCart;

import styled, { css } from "styled-components";

export const Content = styled.div<any>`
  ${(p: any) => {
    return css`
      .icon-container {
        position: relative;
        display: inline-block;
      }

      .count {
        position: absolute;
        top: -8px;
        right: -10px;
        color: var(--icon-count-color);
        border: 1px solid var(--icon-count-color);
        border-radius: 50%;
        // padding: 0px 4px;
        background: white;
        font-size: 8px;
        width: 15px;
        height: 15px;
      text-align: center;
      }
    `;
  }}
`;
